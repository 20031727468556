import { useSelector } from 'react-redux'

import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectOneClickPaymentMethod,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionId,
  selectSubscriptionIntroDifAmount,
  selectSubscriptionIntroDifPercents,
  selectSubscriptionPaywallDiscountPercent,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectTrialSubscriptionFullPriceBeforeDiscount,
} from 'modules/purchaseSubscription/redux/selects'

export const usePaymentStore = () => {
  const fullPrice = useSelector(selectSubscriptionFullPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedSubscription = useSelector(selectSubscription)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPriceBeforeDiscount = useSelector(
    selectTrialSubscriptionFullPriceBeforeDiscount,
  )
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currency = useSelector(selectCurrency)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const discountPercentage = useSelector(selectSubscriptionIntroDifPercents)
  const product = useSelector(selectProductId)
  const oneClickMethod = useSelector(selectOneClickPaymentMethod)
  const paymentMethod = useSelector(selectPaymentMethod)
  const paywallDiscountPercentage = useSelector(
    selectSubscriptionPaywallDiscountPercent,
  )

  return {
    currency,
    discountAmount,
    discountPercentage,
    fullPrice,
    oneClickMethod,
    periodName,
    periodQuantity,
    product,
    selectedSubscription,
    selectedSubscriptionId,
    threeDSecureIframeUrl,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    trialPeriodDays,
    paymentMethod,
    paywallDiscountPercentage,
  }
}
