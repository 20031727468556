import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CaloriesComparison } from 'components/CaloriesComparison'
import { KitPageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledCaloriesEqualityV1 as S } from './CaloriesEqualityV1.styles'

const T_PREFIX = 'onboarding.caloriesEqualityV1'

export const CaloriesEqualityV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const onContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      paddingTop={12}
      hasContinueButton
      onContinueButtonClick={() => onContinue('')}
    >
      <CaloriesComparison />
      <S.Title>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </S.Title>
      <S.Subtitle>
        <Trans i18nKey={`${T_PREFIX}.subtitle`} />
      </S.Subtitle>
    </KitPageContainer>
  )
}
