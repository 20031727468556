import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import fingerUpIcon from 'assets/images/finger-up.png'
import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import { INTEGER_NUMBER_REGEX } from 'root-constants'

import { StyledAge as S } from '../Age.styles'
import { MAX_AGE, MIN_AGE, QUESTION } from '../constants'

export const AgeV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const [age, setAge] = useState({
    value: '',
    isValid: false,
    rangeOverflow: false,
  })
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || INTEGER_NUMBER_REGEX.test(value)) {
      setAge({
        value,
        isValid: validity.valid,
        rangeOverflow: validity.rangeOverflow,
      })
    }
  }, [])

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleContinue(+age.value)
    },
    [age.value, handleContinue],
  )

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <form onSubmit={handleNextClick}>
      <Container>
        <PageTitle marginBottom={20}>{t('onboarding.age.title')}</PageTitle>
        <S.InputContainerV2>
          <S.AgeInput
            type="number"
            min={MIN_AGE}
            max={MAX_AGE}
            pattern="\d*"
            value={age.value}
            onChange={handleChange}
            isContentCentered
            isValid={age.isValid}
            allowFloatNumbers={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {!isFocused && !age.value && <S.Placeholder />}
          {age.value && !age.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>
                {age.rangeOverflow
                  ? t('onboarding.age.errorMessage')
                  : t('onboarding.age.tooYoung')}
              </span>
            </S.ErrorMessage>
          )}
        </S.InputContainerV2>

        <S.InfoContainer>
          <S.InfoImage>
            <img src={fingerUpIcon} alt="info" />
          </S.InfoImage>
          <S.Info>
            <S.InfoTitle>{t('onboarding.age.info.title')}</S.InfoTitle>
            <S.InfoText>{t('onboarding.age.info.text')}</S.InfoText>
          </S.Info>
        </S.InfoContainer>

        <NavigationButton type="submit" disabled={!age.isValid}>
          {t('actions.continue')}
        </NavigationButton>
      </Container>
    </form>
  )
}
