import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TimerProvider } from 'contexts/TimerProvider'
import { buttonTheme } from 'storybook-ui'

import { CustomerReviewsV3 } from 'components/CustomerReviews'
import { KitPageContainer } from 'components/PageContainer'
import { PlanHighlights } from 'components/PlanHighlights'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchaseSubscription/constants'
import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'
import {
  selectSubscription,
  selectSubscriptionIntroDifPercents,
} from 'modules/purchaseSubscription/redux/selects'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/kit-purchase-email-v1-banner.png'

import { goTo } from 'browser-history'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { StyledPurchaseV5 as S } from './PurchaseV5.styles'

export const PurchaseV5: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const targetElementRef = useRef<HTMLHeadingElement>(null)

  const email = useSelector(selectUserOnboardingEmail)
  const discount = useSelector(selectSubscriptionIntroDifPercents)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)
  const selectedSubscription = useSelector(selectSubscription)

  const { hasPrices, handleShowPayment } = usePayment()
  const { title } = useDynamicPaywallConfig()
  const hasVatInfo = useVatInfo()
  const isTargetHidden = useTargetHiddenOnScroll(targetElementRef)
  const { subscriptionBlockTitle } = useDynamicPaywallConfig()

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  const handleContinue = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(nextPagePath)
  }, [dispatch, email, handleShowPayment, isPersonalDataAllowed, nextPagePath])

  return hasPrices ? (
    <TimerProvider id={pageId} time={FIFTEEN_MINUTES_IN_SECONDS}>
      <S.PositionedTimer isVisible={isTargetHidden} />
      <KitPageContainer
        paddingTop={16}
        hasContinueButton
        continueButtonContent={t('actions.continue')}
        onContinueButtonClick={handleContinue}
      >
        <S.Banner>
          <img src={banner} alt="banner" />
        </S.Banner>
        <S.Title>
          {(title && <Trans>{title}</Trans>) || (
            <Trans i18nKey="kitPurchaseEmailV1.title" />
          )}
        </S.Title>
        <S.PersonalizedPlanTimer />
        <PlanHighlights titleElementRef={targetElementRef} />
        <S.SubscriptionTitle>
          {subscriptionBlockTitle || t('purchase.subscription.title')}
        </S.SubscriptionTitle>
        <S.DiscountBlock>
          <S.DiscountBadge>
            <Trans
              i18nKey="purchase.subscription.discountLabel"
              values={{ discount }}
            />
          </S.DiscountBadge>
        </S.DiscountBlock>
        <S.SubscriptionsBlock titleMargin={16} hasTitle={false} />
        {selectedSubscription && (
          <S.Disclaimer selectedSubscription={selectedSubscription} />
        )}
        <S.Button
          type="button"
          width="100%"
          padding="16px"
          margin="0 auto 40px"
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          onClick={handleContinue}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        <CustomerReviewsV3 withTitle />
      </KitPageContainer>
    </TimerProvider>
  ) : (
    <Spinner />
  )
}
