import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledKitCancelOfferQuestionV1 = {
  Text: styled.p`
    font-size: 15px;
    margin-bottom: 24px;
    text-align: center;
    color: ${Color.GREY_101};

    span {
      font-size: 20px;
      color: ${Color.GREEN_300};
      font-weight: 700;
    }
  `,
  ImageWrapper: styled.div`
    position: relative;

    &::before,
    &::after {
      position: absolute;
      bottom: 12px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 20px;
      letter-spacing: 0.5px;
    }

    &::before {
      content: attr(data-period-before);
      left: 12px;
      background-color: ${Color.WHITE};
    }

    &::after {
      content: attr(data-period-after);
      right: 7px;
      background-color: ${Color.GREEN_300};
      color: ${Color.WHITE};
    }
  `,
  Image: styled.img`
    border-radius: 12px;
    margin-bottom: 12px;
    aspect-ratio: 343/212;
  `,
  Note: styled.p`
    color: ${Color.GREY_101};
    font-size: 13px;
    margin-bottom: 24px;
    text-align: center;
  `,
  Offer: styled.p`
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.GREY_101};

    span {
      color: #f90;
      font-size: 20px;
      font-weight: 700;
    }
  `,
}
