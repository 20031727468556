import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { getStripePurchaseCommonEventParams } from 'modules/purchaseSubscription/helpers/getStripePurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

export const getStripePurchaseSuccessEventParams = (state: IAppState) => {
  const {
    trialPeriodPrice,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
    price,
    productId,
    isPersonalDataAllowed,
  } = getStripePurchaseCommonEventParams(state)
  const email = selectUserOnboardingEmail(state)

  return {
    trialPrice: trialPeriodPrice,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    email,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
    price,
    productId,
    isPersonalDataAllowed,
  }
}
