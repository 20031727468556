import styled from 'styled-components'

import { Button } from 'components/Button'
import { FeaturedBlock } from 'components/FeaturedBlock'
import { SecurityInfoV2 } from 'components/SecurityInfo'

import giftImg from 'assets/images/gift.webp'

import { Color, PAGE_HORIZONTAL_PADDING } from 'root-constants'

export const StyledCancelOffer = {
  CustomerReviewsWrap: styled.div`
    h2 {
      text-align: center;
    }
  `,
  PlanDescription: styled.h3`
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.GREY_100};
      font-weight: 700;
    }
  `,
  SubscriptionsContainer: styled.div`
    background-color: ${Color.GREEN_700};
    margin: 0 -${PAGE_HORIZONTAL_PADDING}px 32px;
    padding: 24px 16px 32px;
  `,

  SecurityInfoV1: styled(SecurityInfoV2)`
    margin-bottom: 16px;
  `,

  SubscriptionsContainerV2: styled.div`
    margin: 40px 0 40px;

    p {
      margin-top: 24px;
      padding-bottom: 0;
    }
  `,
  FeaturedBlock: styled(FeaturedBlock)`
    margin-bottom: 40px;
    padding-top: 40px;
  `,
  FeaturedBlockV2: styled(FeaturedBlock)`
    margin-bottom: 40px;
  `,

  SpecialOffer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 12px 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: ${Color.WHITE};
    margin: 0 -${PAGE_HORIZONTAL_PADDING}px;

    img {
      width: 24px;
      height: 24px;
    }
  `,

  Banner: styled.div`
    padding: 18px 16px;
    background-image: url(${giftImg}),
      radial-gradient(
        71.78% 105.13% at 84% 59.39%,
        ${Color.YELLOW_200} 0%,
        ${Color.GREEN_750} 100%
      );
    background-size: contain, cover;
    background-position: top right;
    background-repeat: no-repeat;
    margin: 0 -${PAGE_HORIZONTAL_PADDING}px;
  `,

  BannerContent: styled.div<{ isFrLanguage?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 7px;
    max-width: ${({ isFrLanguage }) => (isFrLanguage ? 265 : 235)}px;
  `,

  PreviousDiscount: styled.span`
    font-size: 17px;
    font-weight: 600;
    color: ${Color.YELLOW_100};
    text-decoration-line: line-through;
    text-decoration-color: ${Color.YELLOW_100};
    text-decoration-thickness: 0.5px;

    span {
      padding: 0 4px;
      border-radius: 16px;
      border: 1px solid ${Color.YELLOW_100};
    }
  `,

  Title: styled.h1`
    color: ${Color.WHITE};
    font-size: 22px;
    font-weight: 700;

    span {
      display: inline-block;
      padding: 0 6px;
      border-radius: 16px;
      color: ${Color.GREEN_300};
      background-color: ${Color.WHITE};
      line-height: 28px;
    }
  `,

  Button: styled(Button)`
    width: initial;
    min-width: 147px;
    height: 48px;
    background-color: ${Color.GREEN_300};
    color: ${Color.WHITE};
    padding: 12px 22px;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 0 auto;
    text-transform: capitalize;
  `,
}
