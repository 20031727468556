const T_PREFIX = 'onboarding.feelGuiltyV1.options'

export const FEEL_GUILTY_V1_OPTIONS = [
  {
    value: 'always',
    textPath: `${T_PREFIX}.always`,
  },
  {
    value: 'sometimes',
    textPath: `${T_PREFIX}.sometimes`,
  },
  {
    value: 'rarely',
    textPath: `${T_PREFIX}.rarely`,
  },
]
