import React from 'react'
import { Trans } from 'react-i18next'

import { StyledPaymentLoading as S } from './PaymentLoading.styles'

type TProps = {
  animationRef: React.RefObject<HTMLDivElement>
}

export const PaymentLoading: React.FC<TProps> = ({ animationRef }) => (
  <>
    <S.Animation ref={animationRef} />
    <S.Title>
      <Trans i18nKey="purchase.paymentWaiting.processing" />
    </S.Title>
    <S.Subtitle>
      <Trans i18nKey="purchase.paymentWaiting.processingPayment" />
    </S.Subtitle>
  </>
)
