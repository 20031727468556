import styled from 'styled-components'

import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import pageBg from 'assets/images/intro-flow-bg.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledAchieveResultV3 = {
  Wrapper: styled.div`
    padding: 10px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled(PageTitle)`
    margin-bottom: 32px;
    letter-spacing: -0.8px;
    color: ${Color.DARK_BLUE};

    strong {
      font-weight: 800;
      color: #5bbf87;
      text-decoration: underline;
    }

    br {
      display: none;
    }
  `,
  Img: styled.img`
    width: 100%;
    margin: 0 auto 87px;
    box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
    border-radius: 24px;
    aspect-ratio: 328/229;
  `,
  Subtitle: styled.h2`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px;
  `,
}
