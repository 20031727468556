const T_PREFIX = 'onboarding.sleepingTime.options2'

export const OPTION_VALUES = {
  LONG_TIME: 'long_time',
  AFTER_ELEVEN: 'after_eleven',
  LESS_SEVEN: 'less_seven',
  MORE_TEN: 'more_ten',
  RESTED: 'rested',
  RECOMMEND: 'recommend',
}

export const OPTIONS_V1 = [
  {
    value: OPTION_VALUES.LONG_TIME,
    title: `${T_PREFIX}.longTimeV1`,
  },
  {
    value: OPTION_VALUES.AFTER_ELEVEN,
    title: `${T_PREFIX}.afterEleven`,
  },
  {
    value: OPTION_VALUES.LESS_SEVEN,
    title: `${T_PREFIX}.lessSeven`,
  },
  {
    value: OPTION_VALUES.MORE_TEN,
    title: `${T_PREFIX}.moreTen`,
  },
  {
    value: OPTION_VALUES.RESTED,
    title: `${T_PREFIX}.rested`,
  },
  {
    value: OPTION_VALUES.RECOMMEND,
    title: `${T_PREFIX}.recommend`,
  },
]

export const OPTIONS_V2 = [
  {
    value: OPTION_VALUES.LONG_TIME,
    title: `${T_PREFIX}.longTimeV2`,
  },
  {
    value: OPTION_VALUES.AFTER_ELEVEN,
    title: `${T_PREFIX}.afterEleven`,
  },
  {
    value: OPTION_VALUES.LESS_SEVEN,
    title: `${T_PREFIX}.lessSeven`,
  },
  {
    value: OPTION_VALUES.MORE_TEN,
    title: `${T_PREFIX}.moreTen`,
  },
  {
    value: OPTION_VALUES.RESTED,
    title: `${T_PREFIX}.rested`,
  },
  {
    value: OPTION_VALUES.RECOMMEND,
    title: `${T_PREFIX}.recommend`,
  },
]
