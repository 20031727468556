import React from 'react'
import { Trans } from 'react-i18next'

import { PriceValue } from 'components/PriceValue'
import { Switch } from 'components/Switch'

import { ISubscription } from 'models/subscriptions.model'

import { StyledUpsellSubscriptionItemSwitchers as S } from './UpsellSubscriptionItemSwitchers.styles'

type TProps = {
  value: string
  isChecked: boolean
  subscription: ISubscription
  onChange: (value) => void
}

export const UpsellSubscriptionItemSwitchers: React.FC<TProps> = ({
  value,
  isChecked,
  subscription: { product, trialPrices },
  onChange,
}) => (
  <S.Offer isChecked={isChecked}>
    <Switch value={value} onChange={onChange} isChecked={isChecked} />
    <S.OfferCaption>
      <Trans i18nKey={`upsell.subscriptionsBlock.${product}`} />
    </S.OfferCaption>
    <S.OfferPrice>
      <PriceValue value={trialPrices.fullPrice} />
    </S.OfferPrice>
  </S.Offer>
)
