import { useSelector } from 'react-redux'

import 'firebase/auth'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { VAT_INCLUDED_COUNTRIES } from 'root-constants'

export const useVatInfo = () => {
  const countryCode = useSelector(selectUserCountryCode)

  return VAT_INCLUDED_COUNTRIES.includes(countryCode)
}
