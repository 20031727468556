import styled from 'styled-components'

import { KitModalV1 } from 'components/KitModal'

import { Color, MODAL_OUTER_INDENT } from 'root-constants'

export const StyledKitCheckoutModalV2 = {
  KitModalV1: styled(KitModalV1)`
    width: calc(100% - ${MODAL_OUTER_INDENT}px);
    max-width: 360px;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  ModalTitle: styled.h4`
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: ${Color.BLACK_400};
    margin: 0 0 24px 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: ${Color.BLACK};
    margin: 0 0 24px 0;

    strong {
      color: ${Color.GREEN_300};
    }
  `,
  TotalRow: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px 0;
  `,
  TotalLabel: styled.span`
    font-weight: 600;
    font-size: 14px;
    color: ${Color.GREY_101};
  `,
  TotalAmount: styled.span`
    color: ${Color.BLACK};
    font-weight: 600;
    font-size: 15px;
  `,

  PaymentMethodsTitle: styled.h4`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLACK_100};
    margin: 0 0 24px 0;
  `,
}
