import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LocalizedDateRoller } from 'components/LocalizedDateRoller'
import { LoseWeightGraphV4 } from 'components/LoseWeightGraphV4'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGoalWeight } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledWeightLossDateV2 as S } from './WeightLossDateV2.styles'

export const WeightLossDateV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const { measuringSystemLabel } = useWeightMeasurementSystem()
  const { realPredictableDate, earlyDaysToGoalWeight } = usePredictableData()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.weightLossDateV2.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={12}>
        <Trans i18nKey="onboarding.weightLossDateV2.title" />
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey="onboarding.weightLossDateV2.subtitle" />
      </S.Subtitle>
      <S.PredictionBlock>
        <S.Text>
          <Trans
            i18nKey="onboarding.weightLossDateV2.days"
            values={{ targetWeight: `${goalWeight} ${measuringSystemLabel}` }}
            components={{ span: <span /> }}
          />
        </S.Text>
        <S.Date>
          <LocalizedDateRoller realPredictableDate={realPredictableDate} />
        </S.Date>
      </S.PredictionBlock>
      <LoseWeightGraphV4 days={earlyDaysToGoalWeight} />
    </KitPageContainer>
  )
}
