import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import image from 'assets/images/users-choice-female-v4.webp'

import { Language, TOTAL_USERS } from 'root-constants'

import { StyledeUsersChoiceV4 as S } from './UsersChoiceV4.styles'

export const UsersChoiceV4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.usersChoiceV4.title', {
      lng: Language.EN,
      count: TOTAL_USERS,
    }),
    nextPagePath,
  })

  return (
    <>
      <S.Logo />
      <S.Banner>
        <img src={image} alt="people" />
      </S.Banner>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.usersChoiceV4.title"
            values={{ count: TOTAL_USERS }}
          />
        </S.Title>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </>
  )
}
