import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OPTIONS_V1, OPTIONS_V2 } from 'pages/sleepingTime/v2/constants'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Cohort, Language } from 'root-constants'

const T_PREFIX = 'onboarding.sleepingTime'

export const SleepingTimeV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const question =
    cohortToUse === Cohort.MF_MEN_FLOW
      ? t(`${T_PREFIX}.title3`, { lng: Language.EN })
      : t(`${T_PREFIX}.title2`, { lng: Language.EN })

  const options = cohortToUse === Cohort.MF_MEN_FLOW ? OPTIONS_V2 : OPTIONS_V1

  const pageTitle =
    cohortToUse === Cohort.MF_MEN_FLOW
      ? `${T_PREFIX}.title3`
      : `${T_PREFIX}.title2`

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])
      } else {
        setAnswers(answers.filter((item) => item !== value))
      }
    },
  }

  const handleContinue = useNextStep({
    pageId,
    question,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answers)

  return (
    <Container>
      <PageTitle marginBottom={24}>
        <Trans i18nKey={pageTitle} />
      </PageTitle>
      {options.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          value={value}
          checked={answers.includes(value)}
        >
          <AnswerButtonV2 withCheckbox>
            <h3>
              <Trans i18nKey={title} />
            </h3>
          </AnswerButtonV2>
        </Option>
      ))}
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={handleNextClick}
        >
          <Trans i18nKey="actions.continue" />
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
