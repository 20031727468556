import React from 'react'
import { useTranslation } from 'react-i18next'

import { KitCheckboxOptionsView } from 'views/KitCheckboxOptionsView'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { KIT_BAD_HABITS_OPTIONS } from './constants'

const T_PREFIX = 'onboarding.kitBadHabits'

export const KitBadHabits: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(`${T_PREFIX}.title`)
  const subtitle = t(`${T_PREFIX}.subtitle`)
  const question = t(`${T_PREFIX}.title`, { lng: Language.EN })

  return (
    <KitCheckboxOptionsView
      title={title}
      subtitle={subtitle}
      question={question}
      options={KIT_BAD_HABITS_OPTIONS}
      {...props}
    />
  )
}
