import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseDisclaimer = {
  DisclaimerContainer: styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: ${Color.DISABLED};

    a {
      color: ${Color.DISABLED};
      text-decoration: underline;
    }

    span {
      white-space: nowrap;
    }
  `,
}
