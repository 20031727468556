import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK } from 'root-constants'

import { IMAGE_PATH_V1, OPTIONS_V1 } from '../constants'
import { StyledSecurityInfoV2 as S } from './SecurityInfoV2.styles'

export type TProps = {
  className?: string
}

export const SecurityInfoV2: React.FC<TProps> = ({ className }) => {
  const language = useSelector(selectLanguage)
  const badgeSrc = `${CDN_FOLDER_LINK}${IMAGE_PATH_V1}-${language}.png`

  return (
    <S.Container className={className}>
      <S.BadgeContainer>
        <S.Badge>
          <img src={badgeSrc} alt="security" />
        </S.Badge>
      </S.BadgeContainer>
      {OPTIONS_V1.map(({ titlePath, textPath }, idx) => (
        <S.InfoContainer key={titlePath}>
          <S.InfoTitle>
            <Trans i18nKey={titlePath} />
          </S.InfoTitle>
          <S.InfoText>
            <Trans i18nKey={textPath} />
            &nbsp;
            {idx === OPTIONS_V1.length - 1 && <S.SupportActionLink />}
          </S.InfoText>
        </S.InfoContainer>
      ))}
    </S.Container>
  )
}
