import React from 'react'

import { KitCheckboxOptionsView } from 'views/KitCheckboxOptionsView'
import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { OptionType } from 'components/Option'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { TPageProps } from 'models/common.model'

export const OptionsRightToLeftV3: React.FC<TPageProps> = (props) => {
  const {
    title,
    subtitle,
    optionType,
    optionData,
    optionProps,
    buttonText,
    answers,
    handleContinue,
  } = useDynamicPage(props)

  return (
    <>
      {optionType === OptionType.CHECKBOX && (
        <KitCheckboxOptionsView
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          options={optionData}
          answers={answers}
          handleContinue={handleContinue}
          isReverse
          {...props}
          {...optionProps}
        />
      )}
      {optionType === OptionType.RADIO && (
        <KitRadioOptionsView
          {...props}
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          options={optionData}
          handleContinue={handleContinue}
          isReverse
          {...props}
          {...optionProps}
        />
      )}
    </>
  )
}
