import React, { createContext, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'

import {
  selectCurrentVariantCohortToUse,
  selectCurrentVariantSteps,
  selectGrowthBookDefaultValues,
} from 'root-redux/selects/common'
import { selectUserAvailableUpsellPages } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { IStep } from 'models/variant.model'

import { PageId } from 'page-constants'
import {
  CANCEL_OFFER_BASE_ID,
  CANCEL_OFFER_QUESTION_BASE_ID,
  CHECKOUT_BASE_ID,
  Cohort,
  POST_PAYMENT_PAGES,
} from 'root-constants'

type TPageInfoContextProps = {
  steps: IStep[]
  upsellPageIds: string[]
  activeUpsellPageIds: string[]
  currentPageId: PageId
  currentPageIndex: number
  isSecondPage: boolean
  hasHeader: boolean
  hasProgressbar: boolean
  hasBurgerMenu: boolean
  pagesWithProgressBar: IStep[]
  currentPageNumber: number
  currentSubscriptionPageId: PageId
  currentPaymentPageId: PageId
  cancelOfferPageId: PageId | null
  cancelOfferQuestionPageId: PageId | null
  currentUpsellPaywallPageId: PageId
  isPaymentPage: boolean
  isCheckoutPage: boolean
  isPostPaymentPage: boolean
  cohortToUse: Cohort
}

const PageInfoContext = createContext<TPageInfoContextProps | null>(null)

const useSelectors = () =>
  useBulkSelector({
    steps: selectCurrentVariantSteps,
    cohortToUse: selectCurrentVariantCohortToUse,
    growthBookDefaultValues: selectGrowthBookDefaultValues,
    availableUpsellPages: selectUserAvailableUpsellPages,
  })

export const PageInfoProvider = ({ children }) => {
  const { pathname } = useLocation()
  const growthBook = useGrowthBook()
  const { steps, cohortToUse, growthBookDefaultValues, availableUpsellPages } =
    useSelectors()

  const unnecessaryPages = (() => {
    if (!growthBook?.ready) return []
    return Object.entries(growthBookDefaultValues).reduce(
      (acc, [key, defaultValues]) => {
        const experimentValue = growthBook.getFeatureValue(
          key,
          JSON.stringify(defaultValues),
        )
        return [...acc, ...JSON.parse(experimentValue)]
      },
      [] as string[],
    )
  })()

  const filteredSteps = unnecessaryPages.length
    ? steps.filter(({ id }) => !unnecessaryPages.includes(id))
    : steps

  const currentPageId = getPageIdFromPathName(pathname)
  const currentPageIndex = filteredSteps.findIndex(
    ({ id }) => id === currentPageId,
  )
  const isSecondPage = currentPageIndex === 1

  const isPostPaymentPage = POST_PAYMENT_PAGES.includes(currentPageId)
  const currentPage = filteredSteps[currentPageIndex]

  const hasHeader = currentPage?.hasHeader || isPostPaymentPage
  const hasProgressbar = currentPage?.hasProgressBar
  const hasBurgerMenu = !currentPageIndex && !currentPage?.isSubscriptions

  const pagesWithProgressBar = filteredSteps.filter(
    ({ hasProgressBar }) => hasProgressBar,
  )

  const currentPageNumber = (() => {
    const pageIndex = pagesWithProgressBar.findIndex(
      ({ id }) => id === currentPageId,
    )

    if (cohortToUse === Cohort.MF_WOMEN_45) {
      const amountOfSkippablePagesBefore = pagesWithProgressBar.reduce(
        (acc, curr, idx) => acc + (idx < pageIndex && curr.isSkippable ? 1 : 0),
        0,
      )

      return pageIndex - amountOfSkippablePagesBefore + 1
    }

    return pageIndex + 1
  })()

  let currentSubscriptionPageId = '' as PageId
  let currentPaymentPageId = '' as PageId
  let cancelOfferPageId: PageId | null = null
  let cancelOfferQuestionPageId: PageId | null = null
  let checkoutPage: PageId | null = null

  filteredSteps.forEach(({ isSubscriptions, isPayment, id }) => {
    if (isSubscriptions && !currentSubscriptionPageId) {
      currentSubscriptionPageId = id
    }

    if (isPayment && !currentPaymentPageId) currentPaymentPageId = id
    if (isPayment && !checkoutPage && id.includes(CHECKOUT_BASE_ID)) {
      checkoutPage = id
    }

    if (
      !cancelOfferPageId &&
      id.includes(CANCEL_OFFER_BASE_ID) &&
      !id.includes(CANCEL_OFFER_QUESTION_BASE_ID)
    ) {
      cancelOfferPageId = id
    }

    if (
      !cancelOfferQuestionPageId &&
      id.includes(CANCEL_OFFER_QUESTION_BASE_ID)
    ) {
      cancelOfferQuestionPageId = id
    }
  })

  const isPaymentPage = currentPaymentPageId === currentPageId
  const isCheckoutPage = checkoutPage === currentPageId

  const upsellPageIds = filteredSteps
    .filter(({ isUpsell }) => isUpsell)
    .map(({ id }) => id)

  const activeUpsellPageIds = availableUpsellPages || upsellPageIds
  const [currentUpsellPaywallPageId] = activeUpsellPageIds

  const value = useMemo(
    () => ({
      steps: filteredSteps,
      upsellPageIds,
      activeUpsellPageIds,
      currentPageId,
      currentPageIndex,
      isSecondPage,
      hasHeader,
      hasProgressbar,
      hasBurgerMenu,
      pagesWithProgressBar,
      currentPageNumber,
      currentSubscriptionPageId,
      currentPaymentPageId,
      cancelOfferPageId,
      cancelOfferQuestionPageId,
      currentUpsellPaywallPageId,
      isPaymentPage,
      isCheckoutPage,
      isPostPaymentPage,
      cohortToUse,
    }),
    [
      filteredSteps,
      upsellPageIds,
      activeUpsellPageIds,
      currentPageId,
      currentPageIndex,
      isSecondPage,
      hasHeader,
      hasProgressbar,
      hasBurgerMenu,
      pagesWithProgressBar,
      currentPageNumber,
      currentSubscriptionPageId,
      currentPaymentPageId,
      cancelOfferPageId,
      cancelOfferQuestionPageId,
      currentUpsellPaywallPageId,
      isPaymentPage,
      isCheckoutPage,
      isPostPaymentPage,
      cohortToUse,
    ],
  )

  return (
    <PageInfoContext.Provider value={value}>
      {children}
    </PageInfoContext.Provider>
  )
}

export const usePageInfo = () => {
  const context = useContext(PageInfoContext)
  if (!context) {
    throw new Error('usePageInfo must be used within a PageInfoProvider')
  }
  return context
}
