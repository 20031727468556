import React from 'react'
import { Trans } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV6 } from 'components/CustomerReviews'
import { Disclaimer } from 'components/Disclaimer'
import { FAQList } from 'components/FAQList'
import { HowItWorks } from 'components/HowItWorks'
import { MoneyBackGuarantee } from 'components/MoneyBackGuarantee'
import { KitPageContainer } from 'components/PageContainer'
import { PlanHighlights } from 'components/PlanHighlights'
import { RefundBanner } from 'components/RefundBanner'
import { Spinner } from 'components/Spinner'

import { CDN_FOLDER_LINK, TOTAL_USERS } from 'root-constants'

import { StyledCancelOffer as S } from '../lib/CancelOffer.styles'
import { IMAGE_PATH } from '../lib/constants'
import { useCancelOffer } from '../lib/useCancelOffer'

export const CancelOfferV2: React.FC = () => {
  const {
    hasPrices,
    language,
    handleGetPlan,
    discountFromSubscriptions,
    selectedSubscription,
  } = useCancelOffer()

  if (!hasPrices) return <Spinner />

  const GetPlanButton = () => (
    <Button
      width="100%"
      margin="0 auto"
      style={{ textTransform: 'capitalize' }}
      theme={buttonTheme.NUTRIMATE_PRIMARY}
      onClick={handleGetPlan}
    >
      <Trans i18nKey="actions.getMyPlan" />
    </Button>
  )

  return (
    <KitPageContainer paddingTop={0}>
      <S.DiscountBanner
        imgSrc={`${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`}
      >
        <S.DiscountContent>
          <S.DiscountTitle>
            <Trans i18nKey="cancelOffer.discountTitle" />
          </S.DiscountTitle>
          <S.DiscountValue>
            <Trans
              i18nKey="cancelOffer.discountValue"
              values={{ discountValue: discountFromSubscriptions }}
            />
          </S.DiscountValue>
        </S.DiscountContent>
      </S.DiscountBanner>

      <S.PlanDescription>
        <Trans
          i18nKey="cancelOffer.planDescription"
          values={{ count: TOTAL_USERS }}
        />
      </S.PlanDescription>

      <S.SubscriptionsContainer>
        <SubscriptionsBlock titleMargin={24} isCancelOffer />
        <MoneyBackGuarantee />
        <GetPlanButton />
      </S.SubscriptionsContainer>

      <RefundBanner />

      <HowItWorks>
        <GetPlanButton />
      </HowItWorks>

      <PlanHighlights />

      <S.CustomerReviewsWrap>
        <CustomerReviewsV6 />
      </S.CustomerReviewsWrap>

      <RefundBanner />

      <S.SubscriptionsContainerV2>
        <SubscriptionsBlock titleMargin={16} isCancelOffer />
        <GetPlanButton />
        {selectedSubscription && (
          <Disclaimer selectedSubscription={selectedSubscription} />
        )}
      </S.SubscriptionsContainerV2>

      <FAQList />
      <S.FeaturedBlockV2 />
      {selectedSubscription && (
        <Disclaimer selectedSubscription={selectedSubscription} />
      )}
    </KitPageContainer>
  )
}
