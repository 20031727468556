import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'

import {
  selectUserAccountLoginMethod,
  selectUserLoginEmail,
} from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY, Emojis } from 'root-constants'

import { LOGIN_METHOD_INSTRUCTION_LINK_MAP } from '../lib/constants'
import { StyledDownloadV1 as S } from './DownloadV1.styles'

export const DownloadV1: React.FC = () => {
  const { t } = useTranslation()
  const loginMethod = useSelector(selectUserAccountLoginMethod)
  const userEmail = useSelector(selectUserLoginEmail)

  const downloadAppLink = useMemo(() => {
    if (!loginMethod) return ''
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return LOGIN_METHOD_INSTRUCTION_LINK_MAP[loginMethod].replace(
      CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
      customToken || '',
    )
  }, [loginMethod])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(() => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadClicked(handleContinue)
  }, [handleContinue])

  return (
    loginMethod && (
      <KitPageContainer
        hasContinueButton
        paddingTop={0}
        continueButtonContent={t('download.buttonText')}
        continueButtonDeepLink={downloadAppLink}
        onContinueButtonClick={handleClick}
      >
        <S.Title>
          <Trans
            i18nKey="download.title"
            components={{ span: <span /> }}
            values={{ emoji: Emojis.PARTY }}
          />
        </S.Title>

        <S.Subtitle>
          <Trans i18nKey="download.subtitle" />
        </S.Subtitle>

        <S.LoginDescription>
          {t('download.loginDescription')}
        </S.LoginDescription>
        <S.List>
          <li>
            <S.DownloadAppText>
              <Trans
                i18nKey="download.signWithEmail"
                components={{ span: <span /> }}
              />
            </S.DownloadAppText>
          </li>
          <li>
            <S.ContinueText>{t('download.email')}</S.ContinueText>
          </li>
        </S.List>
        <S.EmailLabel>{userEmail}</S.EmailLabel>
      </KitPageContainer>
    )
  )
}
