import styled from 'styled-components'

import { AnswerButton } from 'components/AnswerButton'

export const StyledRadioOptionsView = {
  AnswerButton: styled(AnswerButton)`
    min-height: 92px;

    h3 {
      letter-spacing: initial;
    }
  `,
  OptionInfoContainer: styled.div`
    background: linear-gradient(0deg, #e3f2ff 0%, #e3f2ff 100%), #f5f5f5;
    border-radius: 12px;
    column-gap: 8px;
    display: flex;
    margin-bottom: 24px;
    padding: 16px;
  `,
  OptionInfoIcon: styled.div`
    aspect-ratio: 1 / 1;
    height: 24px;
    min-width: 24px;
  `,
  OptionInfo: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  `,
  OptionInfoTitle: styled.h4`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  OptionInfoText: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}
