import React, { useCallback, useState } from 'react'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import { useLottie } from 'hooks/useLottie'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledAnimatedSequenceView as S } from './AnimatedSequenceView.styles'

interface IProps extends TPageProps {
  title: string
  animationPath: string
  previewPath?: string
  className?: string
  question: string
  onComplete?: () => void
  onLoadEnd?: () => void
}

// TODO: move pages from src/pages/options-page to views

export const AnimatedSequenceView: React.FC<IProps> = ({
  title,
  animationPath,
  previewPath,
  pageId,
  question,
  nextPagePath,
  className,
  onComplete,
  onLoadEnd,
}) => {
  const [isDisabled, setIsDisabled] = useState(true)

  const handleComplete = useCallback(() => {
    setIsDisabled(false)
    onComplete?.()
  }, [onComplete])

  const { isAnimationLoaded, animationRef } = useLottie({
    onComplete: handleComplete,
    onLoadEnd,
    path: animationPath,
  })

  const handleNextPage = useNextStep({
    pageId,
    question,
    nextPagePath,
  })

  return (
    <KitPageContainer
      paddingTop={12}
      hasContinueButton
      onContinueButtonClick={() => handleNextPage('')}
      isContinueButtonDisabled={isDisabled}
    >
      <section className={className}>
        <S.Title>{title}</S.Title>
        <S.Wrapper>
          {!isAnimationLoaded ? <Spinner /> : null}
          {previewPath ? (
            <S.Preview
              src={previewPath}
              alt="animation preview"
              $isHide={isAnimationLoaded}
            />
          ) : null}
          <div ref={animationRef} />
        </S.Wrapper>
      </section>
    </KitPageContainer>
  )
}
