import React, { SyntheticEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BodyCalculation } from 'components/BodyCalculation'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { setMultipleAnswerAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { goTo } from 'browser-history'
import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  Language,
  MIN_MAX_WEIGHT,
  MeasurementSystem,
} from 'root-constants'

import { StyledCurrentWeightV2 as S } from './CurrentWeightV2.styles'

const T_PREFIX = 'onboarding.currentWeight'

export const CurrentWeightV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { measuringSystemLabel, isMetricSelected } =
    useWeightMeasurementSystem()

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(
      setMultipleAnswerAction({
        answers: {
          [CustomPageId.CURRENT_WEIGHT]: +weight.value,
          measurement_system: isMetricSelected
            ? MeasurementSystem.METRIC
            : MeasurementSystem.IMPERIAL,
        },
      }),
    )

    eventLogger.logQuestion({
      question: t(`${T_PREFIX}.title2`, { lng: Language.EN }),
      answers: `${weight.value},${
        isMetricSelected ? MeasurementSystem.METRIC : MeasurementSystem.IMPERIAL
      }`,
    })
    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }

  const handleChange = ({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight({
        value,
        isValid: validity.valid,
      })
    }
  }

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          <Trans i18nKey={`${T_PREFIX}.title2`} />
        </PageTitle>
        <S.Actions>
          <S.WeightLabel>
            <S.Label>{measuringSystemLabel}</S.Label>
          </S.WeightLabel>
          <S.InputContainer>
            <S.InputWrapper>
              <S.WeightInput
                isValid={weight.isValid}
                value={weight.value}
                type="number"
                min={
                  isMetricSelected
                    ? MIN_MAX_WEIGHT.MIN_WEIGHT_KG
                    : MIN_MAX_WEIGHT.MIN_WEIGHT_LB
                }
                max={
                  isMetricSelected
                    ? MIN_MAX_WEIGHT.MAX_WEIGHT_KG
                    : MIN_MAX_WEIGHT.MAX_WEIGHT_LB
                }
                lang="en"
                step={isMetricSelected ? '0.1' : '1'}
                onChange={handleChange}
                allowFloatNumbers={isMetricSelected}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
              {!isFocused && !weight.value && <S.Placeholder />}
              <S.Suffix>{measuringSystemLabel}</S.Suffix>
            </S.InputWrapper>
          </S.InputContainer>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>
                <Trans i18nKey={`${T_PREFIX}.errorMessage`} />
              </span>
            </S.ErrorMessage>
          )}
        </S.Actions>
        {weight.isValid && (
          <BodyCalculation isMetric={isMetricSelected} weight={weight.value} />
        )}
      </Container>
      <S.ButtonContainer isValid={weight.isValid} value={weight.value}>
        <Button type="submit" disabled={!weight.isValid}>
          <Trans i18nKey="actions.continue" />
        </Button>
      </S.ButtonContainer>
    </form>
  )
}
