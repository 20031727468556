import styled from 'styled-components'

import { Color } from 'root-constants'

type TPlanItemProps = {
  bullet?: string
  hasPaddingTop?: boolean
  hasPaddingBottom?: boolean
}

export const StyledPersonalizedPlan = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.GREY_100};
    text-transform: capitalize;
    margin: 0 0 16px 0;
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
  `,
  PlanRow: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    &:first-of-type {
      border-bottom: 0.5px solid ${Color.GREY_800};
    }
  `,
  PlanItem: styled.div<TPlanItemProps>`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4px 16px 44px;

    &:nth-of-type(odd) {
      border-right: 0.5px solid ${Color.GREY_800};
    }

    &:nth-of-type(even) {
      padding-left: 58px;

      &:before {
        left: 14px;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      content: url(${({ bullet }) => bullet});
      height: 28px;
      color: ${Color.GREY_100};
    }

    ${({ hasPaddingTop }) => hasPaddingTop && `padding-top: 16px;`}
    ${({ hasPaddingBottom }) => hasPaddingBottom && `padding-bottom: 16px;`}
  `,
  ItemTitle: styled.h3`
    display: inline-block;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: ${Color.GREY_101};
    margin: 0 0 2px 0;

    &:first-letter {
      text-transform: uppercase;
    }
  `,
  ItemValue: styled.p`
    display: inline-block;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: ${Color.GREY_100};

    &:first-letter {
      text-transform: uppercase;
    }
  `,
}
