import styled from 'styled-components'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'

export const StyledCheckboxOptionsView = {
  AnswerButton: styled(AnswerButtonV2)`
    min-height: 92px;

    h3 {
      font-size: 18px;
      letter-spacing: initial;
      line-height: 20px;
    }
  `,
  QuestionDescription: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
  `,
}
