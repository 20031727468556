import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledHitYourWeightLossV1 = {
  Title: styled(PageTitle)`
    color: ${Color.GREEN_300};
    margin-bottom: 8px;
    text-transform: uppercase;
  `,
  Subtitle: styled.h3`
    color: ${Color.GREY_100};
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-bottom: 16px;
  `,
}
