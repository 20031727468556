import changesIconSrc from 'assets/images/upsell-simple-changes.png'
import giftSrc from 'assets/images/upsell-sugar-changes.png'
import sugarIconSrc from 'assets/images/upsell-sugar-free.png'
import item1 from 'assets/images/what-you-get-v2-item-1.png'
import item2 from 'assets/images/what-you-get-v2-item-2.png'
import item3 from 'assets/images/what-you-get-v2-item-3.png'

import { UpsellProduct } from 'root-constants'

export const UPSELL_BENEFITS = [
  'upsellV3.benefits.payment',
  'upsellV3.benefits.access',
  'upsellV3.benefits.advice',
]

export const UPSELL_IMAGES_MAP = {
  [UpsellProduct.SIMPLE_CHANGES]: {
    title: 'upsellV3.subscriptionsBlock.pdf_SimpleChangesTitle',
    info: 'upsellV3.subscriptionsBlock.pdf_SimpleChangesInfo',
    discount: 32,
    img: changesIconSrc,
  },
  [UpsellProduct.SUGAR_FREE]: {
    title: 'upsellV3.subscriptionsBlock.pdf_SugarFreeDessertsTitle',
    info: 'upsellV3.subscriptionsBlock.pdf_SugarFreeDessertsInfo',
    discount: 32,
    img: sugarIconSrc,
  },
  [UpsellProduct.CHANGES_SUGAR]: {
    title: 'upsellV3.subscriptionsBlock.pdf_WeightLossGuidesTitle',
    discount: 50,
    img: giftSrc,
  },
}

export const UPSELL_EVENT_PROPERTY_MAP = {
  [UpsellProduct.SIMPLE_CHANGES]: 'pdf_weightloss',
  [UpsellProduct.SUGAR_FREE]: 'pdf_desserts',
  [UpsellProduct.CHANGES_SUGAR]: 'pdf_bundle',
}

export const STEPS_MAP = [
  {
    title: 'purchase.stepsV1.session.title',
    info: 'purchase.stepsV1.session.info',
  },
  {
    title: 'purchase.stepsV1.coach.title',
    info: 'purchase.stepsV1.coach.info',
  },
  {
    title: 'purchase.stepsV1.between.title',
    info: 'purchase.stepsV1.between.info',
  },
  {
    title: 'purchase.stepsV1.track.title',
    info: 'purchase.stepsV1.track.info',
  },
]

export const WHAT_YOU_GET_MAP = [
  {
    imgSrc: item1,
    background:
      'linear-gradient(0deg, #eff7e2 0%, #eff7e2 100%), linear-gradient(0deg, #f1f5f9 0%, #f1f5f9 100%), #fbf6ee',
    title: 'purchase.whatYouGetV2.approach.title',
    info: 'purchase.whatYouGetV2.approach.info',
  },
  {
    imgSrc: item2,
    background: '#f7efe4',
    title: 'purchase.whatYouGetV2.support.title',
    info: 'purchase.whatYouGetV2.support.info',
  },
  {
    imgSrc: item3,
    background: 'linear-gradient(0deg, #f1f5f9 0%, #f1f5f9 100%), #eefbf4',
    title: 'purchase.whatYouGetV2.noToxic.title',
    info: 'purchase.whatYouGetV2.noToxic.info',
  },
]

export const MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS = 2
