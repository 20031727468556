import styled from 'styled-components'

import { Button } from 'components/Button'

import orangeLine from 'assets/images/orange-line.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseV1 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  Title: styled.h2`
    margin: 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  Subtitle: styled.h2`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DISABLED};
    padding-bottom: 24px;

    strong {
      position: relative;
      color: ${Color.GREY_100};

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -12px;
        width: 100px;
        height: 25px;
        background-image: url(${orangeLine});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px 25px;
      }
    }
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  CustomerReviewsContainer: styled.div`
    background-color: #eef7f2;
    border-radius: 40px 40px 0 0;
    padding: 24px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  `,
}
