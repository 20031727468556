import defined from 'assets/images/defined-body.png'
import muscular from 'assets/images/muscular-body.png'
import toned from 'assets/images/toned-body.png'

const T_PREFIX_V3 = 'onboarding.bodyType.optionsV3'
export const OPTIONS_V3 = [
  {
    value: 'toned',
    title: `${T_PREFIX_V3}.toned`,
    icon: toned,
  },
  {
    value: 'defined',
    title: `${T_PREFIX_V3}.defined`,
    icon: defined,
  },
  {
    value: 'muscular',
    title: `${T_PREFIX_V3}.muscular`,
    icon: muscular,
  },
]
