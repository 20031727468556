import React from 'react'
import { Trans } from 'react-i18next'
import { TransProps } from 'react-i18next/TransWithoutContext'
import { useSelector } from 'react-redux'

import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { selectCurrency } from 'modules/purchaseSubscription/redux/selects'

type TProps = {
  value?: string | number
  formatOptions?: Intl.NumberFormatOptions
} & TransProps<'common'>

export const PriceValue: React.FC<TProps> = ({
  value,
  formatOptions,
  ...rest
}) => {
  const currency = useSelector(selectCurrency)
  const currencyOptions = getCurrencyOptions(currency)

  const options = {
    currency,
    ...currencyOptions,
    ...formatOptions,
  }

  if (!value) return value

  return (
    <Trans
      i18nKey="commonComponents.priceFormat"
      values={{
        price: value,
        ...options,
      }}
      {...rest}
    />
  )
}
