import { KetoDietExperience } from 'value-constants'

const T_PREFIX = 'onboarding.kitKetoDietV1.options'

export const ANSWER_OPTIONS_KIT_V1 = [
  {
    value: KetoDietExperience.NOTHING,
    textPath: `${T_PREFIX}.nothing`,
  },
  {
    value: KetoDietExperience.SOMETHING,
    textPath: `${T_PREFIX}.heard`,
  },
  {
    value: KetoDietExperience.EXPERIENCED,
    textPath: `${T_PREFIX}.experienced`,
  },
]
