import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { KitPageContainer } from 'components/PageContainer'

import { StyledImageOptions as S } from 'styles/ImageOptions.styles'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useDynamicPage } from 'hooks/useDynamicPage'

import { getAspectRatioByDimensions } from 'helpers/getAspectRatioByDimensions'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

export const ImageOptionsV1: React.FC<TPageProps> = (props) => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const { title, subtitle, optionData, optionProps, isAnswersDisabled } =
    useDynamicPage({ ...props, isFirstPage: true })

  const hasDynamicDiscountBanner = useMemo(
    () => cohortToUse === Cohort.MF_INTRO_SALE,
    [cohortToUse],
  )
  return (
    <>
      {hasDynamicDiscountBanner && <DynamicDiscountBanner />}

      <KitPageContainer paddingTop={12}>
        <S.Title>
          <Trans>{title}</Trans>
        </S.Title>
        <S.Subtitle>
          <Trans>{subtitle}</Trans>
        </S.Subtitle>

        <S.OptionsContainer>
          {optionData?.map(
            ({ value, text, imageUrl, imageWidth, imageHeight }) => (
              <S.Option
                {...optionProps}
                key={value}
                disabled={isAnswersDisabled}
                value={value}
              >
                <S.ImageContainer
                  aspectRatio={getAspectRatioByDimensions(
                    imageWidth,
                    imageHeight,
                  )}
                >
                  <img src={imageUrl} alt={text} />
                </S.ImageContainer>
                <S.AnswerText>
                  {text}
                  <S.ArrowIcon />
                </S.AnswerText>
              </S.Option>
            ),
          )}
        </S.OptionsContainer>
        <S.Footer />
      </KitPageContainer>
    </>
  )
}
