import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IconVerticalAlignments } from 'components/AnswerButtonV2'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language, Position } from 'root-constants'

import { StyledCheckboxOptionsView as S } from './CheckboxOptionsView.styles'

type TOption = {
  key: string
  value: string
  iconSrc?: string
  iconPosition?: Position
  iconHeight?: number
  iconWidth?: number
  checkboxPosition?: Position
  strikethroughIcon?: boolean
}

type TCustomOptions = {
  shouldDisableOptions?: boolean
  options: TOption[]
}

type TProps = {
  options: TOption[]
  translationFamily: string
  banner?: ReactNode
  maxAnswers?: number
  customOptions?: TCustomOptions
  hasCheckbox?: boolean
} & TPageProps

export const CheckboxOptionsView: React.FC<TProps> = ({
  pageId,
  nextPagePath,
  options,
  translationFamily,
  banner,
  maxAnswers,
  customOptions = { shouldDisableOptions: false, options: [] },
  hasCheckbox = true,
}) => {
  const { t, i18n } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: t(`${translationFamily}.question`, { lng: Language.EN }),
    nextPagePath,
  })

  const customOptionsValues = useMemo(
    () => customOptions.options.map(({ value }) => value),
    [customOptions],
  )

  const hasDescriptionKey = useMemo(
    () => i18n.exists(`${translationFamily}.description`),
    [translationFamily, i18n],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          customOptionsValues.includes(value)
            ? setAnswers([value])
            : setAnswers(
                [...answers, value].filter(
                  (answer) => !customOptionsValues.includes(answer),
                ),
              )
        } else {
          setAnswers(answers.filter((answer) => answer !== value))
        }
      },
    }),
    [pageId, answers, customOptionsValues],
  )

  const checkIsOptionDisabled = useCallback(
    (value: string) => {
      const isCustomOption = customOptionsValues.includes(value)

      if (isCustomOption) {
        return false
      }

      if (answers.length === maxAnswers && !answers.includes(value)) {
        return true
      }

      return (
        answers.some((answer) => customOptionsValues.includes(answer)) &&
        customOptions.shouldDisableOptions
      )
    },
    [customOptionsValues, answers, maxAnswers, customOptions],
  )

  return (
    <Container>
      <PageTitle marginBottom={banner || hasDescriptionKey ? 8 : 24}>
        <Trans i18nKey={`${translationFamily}.question`} />
      </PageTitle>
      {hasDescriptionKey && (
        <S.QuestionDescription>
          <Trans i18nKey={`${translationFamily}.description`} />
        </S.QuestionDescription>
      )}
      {!!banner && banner}
      {options.map(
        ({
          key,
          value,
          iconSrc,
          iconPosition = Position.LEFT,
          iconHeight = 56,
          iconWidth = 56,
          strikethroughIcon = false,
          checkboxPosition = Position.RIGHT,
        }) => {
          const isDisabled = checkIsOptionDisabled(value)

          return (
            <Option
              {...optionProps}
              key={key}
              value={value}
              disabled={isDisabled}
              checked={answers.includes(value)}
            >
              <S.AnswerButton
                withCheckbox={
                  !customOptionsValues.includes(value) && hasCheckbox
                }
                iconSrc={iconSrc}
                iconHeight={iconHeight}
                iconWidth={iconWidth}
                iconVerticalAlignment={IconVerticalAlignments.CENTER}
                iconPosition={iconPosition}
                checkboxPosition={checkboxPosition}
                strikethroughIcon={strikethroughIcon}
              >
                <h3>
                  <Trans i18nKey={`${translationFamily}.options.${key}`} />
                </h3>
              </S.AnswerButton>
            </Option>
          )
        },
      )}
      <StickyButtonContainer>
        <Button
          type="button"
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          {t('actions.continue')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
