import React from 'react'
import { Trans } from 'react-i18next'

import { GENDER_MAP } from 'components/UserInfoBlock/constants'

import {
  selectCurrentAge,
  selectCurrentUserGender,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'

import bulletAge from 'assets/images/bullet-age.svg'
import bulletBiologicalSex from 'assets/images/bullet-biological-sex.svg'

import { StyledUserInfoBlockV3 as S } from './UserInfoBlockV3.styles'

type TProps = {
  className?: string
}

const useSelectors = () =>
  useBulkSelector({
    gender: selectCurrentUserGender,
    age: selectCurrentAge,
  })

export const UserInfoBlockV3 = ({ className }: TProps) => {
  const { gender, age } = useSelectors()

  const genderTitleKey = GENDER_MAP[gender]
  const options = [
    {
      iconSrc: bulletBiologicalSex,
      alt: 'biological-sex-icon',
      labelKey: 'commonComponents.biologicalSex',
      children: <Trans i18nKey={genderTitleKey} />,
    },
    {
      iconSrc: bulletAge,
      alt: 'age-icon',
      labelKey: 'commonComponents.age',
      children: age,
    },
  ]

  return (
    <S.Container className={className}>
      {options.map(({ iconSrc, children, labelKey, alt }, idx) => (
        <>
          <S.InfoItem key={labelKey}>
            <S.InfoItemIcon src={iconSrc} alt={alt} />
            <S.InfoItemText>
              <S.Label>
                <Trans i18nKey={labelKey} />
              </S.Label>
              <S.InfoValue>{children}</S.InfoValue>
            </S.InfoItemText>
          </S.InfoItem>
          {idx !== options.length - 1 ? <S.Divider /> : null}
        </>
      ))}
    </S.Container>
  )
}
