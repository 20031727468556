import { EventValue, MotivationValue } from 'value-constants'

import { Position } from 'root-constants'

export const TRANSLATION_FAMILY = 'onboarding.eventV1'
export const OPTIONS = [
  {
    key: 'marriageOrRelationship',
    value: EventValue.MARRIAGE_OR_RELATIONSHIP,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'busyWorkOrFamilyLife',
    value: EventValue.BUSY_WORK_OR_FAMILY_LIFE,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'financialStruggles',
    value: EventValue.FINANCIAL_STRUGGLES,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'injuryOrDisability',
    value: EventValue.INJURY_OR_DISABILITY,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'stressOrMentalHealth',
    value: EventValue.STRESS_OR_MENTAL_HEALTH,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'slowerMetabolism',
    value: EventValue.SLOWER_METABOLISM,
    checkboxPosition: Position.LEFT,
  },
  {
    key: 'postTreatmentComplications',
    value: EventValue.POST_TREATMENT_COMPLICATIONS,
    checkboxPosition: Position.LEFT,
  },
]

export const CUSTOM_OPTIONS = {
  options: [
    {
      key: 'none',
      value: EventValue.NONE,
    },
  ],
  shouldDisableOptions: false,
}

const T_PREFIX = 'onboarding.kitEventV1.options'

export const ANSWER_OPTIONS_KIT_V1 = [
  {
    value: MotivationValue.VACATION,
    textPath: `${T_PREFIX}.vacation`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/airplane.png',
  },
  {
    value: MotivationValue.WEDDING,
    textPath: `${T_PREFIX}.wedding`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/calendar.png',
  },
  {
    value: MotivationValue.SPORTS,
    textPath: `${T_PREFIX}.sportingEvent`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/football.png',
  },
  {
    value: MotivationValue.SUMMER,
    textPath: `${T_PREFIX}.summer`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/sun.png',
  },
  {
    value: MotivationValue.REUNION,
    textPath: `${T_PREFIX}.reunion`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/team.png',
  },
  {
    value: MotivationValue.FAMILY,
    textPath: `${T_PREFIX}.familyEvent`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/people.png',
  },
  {
    value: MotivationValue.HOLIDAY,
    textPath: `${T_PREFIX}.holiday`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/firecracker.png',
  },
  {
    value: MotivationValue.OTHER,
    textPath: `${T_PREFIX}.other`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/balloons.png',
  },
  {
    value: MotivationValue.NO,
    textPath: `${T_PREFIX}.dontHave`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/positive-person.png',
  },
]
