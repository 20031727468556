import { PurchaseProduct } from 'modules/purchaseSubscription/constants'

export const TRIAL_DISCLAIMER_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimer',
  [PurchaseProduct.ONE_MONTH]: 'purchase.subscription.monthlyDisclaimer',
  [PurchaseProduct.THREE_MONTHS]: 'purchase.subscription.quarterlyDisclaimer',
  [PurchaseProduct.SIX_MONTHS]: 'purchase.subscription.semiannualDisclaimer',
}

export const NO_TRIAL_DISCLAIMER_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 'purchase.subscription.weeklyDisclaimerV2',
}
