import React, { ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledRadioOptionsView as S } from './RadioOptionsView.styles'

type TOption = {
  key: string
  value: string
  iconSrc?: string
}

type TProps = {
  options: TOption[]
  translationFamily: string
  banner?: ReactNode
  hasContinueButton?: boolean
  hasOptionInfo?: boolean
  optionInfoIcon?: string
} & TPageProps

export const RadioOptionsView: React.FC<TProps> = ({
  pageId,
  nextPagePath,
  options,
  translationFamily,
  banner,
  hasContinueButton = false,
  hasOptionInfo,
  optionInfoIcon,
}) => {
  const { t } = useTranslation()

  const [selectedValue, setSelectedValue] = useState('')

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${translationFamily}.question`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        if (hasContinueButton) {
          setSelectedValue(value)
        } else {
          handleChange(value, handleContinue)
          setIsAnswersDisabled(true)
        }
      },
    }),
    [
      pageId,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
      hasContinueButton,
      setSelectedValue,
    ],
  )

  const { optionInfoTitle, optionInfoText } = useMemo(() => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue,
    )
    const key = selectedOption?.key || ''

    return {
      optionInfoTitle: `${translationFamily}.info.${key}.title`,
      optionInfoText: `${translationFamily}.info.${key}.text`,
    }
  }, [selectedValue])

  return (
    <Container>
      <PageTitle marginBottom={banner ? 8 : 24}>
        <Trans i18nKey={`${translationFamily}.question`} />
      </PageTitle>
      {!!banner && banner}
      {hasOptionInfo && selectedValue && (
        <S.OptionInfoContainer>
          <S.OptionInfoIcon>
            <img src={optionInfoIcon} alt="info" />
          </S.OptionInfoIcon>
          <S.OptionInfo>
            <S.OptionInfoTitle>
              <Trans i18nKey={optionInfoTitle} />
            </S.OptionInfoTitle>
            <S.OptionInfoText>
              <Trans i18nKey={optionInfoText} />
            </S.OptionInfoText>
          </S.OptionInfo>
        </S.OptionInfoContainer>
      )}
      {options.map(({ key, value, iconSrc }) => (
        <Option
          {...optionProps}
          key={key}
          value={value}
          disabled={isAnswersDisabled}
        >
          <S.AnswerButton iconSrc={iconSrc}>
            <h3>
              <Trans i18nKey={`${translationFamily}.options.${key}`} />
            </h3>
          </S.AnswerButton>
        </Option>
      ))}

      {hasContinueButton && (
        <StickyButtonContainer>
          <Button
            type="button"
            disabled={!selectedValue}
            onClick={() => handleContinue(selectedValue)}
          >
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      )}
    </Container>
  )
}
