import React from 'react'
import { Helmet } from 'react-helmet-async'

import dayjs from 'dayjs'
import { GlobalStyleForPrivacyPolicy } from 'pages/privacyPolicy/v1/PrivacyPolicy.styles'

import { LONG_YEAR_FORMAT } from 'helpers/dateHelper'

import logo from 'assets/images/logo.png'

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>NutriMate | Privacy Policy</title>
      </Helmet>
      <GlobalStyleForPrivacyPolicy />
      <header className="header">
        <section className="header__container container">
          <div className="logo">
            <img className="logo__img" src={logo} alt="NutriMate" />
          </div>
          <h3 className="header-title">NutriMate</h3>
        </section>
      </header>
      <main>
        <article className="article container">
          <h1 className="article__title-v2">PRIVACY POLICY</h1>
          <h4 className="article__date-v2">LAST UPDATED: October 23, 2024</h4>
          <section>
            <h2>ABOUT US</h2>
            <p className="mb-10">
              Our Platforms (“<strong>Platforms</strong>” means the website(s),
              including but not limited to{' '}
              <a href="https://nutrimate.fit/" target="_blank" rel="noreferrer">
                https://nutrimate.fit/
              </a>{' '}
              (the “<strong>Site</strong>”); mobile applications (means
              applications and games (each an “<strong>App</strong>”,
              collectively “<strong>Apps</strong>”)) and any related
              documentation, services; any images, logos, music, photographs and
              video content, software, designs, graphics, photos, images,
              illustrations, animations, videos, scripts, texts, music, sounds,
              voiceover, interactive features, wellness plans, and all other
              materials and content accessible within the Apps or Site that are
              incorporated into and form part of our Apps and etc. (“
              <strong>App Content</strong>”) are owned, managed and operated by
              the Company).
            </p>
            <p className="mb-10">Company shall mean:</p>
            <p className="mb-10">
              (1) <strong>EXTRAMILE LIMITED</strong>, a company incorporated in
              Cyprus, company number HΕ 445953, whose registered office is
              located at Prodromou, 75, ONEWORLD PARKVIEW HOUSE, Floor 4, 2063,
              Nicosia, Cyprus.
            </p>
            <p className="mb-10">We can be contacted by writing to</p>
            <p className="mb-10">
              (1) <b>EXTRAMILE LIMITED</b>, Prodromou, 75, ONEWORLD PARKVIEW
              HOUSE, Floor 4, 2063, Nicosia, Cyprus, or via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              .
            </p>
            <p className="mb-10">
              The Apps available via the Platforms may also be distributed to
              you by our partners:
            </p>
            <p className="mb-30">
              <strong>Fulfilling Inc.</strong>, a Delaware corporation with its
              registered address at 1007 N Orange St. 4th Floor Ste 1382,
              Wilmington, New Castle, 19801.
            </p>
            <p className="mb-10">
              We are dedicated to leveraging technology to enhance users'
              physical and emotional well-being, foster enjoyment through music
              and entertainment, and boost productivity and convenience in
              mobile device usage.
            </p>
            <p className="mb-10">
              We believe in the remarkable potential of technology to drive
              positive change and are committed to the highest standards of
              privacy and security. As trusted keepers of your personal data, we
              prioritize transparency and accountability in our data practices,
              ensuring that you have full knowledge of your data while
              benefiting from our Platforms.
            </p>
            <p className="mb-10">
              When you use Platforms, we may collect, store and process some
              data, including personal data. This privacy policy (“
              <strong>Privacy Policy</strong>”) sets out the main principles on
              which the data collected from you, or that you provide to us, will
              be processed by us. This Privacy Policy also aims to remind you
              about your rights and to provide you with all the elements you
              need to exercise them. In accordance with data protection
              legislation (GDPR, UK GDPR, CCPA and etc.), we act as the
              controller of your personal data. In some cases, we may act as the
              processor of personal data, as indicated in this policy below. For
              data protection legislation in the United Kingdom, we are the
              controller of your personal data, and, regarding a part of
              personal data, the processor of your personal data.
            </p>
            <p className="mb-10">
              We encourage you to review our Privacy Policy in its entirety to
              gain insight into our data handling practices. We have
              meticulously crafted this policy to be clear and accessible, but
              if you have any questions or concerns, please don`t hesitate to
              contact us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>{' '}
              or the address below for further information.
            </p>
            <h3 className="mb-10">EXTRAMILE LIMITED</h3>
            <p className="mb-30">
              Prodromou, 75, ONEWORLD PARKVIEW HOUSE, Floor 4, 2063, Nicosia,
              Cyprus.
            </p>
          </section>
          <section>
            <h3 className="mb-30">
              IF YOU DO NOT ACCEPT THE TERMS OF THE PRIVACY POLICY, PLEASE DO
              NOT USE OUR PLATFORMS.
            </h3>
            <h3 className="mb-30">YOUR DATA COLLECTED BY US</h3>
            <p className="mb-10">
              As you engage with our Platforms, we gather data concerning a
              recognized or identifiable living individual (“personal data”)
              through the following means:
            </p>
            <p className="mb-10">
              <strong>Data Directly Provided by You:</strong> this encompasses
              any information you manually input or furnish to us while
              utilizing our Platforms. For instance, this might include details
              like your name, email address, phone number, or any other
              information you decide to disclose during registration or account
              setup.
            </p>
            <p className="mb-10">
              <strong>Data Automatically Collected by Us:</strong> when you
              access our Platforms, we automatically procure certain details
              regarding your engagement and activities within the Platforms.
              This may entail specifics about your device, such as its model,
              operating system, unique identifiers, IP address, and data related
              to your actions within the Platforms.
            </p>
            <p className="mb-10">
              <strong>Data Acquired via Cookies:</strong> to understand your
              interactions with our Platforms better, we utilize cookies and
              similar technologies. Cookies enable us to retain particular
              details about you, such as your preferences or previous
              interactions, thereby enhancing your experience on our Platforms
              and delivering tailored content. You can find more in our Cookie
              Policy.
            </p>
          </section>
          <section>
            <h3 className="mb-30">WHY WE PROCESS YOUR DATA</h3>
            <p className="mb-10">
              Data processing is crucial to enable your usage of our Platforms.
              Whenever you use our Platforms, some personal and non-personal
              data is collected, stored, and analyzed using internal and
              third-party tools.
            </p>
            <p className="mb-10">
              Below are several additional purposes for which we process your
              data:
            </p>
            <ul className="mb-10">
              <li>
                <strong>provide customer support,</strong> we may engage in
                email communications with you if you have contacted our support
                team with any queries relating to our Platforms.
              </li>
              <li>
                <strong>enhance the features of our Platforms,</strong> we
                analyze how you interact with the Platforms to tailor the
                experience to your needs. This includes refining prediction
                accuracy, developing new algorithms for enhanced insights, and
                generating content on topics of interest to you.
              </li>
              <li>
                <strong>enhance the technical aspects of our Platforms,</strong>{' '}
                we continuously work to improve your experience and streamline
                features for your benefit.
              </li>
              <li>
                <strong>optimize our advertising strategies,</strong> we analyze
                the performance of our advertising campaigns on the Platforms.
                This helps us identify your engagement and subscription status,
                as well as gauge interaction with our advertisements.
              </li>
              <li>
                <strong>improve our Site,</strong> we utilize website analytics
                to track performance and understand user behavior. This aids us
                in offering an improved user experience and optimizing our
                Site`s functionality.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="mb-30">HOW YOUR DATA IS HANDLED</h3>
            <p className="mb-10">
              Depending on which features of the Platforms you use, we will
              process your personal data based on one or more of the following
              legal bases (we have provided you with some examples):
            </p>
            <ul className="mb-10">
              <li>
                <strong>Legitimate interest.</strong> We process your usage data
                on this legal basis to technically improve the Platforms based
                on our legitimate interest as a Company to continuously improve
                the Platforms and deliver an improved service to you.
              </li>
              <li>
                <strong>Contract.</strong> We process your usage data on this
                legal basis to fulfill the contractual duty of granting you
                access to use our Platforms.
              </li>
              <li>
                <strong>Legal obligation.</strong> We process your usage data on
                this legal basis to comply with pertinent laws and regulations.
                This implies that we may handle personal data as mandated by
                law, ensuring adherence to applicable legal mandates.
              </li>
              <li>
                <strong>Consent.</strong> We process your usage data on this
                legal basis to promote Platforms and optimize our advertising
                efforts to effectively tell more people about Platforms. This
                may include the user’s contacts, a list of the user’s contacts,
                and related data about the user’s contacts. This data will be
                collected by Us, for example, to display the user’s contact list
              </li>
            </ul>
            <p className="mb-10">
              We are committed to being clear and transparent so that you can
              really understand what we do with your data. Below, we explain the
              purposes for which we process your personal data and the legal
              basis supporting this. We also give you some basic examples:
            </p>
            <ul className="mb-10">
              <li>
                <strong>Legitimate interest.</strong> Responding to your
                requests. We may process your name and email, e.g., to properly
                respond to your unsubscribe request.
              </li>
              <li>
                <strong>Contract.</strong> Processing transactions and sending
                you related information, including confirmations and reminders
                for account management and other administrative purposes. We may
                send you a reminder (e.g., via push notifications) if your
                subscription has expired or is due to expire, or we may email
                you containing your invoice, if applicable, using your device
                data.
              </li>
              <li>
                <strong>Consent.</strong> Making offerings to you. We may offer
                you a discount for our subscriptions.
              </li>
            </ul>
            <p className="mb-10">
              For the processing of physical data and special categories of
              personal data, including sensitive information like health data,
              explicit consent from the user serves as the legal basis. This
              consent is essential for enabling users to utilize the Platforms
              provided by us.
            </p>
            <p className="mb-10">
              Our Platforms offer the option to make purchases directly within
              the application (including subscriptions) or directly through the
              Site. If you choose to make a purchase within the application, you
              may use third-party payment systems and/or providers. We do not
              collect or process any information related to your payment
              instruments, such as your bank card number or its validity term,
              under any circumstances.
            </p>
            <p className="mb-10">
              However, when you make purchases directly through our Site,
              including subscriptions, you authorize an applicable payment
              system and/or provider to collect this payment information. We do
              not collect or process your payment data (such as credit card
              number, expiration date, etc.) for purchases made through our
              Platforms. We strongly recommend reviewing the Privacy Policy of
              the payment system you use for making payments, such as the
              privacy policy of Stripe:{' '}
              <a
                href="https://stripe.com/en-pl/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://stripe.com/en-pl/privacy
              </a>
              .
            </p>
          </section>
          <section>
            <h3>DATA RETENTION</h3>
            <p className="mb-10">
              We retain your personal data for as long as your account is active
              or for as long as it is necessary for the purposes of its
              collection and processing (e.g., for resolving disputes, for
              safety and security reasons, or for complying with our legal
              obligations). However, the period for which we may retain your
              personal data cannot exceed 2 (two) months from the date of
              account deactivation and/or deletion.
            </p>
          </section>
          <section>
            <h3>SHARING OF YOUR PERSONAL DATA</h3>
            <p className="mb-10">
              We may need to share your personal data with certain third parties
              as follows:
            </p>
            <ul className="mb-10">
              <li>
                <strong>Third-party service providers.</strong>
                This may include providers of certain systems and services that
                we use to host, administer, and maintain our Platforms,
                including the servers used to host our Platforms, email service
                providers, payment processors, fraud prevention vendors,
                analytics, customer service providers and other service
                providers.
              </li>
              <li>
                <strong>
                  Third-party service providers for marketing purposes.
                </strong>
                If you consent to any marketing from us, certain personal data
                may be shared with third-party service providers we use to help
                us carry out marketing including, e.g., third-party marketing
                automation platforms.
              </li>
              <li>
                <strong>Compliance with Laws.</strong>
                We may disclose your personal data to a third party if ( we
                believe that disclosure is reasonably necessary to comply with
                any applicable law, regulation, legal process, or governmental
                request; or to protect the security or integrity of the
                Platforms; or to protect us, our customers or the public from
                harm or illegal activities; or to respond to an emergency which
                we believe in the good faith requires us to disclose information
                to assist in preventing the death or serious bodily injury of
                any person.
              </li>
              <li>
                <strong>Aggregated or Anonymized Data.</strong>
                We may also share aggregated or anonymized information with
                third parties that do not directly identify you.
              </li>
              <li>
                <strong>Group companies.</strong>
                Your personal data may be shared among affiliates and
                subsidiaries. In such cases, these companies must abide by our
                data privacy and security requirements and are not allowed to
                use personal data they receive from us for any other purpose. We
                may also disclose personal data as part of a corporate
                transaction, such as a merger or sale of assets.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="mb-10">
              THE FOLLOWING ARE A FEW OF THE ESSENTIAL PROCESSORS WE TRUST:
            </h3>
            <ul className="mb-10">
              <li>
                <strong>Amplitude</strong>{' '}
                <a
                  href="https://amplitude.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://amplitude.com/privacy/
                </a>
                . Track user interactions and engagement within the App. Collect
                user behavior data, event tracking, and App usage statistics to
                analyze user behavior, measure app performance, and improve user
                experience.
              </li>
              <li>
                <strong>AppsFlyer</strong>{' '}
                <a
                  href="https://www.appsflyer.com/legal/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.appsflyer.com/legal/privacy-policy/
                </a>
                . Provide mobile attribution and marketing analytics. Collect
                device information, user interactions, and attribution data to
                measure the effectiveness of marketing campaigns and improve
                user acquisition strategies.
              </li>
              <li>
                <strong>Facebook (Analytics)</strong>{' '}
                <a
                  href="https://www.facebook.com/privacy/policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/privacy/policy/
                </a>
                . Track user interactions and engagement within the App.
                Collects user behavior data, event tracking, and App usage
                statistics to analyze user behavior, measure app performance,
                and improve user experience.
              </li>
              <li>
                <strong>Google Sign-In</strong>{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
                . Allow users to sign in to our App using their Google account.
                Collects user`s Google ID, name, email address, and profile
                picture to authenticate users and provide a seamless login
                experience.
              </li>
              <li>
                <strong>Firebase Crashlytics</strong>{' '}
                <a
                  href="https://firebase.google.com/support/privacy?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy?hl=en
                </a>
                . Track and report App crashes and stability issues. Collect
                crash reports, device state information, and user interactions
                leading up to a crash to help us identify and fix bugs, ensuring
                a stable and reliable App experience.
              </li>
              <li>
                <strong>Firebase Authentication</strong>{' '}
                <a
                  href="https://firebase.google.com/support/privacy?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy?hl=en
                </a>
                . Provide secure authentication for users signing in to our App.
                Collect user identifiers (e.g., email, phone number) and
                authentication tokens to manage user sessions and secure access
                to the App.
              </li>
              <li>
                <strong>Firebase Analytics</strong>{' '}
                <a
                  href="https://firebase.google.com/support/privacy?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy?hl=en
                </a>
                . Track user interactions and engagement within the App. Collect
                user behavior data, event tracking, and App usage statistics to
                analyze user behavior, measure App performance, and improve user
                experience.
              </li>
              <li>
                <strong>Firebase Remote Config</strong>{' '}
                <a
                  href="https://firebase.google.com/support/privacy?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy?hl=en
                </a>
                . Provide a service to remotely configure and customize the
                App`s behavior and appearance without requiring users to
                download an App update. Collects user interaction data and App
                usage statistics to deliver personalized content and experiences
                based on user behavior and preferences.
              </li>
              <li>
                <strong>Firebase In-App Messaging</strong>{' '}
                <a
                  href="https://firebase.google.com/support/privacy?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://firebase.google.com/support/privacy?hl=en
                </a>
                . Send targeted messages to users within the App. Collect user
                interaction data with messages and in-app events to help us
                deliver relevant messages and promotions to users.
              </li>
              <li>
                <strong>Reteno</strong>{' '}
                <a
                  href="https://reteno.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://reteno.com/privacy-policy
                </a>
                . Enhance user engagement through personalized communication and
                retention strategies, including marketing emails and push
                notifications. Collect user activity data, preferences,
                engagement metrics, and contact information to create
                personalized user experiences, improve retention efforts, and
                send targeted marketing emails and push notifications.
              </li>
            </ul>
          </section>
          <section>
            <h3>DATA STORAGE AND CROSS-BORDER TRANSFERS</h3>
            <p className="mb-10">
              The personal data we maintain will primarily be stored and
              processed within the EU. We will do our best to keep this personal
              data secure. All information we hold is stored on our secure
              servers (which we own or license from appropriate third parties).
              We use industry-standard procedures and security standards to
              prevent unauthorized access to our servers. However, there may
              arise situations where we need to collaborate with trusted third
              parties located outside the EU to deliver services and
              subscriptions to you (for instance, when utilizing servers in the
              US). We choose our processors very carefully. We do not work with
              processors based in countries where we are concerned about the
              rule of law with respect to privacy.
            </p>
            <p className="mb-10">
              We have entered into Standard Contractual Clauses with all non-EEA
              third parties whose data processing tools we use (data processors)
              if there is no adequacy decision by the EU Commission for their
              particular country. We adhere to the principles of minimization
              and anonymization, where feasible, to ensure compliance with the
              GDPR and other relevant data privacy laws when transferring
              personal data, if necessary.
            </p>
            <p className="mb-10">
              By providing your personal data, you explicitly consent to its
              transfer, storage, or processing outside the EU. We will take all
              reasonable steps to ensure that this information is handled
              securely and in accordance with this Privacy Policy.
            </p>
          </section>
          <section>
            <h3>SECURITY</h3>
            <p className="mb-10">
              Ensuring the security of your data is a top priority for us. We
              employ robust technical and organizational measures to safeguard
              the personal information entrusted to us.
            </p>
            <p className="mb-10">
              Your personal data is safeguarded by the password you create when
              registering on our Platforms. It`s essential to choose a strong
              password and keep it confidential to prevent unauthorized access.
              Additionally, refrain from sharing your password and ensure the
              security of your computer or mobile device.
            </p>
            <p className="mb-10">
              We have instituted reasonable administrative, technical, and
              physical security measures to protect your personal data from
              unauthorized access, alteration, or destruction. For instance:
            </p>
            <ul className="mb-10">
              <li>
                We utilize SSL encryption (HTTPS) for all interactions involving
                personal data.
              </li>
              <li>
                Our databases are encrypted, and we store data on physically
                secure servers protected by firewalls.
              </li>
            </ul>
            <p className="mb-10">
              In the event of a personal data breach as defined in Article 4.12
              of the GDPR, we will promptly notify you. This notification will
              include relevant details, measures taken, and an assessment of
              associated risks, as required by applicable law and our Privacy
              Policy. We are committed to addressing breaches promptly and
              transparently, taking necessary actions such as logging affected
              users out, initiating password resets, and other appropriate
              measures to mitigate the breach.
            </p>
            <p className="mb-10">
              To report a personal data breach or seek assistance, please
              contact us at{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              . We will address your concerns accordingly.
            </p>
          </section>
          <section>
            <h3>YOUR PRIVACY RIGHTS </h3>
            <p className="mb-10">
              We aim to ensure that you are fully informed about all your data
              protection rights and the methods available to exercise them.
              Please note that these rights may vary depending on your location:
            </p>
            <ul className="mb-10">
              <li>
                <strong>Access:</strong> you can request to receive a copy of
                the personal data we hold about you.
              </li>
              <li>
                <strong>Rectification:</strong> if you believe that any personal
                data, we are holding about you is incorrect or incomplete, you
                can request that we correct or supplement it. You can also
                correct some of this information directly from your account.
                Please contact us as soon as possible upon noticing any such
                inaccuracy or incompleteness.
              </li>
              <li>
                <strong>Objection:</strong> you can contact us to inform us that
                you object to the collection or use of your personal data for
                certain purposes.
              </li>
              <li>
                <strong>Erasure:</strong> you can request that we erase some or
                all of your personal data from our systems.
              </li>
              <li>
                <strong>Restriction of Processing:</strong> you can ask us to
                restrict or limit further processing of your personal data.
              </li>
              <li>
                <strong>Portability:</strong> you have the right to ask for a
                copy of your personal data in a machine-readable format. You can
                also request that we transmit the personal data to another
                entity where technically feasible.
              </li>
              <li>
                <strong>Withdrawal of Consent:</strong> if we are processing
                your personal data based on your consent (as indicated at the
                time of such data collection), you have the right to withdraw
                your consent at any time. Please note, however, that if you
                exercise this right, you may have then to provide express
                consent on a case-by-case basis for the use or disclosure of
                certain of your personal data, if such use or disclosure is
                necessary to enable you to utilize some or all Platforms.
              </li>
              <li>
                <strong>Right to File Complaint:</strong> you have the right to
                lodge a complaint about our practices with respect to your
                personal data with the supervisory authority of your country.
              </li>
            </ul>
            <p className="mb-10">
              To exercise your rights, please contact us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              or write to us at the address set forth in the “Contact” section.
            </p>
            <p className="mb-10">
              If you submit a request, we typically aim to fulfill it within one
              month. If additional time is needed to assist you in exercising
              your rights, we will inform you accordingly. We reserve the right
              to reject manifestly unfounded or excessive requests.
            </p>
            <p className="mb-10">
              During the process of exercising your data protection rights, we
              may ask you to confirm your identity. This verification step
              ensures that you are entitled to access certain information and
              that the rights of third parties are not infringed upon. If we are
              unable to verify your request, we will be unable to fulfill it
            </p>
          </section>
          <section>
            <h3>OUR POLICIES CONCERNING CHILDREN</h3>
            <p className="mb-10">
              If you are in the EU, you must meet the age requirement specified
              by the laws of your country to use our services, e.g. if you are
              at least 16 years old in Germany, you are eligible to provide
              explicit consent for the processing of your data. Alternatively,
              if you are under the required age, you can still use our services
              with the consent of your parent or legal guardian. In the event
              that we learn that we have inadvertently gathered personal data
              from children, we will take reasonable measures to promptly erase
              such information from our records.
            </p>
            <p className="mb-10">
              If you are a parent and learn that your child is using our
              Platforms without your permission, or if you have a specific
              question about data privacy, do not hesitate to get in touch with
              us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              .
            </p>
            <p className="mb-10">
              If you are in the United States, you must be at least 13 years old
              to use the Platforms. If we become aware that information has been
              collected from children under the age of 13 in the United States
              in violation of the Children’s Online Privacy Protection Act of
              1998 and its regulations, we will not disclose this data. We
              reserve the right to promptly delete the account and erase all
              associated information, including health and sensitive data, from
              our servers.
            </p>
          </section>
          <section>
            <h3>HOW CAN YOU MANAGE YOUR DATA?</h3>
            <p className="mb-10">
              If you wish to access, correct, or update your personal data, you
              can do so at any time by contacting us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              .
            </p>
            <p className="mb-10">
              If you would like us to delete your information, you may:
            </p>
            <p className="mb-10">
              (1) contact us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              and describe your request in a few words, and/or
            </p>
            <p className="mb-10">
              (2) If you are Android user: open “Profile”, then “Settings”,
              select “Account Details”, press “Delete Account” button, confirm
              deletion on the popup and write email to support team via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              .
            </p>
            <p className="mb-10">
              (3) If you are IOS user: open “Profile”, then “Settings”, select
              “Account Details”, press “Delete Account” button, confirm deletion
              on the popup:
            </p>
            <p className="mb-10">
              (i) if you purchased a subscription on the Site: write email on
              the opened chat to support team;
            </p>
            <p className="mb-10">
              (ii) if you purchased a subscription via App Store: no additional
              actions required, account deleted.
            </p>
            <p className="mb-10">
              If you purchased a subscription via our Site and proceed with the
              deletion of your account, you will no longer have access to the
              account or subscriptions associated with your account.
            </p>
            <p className="mb-10">
              Please note that if you ask us to delete your account, all your
              progress in the application and any unused virtual items will be
              lost and we may not be able to restore them in the future.
            </p>
          </section>
          <section>
            <h3>CHANGES TO THIS PRIVACY POLICY</h3>
            <p className="mb-10">
              We reserve the right to amend this Privacy Policy from time to
              time to reflect changes in the law, our data collection and data
              use practices, the features of our Platforms, or advances in
              technology.
            </p>
            <p className="mb-10">
              Please check this page periodically for changes and refer to the
              “last updated” date at the top of the page to know if it has been
              revised since your last visit. If we make any changes to this
              Privacy Policy that we consider to be material to your consent, we
              will notify you of them.
            </p>
          </section>
          <section>
            <h3>PRIVACY NOTICE FOR CALIFORNIA RESIDENTS, US</h3>
            <p className="mb-10">
              <strong>
                We don`t sell your personal info to others for money, and we`re
                not in the business of trading data.
              </strong>
              But like many online companies, we team up with other parties to
              handle our ads on different platforms. Sometimes, we may share
              some basic personal info with them for targeted ads. This might be
              seen as “selling“ or “sharing“ under the CCPA. If you want to opt
              out of this, check out this Privacy Policy on how to protect your
              privacy.
            </p>
            <p className="mb-10">
              <strong>Opt-Out Provision:</strong>
              even though we don`t make money by selling your personal info, you
              still have the right to opt out of sharing it with our analytics
              and advertising partners, as defined by California or other
              relevant US state laws. You can do this by adjusting your browser
              settings by reaching out to us directly.
            </p>
            <p className="mb-10">
              <strong>Limitation on Handling Sensitive Information:</strong>
              we only process sensitive personal information when absolutely
              necessary to provide you with products or access to use Platforms.
            </p>
            <p className="mb-10">
              <strong>Request for Access:</strong>
              you have the right to ask for access to (i) the personal and
              sensitive information we have about you and how we use it; and
              (ii) the categories, sources, and third parties that have received
              your personal information or to whom it has been “sold“ or
              disclosed in the past 12 months. You can make this request twice a
              year at no cost.
            </p>
            <p className="mb-10">
              <strong>California Residents` Rights:</strong>
              residents of California have the right to request, once a year,
              disclosure regarding any personal information shared with third
              parties for their separate direct marketing purposes. Even though
              we do not share information with third parties for marketing, you
              can reach out to us via{' '}
              <a
                href="https://account.nutrimate.fit/contact-form"
                target="_blank"
                rel="noreferrer"
              >
                https://account.nutrimate.fit/contact-form
              </a>
              . Please ensure the subject line reads “California Privacy Rights
              Request,“ and include relevant details such as your name, street
              address, city, state, and ZIP code.
            </p>
            <p className="mb-10">
              All mentions of “personal data“ in this policy include
              “sensitive/personal information“ as defined by California laws.
            </p>
          </section>
          <section>
            <h3>
              PRIVACY NOTICE FOR VIRGINIA, CONNECTICUT, COLORADO, UTAH, AND
              NEVADA, US
            </h3>
            <p className="mb-10">
              We include this section for residents of other US states with
              privacy laws that may impact them. These privacy laws include the
              Virginia Consumer Data Privacy Act (“VCDPA”), the Connecticut Data
              Privacy Act (“CTDPA”), the Utah Consumer Privacy Act (“UCPA”), the
              Colorado Privacy Act (“CPA”), and the Nevada Privacy Law (“NPL”).
              This section is intended to comply with these laws by
              supplementing the information provided elsewhere in the Privacy
              Policy.
            </p>
            <p className="mb-10">
              <strong>Collection of personal information.</strong>
              We may collect the personal information as described and
              categorized above. Please note that some of this personal
              information will be considered sensitive under your state’s legal
              definition which can vary across different states. The personal
              information we may collect depending on how you use our Platform
              includes mental or physical health information, racial or ethnic
              origin, and information about sexual orientation or gender
              identity.
            </p>
            <p className="mb-10">
              <strong>Use of personal information.</strong>
              We may collect, use, or disclose personal information about US
              state residents for the purposes described above.
            </p>
            <p className="mb-10">
              <strong>Disclosure of personal information.</strong>
              We may disclose your personal information to the categories of
              service providers and third parties identified and described in
              this Privacy Policy.
            </p>
            <p className="mb-10">
              <strong>Your privacy rights.</strong>
              We generally provide the privacy rights described above to you
              regardless of your location. Your state may afford you additional
              privacy rights as noted below. To exercise your right, please see
              the contact information here or follow the instructions below for
              specific state rights. We will respond to your verifiable request
              within the time limit afforded under applicable law.
            </p>
            <p className="mb-10">
              <strong>
                Residents of Colorado, Connecticut, Virginia, and Utah
              </strong>
              have the right to opt out of targeted advertising and sales.
              Please know that we do not trigger this requirement because we do
              not sell your personal information for payment.
            </p>
            <p className="mb-10">
              <strong>For users in Colorado, Connecticut, and Virginia,</strong>
              you may opt out of profiling in furtherance of decisions that
              produce legal or similarly significant effects. While you may
              still make this request, we do not currently use profiling in this
              manner.
            </p>
            <p className="mb-10">
              <strong>Nevada</strong> provides its residents a limited right to
              opt out of the sale of personal information. Please know that we
              do not trigger this requirement because we do not sell your
              personal information for payment.
            </p>
          </section>
        </article>
      </main>
      <footer className="footer">
        <p className="footer__content">
          ©{dayjs().format(LONG_YEAR_FORMAT)} All Rights Reserved.
        </p>
      </footer>
    </>
  )
}
