const T_PREFIX = 'onboarding.kitLifeStyle.options'
export const KIT_LIFE_STYLE_OPTIONS = [
  {
    textPath: `${T_PREFIX}.busy`,
    value: 'busy',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/employee.png',
  },
  {
    textPath: `${T_PREFIX}.haveTime`,
    value: 'have_some_time',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/working-hours.png',
  },
  {
    textPath: `${T_PREFIX}.free`,
    value: 'im_free',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/relax-working-hours.png',
  },
]
