import React from 'react'
import { Trans } from 'react-i18next'

import { OnboardingButtonText } from 'value-constants'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV3 } from 'components/CustomerReviews'
import { FAQList } from 'components/FAQList'
import { FeaturedBlock } from 'components/FeaturedBlock'
import { Spinner } from 'components/Spinner'

import { TPageProps } from 'models/common.model'

import { TOTAL_USERS } from 'root-constants'

import {
  DiscountBanner,
  IntroMediaV2,
  PurchaseMoneyBackGuarantee,
} from '../lib/components'
import { usePurchase } from '../lib/usePurchase'
import { StyledPurchaseV3 as S } from './PurchaseV3.styles'

export const PurchaseV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const {
    hasPrices,
    btnLabel,
    title,
    subtitle,
    firstSubscriptionsRef,
    titleElementRef,
    selectedSubscription,
    handleGetPlan,
    handleIntroMediaButtonClick,
  } = usePurchase(nextPagePath)

  if (!hasPrices) return <Spinner />

  return (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>{title || <Trans i18nKey="purchase.title" />}</S.Title>
          <S.Subtitle>
            {subtitle || (
              <Trans
                i18nKey="purchase.subtitle"
                values={{ count: TOTAL_USERS }}
              />
            )}
          </S.Subtitle>
          <DiscountBanner />
          <IntroMediaV2
            hasDiscountBanner
            elemForComparisonRef={titleElementRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <S.PlanHighlights titleElementRef={titleElementRef} />
        </S.ProgramPlanContainer>

        <SubscriptionsBlock ref={firstSubscriptionsRef} titleMargin={16} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {btnLabel}
        </S.Button>

        {selectedSubscription && (
          <S.Disclaimer selectedSubscription={selectedSubscription} />
        )}
        <FeaturedBlock />
        <PurchaseMoneyBackGuarantee />
        <S.SecurityInfo />
      </S.Wrapper>

      <S.SecondaryWrapper>
        <S.CustomerReviewsContainer>
          <S.PhotoResult titlePath="purchase.photoResult.titleV2" />
          <FAQList />
          <CustomerReviewsV3 />
          <S.Button
            type="button"
            data-button-number="3"
            data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
            onClick={handleGetPlan}
          >
            {btnLabel}
          </S.Button>
        </S.CustomerReviewsContainer>
      </S.SecondaryWrapper>
    </>
  )
}
