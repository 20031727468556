import { IdealWeightTimeValue } from 'value-constants'

export const ANSWER_OPTIONS = [
  {
    value: IdealWeightTimeValue.LESS_THAN_YEAR_AGO,
    title: 'onboarding.idealWeightTime.options.lessThanYearAgo',
  },
  {
    value: IdealWeightTimeValue.ONE_TWO_YEARS_AGO,
    title: 'onboarding.idealWeightTime.options.oneTwoYearsAgo',
  },
  {
    value: IdealWeightTimeValue.MORE_THAN_THREE_YEARS_AGO,
    title: 'onboarding.idealWeightTime.options.moreThanThreeYearsAgo',
  },
  {
    value: IdealWeightTimeValue.NEVER,
    title: 'onboarding.idealWeightTime.options.never',
  },
]

const T_PREFIX = 'onboarding.idealWeightTime.options'
export const KIT_IDEAL_WEIGHT_TIME_OPTIONS = [
  {
    textPath: `${T_PREFIX}.lessThanYearAgo`,
    value: 'less_than_year_ago',
  },
  {
    textPath: `${T_PREFIX}.oneTwoYearsAgo`,
    value: 'one_two_years_ago',
  },
  {
    textPath: `${T_PREFIX}.moreThanThreeYearsAgo`,
    value: 'more_than_three_years_ago',
  },
  {
    textPath: `${T_PREFIX}.never`,
    value: 'never',
  },
]
