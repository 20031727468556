import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { CustomPageId } from 'page-constants'
import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledKitAgeRangeV1 as S } from './KitAgeRangeV1.styles'
import { RANGES } from './constants'

export const KitAgeRangeV1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const { title, subtitle } = useDynamicOBConfig()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const question =
    title || t('onboarding.ageRangeV5.title', { lng: Language.EN })

  const localePrefix = language === Language.ES ? language : Language.EN

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    question,
    nextPagePath,
  })

  const optionProps = {
    name: CustomPageId.AGE_RANGE,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
      eventLogger.logFirstPageCompleted({
        question,
        answers: value,
      })
    },
  }

  return (
    <KitPageContainer paddingTop={12}>
      <S.Title>{title || t('onboarding.ageRangeV5.title')}</S.Title>
      <S.Subtitle>{subtitle || t('onboarding.ageRangeV5.subtitle')}</S.Subtitle>

      <S.OptionsContainer>
        {RANGES.map(({ value, text, imagePath }) => (
          <S.Option
            {...optionProps}
            key={value}
            disabled={isAnswersDisabled}
            value={value}
          >
            <S.RangeImage>
              <img
                src={`${CDN_FOLDER_LINK}${imagePath}-${localePrefix}.png`}
                alt="age-range"
              />
            </S.RangeImage>
            <S.Range>
              {t('onboarding.ageRangeV5.ageRange', {
                ageRange: text,
              })}
              <S.ArrowIcon />
            </S.Range>
          </S.Option>
        ))}
      </S.OptionsContainer>
      <S.Footer />
    </KitPageContainer>
  )
}
