import React from 'react'
import { Trans } from 'react-i18next'

import { TimerProvider } from 'contexts/TimerProvider'
import { buttonTheme } from 'storybook-ui'
import { OnboardingButtonText } from 'value-constants'
import { AdaptiveDiscountBlock } from 'widgets/AdaptiveDiscountBlock'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV6 } from 'components/CustomerReviews'
import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { HowItWorks } from 'components/HowItWorks'
import { RefundBanner } from 'components/RefundBanner'
import { SecurityInfoV2 } from 'components/SecurityInfo'
import { Spinner } from 'components/Spinner'

import { TEN_MINUTES_IN_SECONDS } from 'modules/purchaseSubscription/constants'

import { TPageProps } from 'models/common.model'

import { StyledKitPurchaseVariant1 as S } from '../lib/KitPurchaseVariant1.styles'
import {
  AppReached,
  BoostResults,
  IntroMediaV3,
  PurchaseDisclaimer,
  PurchaseFeaturedBlock,
  WeightLossJourney,
} from '../lib/components'
import { usePurchase } from '../lib/usePurchase'

export const KitPurchaseVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    hasPrices,
    selectedSubscription,
    btnLabel,
    hasDynamicDiscountBanner,
    headerTimerRef,
    firstSubscriptionsRef,
    handleScrollOnSubscriptionBlock,
    handleGetPlan,
  } = usePurchase(nextPagePath)

  if (!hasPrices) return <Spinner />

  return (
    <>
      {hasDynamicDiscountBanner && <DynamicDiscountBanner />}
      <div>
        <TimerProvider id={pageId} time={TEN_MINUTES_IN_SECONDS}>
          <S.DiscountTimer
            elemRef={headerTimerRef}
            onButtonClick={handleScrollOnSubscriptionBlock}
          />
          <div>
            <S.Wrapper>
              <RefundBanner variant="secondary" />
              <S.Section>
                <IntroMediaV3 />
              </S.Section>
              <S.PersonalizedPlanV1 />
              <S.DiscountTitle>
                <Trans i18nKey="purchase.discountTitle" />
              </S.DiscountTitle>
              <AdaptiveDiscountBlock />
              <S.Stack>
                <SubscriptionsBlock
                  hasTitle={false}
                  ref={firstSubscriptionsRef}
                />
              </S.Stack>
              <S.MoneyBackText>
                <Trans i18nKey="purchase.moneyBackGuaranteeV3.description" />
              </S.MoneyBackText>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="2"
                data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {btnLabel}
              </S.Button>
              {selectedSubscription && (
                <S.Section>
                  <PurchaseDisclaimer
                    selectedSubscription={selectedSubscription}
                  />
                </S.Section>
              )}
              <RefundBanner />
              <S.HowItWorksWrap>
                <HowItWorks>
                  <S.Button
                    width="100%"
                    data-button-number="3"
                    data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
                    theme={buttonTheme.NUTRIMATE_PRIMARY}
                    onClick={handleGetPlan}
                  >
                    {btnLabel}
                  </S.Button>
                </HowItWorks>
              </S.HowItWorksWrap>
              <S.MoneyBackGuaranteeV3 />
            </S.Wrapper>
            <S.VideoReviews title="kitPurchaseV1.videoReviewTitle" />
            <S.Wrapper>
              <S.Section>
                <WeightLossJourney title="kitPurchaseV1.weightLossJourneyTitle" />
              </S.Section>
              <S.Section>
                <AppReached>
                  <S.Button
                    data-button-number="3"
                    data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE_2}
                    margin="0 auto"
                    onClick={handleScrollOnSubscriptionBlock}
                    theme={buttonTheme.NUTRIMATE_PRIMARY}
                  >
                    {btnLabel}
                  </S.Button>
                </AppReached>
              </S.Section>
              <S.Section>
                <BoostResults />
              </S.Section>
              <CustomerReviewsV6 />
              <S.Section>
                <PurchaseFeaturedBlock />
              </S.Section>
              <RefundBanner />
              <S.DiscountTitle>
                <Trans i18nKey="purchase.discountTitle" />
              </S.DiscountTitle>
              <AdaptiveDiscountBlock />
              <S.Stack>
                <SubscriptionsBlock hasTitle={false} />
              </S.Stack>
              <S.MoneyBackText>
                <Trans i18nKey="purchase.moneyBackGuaranteeV3.description" />
              </S.MoneyBackText>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="5"
                data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {btnLabel}
              </S.Button>
              {selectedSubscription && (
                <S.DisclaimerV2 selectedSubscription={selectedSubscription} />
              )}
              <SecurityInfoV2 />
            </S.Wrapper>
          </div>
        </TimerProvider>
      </div>
    </>
  )
}
