import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PriceValue } from 'components/PriceValue'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useVatInfo } from 'hooks/useHasVatInfo'

import {
  COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN,
  PurchaseProduct,
} from 'modules/purchaseSubscription/constants'
import {
  selectCurrency,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionIntroDifAmount,
  selectSubscriptionIntroDifPercents,
  selectSubscriptionTrialPeriodPrice,
  selectTrialSubscriptionFullPriceBeforeDiscount,
} from 'modules/purchaseSubscription/redux/selects'

import { StyledCheckoutSummary as S } from './CheckoutSummary.styles'

type TProps = {
  isTrialVariant?: boolean
}

const useSelectors = () =>
  useBulkSelector({
    currency: selectCurrency,
    cohortToUse: selectCurrentVariantCohortToUse,
    product: selectProductId,
    discountAmount: selectSubscriptionIntroDifAmount,
    discountPercent: selectSubscriptionIntroDifPercents,
    fullPrice: selectSubscriptionFullPrice,
    trialCurrentPrice: selectSubscriptionTrialPeriodPrice,
    trialPriceBeforeDiscount: selectTrialSubscriptionFullPriceBeforeDiscount,
  })

export const CheckoutSummary: React.FC<TProps> = ({
  isTrialVariant = false,
}) => {
  const { t } = useTranslation()
  const {
    currency,
    fullPrice,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    discountPercent,
    discountAmount,
    cohortToUse,
    product,
  } = useSelectors()

  const hasVatInfo = useVatInfo()

  const getOldPrice = () => {
    if (
      product === PurchaseProduct.SEVEN_DAY &&
      !COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse)
    ) {
      return trialPriceBeforeDiscount.toFixed(2)
    }

    return fullPrice.toFixed(2)
  }

  if (!isTrialVariant) {
    return (
      <S.DescriptionContainer>
        <S.PersonalizedPlan>
          <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
        </S.PersonalizedPlan>
        <S.PlanPrice>
          <PriceValue value={fullPrice} />
        </S.PlanPrice>
      </S.DescriptionContainer>
    )
  }

  return (
    <>
      <S.DescriptionContainer>
        <S.PersonalizedPlan>
          <Trans i18nKey="purchase.checkoutV1.personalizedPlan" />
        </S.PersonalizedPlan>
        <S.PlanPrice>
          <PriceValue value={getOldPrice()} />
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.DescriptionContainer>
        <S.PersonalizedPlan>
          <Trans
            i18nKey="purchase.checkoutV1.offerDiscount"
            values={{ discountPercent }}
          />
        </S.PersonalizedPlan>
        <S.Discount>
          -
          <PriceValue value={discountAmount} />
        </S.Discount>
      </S.DescriptionContainer>
      <S.TotalAmountContainer>
        <span>
          <Trans
            i18nKey="purchase.checkoutV1.total"
            values={{
              includingVat: hasVatInfo ? t('purchase.includingVat') : '',
            }}
          />
        </span>
        <span>
          <PriceValue value={trialCurrentPrice.toFixed(2)} />
        </span>
      </S.TotalAmountContainer>
      <S.DiscountDescription>
        <Trans
          i18nKey="purchase.checkoutV1.discountDescription"
          values={{
            discountAmount,
            discountPercent,
            currency,
          }}
        />
      </S.DiscountDescription>
    </>
  )
}
