import React from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { PriceValue } from 'components/PriceValue'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { usePayment } from 'hooks/usePayment'

import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { goTo } from 'browser-history'
import { Color } from 'root-constants'

import { useSubscriptionItem } from '../../hooks/useSubscriptionItem'
import { StyledKitEmailSubscriptionItemV1 as S } from './KitEmailSubscriptionItemV1.styles'

export const KitEmailSubscriptionItemV1: React.FC<TSubscriptionItemProps> = ({
  subscription,
}) => {
  const { mainPrices, introDiff } = subscription
  const dispatch = useDispatch()
  const { search } = useLocation()
  const email = useSelector(selectUserOnboardingEmail)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)

  const { trialCurrentPrice, periodName } = useSubscriptionItem(subscription)
  const { currentPaymentPageId } = usePageInfo()
  const { handleShowPayment } = usePayment()

  const oldPrice = mainPrices.fullPrice.toFixed(2)

  const handleClick = () => {
    dispatch(sendFacebookParamsAction())
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    goTo(`${currentPaymentPageId}${search}`)
  }

  return (
    <div>
      <S.PlanRow>
        <S.Period>{periodName}</S.Period>
        <S.Price>
          <PriceValue value={trialCurrentPrice} />
        </S.Price>
      </S.PlanRow>
      <S.DiscountRow>
        <S.Button
          minHeight="32px"
          padding="0 16px"
          borderRadius="16px"
          backgroundColor={Color.ORANGE_200}
          color={Color.WHITE}
          fontWeight="700"
          fontSize="16px"
          onClick={handleClick}
        >
          <Trans
            i18nKey="purchase.subscription.discountLabel"
            values={{ discount: introDiff.discountPercentage }}
          />
        </S.Button>
        <S.OldPrice>
          <PriceValue value={oldPrice} />
        </S.OldPrice>
      </S.DiscountRow>
    </div>
  )
}
