import { TFunction, i18n } from 'i18next'

let addTranslationCallback:
  | ((key: string, translation: string) => void)
  | null = null

export const setI18nInterceptorCallback = (
  callback: (key: string, translation: string) => void,
) => {
  addTranslationCallback = callback
}

export const i18nInterceptor = (i18nInstance: i18n): void => {
  const originalT = i18nInstance.t.bind(i18nInstance)

  i18nInstance.t = ((key: string, options?: any) => {
    const translation = originalT(key, options)

    if (addTranslationCallback) {
      addTranslationCallback(key, translation as string)
    }

    return translation
  }) as TFunction
}
