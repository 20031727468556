import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportActionLink } from 'components/SupportActionLink'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK } from 'root-constants'

import { IMAGE_PATH } from '../constants'
import { StyledSecurityInfo as S } from './SecurityInfo.styles'

type TProps = {
  topIndent?: number
  className?: string
}

export const SecurityInfo: React.FC<TProps> = ({ topIndent, className }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const badgeSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <S.Wrapper topIndent={topIndent} className={className}>
      <S.SecureBadge src={badgeSrc} alt="Secure badge" />
      <S.Title>{t('purchase.securityInfo.titleFirst')}</S.Title>
      <S.Text>{t('purchase.securityInfo.textFirst')}</S.Text>
      <S.Title>{t('purchase.securityInfo.titleSecond')}</S.Title>
      <S.Text>{t('purchase.securityInfo.textSecond')}</S.Text>
      <S.Title>{t('purchase.securityInfo.titleThird')}</S.Title>
      <S.Text>
        {t('purchase.securityInfo.textThird')} <SupportActionLink />
      </S.Text>
    </S.Wrapper>
  )
}
