import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import {
  COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN,
  CURRENCY_SYMBOLS,
  Period,
  PurchaseProduct,
} from 'modules/purchaseSubscription/constants'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { StyledSubscriptionItemV4 as S } from './SubscriptionItemV4.styles'

export const SubscriptionItemV4: React.FC<TSubscriptionItemProps> = ({
  subscription: { product, mainPrices, trialPrices, isDefault, currency },
  isSelected,
  hasWeeklyTrial = true,
}) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isWeeklyPlanWithoutTrial = useMemo(
    () =>
      (COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN.includes(cohortToUse) ||
        !hasWeeklyTrial) &&
      trialPrices.durationDays === Period.SEVEN_DAYS,
    [cohortToUse, trialPrices, hasWeeklyTrial],
  )

  const oldFullPrice = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice
        : mainPrices.fullPrice,
    [product, trialPrices, mainPrices],
  )

  const period = useMemo(
    () =>
      product === PurchaseProduct.SEVEN_DAY
        ? t('purchase.subscription.weeklyPeriod', {
            periodQuantity: trialPrices.durationDays,
          })
        : t('purchase.subscription.monthlyPeriod', {
            periodQuantity: mainPrices.periodQuantity,
            count: mainPrices.periodQuantity,
          }),
    [product, mainPrices, trialPrices, t],
  )

  return (
    <S.Wrapper isSelected={isSelected}>
      {isDefault && (
        <S.MostPopularBadge>
          {t('purchase.subscription.mostPopularBadge')}
        </S.MostPopularBadge>
      )}
      <S.Content isSelected={isSelected}>
        <S.PlanContainer>
          <S.PlanPeriod isSelected={isSelected}>{period}</S.PlanPeriod>
          <S.PriceContainer>
            {isWeeklyPlanWithoutTrial ? (
              <div>
                <S.CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {trialPrices.fullPrice}
                </S.CurrentPrice>
              </div>
            ) : (
              <div>
                <S.PreviousPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {oldFullPrice}
                </S.PreviousPrice>{' '}
                <S.CurrentPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {trialPrices.fullPrice}
                </S.CurrentPrice>
              </div>
            )}

            {!isWeeklyPlanWithoutTrial && (
              <S.PreviousPrice>
                {CURRENCY_SYMBOLS[currency]}
                {trialPrices.oldPrices.beforeCustomDiscount?.daily}
              </S.PreviousPrice>
            )}
          </S.PriceContainer>
        </S.PlanContainer>

        <S.CustomPrice isSelected={isSelected}>
          <S.PriceValue>
            {CURRENCY_SYMBOLS[currency]}
            {trialPrices.daily}
          </S.PriceValue>
          <S.TimePeriod>
            {t('purchase.subscription.interval')}{' '}
            {t('purchase.subscription.day')}
          </S.TimePeriod>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
