import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  BMI_INFO_MAP_V2,
  MAX_WEIGHT_VALUE_LENGTH,
  MIN_WEIGHT_VALUE,
} from 'pages/currentWeight/v4/constants'
import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'
import { FloatNumberRegex, INITIAL_INPUT_VALUE, Language } from 'root-constants'

import { StyledCurrentWeightV4 as S } from './CurrentWeightV4.styles'

const T_PREFIX = 'onboarding.currentWeight'

export const CurrentWeightV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()
  const { measuringSystemLabel, measurementSystem, isMetricSelected } =
    useWeightMeasurementSystem()
  const { currentBMI } = useUsersBmi({
    currentUserWeight: (weight.isValid && +weight.value) || 70,
  })
  const handleContinue = useNextStep({
    pageId: CustomPageId.CURRENT_WEIGHT,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const { bmiValue, text, iconSrc, background } =
    BMI_INFO_MAP_V2[currentBMI.bmiValue.value]

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const handleChange = ({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid && value.length <= MAX_WEIGHT_VALUE_LENGTH,
      }))
    }
  }
  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleContinue(+weight.value, measurementSystem)
  }

  useEffect(() => {
    if (weight.isValid) inputRef?.current?.blur()
  }, [weight.isValid])

  return (
    <form onSubmit={handleSubmit}>
      <KitPageContainer>
        <PageTitle marginBottom={24} textAlign="left">
          <Trans i18nKey={`${T_PREFIX}.title`} />
        </PageTitle>
        <S.WeightLabelWrapper>
          <S.WeightLabel>{measuringSystemLabel}</S.WeightLabel>
        </S.WeightLabelWrapper>
        <S.Actions>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              inputMode="numeric"
              value={weight.value}
              inputRef={inputRef}
              isContentCentered
              isValid={weight.isValid}
              min={
                isMetricSelected
                  ? MIN_WEIGHT_VALUE.MIN_WEIGHT_KG
                  : MIN_WEIGHT_VALUE.MIN_WEIGHT_LB
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix>{measuringSystemLabel}</S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <Trans i18nKey={`${T_PREFIX}.errorMessage`} />
            </S.ErrorMessage>
          )}
        </S.Actions>

        {weight.isValid && (
          <S.InfoContainer background={background}>
            <S.InfoImage>
              <img src={iconSrc} alt="bmi-info-icon" />
            </S.InfoImage>
            <S.Info>
              <S.InfoTitle>
                <Trans
                  i18nKey={`${T_PREFIX}.mbiInfo.title`}
                  values={{
                    bmiIndex: currentBMI.bmiIndex,
                    bmiValue: t(bmiValue),
                  }}
                />
              </S.InfoTitle>
              <S.InfoText>
                <Trans i18nKey={text} />
              </S.InfoText>
            </S.Info>
          </S.InfoContainer>
        )}

        <Button
          width="100%"
          type="submit"
          margin="0 auto"
          theme={buttonTheme.NUTRIMATE_PRIMARY}
          disabled={!weight.isValid}
        >
          <Trans i18nKey="actions.continue" />
        </Button>
      </KitPageContainer>
    </form>
  )
}
