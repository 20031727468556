import React from 'react'
import { Trans } from 'react-i18next'

import { PaymentWaitingModal } from 'widgets/PaymentWaitingModal'

import { Container } from 'components/Container'
import { Separator } from 'components/Separator'
import { Spinner } from 'components/Spinner'
import { ThreeDSecureIframe } from 'components/ThreeDSecureIframe'

import securitySystems from 'assets/images/security-systems.png'

import {
  CreditCardFormV1,
  CreditCardHeader,
  PaymentRequestButton,
  PrimerPayPalButton,
} from '../lib/components'
import { StyledCheckout as S } from '../lib/styles'
import { useCheckout } from '../lib/useCheckout'

export const CheckoutVariant2: React.FC = () => {
  const {
    isPaymentWaitingShown,
    isCheckoutReady,
    hasPaypal,
    threeDSecureIframeUrl,
    creditCardPaymentRef,
    setIsPaymentWaitingShown,
    handleCloseCheckout,
  } = useCheckout()

  return (
    <Container>
      {threeDSecureIframeUrl ? (
        <ThreeDSecureIframe />
      ) : (
        <>
          <S.Wrapper isVisible={isCheckoutReady}>
            <S.Title>
              <Trans i18nKey="purchase.checkoutV2.title" />
            </S.Title>
            <S.CloseButton onClick={handleCloseCheckout} />
            <S.PaymentContainer>
              <div ref={creditCardPaymentRef}>
                <CreditCardHeader />
                {isCheckoutReady && <CreditCardFormV1 />}
              </div>
              {hasPaypal && (
                <>
                  <Separator />
                  <PrimerPayPalButton />
                </>
              )}
              <S.ButtonContainer>
                <PaymentRequestButton />
              </S.ButtonContainer>
              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>
                <Trans i18nKey="purchase.checkout.moneyBackGuarantee" />
              </S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </Container>
  )
}
