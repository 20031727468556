import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { PurchaseProduct } from 'modules/purchaseSubscription/constants'
import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'
import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { useSubscriptionPeriodName } from 'modules/purchaseSubscription/hooks/useSubscriptionPeriodName'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { Language } from 'root-constants'

import { COMPLEX_CURRENCY_CN } from '../constants/constants'

const formatPrice = (price?: number) => price?.toFixed(2)

export const useSubscriptionItem = (
  subscription: TSubscriptionItemProps['subscription'],
) => {
  const { product, mainPrices, trialPrices, currency } = subscription
  const language = useSelector(selectLanguage)
  const { periodName } = useSubscriptionPeriodName(subscription)

  const isSevenDay = product === PurchaseProduct.SEVEN_DAY
  const isSubscriptionTrial = trialPrices.fullPrice !== mainPrices.fullPrice
  const isComplexCurrency = isComplexCurrencySymbol(currency)
  const isCompactVariant = language === Language.FR

  const className = isComplexCurrency ? COMPLEX_CURRENCY_CN : ''
  const noTrialCurrentPrice = formatPrice(mainPrices.fullPrice)
  const trialCurrentPrice = formatPrice(trialPrices.fullPrice)
  const oldPrice = formatPrice(
    isSevenDay
      ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice
      : mainPrices.fullPrice,
  )
  const pricePerDay = formatPrice(
    isSevenDay ? mainPrices.daily : trialPrices.daily,
  )
  const oldPricePerDay = formatPrice(
    trialPrices.oldPrices.beforeCustomDiscount?.daily,
  )

  const currencyOptions = getCurrencyOptions(currency)

  return {
    periodName,
    isSubscriptionTrial,
    className,
    isCompactVariant,
    noTrialCurrentPrice,
    trialCurrentPrice,
    oldPrice,
    pricePerDay,
    oldPricePerDay,
    isComplexCurrency,
    currencyOptions,
  }
}
