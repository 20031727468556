import React from 'react'
import { Trans } from 'react-i18next'

import dayjs, { Dayjs } from 'dayjs'

import { useDelayedAnimationPlaying } from 'components/LoseWeightGraph/hooks/useDelayedAnimationPlaying'
import { StyledLoseWeightGraphV3 as S } from 'components/LoseWeightGraphV3/LoseWeightGraphV3.styles'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectEventDate,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'
import {
  DEFAULT_DATE_FORMAT,
  getIntermediateMonthsArray,
} from 'helpers/dateHelper'
import { i18nFormatters } from 'helpers/i18nFormatters'
import { roundNumber } from 'helpers/numberHelper'

import { CDN_FOLDER_LINK } from 'root-constants'

import {
  ANIMATION_PATH,
  MAX_MONTHS_ARRAY_LENGTH,
  MIN_MONTHS_ARRAY_LENGTH,
  SUB_ARRAY_LAST_INDEX,
  SUB_ARRAY_LENGTH,
  WEIGHT_ARRAY_LENGTH,
} from './constants'

type TProps = {
  predictableDate: Dayjs
  eventName: string
  className?: string
}

const useSelectors = () =>
  useBulkSelector({
    goalWeight: selectCurrentUserGoalWeight,
    currentWeight: selectCurrentUserCurrentWeight,
    eventDate: selectEventDate,
    language: selectLanguage,
  })

export const LoseWeightGraphV3: React.FC<TProps> = ({
  predictableDate,
  eventName,
  className,
}) => {
  const { eventDate, language, goalWeight, currentWeight } = useSelectors()
  const [animationRef, isAnimationStarted] = useDelayedAnimationPlaying({
    animationPath: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
  })
  const { measuringSystemLabel } = useWeightMeasurementSystem()

  const isPredictableYearCurrent = predictableDate.year() > dayjs().year()
  const isEvent = eventDate && eventName
  const weightArray = (() => {
    const weightSteps = new Array(WEIGHT_ARRAY_LENGTH).fill(0)

    const step = roundNumber(
      (currentWeight - goalWeight) / WEIGHT_ARRAY_LENGTH,
      2,
    )

    return [
      currentWeight,
      ...weightSteps.reduce(
        (acc, cur, index) => [
          ...acc,
          index
            ? roundNumber(acc[index - 1] - step, 2)
            : roundNumber(currentWeight - step, 2),
        ],
        [],
      ),
    ]
  })()

  const monthsArray = (() => {
    const allMonths = getIntermediateMonthsArray(predictableDate, language)

    if (allMonths.length === MIN_MONTHS_ARRAY_LENGTH) {
      return [...allMonths, ...allMonths]
    }

    if (allMonths.length > MAX_MONTHS_ARRAY_LENGTH) {
      const composedMonths: string[] = new Array(SUB_ARRAY_LENGTH).fill('')

      return [
        ...composedMonths.reduce((acc: string[], cur, index) => {
          return index <= SUB_ARRAY_LAST_INDEX
            ? [...acc, allMonths[index]]
            : [...acc, allMonths[index + 1]]
        }, []),
        allMonths[allMonths.length - 1],
      ]
    }

    return allMonths
  })()

  const getEvent = () => {
    const eventYear = dayjs(eventDate, DEFAULT_DATE_FORMAT).year()
    const currentYear = dayjs().year()
    const isEventInFutureYear = eventYear > currentYear

    let dateOptions: Intl.DateTimeFormatOptions = {}

    if (!isEventInFutureYear) {
      dateOptions = {
        month: 'long',
        day: 'numeric',
        dateStyle: undefined,
      }
    }

    const ISODate = eventDate.split('.').reverse().join('-')
    const formattedEventDate = i18nFormatters.datetime(
      new Date(ISODate),
      language,
      dateOptions,
    )

    return `${eventName} ･ ${formattedEventDate}`
  }

  return (
    <S.GraphContainer className={className}>
      <S.Weight>
        {weightArray.map((item) => (
          <S.Text key={item}>{roundNumber(item, 1)}</S.Text>
        ))}
      </S.Weight>
      <S.Graph ref={animationRef}>
        {isEvent && (
          <S.EventBadge isAnimationStarted={isAnimationStarted}>
            {getEvent()}
          </S.EventBadge>
        )}
        <S.GoalBadge isAnimationStarted={isAnimationStarted}>
          <Trans
            i18nKey="onboarding.eventFeedback.goalBadge"
            values={{
              weight: goalWeight,
              measurementSystem: measuringSystemLabel,
            }}
          />
        </S.GoalBadge>
      </S.Graph>
      <S.Months>
        {monthsArray.map((item, index) =>
          isPredictableYearCurrent && index === monthsArray.length - 1 ? (
            <S.WithYear>
              <S.Text key={item}>{convertTextFromSnakeCase(item)}</S.Text>
              <S.Text>{predictableDate.year()}</S.Text>
            </S.WithYear>
          ) : (
            <S.Text key={item}>{convertTextFromSnakeCase(item)}</S.Text>
          ),
        )}
      </S.Months>
    </S.GraphContainer>
  )
}
