export const IMAGE_PATH = '/images/secure'
export const IMAGE_PATH_V1 = '/images/security-info-badge'

const T_PREFIX = 'purchase.securityInfo'
export const OPTIONS_V1 = [
  {
    titlePath: `${T_PREFIX}.titleFirst`,
    textPath: `${T_PREFIX}.textFirst`,
  },
  {
    titlePath: `${T_PREFIX}.titleSecond`,
    textPath: `${T_PREFIX}.textSecond`,
  },
  {
    titlePath: `${T_PREFIX}.titleThird`,
    textPath: `${T_PREFIX}.textThird`,
  },
] as const
