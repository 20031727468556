import styled, { keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

type TProps = {
  marginBottom?: number
}

const animation = keyframes`
    0% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const StyledCreatingPlanV2 = {
  Wrapper: styled.div`
    position: relative;
    padding: 7px 0 40px;
  `,
  LogoContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 38px;
  `,
  LogoText: styled.div`
    padding-left: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
  `,
  Title: styled.h2`
    margin-top: 32px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: ${Color.DISABLED};
  `,
  Arrows: styled(SvgImage)`
    display: block;
    width: 12px;
    height: 12px;
    margin: 0 auto 8px;
  `,
  Images: styled.div`
    position: relative;
    min-height: 417px;
    margin-bottom: 40px;
  `,
  ChangeableContent: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 328px;
    opacity: 0;

    &[data-is-shown='true'] {
      animation: ${animation} 3000ms ease-in 1;
    }

    img {
      max-width: 320px;
      margin: 0 auto;
    }
  `,
  Subtitle: styled.h2<TProps>`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-bottom: ${({ marginBottom }) => marginBottom || 40}px;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 39px;
    max-width: 464px;
  `,
  ProgressbarContainer: styled.div`
    position: relative;
  `,
}
