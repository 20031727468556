import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'
import { useVatInfo } from 'hooks/useHasVatInfo'

import {
  CURRENCY_SYMBOLS,
  Currency,
} from 'modules/purchaseSubscription/constants'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import fireIcon from 'assets/images/fire-icon.png'

import { ScreenName } from 'root-constants'

import { StyledUpsellSubscriptionBlock as S } from './UpsellSubscriptionBlock.styles'
import {
  ALTERNATIVE_WEIGHT_LOSS_GUIDE_PRICE,
  DEFAULT_DISCOUNT_PERCENTAGE,
  DEFAULT_FULL_PRICE,
  WEIGHT_LOSS_GUIDE_PRICE,
} from './constants'

type TProps = {
  titleI18nKey?: string
  hasDiscount?: boolean
  selectedSubscription: ISubscription | null
  buttonNumber: number
  screenName: ScreenName
  makeUpsell: () => void
}

export const UpsellSubscriptionBlockV2: FC<TProps> = ({
  titleI18nKey,
  hasDiscount = false,
  selectedSubscription,
  buttonNumber,
  screenName,
  makeUpsell,
}) => {
  const { t } = useTranslation()

  const hasVatInfo = useVatInfo()
  const { isAlternativeUpsell } = useExperimentalFeatures()

  const { currency, fullPrice, fullPriceBeforeDiscount, discountPercentage } =
    useMemo(
      () => ({
        currency: selectedSubscription?.currency || Currency.USD,
        fullPrice:
          selectedSubscription?.trialPrices.fullPrice || DEFAULT_FULL_PRICE,
        fullPriceBeforeDiscount:
          selectedSubscription?.mainPrices.fullPrice || DEFAULT_FULL_PRICE,
        discountPercentage:
          selectedSubscription?.introDiff?.discountPercentage ||
          DEFAULT_DISCOUNT_PERCENTAGE,
      }),
      [selectedSubscription],
    )

  const weightLossGuidePrice = `${CURRENCY_SYMBOLS[currency]}${
    isAlternativeUpsell
      ? ALTERNATIVE_WEIGHT_LOSS_GUIDE_PRICE[currency]
      : WEIGHT_LOSS_GUIDE_PRICE[currency]
  }`

  const isMexican = useMemo(() => currency === Currency.MXN, [currency])

  const oneMonthPlan = useMemo(
    () => (
      <S.Item>
        <S.ItemTitle>
          {t('purchase.upsellSubscriptionBlockV2.oneMonthPlan')}
        </S.ItemTitle>
        <S.ItemValue>
          {hasDiscount ? (
            <>
              <S.DiscountLabel>
                <S.DiscountImage>
                  <img src={fireIcon} alt="discount" />
                </S.DiscountImage>
                <S.DiscountValue>
                  <Trans
                    i18nKey="purchase.upsellSubscriptionBlockV2.discountPercentage"
                    values={{ percentageValue: discountPercentage }}
                  />
                </S.DiscountValue>
              </S.DiscountLabel>
              <S.ItemValueStrong>
                <Trans
                  i18nKey="purchase.upsellSubscriptionBlockV2.pricePerMonth"
                  values={{ price: fullPrice, currency }}
                />
              </S.ItemValueStrong>
              {!isMexican && (
                <S.ItemValueStrikethrough>
                  <Trans
                    i18nKey="purchase.upsellSubscriptionBlockV2.pricePerMonth"
                    values={{ price: fullPriceBeforeDiscount, currency }}
                  />
                </S.ItemValueStrikethrough>
              )}
            </>
          ) : (
            <S.ItemValueStrong>
              <Trans
                i18nKey="purchase.upsellSubscriptionBlockV2.pricePerMonth"
                values={{ price: fullPrice, currency }}
              />
            </S.ItemValueStrong>
          )}
        </S.ItemValue>
      </S.Item>
    ),
    [
      hasDiscount,
      isMexican,
      currency,
      fullPrice,
      fullPriceBeforeDiscount,
      discountPercentage,
      t,
    ],
  )

  const weightLossGuide = useMemo(
    () => (
      <S.Item>
        <S.ItemTitle>
          {t('purchase.upsellSubscriptionBlockV2.weightLossGuide')}
        </S.ItemTitle>
        <S.ItemValue>
          <S.DiscountLabel>
            <S.DiscountImage>
              <img src={fireIcon} alt="discount" />
            </S.DiscountImage>
            <S.DiscountValue>
              {t('purchase.upsellSubscriptionBlockV2.free')}
            </S.DiscountValue>
          </S.DiscountLabel>

          <S.ItemValueStrikethrough>
            {weightLossGuidePrice}
          </S.ItemValueStrikethrough>
        </S.ItemValue>
      </S.Item>
    ),
    [weightLossGuidePrice, t],
  )

  const disclaimer = useMemo(
    () =>
      t(
        `purchase.upsellSubscriptionBlockV2.${
          hasDiscount ? 'disclaimerTrial' : 'disclaimer'
        }`,
        {
          fullPrice: hasDiscount ? fullPriceBeforeDiscount : fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
          includingVat: hasVatInfo ? t('purchase.includingVat') : '',
        },
      ),
    [hasDiscount, currency, t, fullPriceBeforeDiscount, fullPrice, hasVatInfo],
  )

  const handleMakeUpsell = useCallback(() => {
    makeUpsell()

    eventLogger.logUpsellCoachingButtonTapped({
      buttonNumber,
      screenName,
    })
  }, [makeUpsell, buttonNumber, screenName])

  return (
    <S.Container>
      <S.Title>
        <Trans
          i18nKey={titleI18nKey || 'purchase.upsellSubscriptionBlockV2.title'}
        />
      </S.Title>

      <S.List>
        {oneMonthPlan}
        {weightLossGuide}
      </S.List>

      <S.Button type="button" onClick={handleMakeUpsell}>
        {t('purchase.upsellSubscriptionBlockV2.addToMyPlan')}
      </S.Button>

      <S.Disclaimer>
        {disclaimer} <TermsOfUseLink /> {t('purchase.contactUs')}{' '}
        <SupportActionLink />
      </S.Disclaimer>
    </S.Container>
  )
}
