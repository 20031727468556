import React, { useCallback, useEffect, useRef } from 'react'

import { StyledTooltip as S } from './Tooltip.styles'

type TTooltipProps = {
  onClose: () => void
  children: React.ReactNode
}

export const Tooltip: React.FC<TTooltipProps> = ({ onClose, children }) => {
  const tooltipRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!tooltipRef.current) return

      if (!tooltipRef.current.contains(event.target as Node)) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, {
      passive: true,
    })
    document.addEventListener('touchstart', handleClickOutside, {
      passive: true,
    })

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [handleClickOutside])

  return <S.TooltipContainer ref={tooltipRef}>{children}</S.TooltipContainer>
}
