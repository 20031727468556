import React, { SyntheticEvent, useRef } from 'react'
import { Trans } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV5 } from 'components/CustomerReviews'
import { Disclaimer } from 'components/Disclaimer'
import { FloatingCTABanner } from 'components/FloatingCTABanner'
import { MoneyBackGuarantee } from 'components/MoneyBackGuarantee'
import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'

import burnIcon from 'assets/images/burn-emoji.png'

import { Language } from 'root-constants'

import { useCancelOffer } from '../lib/useCancelOffer'
import { StyledCancelOffer as S } from './KitCancelOfferV1.styles'

export const KitCancelOfferV1: React.FC = () => {
  const {
    hasPrices,
    language,
    handleGetPlan,
    discountFromSubscriptions,
    handleAmplitudePaymentEvents,
    selectedSubscription,
  } = useCancelOffer()
  const { buttonText } = useDynamicPaywallConfig()

  const triggerElementRef = useRef<HTMLDivElement | null>(null)
  const buttonProps = {
    width: '100%',
    style: { textTransform: 'capitalize' },
    theme: buttonTheme.NUTRIMATE_PRIMARY,
  }

  const handleScroll = (event: SyntheticEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement
    const element = triggerElementRef?.current

    if (element) {
      const { offsetTop, offsetHeight } = element
      const { innerHeight } = window
      const centerOffset = offsetTop - innerHeight / 2 + offsetHeight / 2

      document.body.scrollTo({
        top: centerOffset,
        behavior: 'smooth',
      })
    }

    handleAmplitudePaymentEvents(target)
  }

  if (!hasPrices) return <Spinner />

  return (
    <KitPageContainer paddingTop={0}>
      <S.SpecialOffer>
        <Trans i18nKey="kitCancelOfferV1.title" />
        <img src={burnIcon} alt="fire icon" />
      </S.SpecialOffer>
      <FloatingCTABanner
        elemForComparisonRef={triggerElementRef}
        buttonText={buttonText}
        btnProps={{ onClick: handleScroll }}
      />
      <S.Banner>
        <S.BannerContent isFrLanguage={language === Language.FR}>
          <S.PreviousDiscount>
            <Trans
              i18nKey="kitCancelOfferV1.discount"
              values={{ discount: 50 }}
              components={{ span: <span /> }}
            />
          </S.PreviousDiscount>
          <S.Title>
            <Trans
              i18nKey="kitCancelOfferV1.personalPlan"
              values={{ discount: discountFromSubscriptions }}
              components={{ span: <span /> }}
            />
          </S.Title>
        </S.BannerContent>
      </S.Banner>
      <S.SubscriptionsContainer>
        <SubscriptionsBlock
          titleMargin={24}
          ref={triggerElementRef}
          isCancelOffer
        />
        <Button {...buttonProps} margin="0 auto 24px" onClick={handleGetPlan}>
          <Trans i18nKey="actions.getMyPlan" />
        </Button>
        <MoneyBackGuarantee variant="secondary" />
      </S.SubscriptionsContainer>

      <CustomerReviewsV5 />

      <Button
        {...buttonProps}
        margin="24px auto 64px"
        data-button-number="3"
        onClick={handleScroll}
      >
        <Trans i18nKey="actions.startMyPlan" />
      </Button>
      <S.SecurityInfoV1 />
      {selectedSubscription && (
        <Disclaimer selectedSubscription={selectedSubscription} />
      )}
    </KitPageContainer>
  )
}
