import { useEffect, useRef, useState } from 'react'

import { AnimationConfig } from 'lottie-web'
import lottie from 'lottie-web/build/player/lottie_light'

interface IParams extends Omit<AnimationConfig, 'container'> {
  path?: string
  onComplete?: () => void
  onLoadEnd?: () => void
}

export const useLottie = <T extends Element = HTMLDivElement>({
  path,
  onComplete,
  onLoadEnd,
  name,
  loop = false,
  renderer = 'svg',
  autoplay = true,
  initialSegment,
  rendererSettings,
  audioFactory,
  assetsPath,
}: IParams) => {
  const [isAnimationLoaded, setIsAnimationLoaded] = useState(false)
  const animationRef = useRef<T | null>(null)
  const animationInstance = useRef<any>(null)
  const animationName = name || path

  useEffect(() => {
    if (!animationRef.current || !path) return undefined

    setIsAnimationLoaded(false)

    animationInstance.current = lottie.loadAnimation({
      container: animationRef.current,
      name: animationName,
      path,
      loop,
      renderer,
      autoplay,
      initialSegment,
      rendererSettings,
      audioFactory,
      assetsPath,
    })

    animationInstance.current.addEventListener('DOMLoaded', () => {
      setIsAnimationLoaded(true)
      onLoadEnd?.()
    })

    animationInstance.current.addEventListener('complete', () => {
      onComplete?.()
    })

    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy()
        animationInstance.current = null
      }
    }
  }, [
    path,
    animationName,
    loop,
    renderer,
    autoplay,
    initialSegment,
    rendererSettings,
    audioFactory,
    assetsPath,
    onComplete,
    onLoadEnd,
  ])

  return {
    isAnimationLoaded,
    animationRef,
    play: () => animationInstance.current?.play(),
    pause: () => animationInstance.current?.pause(),
    stop: () => animationInstance.current?.stop(),
  }
}
