import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledCaloriesEqualityV1 = {
  Title: styled(PageTitle)`
    margin-top: 24px;
    text-align: left;
  `,
  Subtitle: styled.h3`
    margin-top: 8px;
    color: ${Color.GREY_101};
    font-size: 17px;
    font-weight: 400;
  `,
}
