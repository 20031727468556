import { PaymentInstrumentType } from '@primer-io/checkout-web'
import { BmiValue } from 'value-constants'

import { Cohort, CountryCode, Gender, Language } from 'root-constants'

export const TEN_MINUTES_IN_SECONDS = 600
export const FIFTEEN_MINUTES_IN_SECONDS = 900

export const enum PaymentState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMPTY_FIELD_ERROR = `can't be blank`
export const DEFAULT_CARDHOLDER_NAME = ' '

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  ONE_CLICK = 'one_click',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  PRIMER = 'primer',
  STRIPE = 'stripe',
}

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  AUD = 'aud',
  CAD = 'cad',
  MXN = 'mxn',
  BRL = 'brl',
  JPY = 'jpy',
  PLN = 'pln',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.MXN]: 'MXN',
  [Currency.BRL]: 'R$',
  [Currency.JPY]: '¥',
  [Currency.PLN]: 'zł',
}

export const ZERO_DECIMAL_CURRENCIES = [
  'gnf',
  'pyg',
  'rwf',
  'jpy',
  'bif',
  'kmf',
  'krw',
  'clp',
  'mga',
  'ugx',
  'djf',
  'vnd',
  'vuv',
  'xaf',
  'xof',
  'xpf',
]

export const enum PurchasePageType {
  WEB_LANDING = 'web_landing',
  EMAIL_LANDING = 'email_landing',
}

export const enum PurchaseProduct {
  SEVEN_DAY = 'sevenDay',
  ONE_MONTH = 'oneMonth',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
}

export const COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN = [
  Cohort.MF_INTRO_2,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]

export const enum Period {
  SEVEN_DAYS = 7,
}

export const WEEKS_BY_PRODUCT_NAME_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 1,
  [PurchaseProduct.ONE_MONTH]: 4,
  [PurchaseProduct.THREE_MONTHS]: 12,
}

export type TCardPaymentSystem = PaymentSystem.PRIMER | PaymentSystem.STRIPE

export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'purchase.paymentErrors.insufficientFunds',
  PAYMENT_FAILED: 'purchase.paymentErrors.insufficientFunds',
  DECLINED: 'purchase.paymentErrors.declinedError',
  AUTHENTICATION_REQUIRED: 'purchase.paymentErrors.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'purchase.paymentErrors.withdrawLimit',
  EXPIRED_CARD: 'purchase.paymentErrors.expiredCard',
  COMMON_ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  INVALID_CARD_NUMBER: 'purchase.paymentErrors.somethingWentWrongError',
  LOST_OR_STOLEN_CARD: 'purchase.paymentErrors.somethingWentWrongError',
  SUSPECTED_FRAUD: 'purchase.paymentErrors.somethingWentWrongError',
  UNKNOWN: 'purchase.paymentErrors.somethingWentWrongError',
  REFER_TO_CARD_ISSUER: 'purchase.paymentErrors.somethingWentWrongError',
  DO_NOT_HONOR: 'purchase.paymentErrors.somethingWentWrongError',
  ISSUER_TEMPORARILY_UNAVAILABLE:
    'purchase.paymentErrors.somethingWentWrongError',
}

export const PRIMER_PAY_PAL_ID = 'paypal-button'

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
}

export const PRIMER_PAYMENT_METHODS_MAP = {
  [PaymentInstrumentType.CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentInstrumentType.APPLE_PAY]: PaymentMethod.APPLE_PAY,
  [PaymentInstrumentType.GOOGLE_PAY]: PaymentMethod.GOOGLE_PAY,
  [PaymentInstrumentType.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentInstrumentType.PAYPAL_VAULTED]: PaymentMethod.PAYPAL,
}

export const GOAL_FAT_LEVEL_MAP = {
  [Gender.FEMALE]: '14-20',
  [Gender.MALE]: '6-13',
}

export const CURRENT_FAT_LEVEL_MAP = {
  [Gender.MALE]: {
    [BmiValue.UNDERWEIGHT]: '2-4',
    [BmiValue.NORMAL]: '6-13',
    [BmiValue.OVERWEIGHT]: '14-17',
    [BmiValue.OBESITY]: '18-25',
    [BmiValue.EXTREME_OBESITY]: '26+',
  },
  [Gender.FEMALE]: {
    [BmiValue.UNDERWEIGHT]: '0-12',
    [BmiValue.NORMAL]: '14-20',
    [BmiValue.OVERWEIGHT]: '21-24',
    [BmiValue.OBESITY]: '25-31',
    [BmiValue.EXTREME_OBESITY]: '32+',
  },
}

export const NO_TRIAL_PLAN_NAME_MAP = {
  [PurchaseProduct.SEVEN_DAY]: 'checkout.noTrialPlanName.oneWeek',
  [PurchaseProduct.ONE_MONTH]: 'checkout.noTrialPlanName.oneMonth',
  [PurchaseProduct.THREE_MONTHS]: 'checkout.noTrialPlanName.threeMonths',
}

export const LANGUAGE_STRIPE_LOCALE_MAP = {
  [Language.EN]: 'GB',
  [Language.ES]: 'ES',
  [Language.PT]: 'PT',
  [Language.FR]: 'FR',
  [Language.DE]: 'DE',
  [Language.JA]: 'JP',
  [Language.PL]: 'PL',
}

export const COUNTRIES_WITHOUT_PAYPAL: string[] = [
  CountryCode.ARGENTINA,
  CountryCode.COLOMBIA,
  CountryCode.PERU,
  CountryCode.CHILE,
  CountryCode.DOMINICAN_REPUBLIC,
  CountryCode.URUGUAY,
  CountryCode.ECUADOR,
  CountryCode.GUATEMALA,
  CountryCode.VENEZUELA,
  CountryCode.PARAGUAY,
  CountryCode.BOLIVIA,
  CountryCode.NICARAGUA,
  CountryCode.HONDURAS,
  CountryCode.COSTA_RICA,
  CountryCode.PANAMA,
  CountryCode.EL_SALVADOR,
  CountryCode.BRAZIL,
  CountryCode.INDIA,
  CountryCode.SOUTH_AFRICA,
  CountryCode.NIGERIA,
  CountryCode.KENYA,
  CountryCode.ZAMBIA,
  CountryCode.TANZANIA,
  CountryCode.ETHIOPIA,
  CountryCode.ZIMBABWE,
  CountryCode.SENEGAL,
  CountryCode.NAMIBIA,
]
