import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import alertIcon from 'assets/images/exclamation-mark-error.svg'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.BLACK_100,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '64px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.GREY_101,
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '24px',
    },
  },
  invalid: {
    color: Color.BLACK_100,
  },
}

export const commonInputStyles = css`
  color: ${Color.GREY_100};
  height: 64px;
  background-color: ${Color.WHITE};
  line-height: 24px;
  border-radius: 16px;
  border: 1px solid ${Color.GREY_1000};
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 12px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.InputElement.is-invalid {
    color: ${Color.BLACK};
  }

  &.StripeElement--invalid {
    position: relative;
    color: ${Color.BLACK_100};
    border-color: ${Color.RED_300};
    background-color: ${Color.WHITE};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
    }
  }
`

export const StyledCreditCardFormV1 = {
  Form: styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 11px;
    grid-template-areas:
      'cardNumber cardNumber'
      'cardExpiry cardCvc'
      'cardholder cardholder'
      'button button';
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    grid-area: cardNumber;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
    grid-area: cardCvc;
  `,
  CardholderInput: styled.div`
    grid-area: cardholder;
  `,
  Button: styled(Button)`
    grid-area: button;
  `,
}
