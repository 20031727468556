import React, { VideoHTMLAttributes, useEffect, useRef } from 'react'

import { VideoPlayer } from 'components/VideoPlayer'
import { VideoProgressBar } from 'components/VideoProgressBar'

import scannerPreview from 'assets/images/scanner-preview.png'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledScannerIntro as S } from './ScannerIntro.styles'

type TProps = {
  onVideoEnd?: () => void
  videoProps?: Omit<VideoHTMLAttributes<HTMLVideoElement>, 'poster' | 'src'>
  withProgressBar?: boolean
}

export const ScannerIntro: React.FC<TProps> = ({
  onVideoEnd,
  videoProps,
  withProgressBar = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const videoPath = `${CDN_FOLDER_LINK}/videos/food-scanner-a.mp4`

  useEffect(() => {
    const video = videoRef.current
    if (!video) return undefined

    if (onVideoEnd) video.addEventListener('ended', onVideoEnd)

    return () => {
      if (onVideoEnd) video.removeEventListener('ended', onVideoEnd)
    }
  }, [videoRef, onVideoEnd])

  return (
    <>
      <S.Wrapper>
        <VideoPlayer
          ref={videoRef}
          videoProps={{ ...videoProps, poster: scannerPreview, src: videoPath }}
        />
      </S.Wrapper>
      {withProgressBar ? <VideoProgressBar videoRef={videoRef} /> : null}
    </>
  )
}
