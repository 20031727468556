import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'

import { Cohort, INFLUENCER_NAME_SESSION_STORAGE_KEY } from 'root-constants'

import {
  DefaultDiscountBlock,
  DiscountBlockV1,
  DiscountBlockV2,
  DiscountBlockV3,
} from './components'
import { StyledAdaptiveDiscountBlock as S } from './styles/AdaptiveDiscountBlock.styles'

export const AdaptiveDiscountBlock = () => {
  const currentCohort = useSelector(selectCurrentVariantCohortToUse)
  const { isAlternativeDiscountVariant } = useExperimentalFeatures()

  const hasDynamicDiscountBanner = currentCohort === Cohort.MF_INTRO_SALE
  const isInfluencerDiscountBanner =
    currentCohort === Cohort.MF_INFLUENCER &&
    window.sessionStorage.getItem(INFLUENCER_NAME_SESSION_STORAGE_KEY)

  if (hasDynamicDiscountBanner && isAlternativeDiscountVariant) {
    return (
      <S.Stack>
        <DiscountBlockV1 />
      </S.Stack>
    )
  }

  if (hasDynamicDiscountBanner) {
    return (
      <S.Stack>
        <DiscountBlockV2 />
      </S.Stack>
    )
  }

  if (isInfluencerDiscountBanner) {
    return (
      <S.Stack>
        <DiscountBlockV3 />
      </S.Stack>
    )
  }

  return (
    <S.Stack>
      <DefaultDiscountBlock />
    </S.Stack>
  )
}
