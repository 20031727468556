import React from 'react'
import { Trans } from 'react-i18next'

import { OnboardingButtonText } from 'value-constants'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV3 } from 'components/CustomerReviews'
import { Disclaimer } from 'components/Disclaimer'
import { FAQList } from 'components/FAQList'
import { FeaturedBlock } from 'components/FeaturedBlock'
import { MoneyBackGuarantee } from 'components/MoneyBackGuarantee'
import { PhotoResult } from 'components/PhotoResult'
import { PlanHighlights } from 'components/PlanHighlights'
import { SecurityInfo } from 'components/SecurityInfo'
import { Spinner } from 'components/Spinner'

import { TPageProps } from 'models/common.model'

import { TOTAL_USERS } from 'root-constants'

import { IntroMedia } from '../lib/components'
import { usePurchase } from '../lib/usePurchase'
import { StyledPurchaseV1 as S } from './PurchaseV1.styles'

export const PurchaseV1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const {
    hasPrices,
    btnLabel,
    title,
    subtitle,
    firstSubscriptionsRef,
    titleElementRef,
    selectedSubscription,
    handleGetPlan,
    handleIntroMediaButtonClick,
  } = usePurchase(nextPagePath)

  if (!hasPrices) return <Spinner />

  return (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>{title || <Trans i18nKey="purchase.title" />}</S.Title>
          <S.Subtitle>
            {subtitle || (
              <Trans
                i18nKey="purchase.subtitle"
                values={{ count: TOTAL_USERS }}
              />
            )}
          </S.Subtitle>
          <IntroMedia
            elemForComparisonRef={titleElementRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <PlanHighlights titleElementRef={titleElementRef} />
        </S.ProgramPlanContainer>
        <SubscriptionsBlock ref={firstSubscriptionsRef} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {btnLabel}
        </S.Button>

        <FeaturedBlock />
        <MoneyBackGuarantee />
        <SecurityInfo />
      </S.Wrapper>
      <S.CustomerReviewsContainer>
        <PhotoResult />
        <FAQList />
        <CustomerReviewsV3 />
        <S.Button
          type="button"
          data-button-number="3"
          data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
          onClick={handleGetPlan}
        >
          {btnLabel}
        </S.Button>
        {selectedSubscription && (
          <Disclaimer selectedSubscription={selectedSubscription} />
        )}
      </S.CustomerReviewsContainer>
    </>
  )
}
