import React from 'react'

import { StyledPageTitle as S } from './PageTitle.styles'

export type TPageTitleProps = {
  children: React.ReactElement | string | number
  marginBottom?: number
  marginTop?: number
  textAlign?: 'left' | 'center'
  className?: string
}

export const PageTitle: React.FC<TPageTitleProps> = ({
  children,
  marginBottom,
  marginTop,
  textAlign = 'center',
  className,
  ...props
}) => (
  <S.PageTitle
    className={className}
    marginBottom={marginBottom}
    marginTop={marginTop}
    textAlign={textAlign}
    {...props}
  >
    {children}
  </S.PageTitle>
)
