import React from 'react'
import { Trans } from 'react-i18next'

import { CreditCardFormV1 } from '../CreditCardForm/v1/CreditCardFormV1'
import { StyledCreditCardPayment as S } from './CreditCardPayment.styles'

export const CreditCardPayment = () => (
  <>
    <S.TitleContainer>
      <S.PaymentInfo>
        <Trans i18nKey="checkout.paymentInfo" />
      </S.PaymentInfo>
      <S.Label>
        <Trans i18nKey="checkout.protectedLabel" />
      </S.Label>
    </S.TitleContainer>
    <CreditCardFormV1 />
  </>
)
