import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSwitch = {
  Root: styled.label`
    display: flex;
    width: 44px;
    height: 24px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translate(90%, -50%);
    }

    &:checked ~ div {
      background-color: ${Color.GREEN_400};
      border: 1px solid ${Color.GREEN_400};
    }
  `,
  Track: styled.div`
    width: inherit;
    transition: 0.2s;
    transition-property: background, border;
    border-radius: 16px;
    background: ${Color.GREY_800};
  `,
  Button: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: 0.2s;
    transition-property: transform;
    background: #fff;
  `,
}
