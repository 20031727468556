import React from 'react'

import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import {
  KitEmailSubscriptionItemV1,
  KitSubscriptionItemDynamic,
  KitSubscriptionItemV1,
  KitSubscriptionItemV1Trial,
  KitSubscriptionItemV2,
  SubscriptionItemV1,
  SubscriptionItemV2,
  SubscriptionItemV3,
  SubscriptionItemV3Trial,
  SubscriptionItemV3WithoutWeeklyTrial,
  SubscriptionItemV4,
  SubscriptionItemV4WithoutWeeklyTrial,
  SubscriptionItemV5,
  SubscriptionItemV5Trial,
  SubscriptionItemWithoutWeeklyTrial,
} from '../components'

export const SUBSCRIPTION_ITEMS: Record<
  string,
  React.FC<TSubscriptionItemProps>
> = {
  variant_1: SubscriptionItemV1,
  variant_1_no_weekly_trial: SubscriptionItemWithoutWeeklyTrial,
  variant_2: SubscriptionItemV2,
  variant_3: SubscriptionItemV3,
  variant_3_trial: SubscriptionItemV3Trial,
  variant_3_no_weekly_trial: SubscriptionItemV3WithoutWeeklyTrial,
  variant_4: SubscriptionItemV4,
  variant_4_no_weekly_trial: SubscriptionItemV4WithoutWeeklyTrial,
  variant_5: SubscriptionItemV5,
  variant_5_trial: SubscriptionItemV5Trial,
  kit_variant_1: KitSubscriptionItemV1,
  kit_variant_2: KitSubscriptionItemV2,
  kit_variant_1_trial: KitSubscriptionItemV1Trial,
  kit_variant_1_dynamic: KitSubscriptionItemDynamic,
  kit_email_variant_1: KitEmailSubscriptionItemV1,
}
