import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { ScannerIntro } from 'components/ScannerIntro'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

const T_PREFIX = 'onboarding.stayOnTrackV1'

export const StayOnTrackV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const { t } = useTranslation()
  const handleNextPage = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      paddingTop={30}
      hasContinueButton
      isContinueButtonDisabled={!isCompleted}
      onContinueButtonClick={() => handleNextPage('')}
    >
      <ScannerIntro onVideoEnd={() => setIsCompleted(true)} withProgressBar />
      <PageTitle textAlign="left" marginTop={24}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
    </KitPageContainer>
  )
}
