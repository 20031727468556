import React from 'react'
import { useTranslation } from 'react-i18next'

import { STEPS_MAP } from '../../constants'
import { StyledSteps as S } from './Steps.styles'

export const Steps = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>{t('purchase.stepsV1.title')}</S.Title>
      <S.List>
        {STEPS_MAP.map(({ title, info }, index) => (
          <S.Item key={title}>
            <S.Step>{t('purchase.stepsV1.step', { index: index + 1 })}</S.Step>
            <S.ItemTitle>{t(title)}</S.ItemTitle>
            <S.ItemInfo>{t(info)}</S.ItemInfo>
          </S.Item>
        ))}
      </S.List>
    </S.Container>
  )
}
