import ectomorph from 'assets/images/ectomorph-male.png'
import endomorph from 'assets/images/endomorph-male.png'
import hourglassBodyType from 'assets/images/hourglass-body-type.png'
import mesomorph from 'assets/images/mesomorph-male.png'
import pearBodyType from 'assets/images/pear-body-type.png'
import rectangleBodyType from 'assets/images/rectangle-body-type.png'
import redQuestion from 'assets/images/red-question-mark.png'
import roundBodyType from 'assets/images/round-body-type.png'

import { Gender } from 'root-constants'

const T_PREFIX_V1 = 'onboarding.bodyType'
export const OPTIONS_V1 = {
  [Gender.FEMALE]: [
    {
      value: 'rectangle',
      title: `${T_PREFIX_V1}.femaleOptions.rectangle`,
      icon: rectangleBodyType,
    },
    {
      value: 'hourglass',
      title: `${T_PREFIX_V1}.femaleOptions.hourglass`,
      icon: hourglassBodyType,
    },
    {
      value: 'pear',
      title: `${T_PREFIX_V1}.femaleOptions.pear`,
      icon: pearBodyType,
    },
    {
      value: 'round',
      title: `${T_PREFIX_V1}.femaleOptions.round`,
      icon: roundBodyType,
    },
  ],
  [Gender.MALE]: [
    {
      value: 'ectomorph',
      title: `${T_PREFIX_V1}.maleOptions.ectomorph`,
      icon: ectomorph,
    },
    {
      value: 'mesomorph',
      title: `${T_PREFIX_V1}.maleOptions.mesomorph`,
      icon: mesomorph,
    },
    {
      value: 'endomorph',
      title: `${T_PREFIX_V1}.maleOptions.endomorph`,
      icon: endomorph,
    },
    {
      value: 'not_sure',
      title: `${T_PREFIX_V1}.maleOptions.notSure`,
      icon: redQuestion,
    },
  ],
}
