import styled, { css } from 'styled-components'

import earth from 'assets/images/earth.svg'
import star from 'assets/images/review-red-star.svg'

import { Color } from 'root-constants'

export const iconStyles = css`
  position: absolute;
  content: url(${star});
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 6px;
`

export const StyledCustomerReviewsV6 = {
  Section: styled.section`
    margin: 40px 0;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,

  Header: styled.h2`
    margin-bottom: 12px;
    color: ${Color.GREY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    strong {
      color: ${Color.GREEN_300};
    }
  `,

  ReviewWrapper: styled.div`
    border: 1px solid ${Color.GREY_800};
    padding: 16px 16px 25px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
  `,

  ProfileImage: styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
  `,

  Head: styled.div`
    display: flex;
    gap: 12px;
  `,

  Info: styled.div`
    h4 {
      position: relative;
      color: ${Color.GREY_100};
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      &::after {
        ${iconStyles};
        content: url(${star});
      }
    }

    div {
      position: relative;
      color: ${Color.GREY_101};
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;

      &::after {
        ${iconStyles};
        content: url(${earth});
      }
    }
  `,

  InfoName: styled.h4`
    position: relative;
    color: ${Color.GREY_100};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    &::after {
      ${iconStyles};
      content: url(${star});
    }

    div {
      position: relative;
      color: ${Color.GREY_101};
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;

      &::after {
        ${iconStyles};
        content: url(${earth});
      }
    }
  `,

  InfoDate: styled.div`
    position: relative;
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    &::after {
      ${iconStyles};
      content: url(${earth});
    }
  `,

  ReviewText: styled.p`
    margin-top: 16px;
    color: ${Color.GREY_100};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,

  ImageContainer: styled.div`
    margin-top: 16px;
    width: 100%;

    img {
      border-radius: 16px;
    }
  `,
  InteractionSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    font-size: 0.9rem;
    color: #666;
  `,

  Like: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;

    p {
      margin-left: 8px;
    }
  `,

  Comment: styled.div`
    color: ${Color.GREY_101};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,

  Disclaimer: styled.div`
    text-align: center;
    max-width: 277px;
    margin: 16px auto 0 auto;
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
}
