import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserSCCID } from 'root-redux/actions/user'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { getCookie } from 'helpers/getCookie'

export const useSnapPixelInitialization = (snapPixelId: string): void => {
  const dispatch = useDispatch()
  const email = useSelector(selectUserOnboardingEmail)

  const {
    isEUUser,
    hasAdvertisingAndTargetingCookie,
    isPersonalDataAllowed,
    cookieConsent,
  } = useCookieConsentAnswer()

  const shouldSnapBePaused = useMemo(
    () =>
      (isEUUser && !cookieConsent?.length) ||
      (isEUUser && !hasAdvertisingAndTargetingCookie),
    [isEUUser, hasAdvertisingAndTargetingCookie, cookieConsent?.length],
  )

  useEffect(() => {
    if (!snapPixelId || !window.snaptr || shouldSnapBePaused) return

    if (!window.snapPixelInitialized) {
      window.snaptr('init', snapPixelId, {
        user_email: isPersonalDataAllowed ? email : '',
      })
      window.snapPixelInitialized = true
    }

    window.snaptr('track', 'PAGE_VIEW', {
      user_email: isPersonalDataAllowed ? email : '',
    })

    const searchParams = new URLSearchParams(document.location.search)
    const sccid = searchParams.get('sccid')
    const scid = getCookie('_scid')

    if (sccid) {
      dispatch(sendUserSCCID(sccid, scid))
    }
  }, [dispatch, email, isPersonalDataAllowed, shouldSnapBePaused, snapPixelId])
}
