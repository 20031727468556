import result1FemaleAfter from 'assets/images/result1-female-after.png'
import result1FemaleAfterWebp from 'assets/images/result1-female-after.webp'
import result1FemaleBefore from 'assets/images/result1-female-before.png'
import result1FemaleBeforeWebp from 'assets/images/result1-female-before.webp'
import result1MaleAfter from 'assets/images/result1-male-after.png'
import result1MaleAfterWebp from 'assets/images/result1-male-after.webp'
import result1MaleBefore from 'assets/images/result1-male-before.png'
import result1MaleBeforeWebp from 'assets/images/result1-male-before.webp'
import result2FemaleAfter from 'assets/images/result2-female-after.png'
import result2FemaleAfterWebp from 'assets/images/result2-female-after.webp'
import result2FemaleBefore from 'assets/images/result2-female-before.png'
import result2FemaleBeforeWebp from 'assets/images/result2-female-before.webp'
import result2MaleAfter from 'assets/images/result2-male-after.png'
import result2MaleAfterWebp from 'assets/images/result2-male-after.webp'
import result2MaleBefore from 'assets/images/result2-male-before.png'
import result2MaleBeforeWebp from 'assets/images/result2-male-before.webp'
import result3FemaleAfter from 'assets/images/result3-female-after.png'
import result3FemaleAfterWebp from 'assets/images/result3-female-after.webp'
import result3FemaleBefore from 'assets/images/result3-female-before.png'
import result3FemaleBeforeWebp from 'assets/images/result3-female-before.webp'

export const SLIDES_FEMALE = [
  {
    id: '1',
    img: {
      before: result1FemaleBefore,
      after: result1FemaleAfter,
    },
    imgWebp: {
      before: result1FemaleBeforeWebp,
      after: result1FemaleAfterWebp,
    },
    author: 'purchase.photoResult.firstAuthorFemale',
    lostWeight: 'purchase.photoResult.firstLostWeightFemale',
    review: 'purchase.photoResult.firstReviewFemale',
  },
  {
    id: '2',
    img: {
      before: result2FemaleBefore,
      after: result2FemaleAfter,
    },
    imgWebp: {
      before: result2FemaleBeforeWebp,
      after: result2FemaleAfterWebp,
    },
    author: 'purchase.photoResult.secondAuthorFemale',
    lostWeight: 'purchase.photoResult.secondLostWeightFemale',
    review: 'purchase.photoResult.secondReviewFemale',
  },
  {
    id: '3',
    img: {
      before: result3FemaleBefore,
      after: result3FemaleAfter,
    },
    imgWebp: {
      before: result3FemaleBeforeWebp,
      after: result3FemaleAfterWebp,
    },
    author: 'purchase.photoResult.thirdAuthorFemale',
    lostWeight: 'purchase.photoResult.thirdLostWeightFemale',
    review: 'purchase.photoResult.thirdReviewFemale',
  },
]

export const SLIDES_MALE_V1 = [
  {
    id: '1',
    img: {
      before: result1MaleBefore,
      after: result1MaleAfter,
    },
    imgWebp: {
      before: result1MaleBeforeWebp,
      after: result1MaleAfterWebp,
    },
    author: 'purchase.photoResult.firstAuthorMale',
    lostWeight: 'purchase.photoResult.firstLostWeightMale',
    review: 'purchase.photoResult.firstReviewMale',
  },
  {
    id: '2',
    img: {
      before: result2MaleBefore,
      after: result2MaleAfter,
    },
    imgWebp: {
      before: result2MaleBeforeWebp,
      after: result2MaleAfterWebp,
    },
    author: 'purchase.photoResult.secondAuthorMale',
    lostWeight: 'purchase.photoResult.secondLostWeightMale',
    review: 'purchase.photoResult.secondReviewMale',
  },
]

export const SLIDES_MALE_V2 = [
  {
    id: '1',
    img: {
      before: result1MaleBefore,
      after: result1MaleAfter,
    },
    imgWebp: {
      before: result1MaleBeforeWebp,
      after: result1MaleAfterWebp,
    },
    author: 'purchase.photoResult.slidesMaleV2.firstAuthorMale',
    lostWeight: 'purchase.photoResult.slidesMaleV2.firstLostWeightMale',
    review: 'purchase.photoResult.slidesMaleV2.firstReviewMale',
  },
  {
    id: '2',
    img: {
      before: result2MaleBefore,
      after: result2MaleAfter,
    },
    imgWebp: {
      before: result2MaleBeforeWebp,
      after: result2MaleAfterWebp,
    },
    author: 'purchase.photoResult.slidesMaleV2.secondAuthorMale',
    lostWeight: 'purchase.photoResult.slidesMaleV2.secondLostWeightMale',
    review: 'purchase.photoResult.slidesMaleV2.secondReviewMale',
  },
]
