import { BmiValue } from 'value-constants'

import checkIcon from 'assets/images/check-circle-2.png'
import exclamationIcon from 'assets/images/exclaimation-circle-2.png'
import exclamationWarningIcon from 'assets/images/exclaimation-warning-circle-2.png'

export const MAX_WEIGHT_VALUE_LENGTH = 3

export const MIN_WEIGHT_VALUE = {
  MIN_WEIGHT_KG: 21,
  MIN_WEIGHT_LB: 46,
}

const T_PREFIX_MBI = 'onboarding.currentWeight.mbiInfo'
const T_PREFIX_LABEL = 'onboarding.currentWeight.disclaimer.label'

export const BMI_INFO_MAP_V2 = {
  [BmiValue.UNDERWEIGHT]: {
    iconSrc: exclamationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: `${T_PREFIX_MBI}.underweight`,
    bmiValue: `${T_PREFIX_LABEL}.underweight`,
  },
  [BmiValue.NORMAL]: {
    iconSrc: checkIcon,
    background: 'rgba(169, 205, 80, 0.20)',
    text: `${T_PREFIX_MBI}.normal`,
    bmiValue: `${T_PREFIX_LABEL}.normal`,
  },
  [BmiValue.OVERWEIGHT]: {
    iconSrc: exclamationWarningIcon,
    background: 'rgba(255, 153, 0, 0.10)',
    text: `${T_PREFIX_MBI}.overweight2`,
    bmiValue: `${T_PREFIX_LABEL}.overweight`,
  },
  [BmiValue.OBESITY]: {
    iconSrc: exclamationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: `${T_PREFIX_MBI}.obesity`,
    bmiValue: `${T_PREFIX_LABEL}.obese`,
  },
  [BmiValue.EXTREME_OBESITY]: {
    iconSrc: exclamationIcon,
    background: 'rgba(231, 76, 60, 0.10)',
    text: `${T_PREFIX_MBI}.obesity`,
    bmiValue: `${T_PREFIX_LABEL}.extrObese`,
  },
}
