import { i18nFormatters } from 'helpers/i18nFormatters'

import { Language } from 'root-constants'

export const getLocalizedDate = (date: string, lng: Language) => {
  const [day, month, year] = date.split('.').map(Number)
  const dateObject = new Date(year, month - 1, day)

  return i18nFormatters.datetime(dateObject, lng, {
    dateStyle: 'short',
  })
}
