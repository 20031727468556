import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SwiperSlide } from 'swiper/react'

import { SliderWithScaledActiveSlideV2 } from 'components/SliderWithScaledActiveSlideV2'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledWeightLossJourney as S } from './WeightLossJourney.styles'

type TProps = {
  title: string
  hasIndent?: boolean
  className?: string
}

const IMAGE_PATH_TUNA = '/images/screen-tuna'
const IMAGE_PATH_PLANNING = '/images/screen-planning'
const IMAGE_PATH_JOURNEY = '/images/screen-journey'
const IMAGE_PATH_INGREDIENTS = '/images/screen-ingredients'
const IMAGE_PATH_FASTING = '/images/screen-fasting'

export const WeightLossJourney: React.FC<TProps> = ({
  className,
  title,
  hasIndent = true,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const screens = useMemo(
    () => [
      `${CDN_FOLDER_LINK}${IMAGE_PATH_INGREDIENTS}-${language}.png`,
      `${CDN_FOLDER_LINK}${IMAGE_PATH_TUNA}-${language}.png`,
      `${CDN_FOLDER_LINK}${IMAGE_PATH_PLANNING}-${language}.png`,
      `${CDN_FOLDER_LINK}${IMAGE_PATH_JOURNEY}-${language}.png`,
      `${CDN_FOLDER_LINK}${IMAGE_PATH_FASTING}-${language}.png`,
    ],
    [language],
  )

  return (
    <div className={className}>
      <S.Title hasIndent={hasIndent}>{t(title)}</S.Title>
      <SliderWithScaledActiveSlideV2>
        {screens.map((screen) => (
          <SwiperSlide key={screen}>
            <S.ScreenContainer>
              <img src={screen} alt="screen" />
            </S.ScreenContainer>
          </SwiperSlide>
        ))}
      </SliderWithScaledActiveSlideV2>
    </div>
  )
}
