import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledIntroMediaV3 = {
  Wrapper: styled.div`
    background-color: ${Color.GREY_800};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    overflow: hidden;
  `,
  BeforeAfter: styled.div`
    display: flex;
    width: 100%;
    aspect-ratio: 343/293;
  `,
  FatLevel: styled.div`
    display: flex;
    column-gap: 1px;
    width: 100%;
  `,
  FatValue: styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 50%;
    background-color: ${Color.WHITE};

    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_100};

    strong {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 4px 0;
    }
  `,
}
