import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledAchieveResultV4 as S } from './AchieveResultV4.styles'

const T_PREFIX = 'onboarding.achieveResultV4'
const IMAGE_V4_PATH = '/images/achieve-result-banner'

export const AchieveResultV4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={16}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey={`${T_PREFIX}.subtitle`} />
      </S.Subtitle>
      <S.Banner>
        <img
          src={`${CDN_FOLDER_LINK}${IMAGE_V4_PATH}-${language}.png`}
          alt="banner"
        />
      </S.Banner>
      <S.CallToAction>
        <Trans i18nKey={`${T_PREFIX}.callToAction`} />
      </S.CallToAction>
    </KitPageContainer>
  )
}
