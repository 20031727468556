const T_PREFIX = 'onboarding.caloriesImpactV1.options'

export const CALORIES_IMPACT_V1_OPTIONS = [
  {
    value: 'yes',
    textPath: `${T_PREFIX}.yes`,
  },
  {
    value: 'somewhat',
    textPath: `${T_PREFIX}.somewhat`,
  },
  {
    value: 'no',
    textPath: `${T_PREFIX}.no`,
  },
]
