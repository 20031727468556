import styled, { css } from 'styled-components'

import { LoseWeightGraphV3 } from 'components/LoseWeightGraphV3'

import { Color } from 'root-constants'

const commonTextStyles = css`
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: ${Color.GREY_100};
`

export const StyledEventFeedbackV3 = {
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 20px 0;
  `,

  Prediction: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    ${commonTextStyles};

    span {
      font-weight: 700;
      font-size: 18px;
      color: ${Color.GREEN_300};
    }

    div {
      background: #a9cd5033;
      border-radius: 8px;
      padding: 6px 12px;
      ${commonTextStyles}

      strong {
        font-weight: 700;
      }
    }
  `,
  LoseWeightGraphV3: styled(LoseWeightGraphV3)`
    background-color: ${Color.WHITE};
  `,
}
