import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  isSecondaryVariant: boolean
}

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div<TProps>`
    position: relative;
    background-color: ${Color.WHITE};
    border-width: 1px;
    border-color: ${({ isSecondaryVariant }) =>
      isSecondaryVariant ? Color.GREY_800 : Color.GREEN_400};
    border-style: solid;
    border-radius: 24px;
    padding: 8px;
    margin-bottom: ${({ isSecondaryVariant }) =>
      isSecondaryVariant ? 40 : 24}px;
  `,
  Title: styled.h2`
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
  `,
  Content: styled.div<TProps>`
    padding: 20px 16px;
    border-radius: 16px;
    border-width: 1px;
    border-color: ${Color.GREEN_400};
    border-style: ${({ isSecondaryVariant }) =>
      isSecondaryVariant ? 'solid' : 'dashed'};
  `,
  Text: styled.p`
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    a {
      color: ${Color.GREY_101};
      text-decoration: underline;
    }
  `,
  Icon: styled.img`
    position: absolute;
    bottom: -65px;
    right: 0;
  `,
}
