import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import bannerMen from 'assets/images/commit-men.png'
import bannerWomen from 'assets/images/commit-women.png'

import { Gender, Language } from 'root-constants'

import { StyledKitCommitV1 as S } from './KitCommitV1.styles'

const T_PREFIX = 'onboarding.kitCommitV1'

export const KitCommitV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const gender = useSelector(selectCurrentUserGender)

  const imgSrc = gender === Gender.MALE ? bannerMen : bannerWomen

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={t('actions.commitToTry')}
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.Banner>
        <img src={imgSrc} alt="commit-banner" />
      </S.Banner>
      <PageTitle marginBottom={16} textAlign="left">
        {t(`${T_PREFIX}.title`)}
      </PageTitle>
      <S.Info>{t(`${T_PREFIX}.info`)}</S.Info>
    </KitPageContainer>
  )
}
