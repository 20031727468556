import styled from 'styled-components'

export const StyledVideoWrapper = {
  VideoWrapper: styled.div<{ $poster?: string }>`
    background-image: url(${({ $poster }) => $poster});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: auto;
    overflow: hidden;

    video {
      clip-path: inset(-2px 2px);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `,
}
