import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BMI_INFO_MAP_V1 } from 'pages/currentWeight/v3/constants'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  Language,
  MIN_MAX_WEIGHT,
} from 'root-constants'

import { StyledCurrentWeightV3 as S } from './CurrentWeightV3.styles'

const T_PREFIX = 'onboarding.currentWeight'

export const CurrentWeightV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const { t } = useTranslation()
  const { measuringSystemLabel, isMetricSelected, measurementSystem } =
    useWeightMeasurementSystem()
  const { currentBMI } = useUsersBmi({
    currentUserWeight: (weight.isValid && +weight.value) || 70,
  })
  const handleContinue = useNextStep({
    pageId: CustomPageId.CURRENT_WEIGHT,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const { bmiValue, text, iconSrc, background } =
    BMI_INFO_MAP_V1[currentBMI.bmiValue.value]

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleContinue(+weight.value, measurementSystem)
  }

  const handleChange = useCallback(({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }, [])

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          <Trans i18nKey={`${T_PREFIX}.title`} />
        </PageTitle>
        <S.WeightLabel>{measuringSystemLabel}</S.WeightLabel>
        <S.ActionsV2>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              value={weight.value}
              isContentCentered
              isValid={weight.isValid}
              min={
                isMetricSelected
                  ? MIN_MAX_WEIGHT.MIN_WEIGHT_KG
                  : MIN_MAX_WEIGHT.MIN_WEIGHT_LB
              }
              max={
                isMetricSelected
                  ? MIN_MAX_WEIGHT.MAX_WEIGHT_KG
                  : MIN_MAX_WEIGHT.MAX_WEIGHT_LB
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix>{measuringSystemLabel}</S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>
                <Trans i18nKey="onboarding.goalWeight.errorMessage" />
              </span>
            </S.ErrorMessage>
          )}
        </S.ActionsV2>

        {weight.isValid && (
          <S.InfoContainer background={background}>
            <S.InfoImage>
              <img src={iconSrc} alt="bmi-info-icon" />
            </S.InfoImage>
            <S.Info>
              <S.InfoTitle>
                <Trans
                  i18nKey={`${T_PREFIX}.mbiInfo.title`}
                  values={{
                    bmiIndex: currentBMI.bmiIndex,
                    bmiValue: t(bmiValue),
                  }}
                />
              </S.InfoTitle>
              <S.InfoText>
                <Trans i18nKey={text} />
              </S.InfoText>
            </S.Info>
          </S.InfoContainer>
        )}

        <NavigationButton type="submit" disabled={!weight.isValid}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </Container>
    </form>
  )
}
