import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import {
  AnswerWithIconAndCheckbox,
  answerWithIconAndCheckboxTheme,
} from 'storybook-ui'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { StyledOptions as S } from 'styles/KitOptions.styles'

import { useNextStep } from 'hooks/useNextStep'

import {
  TAnswer,
  TBuilderOptionData,
  TBuilderOptionProps,
  TPageProps,
} from 'models/common.model'

import check from 'assets/images/white-check-icon.svg'

type TProps = {
  title: string
  subtitle?: string
  question?: string
  buttonText?: string
  options?: TBuilderOptionData[]
  answers?: string[]
  isReverse?: boolean
  handleContinue?: (value: TAnswer) => void
} & TPageProps &
  Partial<TBuilderOptionProps>

export const KitCheckboxOptionsView: React.FC<TProps> = ({
  title,
  subtitle,
  question,
  options,
  buttonText,
  pageId,
  name,
  nextPagePath,
  onChange,
  answers: dynamicAnswers,
  isFirstPage,
  isReverse = true,
  handleContinue,
}) => {
  const [answers, setAnswers] = useState<string[]>([])
  const currentAnswers = dynamicAnswers || answers

  const onContinue = useNextStep({
    pageId,
    question: question || title,
    nextPagePath,
    isFirstPage,
  })

  const continueHandler = handleContinue || onContinue
  const handleChange = (value: string, isChecked: boolean) => {
    if (isChecked) return setAnswers((prev) => [...prev, value])

    return setAnswers((prev) => prev.filter((val) => val !== value))
  }

  const checkboxProps = {
    name: name || pageId,
    type: OptionType.CHECKBOX,
    theme: answerWithIconAndCheckboxTheme.NUTRIMATE,
    borderRadius: '48px',
    maxWidth: '100%',
    margin: '0 0 12px 0',
    checkboxBorderRadius: '6px',
    checkboxBorder: '1.5px solid #CACACA',
    checkboxWidth: '24px',
    checkboxHeight: '24px',
    spacingBetweenCheckboxAndContent: '0 16px 0 16px',
    iconSrc: check,
    iconSize: 'auto',
    reverse: isReverse,
    onChange: onChange || handleChange,
  }

  return (
    <KitPageContainer
      hasContinueButton
      isContinueButtonDisabled={!currentAnswers.length}
      continueButtonContent={buttonText}
      onContinueButtonClick={() => continueHandler(currentAnswers)}
    >
      <PageTitle marginBottom={subtitle ? 8 : 24} textAlign="left">
        {title}
      </PageTitle>
      {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}

      {options &&
        options.map(({ value, imageUrl, text, textPath }) => {
          const size = imageUrl ? '40px' : undefined

          return (
            <AnswerWithIconAndCheckbox
              {...checkboxProps}
              key={value}
              value={value}
              imageSrc={imageUrl}
              imageWidth={size}
              imageHeight={size}
              spacingBetweenCheckboxAndContent="0 16px 0 16px"
            >
              <S.AnswerTitle>
                {text || <Trans i18nKey={textPath} />}
              </S.AnswerTitle>
            </AnswerWithIconAndCheckbox>
          )
        })}
    </KitPageContainer>
  )
}
