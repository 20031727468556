import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledVideoProgressBar = {
  ProgressBarWrapper: styled.div`
    margin-top: 44px;
    width: 100%;
    height: 8px;
    background-color: ${Color.GREY_800};
    border-radius: 8px;
    overflow: hidden;
  `,
  ProgressBar: styled.div`
    height: 100%;
    background-color: ${Color.GREEN_300};
    width: 0;
    transition: width 0.1s linear;
  `,
}
