import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTimerEffect } from 'contexts/TimerProvider'
import dayjs from 'dayjs'

import { MEDIUM_MONTH_FORMAT, SHORT_YEAR_FORMAT } from 'helpers/dateHelper'

import { getDecoratedTimerValue } from 'modules/purchaseSubscription/helpers/rootHelpers'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchaseSubscription/redux/selects'

import { DEFAULT_TIMER_CONTENT } from 'root-constants'

import { StyledDefaultDiscountBlock as S } from '../styles/DefaultDiscountBlock.styles'

type TProps = {
  className?: string
}

export const DefaultDiscountBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const discount = useSelector(selectSubscriptionPaywallDiscountPercent)
  const currentDate = dayjs()

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  const code = `${currentDate
    .format(MEDIUM_MONTH_FORMAT)
    .toLowerCase()}${currentDate.format(SHORT_YEAR_FORMAT)}`

  return (
    <S.ContentBlock className={className}>
      <S.ContentColumn>
        <S.ColumnTitle>{t('purchase.limitedOffer.applied')}</S.ColumnTitle>
        <S.Offer>
          <S.OldOffer>
            <Trans
              i18nKey="purchase.limitedOffer.offerPercentage"
              values={{
                currentPercentage: discount,
                oldPercentage: 33,
              }}
              components={{ span: <span /> }}
            />
          </S.OldOffer>
          <S.NewOffer>
            <S.OldPromoCode>
              <Trans
                i18nKey="purchase.limitedOffer.newcomer"
                values={{
                  code,
                }}
              />
            </S.OldPromoCode>
            <S.NewPromoCode>
              <Trans
                i18nKey="purchase.limitedOffer.lastcall"
                values={{
                  code,
                }}
              />
            </S.NewPromoCode>
          </S.NewOffer>
        </S.Offer>
      </S.ContentColumn>
      <S.ContentColumn>
        <S.ColumnTitle>{t('purchase.limitedOffer.applicable')}</S.ColumnTitle>
        <S.TimerBlock>
          <S.TimerColumn>
            <S.TimerValue ref={timerMinutesRef}>
              {DEFAULT_TIMER_CONTENT}
            </S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.minutes')}</S.TimerUnits>
          </S.TimerColumn>
          <S.TimerColumn>
            <S.TimerValue ref={timerSecondsRef}>
              {DEFAULT_TIMER_CONTENT}
            </S.TimerValue>
            <S.TimerUnits>{t('commonComponents.timer.seconds')}</S.TimerUnits>
          </S.TimerColumn>
        </S.TimerBlock>
      </S.ContentColumn>
    </S.ContentBlock>
  )
}
