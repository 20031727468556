import React from 'react'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { AnimatedRoller } from 'components/AnimatedRoller'

import { selectLanguage } from 'root-redux/selects/common'

import { extractDateParts } from 'helpers/extractDateParts'

type TDateRollerProps = {
  realPredictableDate: dayjs.Dayjs
}

const DATE_TEMPLATE = {
  day: '{day}',
  month: '{month}',
  year: '{year}',
} as const

const DATE_FORMAT_CONFIG = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
} as const

const templateRegex = new RegExp(
  `(${DATE_TEMPLATE.year}|${DATE_TEMPLATE.month}|${DATE_TEMPLATE.day})`,
)

const MOCK_DATE = '2025-02-27'

export const LocalizedDateRoller: React.FC<TDateRollerProps> = ({
  realPredictableDate,
}) => {
  const language = useSelector(selectLanguage)

  const { years, months, days } = extractDateParts(
    realPredictableDate,
    language,
  )

  const parts = new Intl.DateTimeFormat(language, DATE_FORMAT_CONFIG)
    .format(new Date(MOCK_DATE))
    .replace('2025', DATE_TEMPLATE.year)
    .replace('02', DATE_TEMPLATE.month)
    .replace('27', DATE_TEMPLATE.day)
    .split(templateRegex)

  return (
    <>
      {parts.map((part, index) => {
        let children = part as unknown as React.JSX.Element

        if (part === DATE_TEMPLATE.year) {
          children = <AnimatedRoller items={years} />
        }
        if (part === DATE_TEMPLATE.month) {
          children = <AnimatedRoller items={months} />
        }
        if (part === DATE_TEMPLATE.day) {
          children = <AnimatedRoller items={days} />
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${part}-${index}`}>{children}</React.Fragment>
        )
      })}
    </>
  )
}
