import React, { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { PaymentMethod } from 'modules/purchaseSubscription/constants'
import { useAvailablePaymentMethods } from 'modules/purchaseSubscription/hooks/useAvailablePaymentMethods'

import creditCardBannerSrc from 'assets/images/credit-card-banner.png'

import { CreditCardFormV2 } from '../../CreditCardForm/v2/CreditCardFormV2'
import { PAYMENT_METHODS_TAB_ICONS, PaymentMethodStatus } from '../constants'
import { StyledPaymentMethodsV2 as S } from './PaymentMethodsV2.styles'

export const PaymentMethodsV2 = () => {
  const [activePaymentMethod, setActivePaymentMethod] =
    useState<PaymentMethod | null>(null)

  const availablePaymentMethods = useAvailablePaymentMethods()
  const isCreditCardSelected = activePaymentMethod === PaymentMethod.CREDIT_CARD
  const isPayPalSelected = activePaymentMethod === PaymentMethod.PAYPAL
  const isOneClickSelected =
    activePaymentMethod === PaymentMethod.APPLE_PAY ||
    activePaymentMethod === PaymentMethod.GOOGLE_PAY

  const initialPaymentMethod = useMemo(() => {
    if (availablePaymentMethods.includes(PaymentMethod.APPLE_PAY)) {
      return PaymentMethod.APPLE_PAY
    }

    if (availablePaymentMethods.includes(PaymentMethod.GOOGLE_PAY)) {
      return PaymentMethod.GOOGLE_PAY
    }

    return PaymentMethod.CREDIT_CARD
  }, [availablePaymentMethods])

  const getPaymentMethodIcon = (method: PaymentMethod): string =>
    PAYMENT_METHODS_TAB_ICONS[method][
      method === activePaymentMethod
        ? PaymentMethodStatus.ACTIVE
        : PaymentMethodStatus.INACTIVE
    ]

  useEffect(() => {
    setActivePaymentMethod(initialPaymentMethod)
  }, [initialPaymentMethod])

  return (
    <div>
      <S.ButtonList>
        {availablePaymentMethods.map((method) => (
          <S.Button
            key={method}
            isActive={activePaymentMethod === method}
            onClick={() => setActivePaymentMethod(method)}
          >
            <div>
              {method === PaymentMethod.CREDIT_CARD ? (
                <>
                  <S.ButtonText isActive={activePaymentMethod === method}>
                    <Trans i18nKey="kitCheckoutV1.creditCard" />
                  </S.ButtonText>
                  <S.ImageContainer $height={14}>
                    <img
                      src={getPaymentMethodIcon(method)}
                      alt="payment-method"
                    />
                  </S.ImageContainer>
                </>
              ) : (
                <S.ImageContainer $height={46}>
                  <img
                    src={getPaymentMethodIcon(method)}
                    alt="payment-method"
                  />
                </S.ImageContainer>
              )}
            </div>
          </S.Button>
        ))}
      </S.ButtonList>
      <div>
        <S.PaymentContent isDisplayed={isCreditCardSelected}>
          <S.BannerContainer>
            <img src={creditCardBannerSrc} alt="credit-card" />
          </S.BannerContainer>
          <CreditCardFormV2 isDisplayed={isCreditCardSelected} />
        </S.PaymentContent>
        <S.PaymentContent isDisplayed={isPayPalSelected}>
          <S.Slogan>
            <Trans i18nKey="kitCheckoutModalV1.oneClickPaymentMethodSlogan" />
          </S.Slogan>
          <S.PrimerPayPalButton />
        </S.PaymentContent>
        <S.PaymentContent isDisplayed={isOneClickSelected}>
          <S.Slogan>
            <Trans i18nKey="kitCheckoutModalV1.oneClickPaymentMethodSlogan" />
          </S.Slogan>
          <S.PaymentRequestButton />
        </S.PaymentContent>
      </div>
    </div>
  )
}
