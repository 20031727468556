import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useLockScroll } from 'hooks/useLockScroll'

import { PaymentState } from 'modules/purchaseSubscription/constants'
import {
  CHECK_3D_SECURE,
  PURCHASE,
} from 'modules/purchaseSubscription/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchaseSubscription/redux/selects'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledPaymentWaitingModal as S } from './PaymentWaitingModal.styles'
import { PaymentError, PaymentLoading, PaymentSuccess } from './components'

type TProps = {
  isPaymentWaitingShown: boolean
  setIsPaymentWaitingShown: (value: boolean) => void
}

const ANIMATION_PATH = '/animations/kit-payment-animation.json'

const useSelectors = () =>
  useBulkSelector({
    fetchingActionsList: selectActionList,
    error: selectError,
    threeDSecureIframeURL: select3DSecureIframeUrl,
    uuid: selectUUID,
  })

export const PaymentWaitingModal: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
}) => {
  const [paymentState, setPaymentState] = useState<PaymentState>(
    PaymentState.LOADING,
  )
  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)

  const dispatch: TAppDispatch = useDispatch()
  const { fetchingActionsList, error, threeDSecureIframeURL, uuid } =
    useSelectors()
  useLockScroll(isPaymentWaitingShown)

  const isPurchaseInProcess =
    fetchingActionsList.includes(PURCHASE) ||
    fetchingActionsList.includes(CHECK_3D_SECURE)

  const handleSuccessButtonClick = () => {
    setIsPaymentWaitingShown(false)
    dispatch(getUserStatusAction(uuid))
  }

  const handleErrorButtonClick = () => {
    setIsPaymentWaitingShown(false)
    setPaymentState(PaymentState.LOADING)
    dispatch(resetErrorAction())
  }

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
        loop: true,
        name: 'waitingAnimation',
      })
    }

    if (!isPurchaseInProcess && isPaymentWaitingShown && error) {
      setPaymentState(PaymentState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !threeDSecureIframeURL
    ) {
      setPaymentState(PaymentState.SUCCESS)
    }

    return () => lottie.destroy('waitingAnimation')
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    threeDSecureIframeURL,
  ])

  useEffect(() => {
    if (loadingAnimationRef.current && paymentState === PaymentState.LOADING) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}`,
        loop: true,
        name: 'paymentStateAnimation',
      })
    }

    return () => lottie.destroy('paymentStateAnimation')
  }, [paymentState])

  return (
    isPaymentWaitingShown && (
      <S.KitModalV1 isCentered>
        <S.LottieContainer>
          {paymentState === PaymentState.LOADING && (
            <PaymentLoading animationRef={loadingAnimationRef} />
          )}
          {paymentState === PaymentState.SUCCESS && (
            <PaymentSuccess onClick={handleSuccessButtonClick} />
          )}
          {paymentState === PaymentState.ERROR && (
            <PaymentError error={error} onClick={handleErrorButtonClick} />
          )}
        </S.LottieContainer>
      </S.KitModalV1>
    )
  )
}
