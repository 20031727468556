import { MotivationValue } from 'value-constants'

export const QUESTION = 'How motivated are you to burn off excess pounds?'
export const QUESTION_V2 =
  'Do you have an important event coming up that will motivate you to lose weight?'

export const OPTION_VALUES = {
  SEE_THE_BUZZ: 'see_the_buzz',
  READY_TO_TRY_LOSE: 'ready_to_try_lose',
  DETERMINED_TO_DROP: 'determined_to_drop',
}

export const OPTIONS = [
  {
    value: MotivationValue.VACATION,
    title: 'onboarding.motivation.otherOptions.vacation',
  },
  {
    value: MotivationValue.WEDDING,
    title: 'onboarding.motivation.otherOptions.wedding',
  },
  {
    value: MotivationValue.SPORTS,
    title: 'onboarding.motivation.otherOptions.sports',
  },
  {
    value: MotivationValue.SUMMER,
    title: 'onboarding.motivation.otherOptions.summer',
  },
  {
    value: MotivationValue.REUNION,
    title: 'onboarding.motivation.otherOptions.reunion',
  },
  {
    value: MotivationValue.FAMILY,
    title: 'onboarding.motivation.otherOptions.family',
  },
  {
    value: MotivationValue.HOLIDAY,
    title: 'onboarding.motivation.otherOptions.holiday',
  },
  {
    value: MotivationValue.OTHER,
    title: 'onboarding.motivation.otherOptions.other',
  },
  {
    value: MotivationValue.NO,
    title: 'onboarding.motivation.otherOptions.no',
  },
]

const T_PREFIX = 'onboarding.kitMotivationV1.options'

export const ANSWER_OPTIONS_KIT_V2 = [
  {
    value: MotivationValue.MORE_ATTRACTIVE,
    textPath: `${T_PREFIX}.moreAttractive`,
  },
  {
    value: MotivationValue.MORE_ENERGY,
    textPath: `${T_PREFIX}.moreEnergy`,
  },
  {
    value: MotivationValue.NICE_CLOTHES,
    textPath: `${T_PREFIX}.niceClothes`,
  },
  {
    value: MotivationValue.IDEAL_WEIGHT,
    textPath: `${T_PREFIX}.idealWeight`,
  },
]

export const OPTIONS_V2 = [
  {
    value: 'want_to_give_it_a_try',
    textPath: 'onboarding.motivation.options2.giveATry',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/superhero.png',
  },
  {
    value: 'make_some_progress',
    textPath: 'onboarding.motivation.options2.makeSomeProgress',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/man-goes-to-goal.png',
  },
  {
    value: 'to_achieve_results_fast',
    textPath: 'onboarding.motivation.options2.achieveResults',
    textPathFemale: 'onboarding.motivation.options2.achieveResults_female',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/man-reached-goal.png',
  },
]
