import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { FEATURE_KEYS } from 'root-constants'

export const useExperimentalFeatures = () => {
  const isAlternativeDiscountVariant = useFeatureIsOn(
    FEATURE_KEYS.fas_801_dynamic_discount,
  )
  const isAlternativeCheckoutVariant = useFeatureIsOn(
    FEATURE_KEYS.fas_1055_checkout_in_modal,
  )

  const isAlternativeUpsell = useFeatureIsOn(FEATURE_KEYS.fas_530_new_upsell)

  return {
    isAlternativeDiscountVariant,
    isAlternativeCheckoutVariant,
    isAlternativeUpsell,
  }
}
