import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Container } from 'components/Container'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import dish1 from 'assets/images/dish-1.png'
import dish2 from 'assets/images/dish-2.png'
import dish3 from 'assets/images/dish-3.png'
import dish4 from 'assets/images/dish-4.png'
import arrowsSvg from 'assets/images/sprite/arrows-down.svg'

import {
  COHORTS_BASED_ON_FLOW2,
  Color,
  Language,
  TOTAL_USERS,
} from 'root-constants'

import { StyledCreatingPlanV2 as S } from './CreatingPlanV2.styles'

const T_PREFIX = 'onboarding.creatingPlan'

export const CreatingPlanV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [isFirstChangeableContentShown, setIsFirstChangeableContentShown] =
    useState(true)
  const [isSecondChangeableContentShown, setIsSecondChangeableContentShown] =
    useState(false)
  const [isThirdChangeableContentShown, setIsThirdChangeableContentShown] =
    useState(false)
  const [isFourthChangeableContentShown, setIsFourthChangeableContentShown] =
    useState(false)

  const { t } = useTranslation()
  const { search } = useLocation()

  const { currentSubscriptionPageId } = usePageInfo()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath: COHORTS_BASED_ON_FLOW2.includes(cohortToUse)
      ? `${currentSubscriptionPageId}${search}`
      : nextPagePath,
  })

  const { counter, linearCounterValue } = useCounter(true, {
    duration: 12500,
    callback: handleContinue,
  })

  useEffect(() => {
    switch (linearCounterValue) {
      case 0:
        setIsFirstChangeableContentShown(true)
        break
      case 25:
        setIsSecondChangeableContentShown(true)
        break
      case 50:
        setIsThirdChangeableContentShown(true)
        break
      case 75:
        setIsFourthChangeableContentShown(true)
        break
      default:
    }
  }, [linearCounterValue])

  return (
    <Container>
      <S.Title>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </S.Title>
      <S.Arrows svg={arrowsSvg} />

      <S.Images>
        <S.ChangeableContent data-is-shown={isFirstChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleFirst`} />
          </S.Subtitle>
          <img src={dish1} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isSecondChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleSecond`} />
          </S.Subtitle>
          <img src={dish2} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isThirdChangeableContentShown}>
          <S.Subtitle marginBottom={70}>
            <Trans i18nKey={`${T_PREFIX}.subtitleThird`} />
          </S.Subtitle>
          <img src={dish3} alt="dish" />
        </S.ChangeableContent>

        <S.ChangeableContent data-is-shown={isFourthChangeableContentShown}>
          <S.Subtitle marginBottom={24}>
            <Trans i18nKey={`${T_PREFIX}.subtitleFourth`} />
          </S.Subtitle>
          <S.Description>
            <Trans
              i18nKey="onboarding.creatingPlan.description"
              values={{ count: TOTAL_USERS }}
            />
          </S.Description>
          <img src={dish4} alt="dish" />
        </S.ChangeableContent>
      </S.Images>

      <S.ProgressbarContainer>
        <ProgressAnalyzer value={counter} color={Color.PRIMARY}>
          <Trans i18nKey={`${T_PREFIX}.loaderText`} />
        </ProgressAnalyzer>
      </S.ProgressbarContainer>
    </Container>
  )
}
