import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectLanguage,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'

import { createProductId } from 'helpers/createProductId'

import { setCheckoutModalShownAction } from 'modules/purchaseSubscription/redux/actions/common'
import {
  selectSubscription,
  selectSubscriptionPaywallDiscountPercent,
} from 'modules/purchaseSubscription/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import { compareSubscriptionList } from '../lib/compareSubscriptionList'

export const useCancelOffer = () => {
  const subscriptions = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const email = useSelector(selectUserOnboardingEmail)
  const discountFromSubscriptions = useSelector(
    selectSubscriptionPaywallDiscountPercent,
  )
  const selectedSubscription = useSelector(selectSubscription)
  const language = useSelector(selectLanguage)
  const isPersonalDataAllowed = useSelector(selectIsPersonalDataAllowed)
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()
  const hasVatInfo = useVatInfo()
  const { currentPaymentPageId } = usePageInfo()
  const { isAlternativeCheckoutVariant } = useExperimentalFeatures()
  const paymentPagePath = `${currentPaymentPageId}${search}`

  const handleGetPlan = () => {
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    handleShowPayment()

    if (isAlternativeCheckoutVariant) {
      dispatch(setCheckoutModalShownAction(true))
    } else {
      goTo(paymentPagePath)
    }
  }

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))

    const tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (subscriptions.length > 0) {
      eventLogger.logSalePageShown({
        productIds: subscriptions.map(({ mainPrices }) =>
          createProductId({
            periodName: mainPrices.periodName,
            periodQuantity: mainPrices.periodQuantity,
            price: mainPrices.fullPrice,
          }),
        ),
        screenName: ScreenName.CANCEL_OFFER,
      })
    }
  }, [subscriptions])

  return {
    language,
    hasPrices,
    discountFromSubscriptions,
    selectedSubscription,
    handleGetPlan,
    handleAmplitudePaymentEvents,
  }
}
