import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { usePageInfo } from 'contexts/PageInfoProvider'

import {
  resetErrorAction,
  setSubscriptionListAction,
  stopFetching,
} from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectCurrentVariantCohort,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'
import { useUserStatus } from 'hooks/useUserStatus'

import { createProductId } from 'helpers/createProductId'
import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  setCheckoutModalShownAction,
} from 'modules/purchaseSubscription/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchaseSubscription/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { Cohort, FEATURE_KEYS, ScreenName } from 'root-constants'

import { logGeneralCheckoutStartedEvents } from '../lib/logGeneralCheckoutStartedEvents'

const featureFallback = JSON.stringify([
  PageId.CANCEL_OFFER_1,
  PageId.CANCEL_OFFER_QUESTION_1,
])

const useSelectors = () =>
  useBulkSelector({
    fullPrice: selectSubscriptionFullPrice,
    periodName: selectSubscriptionPeriodName,
    periodQuantity: selectSubscriptionPeriodQuantity,
    selectedSubscription: selectSubscription,
    trialCurrentPrice: selectSubscriptionTrialPeriodPrice,
    threeDSecureIframeUrl: select3DSecureIframeUrl,
    cohort: selectCurrentVariantCohort,
    fetchingActionsList: selectActionList,
    screenName: selectScreenName,
    stripeAccountId: selectStripeAccountId,
    stripeAccountName: selectStripeAccountName,
    subscriptionsList: selectSubscriptionList,
    countryCode: selectUserCountryCode,
    uuid: selectUUID,
  })

export const useCheckout = () => {
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const creditCardPaymentRef = useRef<HTMLDivElement>(null)

  const {
    periodName,
    periodQuantity,
    screenName,
    stripeAccountName,
    stripeAccountId,
    fetchingActionsList,
    subscriptionsList,
    selectedSubscription,
    threeDSecureIframeUrl,
    trialCurrentPrice,
    fullPrice,
    uuid,
    cohort,
    countryCode,
  } = useSelectors()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const {
    currentSubscriptionPageId,
    cancelOfferPageId,
    cancelOfferQuestionPageId,
  } = usePageInfo()
  const availableRoute = useUserStatus()
  const { isAlternativeCheckoutVariant } = useExperimentalFeatures()

  const hasPaypal = isPayPalAvailable(countryCode)
  const parseFeatureValue = JSON.parse(
    useFeatureValue(FEATURE_KEYS.fas_1057_cancel_offer, featureFallback),
  )

  const getCancelOfferPageId = () => {
    if (cohort === Cohort.MF_INTRO_2) {
      return parseFeatureValue[0] === PageId.CANCEL_OFFER_1
        ? PageId.KIT_CANCEL_OFFER_1
        : PageId.CANCEL_OFFER_1
    }

    if (!cancelOfferPageId) return currentSubscriptionPageId

    return cancelOfferPageId
  }

  const getCancelOfferQuestionPageId = () => {
    if (cohort === Cohort.MF_INTRO_2) {
      return parseFeatureValue[1] === PageId.CANCEL_OFFER_QUESTION_1
        ? PageId.KIT_CANCEL_OFFER_QUESTION_1
        : PageId.CANCEL_OFFER_QUESTION_1
    }

    if (!cancelOfferQuestionPageId) return currentSubscriptionPageId

    return cancelOfferQuestionPageId
  }

  const redirectToCancelOffer = () => {
    if (screenName !== ScreenName.CANCEL_OFFER) {
      goTo({ pathname: getCancelOfferQuestionPageId(), search })
      return
    }

    goTo({ pathname: getCancelOfferPageId(), search })
  }

  const productId = createProductId({
    periodName,
    periodQuantity,
    price: trialCurrentPrice || fullPrice,
  })

  const isSubscriptionTrial =
    selectedSubscription && trialCurrentPrice !== fullPrice
  const isCheckoutReady = !fetchingActionsList.includes(
    CHECK_PAYMENT_REQUEST_BUTTON,
  )

  const resetSubscriptionList = useCallback(() => {
    dispatch(resetErrorAction())
    dispatch(setSubscriptionListAction([]))
  }, [dispatch])

  const handleCloseCheckout = () => {
    if (!isAlternativeCheckoutVariant) {
      resetSubscriptionList()
    }

    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    if (isAlternativeCheckoutVariant) {
      dispatch(setCheckoutModalShownAction(false))
    }

    redirectToCancelOffer()
  }

  useLayoutEffect(() => {
    dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(getUserStatusAction(uuid))

    if (selectedSubscription) return

    replaceHistory({
      pathname: availableRoute,
      search,
    })
  }, [
    dispatch,
    currentSubscriptionPageId,
    search,
    selectedSubscription,
    availableRoute,
    uuid,
  ])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }

    logGeneralCheckoutStartedEvents({ uuid, subscriptionsList })
  }, [
    productId,
    screenName,
    stripeAccountId,
    stripeAccountName,
    uuid,
    subscriptionsList,
  ])

  useEffect(() => {
    return () => {
      if (!isAlternativeCheckoutVariant) {
        resetSubscriptionList()
      }
    }
  }, [resetSubscriptionList, isAlternativeCheckoutVariant, dispatch])

  useEffect(() => {
    const { current: elem } = creditCardPaymentRef
    if (elem) {
      const yCoordinate = elem.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }, [])

  return {
    isPaymentWaitingShown,
    isCheckoutReady,
    isSubscriptionTrial,
    hasPaypal,
    threeDSecureIframeUrl,
    trialCurrentPrice,
    creditCardPaymentRef,
    setIsPaymentWaitingShown,
    handleCloseCheckout,
  }
}
