import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'
import { PrimaryGoalValue } from 'value-constants'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import getFitIcon from 'assets/images/get-fit-icon.png'
import healthyHabitsIcon from 'assets/images/healthy-habits-icon.png'
import loseWeightFemaleIcon from 'assets/images/lose-weight-female-icon.png'
import loseWeightMaleIcon from 'assets/images/lose-weight-male-icon.png'

import { CustomPageId } from 'page-constants'
import { Gender, Language } from 'root-constants'

import { StyledPrimaryGoalV4 as S } from './PrimaryGoalV4.styles'

const T_COMMON = 'commonComponents.primaryGoal'
const T_ONBOARDING = 'onboarding.primaryGoal'

export const PrimaryGoalV4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const gender = useSelector(selectCurrentUserGender)
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.PRIMARY_GOAL,
    question: t(`${T_ONBOARDING}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: CustomPageId.PRIMARY_GOAL,
    type: OptionType.RADIO,
    iconHeight: '40px',
    iconMinWidth: '40px',
    margin: '0 0 12px 0',
    height: 'auto',
    minHeight: '80px',
    padding: '8px 20px 8px 16px',
    spacingBetweenIconAndContent: '0 0 16px',
    disabled: isAnswersDisabled,
    theme: answerWithIconTheme.NUTRIMATE,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const options = [
    {
      title: `${T_COMMON}.loseWeight`,
      description: `${T_ONBOARDING}.options.loseWeight.description2`,
      value: PrimaryGoalValue.LOSE_WEIGHT,
      iconSrc:
        gender === Gender.FEMALE ? loseWeightFemaleIcon : loseWeightMaleIcon,
    },
    {
      title: `${T_COMMON}.getFit`,
      description: `${T_ONBOARDING}.options.getFit.description`,
      value: PrimaryGoalValue.GET_FIT,
      iconSrc: getFitIcon,
    },
    {
      title: `${T_COMMON}.developHealthyHabits`,
      description: `${T_ONBOARDING}.options.healthyHabits.description2`,
      value: PrimaryGoalValue.BUILD_HEALTHY_HABITS,
      iconSrc: healthyHabitsIcon,
    },
  ]

  return (
    <KitPageContainer>
      <PageTitle textAlign="left" marginBottom={24}>
        <Trans i18nKey="onboarding.primaryGoal.title" />
      </PageTitle>

      {options.map(({ title, description, value, iconSrc }) => (
        <AnswerWithIcon
          key={title}
          value={value}
          iconSrc={iconSrc}
          {...optionProps}
        >
          <S.AnswerTitle>
            <Trans i18nKey={title} />
          </S.AnswerTitle>
          <S.AnswerDescription>
            <Trans i18nKey={description} />
          </S.AnswerDescription>
        </AnswerWithIcon>
      ))}
    </KitPageContainer>
  )
}
