import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTooltip = {
  TooltipContainer: styled.div`
    position: absolute;
    top: 52px;
    right: 0;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 10px;
    padding: 16px;

    &:after {
      position: absolute;
      content: '';
      top: -14px;
      right: 16px;
      width: 21px;
      height: 17px;
      background: ${Color.WHITE};
      clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    }
  `,
}
