import React from 'react'

import { Comparison } from 'components/Comparison'

import mealOne from 'assets/images/meal-1.png'
import mealTwo from 'assets/images/meal-2.png'

const CONS_T_KEY = 'onboarding.caloriesEqualityV1.comparing.cons'
const PROS_T_KEY = 'onboarding.caloriesEqualityV1.comparing.pros'

export const CaloriesComparison = () => {
  const options = {
    titles: [`${CONS_T_KEY}.title`, `${PROS_T_KEY}.title`],
    images: [mealOne, mealTwo],
    options: [
      [
        `${CONS_T_KEY}.options.first`,
        `${CONS_T_KEY}.options.second`,
        `${CONS_T_KEY}.options.thirty`,
      ],
      [
        `${PROS_T_KEY}.options.first`,
        `${PROS_T_KEY}.options.second`,
        `${PROS_T_KEY}.options.thirty`,
      ],
    ],
  }

  return <Comparison comparisonOptions={options} />
}
