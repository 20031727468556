import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlockV3 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_800};
    background-color: ${Color.WHITE};
    padding: 16px;
  `,

  InfoItem: styled.div`
    display: flex;
    width: 50%;
    gap: 12px;
  `,

  InfoItemIcon: styled.img`
    width: 36px;
    height: 36px;
  `,

  InfoItemText: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,

  InfoValue: styled.h3`
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};

    &:first-letter {
      text-transform: uppercase;
    }
  `,

  Label: styled.h3`
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_101};
    margin: 0 0 2px 0;

    &:first-letter {
      text-transform: uppercase;
    }
  `,
  Divider: styled.div`
    margin: 0 14px;
    width: 1px;
    height: 36px;
    background-color: ${Color.GREY_800};
  `,
}
