import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

export const StyledAnimatedSequenceView = {
  Title: styled(PageTitle)`
    margin-bottom: 24px;
    text-align: left;
  `,
  Wrapper: styled.div`
    position: relative;
    height: 365px;
  `,
  Preview: styled.img<{ $isHide: boolean }>`
    position: absolute;
    width: 375px;
    object-fit: contain;
    opacity: ${({ $isHide }) => ($isHide ? 0 : 1)};
  `,
}
