import leanFemale from 'assets/images/body-types-female-lean.png'
import midSizeFemale from 'assets/images/body-types-female-mid-size.png'
import significantlyOverweightFemale from 'assets/images/body-types-female-obessity.png'
import overweightFemale from 'assets/images/body-types-female-overweight.png'
import plusSizeFemale from 'assets/images/body-types-female-plus-size.png'
import leanMale from 'assets/images/body-types-male-lean.png'
import midSizeMale from 'assets/images/body-types-male-mid-size.png'
import significantlyOverweightMale from 'assets/images/body-types-male-obessity.png'
import overweightMale from 'assets/images/body-types-male-overweight.png'
import plusSizeMale from 'assets/images/body-types-male-plus-size.png'

import { Gender } from 'root-constants'

const T_PREFIX_V4 = 'onboarding.bodyType.optionsV4'
export const OPTIONS_V4 = [
  {
    value: 'lean',
    text: `${T_PREFIX_V4}.lean`,
    img: { [Gender.MALE]: leanMale, [Gender.FEMALE]: leanFemale },
  },
  {
    value: 'mid_size',
    text: `${T_PREFIX_V4}.midSize`,
    img: { [Gender.MALE]: midSizeMale, [Gender.FEMALE]: midSizeFemale },
  },
  {
    value: 'plus_size',
    text: `${T_PREFIX_V4}.plusSize`,
    img: { [Gender.MALE]: plusSizeMale, [Gender.FEMALE]: plusSizeFemale },
  },
  {
    value: 'overweight',
    text: `${T_PREFIX_V4}.overweight`,
    img: { [Gender.MALE]: overweightMale, [Gender.FEMALE]: overweightFemale },
  },
  {
    value: 'significantly_overweight',
    text: `${T_PREFIX_V4}.significantlyOverweight`,
    img: {
      [Gender.MALE]: significantlyOverweightMale,
      [Gender.FEMALE]: significantlyOverweightFemale,
    },
  },
]
