import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { WHAT_YOU_GET_MAP } from '../../constants'
import { StyledWhatYouGet as S } from './WhatYouGet.styles'

export const WhatYouGet = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Title>
        <Trans i18nKey="purchase.whatYouGetV2.title" />
      </S.Title>
      <S.List>
        {WHAT_YOU_GET_MAP.map(({ imgSrc, background, title, info }) => (
          <S.Item key={title} background={background}>
            <S.ItemContent>
              <S.ItemTitle>{t(title)}</S.ItemTitle>
              <S.ItemInfo>{t(info)}</S.ItemInfo>
            </S.ItemContent>
            <S.ImageContainer>
              <img src={imgSrc} alt="what-you-get" />
            </S.ImageContainer>
          </S.Item>
        ))}
      </S.List>
    </S.Container>
  )
}
