import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectIsPersonalDataAllowed,
  selectUUID,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createProductId } from 'helpers/createProductId'

import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchaseSubscription/redux/selects'

import { IAppState } from 'models/store.model'

export const getStripePurchaseCommonEventParams = (state: IAppState) => {
  const uuid = selectUUID(state)
  const stripePriceId = selectSubscriptionLookupKey(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
  const price = selectSubscriptionFullPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const currency = selectCurrency(state)
  const screenName = selectScreenName(state)
  const stripeAccountId = selectStripeAccountId(state)
  const stripeAccountName = selectStripeAccountName(state)
  const paymentSystem = selectUserPaymentSystem(state)
  const isPersonalDataAllowed = selectIsPersonalDataAllowed(state)

  const productId = trialPriceId
    ? createIntroOfferProductId({
        priceId: stripePriceId,
        trialPriceId,
        trialPeriodQuantity,
      })
    : createProductId({
        periodName,
        periodQuantity,
        price,
      })

  return {
    uuid,
    price,
    trialPeriodPrice,
    periodName,
    periodQuantity,
    currency,
    trialPriceId,
    screenName,
    productId,
    stripePriceId,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
    isPersonalDataAllowed,
  }
}
