import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBoostResults as S } from './BoostResults.styles'
import { GOALS_MAP } from './constants'

type TProps = {
  className?: string
}

export const BoostResults: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t('purchase.boostResult.title')}</S.Title>
      <S.Subtitle>{t('purchase.boostResult.subtitle')}</S.Subtitle>
      <S.GoalsTitle>{t('purchase.boostResult.goals.title')}</S.GoalsTitle>
      <S.Goals>
        {GOALS_MAP.map(({ icon, translationKey }) => (
          <S.GoalItem key={translationKey} icon={icon}>
            {t(translationKey)}
          </S.GoalItem>
        ))}
      </S.Goals>
    </div>
  )
}
