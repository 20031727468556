import React from 'react'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import { PrimerPayPalButton } from '../PrimerPayPalButton/PrimerPayPalButton'
import { StyledOneClickPayment as S } from './OneClickPayment.styles'

export const OneClickPayment: React.FC = () => {
  const countryCode = useSelector(selectUserCountryCode)
  const hasPaypal = isPayPalAvailable(countryCode)

  return (
    <S.Container>
      <S.PaymentRequestButton />
      {hasPaypal && <PrimerPayPalButton />}
    </S.Container>
  )
}
