import styled, { keyframes } from 'styled-components'

interface IWrapperProps {
  $height: number
  $width: string
  $distance: number
  $duration: number
}

const roll = (distance: number) => keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-${distance}px); }
`

export const AnimatedRoller = {
  Wrapper: styled.div<IWrapperProps>`
    display: inline-block;
    overflow: hidden;
    height: ${({ $height }) => $height}px;
    width: ${({ $width }) => $width};
    transition: width 0.2s ease;

    & > ul {
      position: relative;
      animation: ${({ $distance }) => roll($distance)}
        ${({ $duration }) => $duration}s ease-in-out;
      animation-fill-mode: forwards;
    }
  `,
  Item: styled.li<{ translateY: number; isNumber: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-align: center;
    white-space: nowrap;
    line-height: ${({ isNumber }) => (isNumber ? 1 : 1.5)};
    transform: translateY(${({ translateY }) => translateY}px);
  `,
}
