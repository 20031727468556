import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { FlagContainer } from 'components/FlagContainer'
import { PriceValue } from 'components/PriceValue'

import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { Color } from 'root-constants'

import { useSubscriptionItem } from '../hooks/useSubscriptionItem'
import { StyledKitSubscriptionItemV1 as S } from '../styles/KitSubscriptionItemV1.styles'
import { StyledKitSubscriptionItemV1ComplexCurrency as C } from '../styles/KitSubscriptionItemV1ComplexCurrency.styles'

export const KitSubscriptionItemV1Trial: FC<TSubscriptionItemProps> = ({
  subscription,
  isSelected,
}) => {
  const { id, isDefault, currency } = subscription

  const {
    oldPrice,
    periodName,
    trialCurrentPrice,
    pricePerDay,
    oldPricePerDay,
    currencyOptions,
    isCompactVariant,
  } = useSubscriptionItem(subscription)

  return !isComplexCurrencySymbol(currency) ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod
          isSelected={isSelected}
          isCompactVariant={isCompactVariant}
        >
          {periodName}
        </S.PlanPeriod>
        <S.PlanPrice>
          <S.PreviousPrice>
            <PriceValue value={oldPrice} formatOptions={currencyOptions} />
          </S.PreviousPrice>
          <S.CurrentPrice>
            <PriceValue
              value={trialCurrentPrice}
              formatOptions={currencyOptions}
            />
          </S.CurrentPrice>
          <S.PreviousDayPrice>
            <PriceValue
              value={oldPricePerDay}
              formatOptions={currencyOptions}
            />
          </S.PreviousDayPrice>
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <S.DayPriceContainer
            isSelected={isSelected}
            isCompactVariant={isCompactVariant}
          >
            <S.CurrentDayPrice>
              <PriceValue value={pricePerDay} formatOptions={currencyOptions} />
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <C.PlanContainer key={id} isSelected={isSelected}>
      <C.PlanColumn>
        <C.PlanPeriod
          isSelected={isSelected}
          isCompactVariant={isCompactVariant}
        >
          {periodName}
        </C.PlanPeriod>
        <C.PlanPrice>
          <C.PreviousPrice>
            <PriceValue value={oldPrice} formatOptions={currencyOptions} />
          </C.PreviousPrice>
          <C.CurrentPrice>
            <PriceValue
              value={trialCurrentPrice}
              formatOptions={currencyOptions}
            />
          </C.CurrentPrice>
        </C.PlanPrice>
      </C.PlanColumn>
      <C.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <C.DayPriceContainer isSelected={isSelected}>
            <C.PreviousDayPrice isSelected={isSelected}>
              <PriceValue
                value={oldPricePerDay}
                formatOptions={currencyOptions}
              />
            </C.PreviousDayPrice>
            <C.CurrentDayPrice>
              <PriceValue value={pricePerDay} formatOptions={currencyOptions} />
            </C.CurrentDayPrice>
            <C.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </C.DayPricePeriod>
          </C.DayPriceContainer>
        </FlagContainer>
      </C.PlanColumn>
      {isDefault && (
        <C.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </C.PopularBadge>
      )}
    </C.PlanContainer>
  )
}
