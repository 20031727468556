import { selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import { PaymentMethod } from 'modules/purchaseSubscription/constants'
import { selectOneClickPaymentMethod } from 'modules/purchaseSubscription/redux/selects'

type TPaymentMethodWithOrder = {
  method: PaymentMethod
  order: number
}

const useSelectors = () =>
  useBulkSelector({
    countryCode: selectUserCountryCode,
    oneClickPaymentMethod: selectOneClickPaymentMethod,
  })

export const useAvailablePaymentMethods = () => {
  const { countryCode, oneClickPaymentMethod } = useSelectors()

  const methods: TPaymentMethodWithOrder[] = [
    {
      method: PaymentMethod.CREDIT_CARD,
      order: 3,
    },
  ]

  if (isPayPalAvailable(countryCode)) {
    methods.push({
      method: PaymentMethod.PAYPAL,
      order: 1,
    })
  }

  if (oneClickPaymentMethod) {
    methods.push({
      method: oneClickPaymentMethod as PaymentMethod,
      order: 2,
    })
  }

  return methods.sort((a, b) => a.order - b.order).map(({ method }) => method)
}
