import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  selectCurrentVariantCohortToUse,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'

import stars from 'assets/images/alternative-stars-v2.png'

import { Cohort } from 'root-constants'

import { CUSTOMER_REVIEWS_V4_A, CUSTOMER_REVIEWS_V4_B } from '../constants'
import { getLocalizedDate } from '../lib/getLocalizedDate'
import { StyledCustomerReviewsV4 as S } from './CustomerReviewsV4.styles'

const useSelectors = () =>
  useBulkSelector({
    cohortToUse: selectCurrentVariantCohortToUse,
    lng: selectLanguage,
  })

export const CustomerReviewsV4: React.FC = () => {
  const { t } = useTranslation()
  const { lng, cohortToUse } = useSelectors()

  const customerReviews =
    cohortToUse === Cohort.MF_MEN_FLOW
      ? CUSTOMER_REVIEWS_V4_B
      : CUSTOMER_REVIEWS_V4_A

  return (
    <S.Wrapper>
      <S.Title>{t('purchase.customerReviews.title')}</S.Title>
      {customerReviews.map(({ title, date, author, text }) => (
        <S.ReviewContainer key={author}>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(title)}</S.ReviewTitle>
            <S.Date>{getLocalizedDate(date, lng)}</S.Date>
            <S.Rating>
              <img src={stars} alt="stars" />
            </S.Rating>
            <S.Author>{author}</S.Author>
          </S.ReviewHeader>
          <S.Text>{t(text)}</S.Text>
        </S.ReviewContainer>
      ))}
    </S.Wrapper>
  )
}
