import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import laptopImg from 'assets/images/user-choice-laptop.png'
import phoneImg from 'assets/images/user-choice-mobile.png'
import banner from 'assets/images/users-choice-female.png'

import { Language, TOTAL_USERS, WOMEN_COHORTS } from 'root-constants'

import {
  StyledAlternativeUsersChoiceV2 as A,
  StyledUsersChoiceV2 as S,
} from './UsersChoiceV2.styles'
import { QUESTION } from './constants'

export const UsersChoiceV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isAlternativeView = useMemo(
    () => WOMEN_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const handleContinue = useNextStep({
    pageId,
    question: isAlternativeView
      ? t('onboarding.usersChoice.titleFemale', {
          lng: Language.EN,
          count: TOTAL_USERS,
        })
      : QUESTION,
    nextPagePath,
  })

  return isAlternativeView ? (
    <div>
      <A.Logo marginBottom={28} />
      <A.Banner>
        <img src={banner} alt="banner" />
      </A.Banner>
      <Container>
        <A.Title>
          <Trans
            i18nKey="onboarding.usersChoice.titleFemale"
            values={{ count: TOTAL_USERS }}
          />
        </A.Title>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </div>
  ) : (
    <S.Wrapper>
      <Logo marginBottom={28} />
      <S.Container>
        <S.ImageContainer>
          <picture>
            <source media="(max-width: 1199px)" srcSet={phoneImg} />
            <img src={laptopImg} alt="people" />
          </picture>
        </S.ImageContainer>
        <S.Title>1,100,000+</S.Title>
        <S.Subtitle>{t('onboarding.usersChoice.subtitle')}</S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </S.Wrapper>
  )
}
