import styled from 'styled-components'

import backArrowIcon from 'assets/images/back-arrow.svg'
import forwardArrowIcon from 'assets/images/forward-arrow.svg'

import { Color } from 'root-constants'

export const StyledUpsellPhotoResult = {
  Container: styled.div`
    margin-bottom: 32px;
    width: 100%;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: ${Color.PRIMARY};
    }
  `,
  BeforeAfterImg: styled.div`
    aspect-ratio: 328 / 215;
    width: 100%;
  `,
  BeforeAfterLabels: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 0px 0px 16px 16px;
    box-shadow: 0px 4px 20px 0px rgba(187, 189, 198, 0.4);
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
    padding: 12px 0;
  `,
  Before: styled.span`
    background-color: #f6f6f7;
    border-radius: 16px;
    color: ${Color.DISABLED};
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 8px 16px;
  `,
  After: styled.span`
    background-color: ${Color.PRIMARY};
    border-radius: 16px;
    color: ${Color.WHITE};
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    padding: 8px 16px;
  `,
  Disclaimer: styled.p`
    color: ${Color.DISABLED};
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 24px;
    text-align: center;

    strong {
      font-weight: 800;
    }
  `,
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets {
      bottom: -5px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: #ececec;
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: ${Color.PRIMARY};
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-color: ${Color.WHITE};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-repeat: no-repeat;
      top: 130px;
    }

    .swiper-button-next {
      background-image: url(${forwardArrowIcon});
      background-position: 55% 50%;
    }

    .swiper-button-prev {
      background-image: url(${backArrowIcon});
      background-position: 45% 50%;
    }
  `,
}
