import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { MotivationValue } from 'value-constants'

import { Button } from 'components/Button'
import { LoseWeightGraphV3 } from 'components/LoseWeightGraphV3'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectCurrentUserMotivation,
  selectEventDate,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { DEFAULT_DATE_FORMAT, daysFromNowToGoalDate } from 'helpers/dateHelper'

import { Period } from 'modules/purchaseSubscription/constants'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledEventFeedback as S } from './EventFeedback.styles'
import { EVENT_MAP_BASE, EVENT_MAP_V1, LOST_WEIGHT_PER_WEEK } from './constants'

export const EventFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const motivation = useSelector(selectCurrentUserMotivation) as MotivationValue
  const eventDate = useSelector(selectEventDate)
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const { measuringSystemLabel, measurementSystem } =
    useWeightMeasurementSystem()
  const {
    currentBMI: {
      bmiValue: { value },
    },
  } = useUsersBmi()
  const event = t(EVENT_MAP_V1[motivation])
  const graphEvent = t(EVENT_MAP_BASE[motivation as MotivationValue])
  const targetWeight = `${goalWeight} ${measuringSystemLabel}`
  const lostWeightPerDay = useMemo(
    () =>
      Number(
        (
          LOST_WEIGHT_PER_WEEK[value][measurementSystem] / Period.SEVEN_DAYS
        ).toFixed(2),
      ),
    [value, measurementSystem],
  )

  const predictableDate = useMemo(() => {
    const countOfDay = Number(
      ((currentWeight - goalWeight) / lostWeightPerDay).toFixed(2),
    )

    return dayjs().add(countOfDay, 'day')
  }, [currentWeight, goalWeight, lostWeightPerDay])

  const isEventBeforePredictableDay = !!predictableDate.diff(
    dayjs(eventDate, DEFAULT_DATE_FORMAT),
    'day',
  )

  const lostWeightForEvent = (() => {
    if (isEventBeforePredictableDay) {
      const lostWeight = Number(
        (daysFromNowToGoalDate(eventDate) * lostWeightPerDay).toFixed(1),
      )

      return `<strong>~${lostWeight} ${measuringSystemLabel}</strong>`
    }

    return t(`onboarding.eventFeedback.justInTime`)
  })()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.eventFeedback.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.PageContainer>
      <S.PageTitle marginBottom={16}>
        <Trans i18nKey="onboarding.eventFeedback.title" />
      </S.PageTitle>
      <S.InfoContainer>
        <S.Prediction>
          <Trans
            i18nKey="onboarding.eventFeedback.subTitle"
            values={{
              targetWeight,
              date: predictableDate.format(),
            }}
            components={{ span: <span /> }}
          />
        </S.Prediction>
        {event && eventDate && (
          <>
            <S.LostLabel>{t(`onboarding.eventFeedback.lose`)}</S.LostLabel>
            <S.LostValues>
              <Trans
                i18nKey="onboarding.eventFeedback.lostValues"
                values={{ weight: lostWeightForEvent, event }}
              />
            </S.LostValues>
          </>
        )}
      </S.InfoContainer>
      <LoseWeightGraphV3
        predictableDate={predictableDate}
        eventName={graphEvent}
      />
      <S.StickyButtonContainer>
        <Button type="button" onClick={handleNextClick}>
          {t('actions.continue')}
        </Button>
      </S.StickyButtonContainer>
    </S.PageContainer>
  )
}
