import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { usePageInfo } from 'contexts/PageInfoProvider'
import { Input } from 'storybook-ui'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchaseSubscription/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchaseSubscription/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchaseSubscription/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchaseSubscription/redux/selects'
import {
  TPaymentErrorState,
  TStripeFieldProps,
} from 'modules/purchaseSubscription/types'

import { Color, StripeFieldName } from 'root-constants'

import {
  StyledCreditCardFormV1 as S,
  stripeElementStyle,
} from './CreditCardFormV1.styles'

export const CreditCardFormV1: React.FC = () => {
  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const { currentPageId } = usePageInfo()
  const stripe = useStripe()
  const elements = useElements()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)

  const hasErrors = Object.values(errors).some(
    (error) => error.isShown && error.error,
  )
  const hasUntouchedFields = Object.values(errors).some(
    (error) => error.isShown && !error.isTouched,
  )
  const hasUncompletedFields = Object.values(errors).some(
    (field) => !field.isComplete,
  )
  const isFormValid = !hasErrors && !hasUntouchedFields && !hasUncompletedFields

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card || !currentPageId) return

      dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      currentPageId,
      name,
      errors,
    ],
  )

  const handleChange = ({
    fieldName,
    isEmpty,
    hasError,
    isComplete,
    nextElemRef,
  }: TStripeFieldProps) => {
    dispatch(resetErrorAction())

    let error = ''

    if (hasError) {
      error = 'is invalid'
    }

    if (isEmpty) {
      error = EMPTY_FIELD_ERROR
    }

    if (nextElemRef && isComplete) {
      nextElemRef.current?.focus()
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: { isTouched: true, error, isComplete },
    }))
  }

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.CardNumberElement
        onReady={(elem) => {
          elem.focus()
          cardNumberElemRef.current = elem
        }}
        options={{
          showIcon: false,
          placeholder: t('checkout.cardNumber'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.NUMBER,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardExpiryElemRef,
          })
        }}
      />

      <S.CardExpiryElement
        onReady={(elem) => {
          cardExpiryElemRef.current = elem
        }}
        options={{
          placeholder: t('checkout.cardExpiry'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.EXPIRY,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cvcElemRef,
          })
        }}
      />

      <S.CardCvcElement
        onReady={(elem) => {
          cvcElemRef.current = elem
        }}
        options={{
          placeholder: t('checkout.securityNumber'),
          style: stripeElementStyle,
        }}
        onChange={({ empty, error, complete }) => {
          handleChange({
            fieldName: StripeFieldName.CVC,
            isEmpty: empty,
            hasError: !!error,
            isComplete: complete,
            nextElemRef: cardholderNameElemRef,
          })
        }}
      />
      <S.CardholderInput>
        <Input
          inputRef={cardholderNameElemRef}
          required={false}
          type="text"
          width="100%"
          margin="0 0 16px 0"
          backgroundColor={Color.WHITE}
          borderRadius="16px"
          border="1px #cacaca solid"
          focusedBorderColor={Color.GREY_1000}
          padding="20px 16px"
          lineHeight="24px"
          placeholder={t('checkout.cardholderName')}
          placeholderColor={Color.GREY_101}
          color={Color.BLACK_100}
          fontSize="17px"
          data-invalid={!!errors[StripeFieldName.NAME].error}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            const value = (e.target as HTMLInputElement).value.trim()

            setName(value)

            handleChange({
              fieldName: StripeFieldName.NAME,
              isEmpty: false,
              hasError: false,
              isComplete: true,
            })
          }}
        />
      </S.CardholderInput>

      <S.Button
        type="submit"
        disabled={!stripe || !isFormValid}
        disableBackgroundColor={Color.GREY_1000}
        disableColor={Color.WHITE}
        backgroundColor={Color.GREEN_400}
        padding="16px"
        borderRadius="30px"
        fontSize="17px"
        fontWeight="700"
        lineHeight="24px"
      >
        {t('actions.continue')}
      </S.Button>
    </S.Form>
  )
}
