/* eslint-disable max-lines */
import {
  AchieveResultV1,
  AchieveResultV2,
  AchieveResultV3,
  AchieveResultV4,
} from 'pages/achieveResult'
import { BodyTypeV1, BodyTypeV2, BodyTypeV3, BodyTypeV4 } from 'pages/bodyType'
import { CaloriesEqualityV1 } from 'pages/caloriesEquality'
import { CaloriesImpactV1 } from 'pages/caloriesImpact'
import {
  CreatingPlanV1,
  CreatingPlanV2,
  CreatingPlanV3,
  CreatingPlanV4,
} from 'pages/creatingPlan'
import {
  CurrentWeightV1,
  CurrentWeightV2,
  CurrentWeightV3,
  CurrentWeightV4,
} from 'pages/currentWeight'
import { DownloadV1 } from 'pages/download'
import { Email, EmailV2, EmailV3 } from 'pages/email'
import { EmailConsentV1, EmailConsentV2 } from 'pages/emailConsent'
import { EnergyValueV1 } from 'pages/energyValue'
import { FinishingTouches } from 'pages/finishingTouches'
import { KitAiTechV1 } from 'pages/kitAiTech'
import { KitLifeStyle } from 'pages/lifeStyle'
import { PresaleV1, PresaleV2, PresaleV3 } from 'pages/presale'
import {
  AboutFeelingGood,
  ActivityDuringWork,
  ActivityDuringWorkV2,
  ActivityLevel,
  AnalyzingAnswers,
  AnalyzingAnswersV2,
  BadHabits,
  BellyType,
  Benefits,
  BetweenMeals,
  BreakfastTime,
  BreathAfterClimbing,
  ChallengesYouFaced,
  ContributingEvents,
  ContributingEventsV2,
  CustomOptionsRightToLeft,
  CustomizeMealPlanV1,
  DailyMealsV1,
  DailyWater,
  DailyWaterV2,
  Determined,
  DietType,
  DinnerTime,
  DontExerciseV1,
  DreamBody,
  DreamBodyV2,
  EasyToFollowPlanV1,
  EatingPlace,
  EmotionalEatingV1,
  EventDate,
  EventDateV2,
  EventFeedback,
  EventFeedbackV3,
  FastingAtWeekend,
  FastingFeedbackV2,
  FavouriteFoodsV1,
  Feedback,
  FeedbackV2,
  FeelHungryV1,
  FeelingsToAchieve,
  GainWeightMenopause,
  GainWeightPeriod,
  Gender,
  GenderV2,
  GenderV3,
  GenderV4,
  GenderV5,
  GoalWeight,
  GoalWeightV2,
  GoalWeightV4,
  GoodHands,
  GoodHandsV2,
  HaveYouHeard,
  Height,
  HeightV2,
  HelpCalmCravings,
  IdealWeightTime,
  IdealWeightTimeV2,
  KitCommitV1,
  KitMealsV1,
  KitMotivationPreviewV1,
  LowerRisk,
  LunchTime,
  MealTimingV1,
  MealsPerDay,
  MealsPreparation,
  MenChoice,
  MenuV1,
  Motivation,
  MotivationV2,
  OptionsLeftToRight,
  OptionsLeftToRightV2,
  OptionsLeftToRightV3,
  OptionsRightToLeft,
  OptionsRightToLeftV3,
  PastTries,
  Period,
  PeriodAffectAppetite,
  PlanPace,
  PreparePlanV1,
  PreparePlanV2,
  PrimaryGoal,
  PrimaryGoalV2,
  PrimaryGoalV3,
  PrimaryGoalV4,
  ProfileSummaryV1,
  ProfileSummaryV2,
  ReasonToGiveUp,
  RestrictionsV1,
  SecondaryGoalV1,
  Squats,
  StartPlanningMealsV1,
  StatementQuestion,
  StickingPlanV1,
  StickingPlanV3,
  StopHungerV1,
  TargetAreas,
  TargetAreasV2,
  ThankYouForYourTrust,
  TimeYouFeelHungry,
  UniquelyTailoredPlanV1,
  UsersChoice,
  UsersChoiceV2,
  UsersChoiceV3,
  UsersChoiceV4,
  UsersChoiceV5,
  Walking,
  WeAllGainWeight,
  WeightGain,
  WeightGainResult,
  WeightLossDateV1,
  WeightLossDateV2,
  WhatElse,
  WorkSchedule,
  Workout,
  YoEffect,
  YoEffectV2,
  YouAreNotAlone,
} from 'pages/questions'
import { Age, AgeV2, AgeV3, AgeV4 } from 'pages/questions/Age'
import {
  AgeRange,
  AgeRangeV2,
  AgeRangeV3,
  AgeRangeV4,
  KitAgeRangeV1,
  KitAgeRangeV2,
} from 'pages/questions/AgeRange'
import { KitBadHabits } from 'pages/questions/BadHabits'
import { BadHabitsFeedbackV1 } from 'pages/questions/BadHabitsFeedback'
import { BurnCaloriesV1 } from 'pages/questions/BurnCalories'
import { KitChallengesYouFaced } from 'pages/questions/ChallengesYouFaced'
import { DailyWaterV3, KitDailyWater } from 'pages/questions/DailyWater'
import {
  ImageCenterOptionsBelow,
  ImageOptionsV1,
  ImageWithTextV1,
} from 'pages/questions/Dynamic'
import { InfluencerProfile } from 'pages/questions/Dynamic/InfluencerProfile'
import { EventV1, KitEventV1 } from 'pages/questions/Event'
import { EventFeedbackV2 } from 'pages/questions/EventFeedback'
import { FastingV1, KitFasting } from 'pages/questions/Fasting'
import { FastingFeedbackV1 } from 'pages/questions/FastingFeedback'
import { KitFeelingsToAchieve } from 'pages/questions/FeelingsToAchieve'
import { GoalWeightV3 } from 'pages/questions/GoalWeight'
import { HeightV3, HeightV4 } from 'pages/questions/Height'
import { IdealWeightFeedbackV1 } from 'pages/questions/IdealWeightFeedback'
import { KitIdealWeightTime } from 'pages/questions/IdealWeightTime'
import { ImprovementsV1 } from 'pages/questions/Improvements'
import { KitKetoDietV1 } from 'pages/questions/KitKetoDiet'
import {
  KitReachIdealWeightV1,
  KitReachIdealWeightV2,
} from 'pages/questions/KitReachIdealWeight'
import { KitStatementQuestionV1 } from 'pages/questions/KitStatementQuestion'
import { KitSugarV1 } from 'pages/questions/KitSugar'
import { KitMealsV2 } from 'pages/questions/Meals'
import { KitMotivation1, KitMotivationV2 } from 'pages/questions/Motivation/'
import { NotAloneV1 } from 'pages/questions/NotAlone'
import {
  PerfectSolutionV1,
  PerfectSolutionV2,
  PerfectSolutionV3,
} from 'pages/questions/PerfectSolution'
import { PersonalizeYourPlanV1 } from 'pages/questions/PersonalizeYourPlan'
import { ProfileSummaryV3 } from 'pages/questions/ProfileSummary'
import { ProgramSuiteV1 } from 'pages/questions/ProgramSuite'
import { ReachGoalsV1 } from 'pages/questions/ReachGoals'
import { SleepingTimeFeedbackV1 } from 'pages/questions/SleepingTimeFeedback'
import { StartJourneyV1 } from 'pages/questions/StartJourney'
import { SupportV1 } from 'pages/questions/Support'
import { KitWorkoutV1, WorkoutV2 } from 'pages/questions/Workout'
import { FeelGuiltyV1 } from 'pages/questions/feelGuilty'
import { HitYourWeightLossV1 } from 'pages/questions/hitYourWeightLoss'
import { KitSmartAiV1 } from 'pages/questions/kitSmartAi'
import { KitOtherStruggles } from 'pages/questions/otherStruggles'
import { StayOnTrackV1 } from 'pages/questions/stayOnTrack'
import { Result } from 'pages/result'
import {
  KitSleepingTimeV1,
  SleepingTimeV1,
  SleepingTimeV2,
} from 'pages/sleepingTime'

import { Login } from 'modules/login'
import {
  CancelOfferV1,
  CancelOfferV2,
  KitCancelOfferV1,
} from 'modules/purchaseSubscription/pages/cancelOffer'
import {
  CancelOfferQuestionV1,
  KitCancelOfferQuestionV1,
} from 'modules/purchaseSubscription/pages/cancelOfferQuestion'
import {
  CheckoutVariant1,
  CheckoutVariant2,
  KitCheckoutVariant1,
} from 'modules/purchaseSubscription/pages/checkout'
import {
  KitPurchaseVariant1,
  KitPurchaseVariant2,
  KitPurchaseVariant3,
  PurchaseV1,
  PurchaseV2,
  PurchaseV3,
  PurchaseV4,
  PurchaseV5,
} from 'modules/purchaseSubscription/pages/purchase'
import {
  UpsellPaywallV1,
  UpsellPaywallV2,
  UpsellPaywallV3,
} from 'modules/purchaseSubscription/pages/upsell'

import { TIdToPageMap } from 'models/common.model'

import {
  CANCEL_OFFER_BASE_ID,
  CANCEL_OFFER_QUESTION_BASE_ID,
  CHECKOUT_BASE_ID,
} from 'root-constants'

export const enum CustomPageId {
  ACTIVITY_DURING_WORK = 'activity_during_work',
  AGE = 'age',
  AGE_RANGE = 'age_range',
  CURRENT_WEIGHT = 'current_weight',
  GENDER = 'gender',
  IDEAL_WEIGHT_TIME = 'ideal_weight_time',
  GOAL_WEIGHT = 'goal_weight',
  HEIGHT = 'height',
  PAST_TRIES = 'past_tries',
  PRIMARY_GOAL = 'primary_goal',
  PERIOD = 'period',
  EVENT_DATE = 'event_date',
  CHOOSE_EVENT = 'choose_event',
  MOTIVATION = 'motivation',
  FAVOURITE_FOODS = 'favourite_foods',
  RESTRICTIONS = 'restrictions',
  FASTING = 'fasting',
  TARGET_AREAS = 'target_areas_1',
  KETO_DIET = 'heard_about_keto',
}

export const enum PageId {
  GENDER_1 = 'gender_1',
  GENDER_2 = 'gender_2',
  GENDER_3 = 'gender_3',
  GENDER_4 = 'gender_4',
  GENDER_5 = 'gender_5',
  PRIMARY_GOAL_1 = 'primary_goal_1',
  PRIMARY_GOAL_2 = 'primary_goal_2',
  PRIMARY_GOAL_3 = 'primary_goal_3',
  SECONDARY_GOAL_1 = 'secondary_goal_1',
  BODY_TYPE_1 = 'body_type_1',
  BODY_TYPE_2 = 'body_type_2',
  BODY_TYPE_3 = 'body_type_3',
  KIT_BODY_TYPE_4 = 'kit_body_type_1',
  DREAM_BODY_1 = 'dream_body_1',
  KIT_DREAM_BODY_1 = 'kit_dream_body_1',
  BREAKFAST_TIME_1 = 'breakfast_time_1',
  LUNCH_TIME_1 = 'lunch_time_1',
  DINNER_TIME_1 = 'dinner_time_1',
  MENU_1 = 'menu_1',
  DAILY_MEALS_1 = 'daily_meals_1',
  KIT_HIT_YOUR_WEIGHT_LOSS_1 = 'kit_hit_your_weight_loss_1',
  MEAL_TIMING_1 = 'meal_timing_1',
  START_PLANNING_MEALS_1 = 'start_planning_meals_1',
  FAVOURITE_FOODS_1 = 'favourite_foods_1',
  RESTRICTIONS_1 = 'restrictions_1',
  CUSTOMIZE_MEAL_PLAN_1 = 'customize_meal_plan_1',
  FEEL_HUNGRY_1 = 'feel_hungry_1',
  STOP_HUNGER_1 = 'stop_hunger_1',
  EATING_PLACE_1 = 'eating_place_1',
  FASTING_AT_WEEKEND_1 = 'fasting_at_weekend_1',
  KIT_LIFE_STYLE_1 = 'kit_life_style_1',
  KIT_IDEAL_WEIGHT_TIME_1 = 'kit_ideal_weight_time_1',
  WORKOUT_1 = 'workout_1',
  WORKOUT_2 = 'workout_2',
  WORK_SCHEDULE_1 = 'work_schedule_1',
  ACTIVITY_DURING_WORK_1 = 'activity_during_work_1',
  ACTIVITY_DURING_WORK_2 = 'activity_during_work_2',
  KIT_SLEEPING_TIME_1 = 'kit_sleeping_time_1',
  KIT_FEELINGS_TO_ACHIEVE_1 = 'kit_feelings_to_achieve_1',
  SLEEPING_TIME_1 = 'sleeping_time_1',
  SLEEPING_TIME_2 = 'sleeping_time_2',
  SLEEPING_TIME_FEEDBACK_1 = 'sleeping_time_feedback_1',
  KIT_DAILY_WATER_1 = 'kit_daily_water_1',
  DAILY_WATER_1 = 'daily_water_1',
  DAILY_WATER_2 = 'daily_water_2',
  DAILY_WATER_3 = 'daily_water_3',
  KIT_MOTIVATION_1 = 'kit_motivation_1',
  MOTIVATION_1 = 'motivation_1',
  BAD_HABITS_FEEDBACK_1 = 'bad_habits_feedback_1',
  CHOOSE_EVENT_1 = 'choose_event_1',
  AGE_1 = 'age_1',
  AGE_2 = 'age_2',
  AGE_3 = 'age_3',
  KIT_AGE_1 = 'kit_age_1',
  AGE_RANGE_1 = 'age_range_1',
  AGE_RANGE_2 = 'age_range_2',
  AGE_RANGE_3 = 'age_range_3',
  AGE_RANGE_4 = 'age_range_4',
  HEIGHT_1 = 'height_1',
  HEIGHT_2 = 'height_2',
  HEIGHT_3 = 'height_3',
  CURRENT_WEIGHT_1 = 'current_weight_1',
  CALORIES_IMPACT_1 = 'calories_impact_1',
  CALORIES_EQUALITY_1 = 'calories_equality_1',
  ENERGY_VALUE_1 = 'energy_value_1',
  FEEL_GUILTY_1 = 'feel_guilty_1',
  STAY_ON_TRACK_1 = 'stay_on_track_1',
  CURRENT_WEIGHT_2 = 'current_weight_2',
  CURRENT_WEIGHT_3 = 'current_weight_3',
  KIT_CURRENT_WEIGHT_1 = 'kit_current_weight_1',
  GOAL_WEIGHT_1 = 'goal_weight_1',
  GOAL_WEIGHT_2 = 'goal_weight_2',
  GOAL_WEIGHT_3 = 'goal_weight_3',
  KIT_GOAL_WEIGHT_1 = 'kit_goal_weight_1',
  IDEAL_WEIGHT_TIME_1 = 'ideal_weight_time_1',
  IDEAL_WEIGHT_TIME_2 = 'ideal_weight_time_2',
  IDEAL_WEIGHT_TIME_3 = 'ideal_weight_time_3',
  IDEAL_WEIGHT_FEEDBACK_1 = 'ideal_weight_feedback_1',
  USERS_CHOICE_1 = 'users_choice_1',
  USERS_CHOICE_2 = 'users_choice_2',
  USERS_CHOICE_3 = 'users_choice_3',
  USERS_CHOICE_4 = 'users_choice_4',
  USERS_CHOICE_5 = 'users_choice_5',
  TARGET_AREAS_1 = 'target_areas_1',
  PERFECT_SOLUTION_1 = 'perfect_solution_1',
  PERFECT_SOLUTION_2 = 'perfect_solution_2',
  ACHIEVE_RESULT_1 = 'achieve_result_1',
  ACHIEVE_RESULT_2 = 'achieve_result_2',
  ACHIEVE_RESULT_3 = 'achieve_result_3',
  STICKING_PLAN_1 = 'sticking_plan_1',
  ANALYZING_ANSWERS_1 = 'analyzing_answers_1',
  ANALYZING_ANSWERS_2 = 'analyzing_answers_2',
  PROFILE_SUMMARY_1 = 'profile_summary_1',
  PROFILE_SUMMARY_2 = 'profile_summary_2',
  YOU_ARE_IN_GOOD_HANDS_1 = 'you_are_in_good_hands_1',
  YOU_ARE_IN_GOOD_HANDS_2 = 'you_are_in_good_hands_2',
  YOYO_EFFECT_1 = 'yoyo_effect_1',
  YOYO_EFFECT_2 = 'yoyo_effect_2',
  EVENT_DATE_1 = 'event_date_1',
  EVENT_FEEDBACK_1 = 'event_feedback_1',
  KIT_WEIGHT_LOSS_DATE_1 = 'kit_weight_loss_date_1',
  PRESALE_1 = 'presale_1',
  PRESALE_2 = 'presale_2',
  KIT_PRESALE_1 = 'kit_presale_1',
  HAVE_YOU_HEARD_1 = 'have_you_heard_1',
  BENEFITS_1 = 'benefits_1',
  FEELINGS_TO_ACHIEVE_1 = 'feelings_to_achieve_1',
  CHALLENGES_YOU_FACED_1 = 'challenges_you_faced_1',
  WHAT_ELSE_1 = 'what_else_1',
  YOU_ARE_NOT_ALONE_1 = 'you_are_not_alone_1',
  MEALS_PER_DAY_1 = 'meals_per_day_1',
  TIME_YOU_FEEL_HUNGRY_1 = 'time_you_feel_hungry_1',
  MEALS_PREPARATION_1 = 'meals_preparation_1',
  BETWEEN_MEALS_1 = 'between_meals_1',
  BAD_HABITS_1 = 'bad_habits_1',
  PAST_TRIES_1 = 'past_tries_1',
  DIET_TYPE_1 = 'diet_type_1',
  ACTIVITY_LEVEL_1 = 'activity_level_1',
  WALKING_1 = 'walking_1',
  BREATH_AFTER_CLIMBING_1 = 'breath_after_climbing_1',
  CONTRIBUTING_EVENTS_1 = 'contributing_events_1',
  CONTRIBUTING_EVENTS_2 = 'contributing_events_2',
  WEIGHT_GAIN_TRIGGERS_1 = 'weight_gain_triggers_1',
  WEIGHT_GAIN_RESULT_1 = 'weight_gain_result_1',
  QUESTIONNAIRE_1 = 'questionnaire_1',
  QUESTIONNAIRE_2 = 'questionnaire_2',
  QUESTIONNAIRE_3 = 'questionnaire_3',
  QUESTIONNAIRE_4 = 'questionnaire_4',
  QUESTIONNAIRE_5 = 'questionnaire_5',
  QUESTIONNAIRE_6 = 'questionnaire_6',
  KIT_QUESTIONNAIRE_1 = 'kit_questionnaire_1',
  KIT_QUESTIONNAIRE_2 = 'kit_questionnaire_2',
  KIT_QUESTIONNAIRE_3 = 'kit_questionnaire_3',
  KIT_QUESTIONNAIRE_4 = 'kit_questionnaire_4',
  KIT_QUESTIONNAIRE_5 = 'kit_questionnaire_5',
  KIT_QUESTIONNAIRE_6 = 'kit_questionnaire_6',
  KIT_QUESTIONNAIRE_7 = 'kit_questionnaire_7',
  KIT_QUESTIONNAIRE_8 = 'kit_questionnaire_8',
  KIT_QUESTIONNAIRE_9 = 'kit_questionnaire_9',
  KIT_QUESTIONNAIRE_10 = 'kit_questionnaire_10',
  KIT_DONT_EXERCISE_1 = 'kit_dont_exercise_1',
  KIT_UNIQUELY_TAILORED_PLAN_1 = 'kit_uniquely_tailored_plan_1',
  KIT_EMOTIONAL_EATING_1 = 'kit_emotional_eating_1',
  KIT_EASY_TO_FOLLOW_PLAN_1 = 'kit_easy_to_follow_plan_1',
  KIT_MEALS_1 = 'kit_meals_1',
  KIT_MEALS_2 = 'kit_meals_2',
  HOW_DETERMINED_ARE_YOU_1 = 'how_determined_are_you_1',
  HOW_MANY_SQUATS_1 = 'how_many_squats_1',
  HELP_CALM_CRAVINGS = 'help_calm_cravings',
  PERIOD_1 = 'period_1',
  PLAN_PACE_1 = 'plan_pace_1',
  LOWER_RISK_1 = 'lower_risk_1',
  KIT_CHALLENGES_YOU_FACED_1 = 'kit_challenges_you_faced_1',
  MEN_CHOICE_1 = 'men_choice_1',
  BELLY_TYPE_1 = 'belly_type_1',
  WE_ALL_GAIN_WEIGHT_1 = 'we_all_gain_weight_1',
  FEEDBACKS_1 = 'feedbacks_1',
  REASON_TO_GIVE_UP_1 = 'reason_to_give_up_1',
  PREPARE_PLAN_1 = 'prepare_plan_1',
  PREPARE_PLAN_2 = 'prepare_plan_2',
  CREATING_PLAN_1 = 'creating_plan_1',
  CREATING_PLAN_2 = 'creating_plan_2',
  CREATING_PLAN_3 = 'creating_plan_3',
  ENTER_EMAIL_1 = 'enter_email_1',
  ENTER_EMAIL_2 = 'enter_email_2',
  EMAIL_CONSENT_1 = 'email_consent_1',
  KIT_EMAIL_CONSENT_1 = 'kit_email_consent_1',
  RESULT_1 = 'result_1',
  PURCHASE_1 = 'purchase_1',
  PURCHASE_3 = 'purchase_3',
  PURCHASE_4 = 'purchase_4',
  PURCHASE_5 = 'purchase_5',
  KIT_PURCHASE_1 = 'kit_purchase_1',
  KIT_PURCHASE_2 = 'kit_purchase_2',
  KIT_PURCHASE_3 = 'kit_purchase_3',
  KIT_PURCHASE_EMAIL_1 = 'kit_purchase_email_1',
  KIT_PURCHASE_EMAIL_2 = 'kit_purchase_email_2',
  PURCHASE_PROVIDER = 'purchase_provider',
  CHECKOUT_1 = `${CHECKOUT_BASE_ID}_1`,
  CHECKOUT_2 = `${CHECKOUT_BASE_ID}_2`,
  KIT_CHECKOUT_1 = `kit_${CHECKOUT_BASE_ID}_1`,
  CANCEL_OFFER_QUESTION_1 = `${CANCEL_OFFER_QUESTION_BASE_ID}_1`,
  KIT_CANCEL_OFFER_QUESTION_1 = `kit_${CANCEL_OFFER_QUESTION_BASE_ID}_1`,
  CANCEL_OFFER_1 = `${CANCEL_OFFER_BASE_ID}_1`,
  CANCEL_OFFER_2 = `${CANCEL_OFFER_BASE_ID}_2`,
  KIT_CANCEL_OFFER_1 = `kit_${CANCEL_OFFER_BASE_ID}_1`,
  UPSELL_PAYWALL_1 = 'upsell_paywall_1',
  UPSELL_PAYWALL_2 = 'upsell_paywall_2',
  UPSELL_PAYWALL_3 = 'upsell_paywall_3',
  FINISHING_TOUCHES = 'finishing_touches',
  LOGIN = 'login',
  DOWNLOAD = 'download',
  BURN_CALORIES_1 = 'burn_calories_1',
  PROGRAM_SUITE_1 = 'program_suite_1',
  IMPROVEMENTS_1 = 'improvements_1',
  START_JOURNEY_1 = 'start_journey_1',
  ABOUT_FEELING_GOOD = 'about_feeling_good_1',
  THANK_YOU_FOR_YOUR_TRUST = 'thank_you_for_your_trust_1',
  KIT_FASTING_1 = 'kit_fasting_1',
  FASTING_1 = 'fasting_1',
  FASTING_FEEDBACK_1 = 'fasting_feedback_1',
  EVENT_1 = 'event_1',
  EVENT_FEEDBACK_2 = 'event_feedback_2',
  KIT_AGE_RANGE_1 = 'kit_age_range_1',
  KIT_AGE_RANGE_2 = 'kit_age_range_2',
  KIT_GENDER_1 = 'kit_gender_1',
  KIT_USERS_CHOICE_1 = 'kit_users_choice_1',
  KIT_PRIMARY_GOAL_1 = 'kit_primary_goal_1',
  KIT_SMART_AI_1 = 'kit_smart_ai_1',
  KIT_AI_TECH_1 = 'kit_ai_tech_1',
  KIT_PERFECT_SOLUTION_1 = 'kit_perfect_solution_1',
  KIT_OTHER_STRUGGLES_1 = 'kit_other_struggles_1',
  KIT_REACH_GOALS_1 = 'kit_reach_goals_1',
  KIT_HEIGHT_1 = 'kit_height_1',
  KIT_NOT_ALONE_1 = 'kit_not_alone_1',
  KIT_ACHIEVE_RESULT_1 = 'kit_achieve_result_1',
  KIT_SUPPORT_1 = 'kit_support_1',
  KIT_CREATING_PLAN_1 = 'kit_creating_plan_1',
  KIT_FASTING_FEEDBACK_1 = 'kit_fasting_feedback_1',
  KIT_BAD_HABITS_1 = 'kit_bad_habits_1',
  KIT_FEEDBACKS_1 = 'kit_feedbacks_1',
  KIT_EVENT_DATE_1 = 'kit_event_date_1',
  KIT_EVENT_FEEDBACK_1 = 'kit_event_feedback_1',
  KIT_PROFILE_SUMMARY_1 = 'kit_profile_summary_1',
  KIT_ENTER_EMAIL_1 = 'kit_enter_email_1',
  KIT_EVENT_FEEDBACK = 'kit_event_feedback_1',
  // TODO: remove it after kit-header refactoring
  KIT_DYNAMIC_FEELINGS_TO_ACHIEVE_1 = 'kit_dynamic_options_left_to_right_feelingsToAchieve',
  KIT_DYNAMIC_FASTING_1 = 'kit_dynamic_options_left_to_right_fasting',
  KIT_WEIGHT_LOSS_DATE_2 = 'kit_weight_loss_date_2',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  KIT_MOTIVATION_PREVIEW = 'kit_motivation_preview_1',
  KIT_COMMIT = 'kit_commit_1',
  KIT_REACH_IDEAL_WEIGHT_1 = 'kit_reach_ideal_weight_1',
  KIT_REACH_IDEAL_WEIGHT_2 = 'kit_reach_ideal_weight_2',
  KIT_WORKOUT_1 = 'kit_workout_1',
  KIT_MOTIVATION_2 = 'kit_motivation_2',
  KIT_EVENT_1 = 'kit_event_1',
  KIT_SUGAR_1 = 'kit_sugar_1',
  KIT_KETO_DIET_1 = 'kit_keto_diet_1',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  kit_dynamic_influencer: InfluencerProfile,
  [PageId.START_JOURNEY_1]: StartJourneyV1,
  [PageId.PROGRAM_SUITE_1]: ProgramSuiteV1,
  [PageId.IMPROVEMENTS_1]: ImprovementsV1,
  [PageId.BREAKFAST_TIME_1]: BreakfastTime,
  [PageId.LUNCH_TIME_1]: LunchTime,
  [PageId.DINNER_TIME_1]: DinnerTime,
  [PageId.MENU_1]: MenuV1,
  [PageId.FAVOURITE_FOODS_1]: FavouriteFoodsV1,
  [PageId.RESTRICTIONS_1]: RestrictionsV1,
  [PageId.WORK_SCHEDULE_1]: WorkSchedule,
  [PageId.ABOUT_FEELING_GOOD]: AboutFeelingGood,
  [PageId.KIT_SUPPORT_1]: SupportV1,
  [PageId.ENERGY_VALUE_1]: EnergyValueV1,
  [PageId.FEEL_GUILTY_1]: FeelGuiltyV1,
  [PageId.STAY_ON_TRACK_1]: StayOnTrackV1,
  [PageId.THANK_YOU_FOR_YOUR_TRUST]: ThankYouForYourTrust,
  [PageId.PERIOD_1]: Period,
  period_affect_appetite_1: PeriodAffectAppetite,
  [PageId.HELP_CALM_CRAVINGS]: HelpCalmCravings,
  [PageId.KIT_OTHER_STRUGGLES_1]: KitOtherStruggles,
  [PageId.HAVE_YOU_HEARD_1]: HaveYouHeard,
  [PageId.BENEFITS_1]: Benefits,
  [PageId.WHAT_ELSE_1]: WhatElse,
  [PageId.PAST_TRIES_1]: PastTries,
  [PageId.KIT_LIFE_STYLE_1]: KitLifeStyle,
  [PageId.WALKING_1]: Walking,
  [PageId.BREATH_AFTER_CLIMBING_1]: BreathAfterClimbing,
  [PageId.HOW_MANY_SQUATS_1]: Squats,
  [PageId.HOW_DETERMINED_ARE_YOU_1]: Determined,
  [PageId.LOWER_RISK_1]: LowerRisk,
  [PageId.BELLY_TYPE_1]: BellyType,
  [PageId.REASON_TO_GIVE_UP_1]: ReasonToGiveUp,
  [PageId.KIT_DONT_EXERCISE_1]: DontExerciseV1,
  [PageId.KIT_COMMIT]: KitCommitV1,
  [PageId.RESULT_1]: Result,
  // DYNAMIC
  dynamic_options_left_to_right: OptionsLeftToRight,
  dynamic_options_left_to_right_v2: OptionsLeftToRightV2,
  kit_dynamic_options_left_to_right: OptionsLeftToRightV3,
  kit_dynamic_options_right_to_left: OptionsRightToLeftV3,
  dynamic_options_right_to_left: OptionsRightToLeft,
  dynamic_custom_options_right_to_left: CustomOptionsRightToLeft,
  dynamic_image_center_options_below: ImageCenterOptionsBelow,
  kit_dynamic_image_with_text: ImageWithTextV1,
  kit_dynamic_image_options: ImageOptionsV1,
  // AGE
  [PageId.AGE_1]: Age,
  [PageId.AGE_2]: AgeV2,
  [PageId.AGE_3]: AgeV3,
  [PageId.KIT_AGE_1]: AgeV4,
  // AGE_RANGE
  [PageId.AGE_RANGE_1]: AgeRange,
  [PageId.AGE_RANGE_2]: AgeRangeV2,
  [PageId.AGE_RANGE_3]: AgeRangeV3,
  [PageId.AGE_RANGE_4]: AgeRangeV4,
  [PageId.KIT_AGE_RANGE_1]: KitAgeRangeV1,
  [PageId.KIT_AGE_RANGE_2]: KitAgeRangeV2,
  // GENDER
  [PageId.GENDER_1]: Gender,
  [PageId.GENDER_2]: GenderV2,
  [PageId.GENDER_3]: GenderV3,
  [PageId.GENDER_4]: GenderV4,
  [PageId.GENDER_5]: GenderV4,
  [PageId.KIT_GENDER_1]: GenderV5,
  // GOAL
  [PageId.PRIMARY_GOAL_1]: PrimaryGoal,
  [PageId.PRIMARY_GOAL_2]: PrimaryGoalV2,
  [PageId.PRIMARY_GOAL_3]: PrimaryGoalV3,
  [PageId.KIT_PRIMARY_GOAL_1]: PrimaryGoalV4,
  [PageId.KIT_REACH_GOALS_1]: ReachGoalsV1,
  [PageId.SECONDARY_GOAL_1]: SecondaryGoalV1,
  // BODY_TYPE
  [PageId.BODY_TYPE_1]: BodyTypeV1,
  [PageId.BODY_TYPE_2]: BodyTypeV2,
  [PageId.BODY_TYPE_3]: BodyTypeV3,
  [PageId.KIT_BODY_TYPE_4]: BodyTypeV4,
  // DREAM_BODY
  [PageId.DREAM_BODY_1]: DreamBody,
  [PageId.KIT_DREAM_BODY_1]: DreamBodyV2,
  // HEIGHT
  [PageId.HEIGHT_1]: Height,
  [PageId.HEIGHT_2]: HeightV2,
  [PageId.HEIGHT_3]: HeightV3,
  [PageId.KIT_HEIGHT_1]: HeightV4,
  // GOAL_WEIGHT
  [PageId.GOAL_WEIGHT_1]: GoalWeight,
  [PageId.GOAL_WEIGHT_2]: GoalWeightV2,
  [PageId.GOAL_WEIGHT_3]: GoalWeightV3,
  [PageId.KIT_GOAL_WEIGHT_1]: GoalWeightV4,
  // CURRENT_WEIGHT
  [PageId.CURRENT_WEIGHT_1]: CurrentWeightV1,
  [PageId.CURRENT_WEIGHT_2]: CurrentWeightV2,
  [PageId.CURRENT_WEIGHT_3]: CurrentWeightV3,
  [PageId.KIT_CURRENT_WEIGHT_1]: CurrentWeightV4,
  // IDEAL_WEIGHT
  [PageId.IDEAL_WEIGHT_TIME_1]: IdealWeightTime,
  [PageId.IDEAL_WEIGHT_TIME_2]: IdealWeightTimeV2,
  [PageId.IDEAL_WEIGHT_TIME_3]: IdealWeightTime,
  [PageId.IDEAL_WEIGHT_FEEDBACK_1]: IdealWeightFeedbackV1,
  [PageId.KIT_REACH_IDEAL_WEIGHT_1]: KitReachIdealWeightV1,
  [PageId.KIT_REACH_IDEAL_WEIGHT_2]: KitReachIdealWeightV2,
  [PageId.KIT_IDEAL_WEIGHT_TIME_1]: KitIdealWeightTime,
  // WEIGHT_LOSS
  [PageId.KIT_WEIGHT_LOSS_DATE_1]: WeightLossDateV1,
  [PageId.KIT_WEIGHT_LOSS_DATE_2]: WeightLossDateV2,
  [PageId.KIT_HIT_YOUR_WEIGHT_LOSS_1]: HitYourWeightLossV1,
  // GAIN_WEIGHT
  [PageId.WEIGHT_GAIN_TRIGGERS_1]: WeightGain,
  [PageId.WEIGHT_GAIN_RESULT_1]: WeightGainResult,
  gain_weight_period_1: GainWeightPeriod,
  gain_weight_menopause_1: GainWeightMenopause,
  [PageId.WE_ALL_GAIN_WEIGHT_1]: WeAllGainWeight,
  // HABITS
  [PageId.KIT_BAD_HABITS_1]: KitBadHabits,
  [PageId.BAD_HABITS_FEEDBACK_1]: BadHabitsFeedbackV1,
  [PageId.BAD_HABITS_1]: BadHabits,
  // SLEEPING
  [PageId.KIT_SLEEPING_TIME_1]: KitSleepingTimeV1,
  [PageId.SLEEPING_TIME_1]: SleepingTimeV1,
  [PageId.SLEEPING_TIME_2]: SleepingTimeV2,
  [PageId.SLEEPING_TIME_FEEDBACK_1]: SleepingTimeFeedbackV1,
  // CHALLENGES
  [PageId.KIT_CHALLENGES_YOU_FACED_1]: KitChallengesYouFaced,
  [PageId.CHALLENGES_YOU_FACED_1]: ChallengesYouFaced,
  // WORKOUT
  [PageId.WORKOUT_1]: Workout,
  [PageId.WORKOUT_2]: WorkoutV2,
  [PageId.KIT_WORKOUT_1]: KitWorkoutV1,
  // WATER
  [PageId.KIT_DAILY_WATER_1]: KitDailyWater,
  [PageId.DAILY_WATER_1]: DailyWater,
  [PageId.DAILY_WATER_2]: DailyWaterV2,
  [PageId.DAILY_WATER_3]: DailyWaterV3,
  // QUESTIONNAIRE
  [PageId.QUESTIONNAIRE_1]: StatementQuestion,
  [PageId.QUESTIONNAIRE_2]: StatementQuestion,
  [PageId.QUESTIONNAIRE_3]: StatementQuestion,
  [PageId.QUESTIONNAIRE_4]: StatementQuestion,
  [PageId.QUESTIONNAIRE_5]: StatementQuestion,
  [PageId.QUESTIONNAIRE_6]: StatementQuestion,
  [PageId.KIT_QUESTIONNAIRE_1]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_2]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_3]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_4]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_5]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_6]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_7]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_8]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_9]: KitStatementQuestionV1,
  [PageId.KIT_QUESTIONNAIRE_10]: KitStatementQuestionV1,
  // CHOICE
  [PageId.USERS_CHOICE_1]: UsersChoice,
  [PageId.USERS_CHOICE_2]: UsersChoiceV2,
  [PageId.USERS_CHOICE_3]: UsersChoiceV3,
  [PageId.USERS_CHOICE_4]: UsersChoiceV4,
  [PageId.USERS_CHOICE_5]: UsersChoiceV3,
  [PageId.KIT_USERS_CHOICE_1]: UsersChoiceV5,
  [PageId.MEN_CHOICE_1]: MenChoice,
  // MOTIVATION
  [PageId.KIT_MOTIVATION_1]: KitMotivation1,
  [PageId.MOTIVATION_1]: Motivation,
  [PageId.KIT_MOTIVATION_2]: KitMotivationV2,
  [PageId.KIT_MOTIVATION_PREVIEW]: KitMotivationPreviewV1,
  // EVENT
  [PageId.EVENT_1]: EventV1,
  [PageId.KIT_EVENT_1]: KitEventV1,
  [PageId.EVENT_FEEDBACK_2]: EventFeedbackV2,
  [PageId.CONTRIBUTING_EVENTS_1]: ContributingEvents,
  [PageId.CONTRIBUTING_EVENTS_2]: ContributingEventsV2,
  [PageId.CHOOSE_EVENT_1]: MotivationV2,
  [PageId.EVENT_DATE_1]: EventDate,
  [PageId.KIT_EVENT_DATE_1]: EventDateV2,
  [PageId.EVENT_FEEDBACK_1]: EventFeedback,
  [PageId.KIT_EVENT_FEEDBACK_1]: EventFeedbackV3,
  // ACHIEVE
  [PageId.KIT_FEELINGS_TO_ACHIEVE_1]: KitFeelingsToAchieve,
  [PageId.FEELINGS_TO_ACHIEVE_1]: FeelingsToAchieve,
  [PageId.KIT_ACHIEVE_RESULT_1]: AchieveResultV4,
  [PageId.ACHIEVE_RESULT_1]: AchieveResultV1,
  [PageId.ACHIEVE_RESULT_2]: AchieveResultV2,
  [PageId.ACHIEVE_RESULT_3]: AchieveResultV3,
  // PERFECT
  [PageId.PERFECT_SOLUTION_1]: PerfectSolutionV1,
  [PageId.PERFECT_SOLUTION_2]: PerfectSolutionV2,
  [PageId.KIT_PERFECT_SOLUTION_1]: PerfectSolutionV3,
  // SUMMARY
  [PageId.PROFILE_SUMMARY_1]: ProfileSummaryV1,
  [PageId.PROFILE_SUMMARY_2]: ProfileSummaryV2,
  [PageId.KIT_PROFILE_SUMMARY_1]: ProfileSummaryV3,
  // HANDS
  [PageId.YOU_ARE_IN_GOOD_HANDS_1]: GoodHands,
  [PageId.YOU_ARE_IN_GOOD_HANDS_2]: GoodHandsV2,
  // ALONE
  [PageId.YOU_ARE_NOT_ALONE_1]: YouAreNotAlone,
  [PageId.KIT_NOT_ALONE_1]: NotAloneV1,
  // MEALS
  [PageId.DAILY_MEALS_1]: DailyMealsV1,
  [PageId.MEAL_TIMING_1]: MealTimingV1,
  [PageId.START_PLANNING_MEALS_1]: StartPlanningMealsV1,
  [PageId.KIT_MEALS_1]: KitMealsV1,
  [PageId.KIT_MEALS_2]: KitMealsV2,
  [PageId.MEALS_PREPARATION_1]: MealsPreparation,
  [PageId.BETWEEN_MEALS_1]: BetweenMeals,
  [PageId.MEALS_PER_DAY_1]: MealsPerDay,
  // PLAN
  [PageId.CUSTOMIZE_MEAL_PLAN_1]: CustomizeMealPlanV1,
  [PageId.KIT_UNIQUELY_TAILORED_PLAN_1]: UniquelyTailoredPlanV1,
  [PageId.KIT_EASY_TO_FOLLOW_PLAN_1]: EasyToFollowPlanV1,
  kit_personalize_your_plan_1: PersonalizeYourPlanV1,
  [PageId.KIT_CREATING_PLAN_1]: CreatingPlanV4,
  [PageId.CREATING_PLAN_1]: CreatingPlanV1,
  [PageId.CREATING_PLAN_2]: CreatingPlanV2,
  [PageId.CREATING_PLAN_3]: CreatingPlanV3,
  [PageId.STICKING_PLAN_1]: StickingPlanV1,
  sticking_plan_3: StickingPlanV3,
  [PageId.PLAN_PACE_1]: PlanPace,
  [PageId.PREPARE_PLAN_1]: PreparePlanV1,
  [PageId.PREPARE_PLAN_2]: PreparePlanV2,
  // AI
  [PageId.KIT_SMART_AI_1]: KitSmartAiV1,
  [PageId.KIT_AI_TECH_1]: KitAiTechV1,
  // FASTING
  [PageId.FASTING_AT_WEEKEND_1]: FastingAtWeekend,
  [PageId.KIT_FASTING_1]: KitFasting,
  [PageId.FASTING_1]: FastingV1,
  [PageId.FASTING_FEEDBACK_1]: FastingFeedbackV1,
  [PageId.KIT_FASTING_FEEDBACK_1]: FastingFeedbackV2,
  // CALORIES
  [PageId.BURN_CALORIES_1]: BurnCaloriesV1,
  [PageId.CALORIES_IMPACT_1]: CaloriesImpactV1,
  [PageId.CALORIES_EQUALITY_1]: CaloriesEqualityV1,
  // YOYO
  [PageId.YOYO_EFFECT_1]: YoEffect,
  [PageId.YOYO_EFFECT_2]: YoEffectV2,
  // ANALYZING
  [PageId.ANALYZING_ANSWERS_1]: AnalyzingAnswers,
  [PageId.ANALYZING_ANSWERS_2]: AnalyzingAnswersV2,
  // HUNGRY
  [PageId.FEEL_HUNGRY_1]: FeelHungryV1,
  [PageId.TIME_YOU_FEEL_HUNGRY_1]: TimeYouFeelHungry,
  [PageId.STOP_HUNGER_1]: StopHungerV1,
  // EATING
  [PageId.EATING_PLACE_1]: EatingPlace,
  [PageId.KIT_EMOTIONAL_EATING_1]: EmotionalEatingV1,
  // ACTIVITY
  [PageId.ACTIVITY_DURING_WORK_1]: ActivityDuringWork,
  [PageId.ACTIVITY_DURING_WORK_2]: ActivityDuringWorkV2,
  [PageId.ACTIVITY_LEVEL_1]: ActivityLevel,
  // TARGET_AREAS
  [PageId.TARGET_AREAS_1]: TargetAreas,
  kit_target_areas_1: TargetAreasV2,
  // FEEDBACKS
  [PageId.FEEDBACKS_1]: Feedback,
  [PageId.KIT_FEEDBACKS_1]: FeedbackV2,
  // DIET
  [PageId.KIT_SUGAR_1]: KitSugarV1,
  [PageId.KIT_KETO_DIET_1]: KitKetoDietV1,
  [PageId.DIET_TYPE_1]: DietType,
  // PRESALE
  [PageId.PRESALE_1]: PresaleV1,
  [PageId.PRESALE_2]: PresaleV2,
  [PageId.KIT_PRESALE_1]: PresaleV3,
  // EMAIL
  [PageId.ENTER_EMAIL_1]: Email,
  [PageId.ENTER_EMAIL_2]: EmailV2,
  [PageId.KIT_ENTER_EMAIL_1]: EmailV3,
  [PageId.EMAIL_CONSENT_1]: EmailConsentV1,
  [PageId.KIT_EMAIL_CONSENT_1]: EmailConsentV2,
  // PURCHASE
  [PageId.PURCHASE_1]: PurchaseV1,
  [PageId.PURCHASE_3]: PurchaseV2,
  [PageId.PURCHASE_4]: PurchaseV3,
  [PageId.KIT_PURCHASE_EMAIL_1]: PurchaseV4,
  [PageId.KIT_PURCHASE_EMAIL_2]: PurchaseV5,
  [PageId.KIT_PURCHASE_1]: KitPurchaseVariant1,
  [PageId.KIT_PURCHASE_2]: KitPurchaseVariant2,
  [PageId.KIT_PURCHASE_3]: KitPurchaseVariant3,
  // CHECKOUT
  [PageId.CHECKOUT_1]: CheckoutVariant1,
  [PageId.CHECKOUT_2]: CheckoutVariant2,
  [PageId.KIT_CHECKOUT_1]: KitCheckoutVariant1,
  // CANCEL
  [PageId.CANCEL_OFFER_1]: CancelOfferV1,
  [PageId.CANCEL_OFFER_2]: CancelOfferV2,
  [PageId.KIT_CANCEL_OFFER_1]: KitCancelOfferV1,
  [PageId.CANCEL_OFFER_QUESTION_1]: CancelOfferQuestionV1,
  [PageId.KIT_CANCEL_OFFER_QUESTION_1]: KitCancelOfferQuestionV1,
  // UPSELL
  [PageId.UPSELL_PAYWALL_1]: UpsellPaywallV1,
  [PageId.UPSELL_PAYWALL_2]: UpsellPaywallV2,
  [PageId.UPSELL_PAYWALL_3]: UpsellPaywallV3,
  // FINISH
  [PageId.FINISHING_TOUCHES]: FinishingTouches,
  [PageId.LOGIN]: Login,
  [PageId.DOWNLOAD]: DownloadV1,
}
