import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonTextStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${Color.GREEN_300};
`

export const StyledWeightLossDateV2 = {
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GREY_100};
    margin: 0 0 4px 0;
  `,
  PredictionBlock: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 0 0 20px 0;
  `,

  Text: styled.p`
    ${commonTextStyles};
    color: ${Color.GREY_100};
    margin: 0 6px 0 0;

    span {
      color: ${Color.GREEN_300};
    }
  `,
  Date: styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    height: 32px;
    ${commonTextStyles};
    text-transform: capitalize;
  `,
}
