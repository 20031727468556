import React from 'react'
import { useSelector } from 'react-redux'

import { Comparison } from 'components/Comparison'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import anotherFemale from 'assets/images/compare-another-female.png'
import anotherMale from 'assets/images/compare-another-male.png'
import nutrimateFemale from 'assets/images/compare-nutrimate-female.png'
import nutrimateMale from 'assets/images/compare-nutrimate-male.png'

const CONS_T_KEY = 'nutrimateCompare.another'
const PROS_T_KEY = 'nutrimateCompare.nutrimate'

export const IMAGES_BY_GENDER = {
  male: [anotherMale, nutrimateMale],
  female: [anotherFemale, nutrimateFemale],
}

export const NutrimateComparison = () => {
  const userGender = useSelector(selectCurrentUserGender)
  const images = IMAGES_BY_GENDER[userGender]

  const options = {
    titles: [`${CONS_T_KEY}.title`, `${PROS_T_KEY}.title`],
    images,
    options: [
      [
        `${CONS_T_KEY}.options.first`,
        `${CONS_T_KEY}.options.second`,
        `${CONS_T_KEY}.options.thirty`,
      ],
      [
        `${PROS_T_KEY}.options.first`,
        `${PROS_T_KEY}.options.second`,
        `${PROS_T_KEY}.options.thirty`,
      ],
    ],
  }

  return <Comparison comparisonOptions={options} />
}
