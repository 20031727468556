import { WorkOutFrequencyValue } from 'value-constants'

export const TRANSLATION_FAMILY = 'onboarding.workoutV2'

const T_PREFIX = 'onboarding.kitWorkoutV1.options'
export const ANSWER_OPTIONS_KIT_V1 = [
  {
    value: WorkOutFrequencyValue.EVERY_DAY,
    textPath: `${T_PREFIX}.everyDay`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/biceps.png',
  },
  {
    value: WorkOutFrequencyValue.THREE_FIVE_A_WEEK,
    textPath: `${T_PREFIX}.threeFiveAWeek`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/swimming.png',
  },
  {
    value: WorkOutFrequencyValue.RARELY,
    textPath: `${T_PREFIX}.rarely`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/cycling.png',
  },
  {
    value: WorkOutFrequencyValue.NEVER,
    textPath: `${T_PREFIX}.never`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/positive-person.png',
  },
]
