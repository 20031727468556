import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { createProductId } from 'helpers/createProductId'

import {
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchaseSubscription/constants'
import { logGeneralAddToCartEvents } from 'modules/purchaseSubscription/helpers/logGeneralAddToCartEvents'
import { selectSubscription } from 'modules/purchaseSubscription/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

export const usePrimerAnalytics = (): {
  logPaymentStarted: (paymentMethod: PaymentMethod) => void
  logPaypalPaymentStarted: () => void
  logPaymentFailed: ({
    error,
    paymentMethod,
  }: {
    error: any
    paymentMethod: PaymentMethod
  }) => void
} => {
  const { fullPrice, trialCurrentPrice, currency, periodQuantity, periodName } =
    usePaymentStore()
  const screenName = useSelector(selectScreenName)
  const accountName = useSelector(selectStripeAccountName)
  const accountId = useSelector(selectStripeAccountId)
  const selectedSubscription = useSelector(selectSubscription)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: fullPrice,
      }),
    [periodName, periodQuantity, fullPrice],
  )

  const logPaymentStarted = useCallback(
    (paymentMethod) => {
      eventLogger.logPurchaseStarted({
        screenName,
        productId,
        priceDetails: {
          price: fullPrice,
          trial: !!trialCurrentPrice,
          currency,
        },
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        stripeAccountName: accountName,
        stripeAccountId: accountId,
      })
    },
    [
      screenName,
      productId,
      fullPrice,
      trialCurrentPrice,
      currency,
      accountName,
      accountId,
    ],
  )

  const logPaymentFailed = useCallback(
    ({ error, paymentMethod }) => {
      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: fullPrice,
          trial: !!trialCurrentPrice,
          currency,
        },
        error: {
          type: error.type,
          code: error.code,
          description: error.message,
        },
        paymentMethod,
        screenName,
        paymentSystem: PaymentSystem.PRIMER,
        stripeAccountId: accountId,
        stripeAccountName: accountName,
      })
    },
    [
      productId,
      fullPrice,
      trialCurrentPrice,
      currency,
      screenName,
      accountId,
      accountName,
    ],
  )

  const logPaypalPaymentStarted = useCallback(() => {
    eventLogger.logPurchaseStartedPayPal({
      productId,
      priceDetails: {
        price: fullPrice,
        trial: !!trialCurrentPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      screenName,
      paymentSystem: PaymentSystem.PRIMER,
      accountName,
      accountId,
    })

    logGeneralAddToCartEvents({
      selectedSubscription,
      email,
      uuid,
      isPersonalDataAllowed,
    })
  }, [
    productId,
    fullPrice,
    trialCurrentPrice,
    currency,
    screenName,
    accountName,
    accountId,
    selectedSubscription,
    email,
    uuid,
    isPersonalDataAllowed,
  ])

  return {
    logPaymentStarted,
    logPaymentFailed,
    logPaypalPaymentStarted,
  }
}
