import styled from 'styled-components'

export const StyledScannerIntro = {
  Wrapper: styled.div`
    width: 343px;
    margin: 0 auto;

    & > div {
      width: 343px;
      height: 363px;
    }
  `,
}
