import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { OPTIONS_V3 } from 'pages/bodyType/v3/constants'

import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledBodyTypeV3 as S } from './BodyTypeV3.styles'

const T_PREFIX = 'onboarding.bodyType'

export const BodyTypeV3: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.titleV3`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <Container>
      <PageTitle marginBottom={24}>
        <Trans i18nKey={`${T_PREFIX}.titleV3`} />
      </PageTitle>
      {OPTIONS_V3.map(({ title, icon, value }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <S.AnswerButtonV2 iconSrc={icon}>
            <h3>
              <Trans i18nKey={title} />
            </h3>
          </S.AnswerButtonV2>
        </Option>
      ))}
    </Container>
  )
}
