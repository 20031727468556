import femaleBeforeAfter1 from 'assets/images/photo-result-female-1.png'
import femaleBeforeAfter2 from 'assets/images/photo-result-female-2.png'
import femaleBeforeAfter3 from 'assets/images/photo-result-female-3.png'
import maleBeforeAfter1 from 'assets/images/photo-result-male-1.png'
import maleBeforeAfter2 from 'assets/images/photo-result-male-2.png'
import maleBeforeAfter3 from 'assets/images/photo-result-male-3.png'

import { Gender } from 'root-constants'

export const PHOTO_RESULT_MAP_V2 = {
  [Gender.MALE]: [maleBeforeAfter1, maleBeforeAfter2, maleBeforeAfter3],
  [Gender.FEMALE]: [femaleBeforeAfter1, femaleBeforeAfter2, femaleBeforeAfter3],
}
