import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FlagContainer } from 'components/FlagContainer'
import { PriceValue } from 'components/PriceValue'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { PurchaseProduct } from 'modules/purchaseSubscription/constants'
import { getPriceBeforeDiscount } from 'modules/purchaseSubscription/helpers/getRecalculatedPrice'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { Color, DEFAULT_DYNAMIC_DISCOUNT_AMOUNT } from 'root-constants'

import { useSubscriptionItem } from '../hooks/useSubscriptionItem'
import { StyledKitSubscriptionItemV1 as S } from '../styles/KitSubscriptionItemV1.styles'
import { StyledKitSubscriptionItemV1ComplexCurrency as C } from '../styles/KitSubscriptionItemV1ComplexCurrency.styles'

export const KitSubscriptionItemDynamic: FC<TSubscriptionItemProps> = ({
  subscription,
  isSelected,
}) => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const { id, product, trialPrices, isDefault } = subscription
  const {
    periodName,
    noTrialCurrentPrice,
    trialCurrentPrice,
    isSubscriptionTrial,
    isComplexCurrency,
    pricePerDay,
    isCompactVariant,
    currencyOptions,
  } = useSubscriptionItem(subscription)

  const discount = dynamicDiscount
    ? dynamicDiscount?.amount
    : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT

  const oldPrice =
    product === PurchaseProduct.SEVEN_DAY
      ? trialPrices.oldPrices.beforeCustomDiscount?.fullPrice.toFixed(2)
      : getPriceBeforeDiscount(Number(trialCurrentPrice), discount)

  const oldPricePerDay = getPriceBeforeDiscount(Number(pricePerDay), discount)

  return !isComplexCurrency ? (
    <S.PlanContainer key={id} isSelected={isSelected}>
      <S.PlanColumn>
        <S.PlanPeriod
          isSelected={isSelected}
          isCompactVariant={isCompactVariant}
        >
          {periodName}
        </S.PlanPeriod>
        <S.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <S.PreviousPrice>
                <PriceValue value={oldPrice} formatOptions={currencyOptions} />
              </S.PreviousPrice>
              <S.CurrentPrice>
                <PriceValue
                  value={trialCurrentPrice}
                  formatOptions={currencyOptions}
                />
              </S.CurrentPrice>
              <S.PreviousDayPrice>
                <PriceValue
                  value={oldPricePerDay}
                  formatOptions={currencyOptions}
                />
              </S.PreviousDayPrice>
            </>
          ) : (
            <S.CurrentPrice>
              <PriceValue value={noTrialCurrentPrice} />
            </S.CurrentPrice>
          )}
        </S.PlanPrice>
      </S.PlanColumn>
      <S.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <S.DayPriceContainer
            isSelected={isSelected}
            isCompactVariant={isCompactVariant}
          >
            <S.CurrentDayPrice>
              <PriceValue value={pricePerDay} formatOptions={currencyOptions} />
            </S.CurrentDayPrice>
            <S.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </S.DayPricePeriod>
          </S.DayPriceContainer>
        </FlagContainer>
      </S.PlanColumn>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </S.PopularBadge>
      )}
    </S.PlanContainer>
  ) : (
    <C.PlanContainer key={id} isSelected={isSelected}>
      <C.PlanColumn>
        <C.PlanPeriod
          isSelected={isSelected}
          isCompactVariant={isCompactVariant}
        >
          {periodName}
        </C.PlanPeriod>
        <C.PlanPrice>
          {isSubscriptionTrial ? (
            <>
              <C.PreviousPrice>
                <PriceValue value={oldPrice} formatOptions={currencyOptions} />
              </C.PreviousPrice>
              <C.CurrentPrice>
                <PriceValue
                  value={trialCurrentPrice}
                  formatOptions={currencyOptions}
                />
              </C.CurrentPrice>
            </>
          ) : (
            <C.CurrentPrice>
              <PriceValue
                value={noTrialCurrentPrice}
                formatOptions={currencyOptions}
              />
            </C.CurrentPrice>
          )}
        </C.PlanPrice>
      </C.PlanColumn>
      <C.PlanColumn>
        <FlagContainer
          backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
        >
          <C.DayPriceContainer isSelected={isSelected}>
            {isSubscriptionTrial && (
              <C.PreviousDayPrice isSelected={isSelected}>
                <PriceValue
                  value={oldPricePerDay}
                  formatOptions={currencyOptions}
                />
              </C.PreviousDayPrice>
            )}
            <C.CurrentDayPrice>
              <PriceValue value={pricePerDay} formatOptions={currencyOptions} />
            </C.CurrentDayPrice>
            <C.DayPricePeriod>
              <Trans i18nKey="purchase.subscriptionV2.perDay" />
            </C.DayPricePeriod>
          </C.DayPriceContainer>
        </FlagContainer>
      </C.PlanColumn>
      {isDefault && (
        <C.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </C.PopularBadge>
      )}
    </C.PlanContainer>
  )
}
