import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language, TOTAL_USERS } from 'root-constants'

import { StyledUsersChoiceV5 as S } from './UsersChoiceV5.styles'
import { USERS_CHOICE_IMAGES } from './constants'

export const UsersChoiceV5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.usersChoiceV5.title', {
      lng: Language.EN,
      count: TOTAL_USERS,
    }),
    nextPagePath,
  })

  const localePrefix =
    language === Language.ES ? `-${language}` : `-${Language.EN}`

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.Title>
        <Trans
          i18nKey="onboarding.usersChoiceV5.title"
          values={{ count: TOTAL_USERS }}
        />
      </S.Title>
      <S.Image>
        <img
          src={`${CDN_FOLDER_LINK}${USERS_CHOICE_IMAGES[gender]}${localePrefix}.png`}
          alt="banner"
        />
      </S.Image>
    </KitPageContainer>
  )
}
