import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PriceValue } from 'components/PriceValue'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { NO_TRIAL_PLAN_NAME_MAP } from 'modules/purchaseSubscription/constants'
import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'

import { StyledCheckoutSummaryV2 as S } from '../../styles'

type TProps = {
  className?: string
}

export const CheckoutSummaryV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { currency, fullPrice, periodQuantity, periodName, product } =
    usePaymentStore()

  const hasVatInfo = useVatInfo()

  const periodNameTrans = t(`commonComponents.timeInterval.${periodName}`, {
    count: periodQuantity,
  })

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>{t(NO_TRIAL_PLAN_NAME_MAP[product])}</S.PlanLabel>
        <S.PlanPrice>
          <PriceValue value={fullPrice} />
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isComplex={isComplexCurrencySymbol(currency)}>
        <S.TotalLabel>
          {t('checkout.checkoutSummaryV2.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <Trans
            i18nKey="checkout.checkoutSummaryV2.noTrialTotalPrice"
            values={{
              price: fullPrice,
              periodQuantity,
              periodName: periodNameTrans,
              currency,
            }}
          />
        </S.TotalPrice>
      </S.TotalContainer>
    </div>
  )
}
