import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MotivationValue } from 'value-constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomerReviewsV1 } from 'components/CustomerReviews'
import { Logo } from 'components/Logo'
import { LoseWeightGraphV2 } from 'components/LoseWeightGraphV2'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectCurrentUserMotivation } from 'root-redux/selects/common'

import { useEventWeightInfo } from 'hooks/useEventWeightInfo'
import { useNextStep } from 'hooks/useNextStep'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { TPageProps } from 'models/common.model'

import { StyledContributingEvents as S } from './ContributingEvents.styles'
import { QUESTION } from './constants'

export const ContributingEvents: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const motivation = useSelector(selectCurrentUserMotivation)
  const {
    eventDate,
    goalWeight,
    shortenedGoalDate,
    weightDifference,
    currentWeight,
    isEventWithinGoal,
  } = useEventWeightInfo()
  const { measuringSystemLabel } = useWeightMeasurementSystem()

  const hasMotivationToShow = useMemo(
    () =>
      motivation !== MotivationValue.NO && motivation !== MotivationValue.OTHER,
    [motivation],
  )

  const lostWeightToShow = useMemo(
    () =>
      isEventWithinGoal
        ? weightDifference
        : (Number(currentWeight) - Number(goalWeight)).toFixed(1),
    [currentWeight, goalWeight, isEventWithinGoal, weightDifference],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <>
      <S.ImageContainer>
        <Logo />
        <S.Title>{t('onboarding.contributingEvents.title')}</S.Title>
      </S.ImageContainer>
      <Container>
        <S.DateTitle>
          <Trans
            i18nKey="onboarding.contributingEvents.dateTitle"
            values={{
              date: shortenedGoalDate,
              goal: goalWeight,
              metric: measuringSystemLabel,
            }}
          />
        </S.DateTitle>
        {eventDate && hasMotivationToShow && (
          <S.DateSubTitle>
            <Trans
              i18nKey="onboarding.contributingEvents.dateSubtitle"
              values={{
                weight: lostWeightToShow,
                metric: measuringSystemLabel,
                answer: motivation,
              }}
            />
          </S.DateSubTitle>
        )}
        <LoseWeightGraphV2 />
        <S.FeedbackTitle>
          <Trans
            i18nKey="onboarding.contributingEvents.feedbackTitle"
            values={{ date: shortenedGoalDate }}
          />
        </S.FeedbackTitle>
        <StickyButtonContainer>
          <Button type="button" onClick={handleNextClick}>
            {t('actions.continue')}
          </Button>
        </StickyButtonContainer>
        <CustomerReviewsV1 />
      </Container>
    </>
  )
}
