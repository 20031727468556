import React from 'react'
import { useTranslation } from 'react-i18next'

import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { CALORIES_IMPACT_V1_OPTIONS } from './constants'

const T_PREFIX = 'onboarding.caloriesImpactV1'

export const CaloriesImpactV1: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(`${T_PREFIX}.title`)
  const question = t(`${T_PREFIX}.title`, { lng: Language.EN })

  return (
    <KitRadioOptionsView
      title={title}
      options={CALORIES_IMPACT_V1_OPTIONS}
      question={question}
      isReverse={false}
      {...props}
    />
  )
}
