import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PaymentWaitingModal } from 'widgets/PaymentWaitingModal'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'
import { ThreeDSecureIframe } from 'components/ThreeDSecureIframe'

import { useStripeButton } from 'hooks/useStripeButton'

import { selectIsCheckoutFlowShown } from 'modules/purchaseSubscription/redux/selects'

import { PaymentMethodsV1 } from '../lib/components'
import { useCheckout } from '../lib/useCheckout'
import { StyledKitCheckoutVariant1 as S } from './KitCheckoutVariant1.styles'

export const KitCheckoutVariant1: React.FC = () => {
  const isCheckoutFlowShown = useSelector(selectIsCheckoutFlowShown)
  const {
    isPaymentWaitingShown,
    isSubscriptionTrial,
    isCheckoutReady,
    threeDSecureIframeUrl,
    setIsPaymentWaitingShown,
    handleCloseCheckout,
  } = useCheckout()
  useStripeButton()

  const isLoading = isCheckoutReady && isCheckoutFlowShown

  return (
    <KitPageContainer paddingTop={0}>
      {threeDSecureIframeUrl ? (
        <ThreeDSecureIframe />
      ) : (
        <S.Wrapper>
          <S.Title>
            <Trans i18nKey="kitCheckoutV1.title" />
          </S.Title>
          <S.CloseButton onClick={handleCloseCheckout} />
          {isSubscriptionTrial ? (
            <S.TrialCheckoutSummaryV2 />
          ) : (
            <S.CheckoutSummaryV2 />
          )}
          {isLoading && (
            <>
              <S.PaymentMethodsTitle>
                <Trans i18nKey="kitCheckoutV1.paymentMethodsTitle" />
              </S.PaymentMethodsTitle>
              <PaymentMethodsV1 />
            </>
          )}
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          />
          {!isLoading && <Spinner />}
        </S.Wrapper>
      )}
    </KitPageContainer>
  )
}
