import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import { AnimatedRoller as S } from './AnimatedRoller.styles'

/* eslint react/no-array-index-key: 0 */
/* eslint consistent-return: 0 */

interface IElementsCarouselProps {
  items: number[] | string[]
  duration?: number
  className?: string
}

export const AnimatedRoller: React.FC<IElementsCarouselProps> = ({
  items,
  duration = 1,
  className,
}) => {
  const [itemHeight, setItemHeight] = useState(0)
  const [distance, setDistance] = useState(0)
  const [currentWidth, setCurrentWidth] = useState('auto')

  const parentRef = useRef<HTMLUListElement | null>(null)

  useLayoutEffect(() => {
    if (!items.length) return

    let animationFrameId: number | null = null
    let startTime: number | null = null
    let lastCurrentIndex: number | null = null

    const updateWidth = (timestamp: number) => {
      if (!parentRef.current) return
      if (!startTime) startTime = timestamp

      const elapsedTime = timestamp - startTime
      const progress = Math.min(elapsedTime / (duration * 1000), 1)
      const currentIndex = Math.floor(progress * (items.length - 1))

      if (currentIndex !== lastCurrentIndex) {
        lastCurrentIndex = currentIndex
        const currentItem = parentRef.current.children[currentIndex]

        if (currentItem) {
          const { width } = currentItem.getBoundingClientRect()
          setCurrentWidth(`${width}px`)
        } else {
          console.error(
            `AnimatedRoller: Current item at index ${currentIndex} not found.`,
          )
        }
      }

      if (progress < 1) animationFrameId = requestAnimationFrame(updateWidth)
    }

    animationFrameId = requestAnimationFrame(updateWidth)

    return () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId)
    }
  }, [items, duration])

  useEffect(() => {
    if (!items.length || !parentRef.current) return

    const firstItem = parentRef.current.children[0]
    const { height, width } = firstItem.getBoundingClientRect()

    setItemHeight(height)
    setCurrentWidth(`${width}px`)

    setDistance((items.length - 1) * height)
  }, [items])

  return (
    <S.Wrapper
      $height={itemHeight}
      $width={currentWidth}
      $distance={distance}
      $duration={duration}
      className={className}
    >
      <ul ref={parentRef}>
        {items.map((item, idx) => (
          <S.Item
            key={`${item}-${idx}`}
            translateY={idx * itemHeight}
            isNumber={Number.isInteger(item)}
          >
            {item}
          </S.Item>
        ))}
      </ul>
    </S.Wrapper>
  )
}
