import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrentAge,
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { roundToTenth } from 'helpers/numberHelper'

import { selectSubscription } from 'modules/purchaseSubscription/redux/selects'

import { CDN_FOLDER_LINK, UpsellProduct } from 'root-constants'

import { StyledUpsellGuides as S } from './UpsellGuides.styles'

type TProps = {
  className?: string
}

const IMAGE_PATH_DREAM_BODY = '/images/dream-body'
const IMAGE_PATH_SELF_CARE = '/images/self-care'

export const UpsellGuides: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const age = +useSelector(selectCurrentAge)
  const gender = useSelector(selectCurrentUserGender)
  const subscription = useSelector(selectSubscription)
  const language = useSelector(selectLanguage)

  const isBoth = useMemo(
    () => subscription?.product === UpsellProduct.BOTH,
    [subscription],
  )

  const isFitness = useMemo(
    () => subscription?.product === UpsellProduct.FITNESS,
    [subscription],
  )

  const isSelfCare = useMemo(
    () => subscription?.product === UpsellProduct.SELF_CARE,
    [subscription],
  )

  const dreamBodySrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH_DREAM_BODY}-${language}.png`,
    [language],
  )

  const selfCareSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH_SELF_CARE}-${language}.png`,
    [language],
  )

  return (
    <S.Container className={className}>
      <S.Title>{t('upsell.guides.title')}</S.Title>
      {(isBoth || isFitness) && (
        <S.GuideBlock>
          <S.ImageContainer>
            <img src={dreamBodySrc} alt="dreamBody" />
          </S.ImageContainer>
          <S.GuideInfo>
            <S.GuideTitle>{t('upsell.guides.titleDreamBody')}</S.GuideTitle>
            <S.GuideDescription>
              {t('upsell.guides.dreamBodyDescription', {
                agePeriod: roundToTenth(age),
              })}
            </S.GuideDescription>
          </S.GuideInfo>
        </S.GuideBlock>
      )}
      {(isBoth || isSelfCare) && (
        <S.GuideBlock>
          <S.ImageContainer>
            <img src={selfCareSrc} alt="" />
          </S.ImageContainer>
          <S.GuideInfo>
            <S.GuideTitle>{t('upsell.guides.titleSelfCare')}</S.GuideTitle>
            <S.GuideDescription>
              {t('upsell.guides.selfCareDescription', { context: gender })}
            </S.GuideDescription>
          </S.GuideInfo>
        </S.GuideBlock>
      )}
    </S.Container>
  )
}
