import React from 'react'
import { useTranslation } from 'react-i18next'

import { ANSWER_OPTIONS_KIT_V1 } from 'pages/questions/Event/constants'
import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

const T_PREFIX = 'onboarding.kitEventV1'

export const KitEventV1: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(`${T_PREFIX}.title`)
  const subtitle = t(`${T_PREFIX}.subtitle`)
  const question = t(title, { lng: Language.EN })

  return (
    <KitRadioOptionsView
      {...props}
      pageId={CustomPageId.CHOOSE_EVENT}
      title={title}
      subtitle={subtitle}
      question={question}
      options={ANSWER_OPTIONS_KIT_V1}
    />
  )
}
