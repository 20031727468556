import { BadHabitsValue } from 'value-constants'

export const ANSWER_OPTIONS_MAP = [
  {
    value: BadHabitsValue.ENOUGH_REST,
    title: 'onboarding.badHabits.options.enoughRest',
  },
  {
    value: BadHabitsValue.CHOCOLATE_AND_SWEETS,
    title: 'onboarding.badHabits.options.chocolateAndSweets',
  },
  {
    value: BadHabitsValue.SODAS,
    title: 'onboarding.badHabits.options.sodas',
  },
  {
    value: BadHabitsValue.SALTY_FOODS,
    title: 'onboarding.badHabits.options.saltyFoods',
  },
  {
    value: BadHabitsValue.SNACK,
    title: 'onboarding.badHabits.options.snack',
  },
  {
    value: BadHabitsValue.JUNK,
    title: 'onboarding.badHabits.options.junk',
  },
  {
    value: BadHabitsValue.WHENEVER,
    title: 'onboarding.badHabits.options.whenever',
  },
  {
    value: BadHabitsValue.OVEREAT,
    title: 'onboarding.badHabits.options.overeat',
  },
  {
    value: BadHabitsValue.SAY_NO_TO_DRINK,
    title: 'onboarding.badHabits.options.sayNoToDrink',
  },
]

const T_PREFIX = 'onboarding.kitBadHabits.options'
export const KIT_BAD_HABITS_OPTIONS = [
  {
    textPath: `${T_PREFIX}.likeWine`,
    value: 'like_vine',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/wineglass.png',
  },
  {
    textPath: `${T_PREFIX}.eatLate`,
    value: 'eat_late',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/moon.png',
  },
  {
    textPath: `${T_PREFIX}.raging`,
    value: 'raging_sweet_tooth',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/cake.png',
  },
  {
    textPath: `${T_PREFIX}.snack`,
    value: 'snack_attacks',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/cookie.png',
  },
  {
    textPath: `${T_PREFIX}.soda`,
    value: 'soda',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/soda.png',
  },
  {
    textPath: `${T_PREFIX}.crunchy`,
    value: 'salty_and_crunchy',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/salt.png',
  },
  {
    textPath: `${T_PREFIX}.mealtime`,
    value: 'mealtime',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/laptop.png',
  },
]
