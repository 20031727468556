import React from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import { usePrimer } from 'hooks/usePrimer'

import { PRIMER_PAY_PAL_ID } from 'modules/purchaseSubscription/constants'
import { selectIsPrimerPayPalButtonLoading } from 'modules/purchaseSubscription/redux/selects'

import svgSrc from 'assets/images/sprite/spinner-grey.svg'

import { StyledPrimerPaymentForm as S } from './PrimerPayPalButton.styles'

type TProps = {
  className?: string
}

export const PrimerPayPalButton: React.FC<TProps> = ({ className }) => {
  const isButtonVisible = useSelector(selectIsPrimerPayPalButtonLoading)

  usePrimer()

  return (
    <>
      <S.PayPalButton
        isHidden={!isButtonVisible}
        id={PRIMER_PAY_PAL_ID}
        className={className}
      />
      {!isButtonVisible && <Spinner svgSrc={svgSrc} isFullScreen={false} />}
    </>
  )
}
