import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserMeasurementSystem } from 'root-redux/selects/common'

import beforAfterFemale from 'assets/images/upsell-v2-before-after-female.png'

import {
  ActionItem,
  StyledUpsellIntroMedia as S,
} from './UpsellIntroMedia.styles'
import {
  ACTION_ITEMS_LOSE_WEIGHT,
  ACTION_ITEMS_MAP,
  BEFORE_AFTER_LOSE_WEIGHT,
} from './constants'

type TProps = {
  titleI18nKey?: string
}

export const UpsellIntroMedia: FC<TProps> = ({ titleI18nKey }) => {
  const { t } = useTranslation()

  const userMeasurementSystem = useSelector(selectUserMeasurementSystem)

  const beforeAfterLoseWeight = useMemo(() => {
    const { value, measurementSystem } =
      BEFORE_AFTER_LOSE_WEIGHT[userMeasurementSystem]

    return `${value} ${t(measurementSystem)}`
  }, [userMeasurementSystem, t])

  const actionItemsLoseWeight = useMemo(() => {
    const { value, measurementSystem } =
      ACTION_ITEMS_LOSE_WEIGHT[userMeasurementSystem]

    return `${value} ${t(measurementSystem)}`
  }, [userMeasurementSystem, t])

  return (
    <S.Wrapper>
      <S.PageTitle>
        <Trans i18nKey={titleI18nKey || 'upsellV2.title'} />
      </S.PageTitle>
      <S.Subtitle>
        <Trans i18nKey="upsellV2.subtitle" />
      </S.Subtitle>

      <S.BeforeAfter>
        <S.BeforeAfterImg>
          <img src={beforAfterFemale} alt="before-after" />
        </S.BeforeAfterImg>

        <S.BeforeAfterInfo>
          <Trans
            i18nKey="upsellV2.beforeAfter"
            values={{
              loseWeight: beforeAfterLoseWeight,
            }}
          />
        </S.BeforeAfterInfo>
      </S.BeforeAfter>

      <S.ActionItems>
        <S.ActionItemsInfo>
          <Trans
            i18nKey="upsellV2.actionItems.info"
            values={{
              loseWeight: actionItemsLoseWeight,
            }}
          />
        </S.ActionItemsInfo>

        <S.ActionItemsList>
          {ACTION_ITEMS_MAP.map(({ imgSrc, title, info }) => (
            <ActionItem key={title}>
              <S.ActionItemImg>
                <img src={imgSrc} alt="action-item" />
              </S.ActionItemImg>
              <S.ActionItemInfo>
                <S.ActionItemTitle>{t(title)}</S.ActionItemTitle>
                <S.ActionItemContent>{t(info)}</S.ActionItemContent>
              </S.ActionItemInfo>
            </ActionItem>
          ))}
        </S.ActionItemsList>
      </S.ActionItems>
    </S.Wrapper>
  )
}
