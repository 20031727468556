import { PaymentMethod } from 'modules/purchaseSubscription/constants'

import apple from 'assets/images/apple-pay-icon.png'
import google from 'assets/images/google-pay.png'
import paypal from 'assets/images/pay-pal-icon.png'
import tabApplePayIconActive from 'assets/images/tab-applepay-icon-active.png'
import tabApplePayIconInActive from 'assets/images/tab-applepay-icon-inactive.png'
import tabCreditCardIconActive from 'assets/images/tab-creditcard-icon-active.png'
import tabCreditCardIconInActive from 'assets/images/tab-creditcard-icon-inactive.png'
import tabGooglePayIconActive from 'assets/images/tab-googlepay-icon-active.png'
import tabGooglePayIconInactive from 'assets/images/tab-googlepay-icon-inactive.png'
import tabPayPalIconActive from 'assets/images/tab-paypal-icon-active.png'
import tabPayPalIconInActive from 'assets/images/tab-paypal-icon-inactive.png'

export const PaymentMethodStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const

export const PAYMENT_METHODS_TAB_ICONS = {
  [PaymentMethod.PAYPAL]: {
    [PaymentMethodStatus.ACTIVE]: tabPayPalIconActive,
    [PaymentMethodStatus.INACTIVE]: tabPayPalIconInActive,
  },
  [PaymentMethod.CREDIT_CARD]: {
    [PaymentMethodStatus.ACTIVE]: tabCreditCardIconActive,
    [PaymentMethodStatus.INACTIVE]: tabCreditCardIconInActive,
  },
  [PaymentMethod.APPLE_PAY]: {
    [PaymentMethodStatus.ACTIVE]: tabApplePayIconActive,
    [PaymentMethodStatus.INACTIVE]: tabApplePayIconInActive,
  },
  [PaymentMethod.GOOGLE_PAY]: {
    [PaymentMethodStatus.ACTIVE]: tabGooglePayIconActive,
    [PaymentMethodStatus.INACTIVE]: tabGooglePayIconInactive,
  },
}

export const CHECKOUT_ICON_MAP = {
  [PaymentMethod.GOOGLE_PAY]: google,
  [PaymentMethod.APPLE_PAY]: apple,
  [PaymentMethod.PAYPAL]: paypal,
}
