import styled, { keyframes } from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color, MediaBreakpoint } from 'root-constants'

const animation = keyframes`
    0% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const StyledCreatingPlanV4 = {
  PageTitle: styled(PageTitle)`
    color: ${Color.GREY_101};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  `,
  Arrows: styled.div`
    aspect-ratio: 1 / 1;
    display: flex;
    margin: 0 auto 16px;
    width: 12px;
  `,
  ChangeableContainer: styled.div`
    aspect-ratio: 343 / 376;
    margin-bottom: 24px;
    position: relative;
    width: 100%;

    @media (max-height: ${MediaBreakpoint.MAX_PHONE_HEIGHT}px) {
      margin-bottom: 0;
    }
  `,
  ChangeableContent: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;

    &[data-is-shown='true'] {
      animation: ${animation} 3000ms ease-in 1;
    }
  `,
  Subtitle: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
    min-height: 128px;
    text-align: center;

    @media (max-height: ${MediaBreakpoint.MAX_PHONE_HEIGHT}px) {
      margin-bottom: 0;
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 343 / 248;
    width: 100%;
  `,
  ProgressbarContainer: styled.div`
    position: relative;
  `,
}
