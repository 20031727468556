import { SugarFrequencyValue } from 'value-constants'

const T_PREFIX = 'onboarding.kitSugarV1.options'

export const ANSWER_OPTIONS_KIT_V1 = [
  {
    value: SugarFrequencyValue.EVERY_DAY,
    textPath: `${T_PREFIX}.everyDay`,
  },
  {
    value: SugarFrequencyValue.PER_WEEK,
    textPath: `${T_PREFIX}.perWeek`,
  },
  {
    value: SugarFrequencyValue.PER_MONTH,
    textPath: `${T_PREFIX}.perMonth`,
  },
]
