import React from 'react'
import { Trans } from 'react-i18next'

import { StyledCreditCardHeader as S } from './CreditCardHeader.styles'

type TProps = {
  className?: string
}

export const CreditCardHeader: React.FC<TProps> = ({ className }) => (
  <S.TitleContainer className={className}>
    <S.PaymentInfo>
      <Trans i18nKey="checkout.paymentInfo" />
    </S.PaymentInfo>
    <S.Label>
      <Trans i18nKey="checkout.protectedLabel" />
    </S.Label>
  </S.TitleContainer>
)
