import React, { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectCurrentUserGender } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchaseSubscription/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import femaleImg from 'assets/images/cancelOffer/img_female.png'
import maleImg from 'assets/images/cancelOffer/img_male.png'

import { goTo } from 'browser-history'
import { Gender, SubscriptionListType, SubscriptionTags } from 'root-constants'

import { StyledKitCancelOfferQuestionV1 as S } from './KitCancelOfferQuestionV1.styles'

const useSelectors = () =>
  useBulkSelector({
    gender: selectCurrentUserGender,
    discountAmount: selectSubscriptionPaywallDiscountPercent,
  })

export const KitCancelOfferQuestionV1: FC<TPageProps> = ({ nextPagePath }) => {
  const actionsRef = useRef<HTMLDivElement>(null)

  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const { gender, discountAmount } = useSelectors()
  const hasVatInfo = useVatInfo()

  const imageSrc = gender === Gender.FEMALE ? femaleImg : maleImg

  const handleNextPage = () => {
    eventLogger.logCancelOfferPageClosed()
    goTo(nextPagePath)
  }

  useLayoutEffect(() => {
    const tags = `${SubscriptionTags.CANCEL_OFFER},${
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX
    }`

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, tags))
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  useEffect(() => {
    actionsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [actionsRef.current])

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={handleNextPage}
      continueButtonContent={t('actions.gotIt')}
    >
      <PageTitle marginBottom={16}>
        <Trans i18nKey="kitCancelOfferQuestionV1.title" />
      </PageTitle>

      <S.Text>
        <Trans
          i18nKey="kitCancelOfferQuestionV1.subtitle"
          values={{ usersCount: 74 }}
          components={{ span: <span /> }}
        />
      </S.Text>
      <S.ImageWrapper
        data-period-before={t(`commonComponents.months.march`)}
        data-period-after={t(`commonComponents.months.april`)}
      >
        <S.Image src={imageSrc} alt="result" width="100%" height="auto" />
      </S.ImageWrapper>
      <S.Note>
        <Trans i18nKey="kitCancelOfferQuestionV1.note" />
      </S.Note>
      <S.Offer>
        <Trans
          i18nKey="kitCancelOfferQuestionV1.text"
          values={{ discount: discountAmount }}
          components={{ span: <span /> }}
        />
      </S.Offer>
    </KitPageContainer>
  )
}
