import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import { selectIsInContextEnabled } from 'root-redux/selects/common'

import {
  Color,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
} from 'root-constants'

import { StyledLanguageSelector as S } from './LanguageSelector.styles'

type TProps = {
  defaultValue: string
}

export const LanguageSelector = ({ defaultValue }: TProps) => {
  const dispatch = useDispatch()
  const IsInContextEnabled = useSelector(selectIsInContextEnabled)
  const locales = IsInContextEnabled
    ? [...SUPPORTED_LOCALES, Language.IN_CONTEXT]
    : SUPPORTED_LOCALES

  const options = locales.map((lang) => ({
    value: lang,
    label: lang.toUpperCase(),
  }))

  const initValue = defaultValue
    ? options.find((option) => option.value === defaultValue)
    : options[0]

  const onChange = (option) => {
    const url = new URL(window.location.href)
    url.searchParams.set(LANG_QUERY_PARAM, option.value)
    window.history.pushState({}, '', url)
    dispatch(setLanguageAction(option.value))
  }

  return (
    <S.Wrapper>
      <S.Select
        defaultValue={initValue}
        name="language"
        options={options}
        onChange={onChange}
        classNamePrefix="language-select"
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: Color.GREEN_400,
            primary25: Color.GREEN_600,
          },
        })}
      />
    </S.Wrapper>
  )
}
