import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Logo } from 'components/Logo'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import men1 from 'assets/images/men-1.jpg'
import men2 from 'assets/images/men-2.jpg'
import men3 from 'assets/images/men-3.jpg'
import men4 from 'assets/images/men-4.jpg'
import arrowsSvg from 'assets/images/sprite/arrows-down.svg'

import { Language } from 'root-constants'

import { StyledCreatingPlanV3 as S } from './CreatingPlanV3.styles'

const T_PREFIX = 'onboarding.creatingPlanV3'

export const CreatingPlanV3: React.FC<TPageProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { currentSubscriptionPageId } = usePageInfo()

  const [isFirstChangeableContentShown, setIsFirstChangeableContentShown] =
    useState(true)
  const [isSecondChangeableContentShown, setIsSecondChangeableContentShown] =
    useState(false)
  const [isThirdChangeableContentShown, setIsThirdChangeableContentShown] =
    useState(false)
  const [isFourthChangeableContentShown, setIsFourthChangeableContentShown] =
    useState(false)

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath: `${currentSubscriptionPageId}${search}`,
  })

  const { counter, linearCounterValue } = useCounter(true, {
    duration: 12500,
    callback: handleContinue,
  })

  useEffect(() => {
    switch (linearCounterValue) {
      case 0:
        return setIsFirstChangeableContentShown(true)
      case 25:
        return setIsSecondChangeableContentShown(true)
      case 50:
        return setIsThirdChangeableContentShown(true)
      case 75:
        return setIsFourthChangeableContentShown(true)
      default:
        return setIsFirstChangeableContentShown(true)
    }
  }, [linearCounterValue])

  return (
    <S.Wrapper>
      <S.Container>
        <Logo marginBottom={38} />
        <S.Title>
          <Trans i18nKey={`${T_PREFIX}.title`} />
        </S.Title>
        <S.Arrows svg={arrowsSvg} />
        <S.ChangeableContent data-is-shown={isFirstChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleFirst`} />
          </S.Subtitle>
          <S.ImageContainer>
            <img src={men1} alt="men" />
          </S.ImageContainer>
        </S.ChangeableContent>
        <S.ChangeableContent data-is-shown={isSecondChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleSecond`} />
          </S.Subtitle>
          <S.ImageContainer>
            <img src={men2} alt="men" />
          </S.ImageContainer>
        </S.ChangeableContent>
        <S.ChangeableContent data-is-shown={isThirdChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleThird`} />
          </S.Subtitle>
          <S.ImageContainer>
            <img src={men3} alt="men" />
          </S.ImageContainer>
        </S.ChangeableContent>
        <S.ChangeableContent data-is-shown={isFourthChangeableContentShown}>
          <S.Subtitle>
            <Trans i18nKey={`${T_PREFIX}.subtitleFourth`} />
          </S.Subtitle>
          <S.ImageContainer>
            <img src={men4} alt="men" />
          </S.ImageContainer>
        </S.ChangeableContent>
        <S.ProgressbarContainer>
          <ProgressAnalyzer value={counter}>
            <Trans i18nKey={`${T_PREFIX}.loaderText`} />
          </ProgressAnalyzer>
        </S.ProgressbarContainer>
      </S.Container>
    </S.Wrapper>
  )
}
