import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check-white.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  isSelected: boolean
}

const commonPriceStyles = css`
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: ${Color.DISABLED};
`

export const StyledSubscriptionItemV1 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 0;
    background: ${({ isSelected }) => (isSelected ? '#f1fff8' : Color.WHITE)};
    border: ${({ isSelected }) =>
      `2px solid ${isSelected ? Color.PRIMARY : 'rgba(19, 29, 48, 0.1)'}`};
    border-radius: 20px;
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 2px solid rgba(19, 29, 48, 0.1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      transition: background-color 0.3s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #8cd9ae;
          border: 2px solid ${Color.PRIMARY};
        `};
    }
  `,
  MostPopularBadge: styled.div`
    position: absolute;
    top: -15px;
    left: 45px;
    transform: translateY(-50%);
    padding: 4px 16px;
    border-radius: 24px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    background-color: ${Color.ORANGE};
    color: ${Color.WHITE};
  `,
  PlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 36px;
  `,
  PlanPeriod: styled.span<TProps>`
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    color: ${({ isSelected }) =>
      isSelected ? Color.GREY_100 : Color.DISABLED};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 16px;
    }
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
  PreviousPrice: styled.span`
    ${commonPriceStyles};
    margin-right: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 115%;
      height: 1px;
      background-color: ${Color.ORANGE};
    }
  `,
  CurrentPrice: styled.span`
    ${commonPriceStyles};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 87px;
    min-height: 48px;
    margin-right: 10px;
    color: rgba(19, 29, 48, 0.5);
    transition: background 0.3s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});
    background-size: 100%;

    ${({ isSelected }) =>
      isSelected &&
      `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
    `};
  `,
  PriceValue: styled.span`
    margin: 10px 5px 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  `,
  Period: styled.span`
    margin-right: 5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
  `,
}
