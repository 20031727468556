import React, { useEffect, useLayoutEffect } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Option, OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { PriceValue } from 'components/PriceValue'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { setSelectedSubscriptionAction } from 'modules/purchaseSubscription/redux/actions/common'
import {
  MAKE_UPSELL,
  createInAppAction,
} from 'modules/purchaseSubscription/redux/actions/upsell'
import { selectSubscriptionId } from 'modules/purchaseSubscription/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import arrowSrc from 'assets/images/sprite/right-arrow.svg'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  UpsellProduct,
} from 'root-constants'

import {
  UPSELL_BENEFITS,
  UPSELL_EVENT_PROPERTY_MAP,
  UPSELL_IMAGES_MAP,
} from '../lib/constants'
import { StyledUpsellPaywallV3 as S } from './UpsellPaywallV3.styles'

export const UpsellPaywallV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()

  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { currentPageId, activeUpsellPageIds } = usePageInfo()

  const isUpsellInProgress = fetchingActionsList?.includes(MAKE_UPSELL)
  const arePricesReady = !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST)

  useLayoutEffect(() => {
    const tags = [
      SubscriptionTags.NO_TAX,
      SubscriptionTags.INAPP_CHANGES_DESSERTS,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.ONE_TIME, tags))
  }, [dispatch])

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_LIST)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setSubscriptionListAction([]))
    }
  }, [dispatch])

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_LIST)

    const hasOtherUpsellPages = activeUpsellPageIds.some(
      (id) => id !== currentPageId,
    )
    if (!hasOtherUpsellPages) {
      goTo({ pathname: PageId.FINISHING_TOUCHES, search })
      return
    }

    goTo(nextPagePath || `${PageId.FINISHING_TOUCHES}${search}`)
  }

  const handleAdd = async () => {
    dispatch(
      sendUserConfigAction({
        available_upsell_pages: activeUpsellPageIds.filter(
          (page) => page !== currentPageId,
        ),
      }),
    )
    await dispatch(createInAppAction(ScreenName.UPSELL_LIST))
    replaceHistory(nextPagePath || `${PageId.FINISHING_TOUCHES}${search}`)
    dispatch(getUserStatusAction(uuid))
  }

  const handleChange = (productId: string) => {
    const selectedSubscription = subscriptions.find(
      ({ product }) => product === productId,
    )

    if (selectedSubscription) {
      eventLogger.logUpsellSubscriptionSelected(
        UPSELL_EVENT_PROPERTY_MAP[selectedSubscription.product],
      )
      dispatch(setSelectedSubscriptionAction(selectedSubscription))
    }
  }

  return (
    <>
      {arePricesReady && (
        <KitPageContainer
          paddingTop={0}
          hasContinueButton
          hasSkipButton
          continueButtonContent={<Trans i18nKey="upsell.addToMyProgram" />}
          skipButtonContent={<Trans i18nKey="upsell.noThanksLink" />}
          onContinueButtonClick={handleAdd}
          onSkipButtonClick={handleSkip}
        >
          <PageTitle marginBottom={16}>
            <Trans i18nKey="upsellV3.title" />
          </PageTitle>
          <S.Subtitle>
            <Trans i18nKey="upsellV3.subtitle" />
          </S.Subtitle>
          <S.UpsellList>
            {subscriptions.map(({ product, trialPrices, mainPrices, id }) => (
              <Option
                type={OptionType.RADIO}
                name="upsell"
                value={product}
                key={id}
                onChange={handleChange}
              >
                <S.UpsellItem isActive={id === selectedSubscriptionId}>
                  <S.ImageContainer>
                    <img src={UPSELL_IMAGES_MAP[product]?.img} alt="upsell" />
                  </S.ImageContainer>
                  <S.DataContainer>
                    <S.Info isActive={id === selectedSubscriptionId}>
                      <S.Title>
                        <Trans i18nKey={UPSELL_IMAGES_MAP[product]?.title} />
                      </S.Title>
                      <S.PriceContainer>
                        <S.Price>
                          <S.PrevPrice>
                            <PriceValue value={mainPrices.fullPrice} />
                          </S.PrevPrice>
                          <SvgImage svg={arrowSrc} height={12} width={12} />
                          <S.CurPrice>
                            <PriceValue value={trialPrices.fullPrice} />
                            {product === UpsellProduct.CHANGES_SUGAR && (
                              <>
                                {' '}
                                <Trans i18nKey="upsellV3.subscriptionsBlock.only" />
                              </>
                            )}
                          </S.CurPrice>
                        </S.Price>
                        <S.Discount>
                          <Trans
                            i18nKey="upsellV3.subscriptionsBlock.discountLabel"
                            values={{
                              discount: UPSELL_IMAGES_MAP[product]?.discount,
                            }}
                          />
                        </S.Discount>
                      </S.PriceContainer>
                    </S.Info>
                    {id === selectedSubscriptionId &&
                      UPSELL_IMAGES_MAP[product]?.info && (
                        <S.AdditionalContainer>
                          <S.AdditionalInfo>
                            <Trans i18nKey={UPSELL_IMAGES_MAP[product]?.info} />
                          </S.AdditionalInfo>
                        </S.AdditionalContainer>
                      )}
                  </S.DataContainer>
                </S.UpsellItem>
              </Option>
            ))}
          </S.UpsellList>
          <ul>
            {UPSELL_BENEFITS.map((item) => (
              <S.BenefitItem key={item}>
                <Trans i18nKey={item} />
              </S.BenefitItem>
            ))}
          </ul>
        </KitPageContainer>
      )}
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </>
  )
}
