import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUserInfoBlockV4 = {
  Container: styled.div`
    width: 100%;
    display: flex;
    border-radius: 16px;
    border: 1px solid ${Color.GREY_800};
    background-color: ${Color.WHITE};
    padding: 16px;
    position: relative;
  `,
  InfoList: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 183px;

    > :not(:last-child) {
      margin: 0 0 16px 0;
    }
  `,

  InfoItem: styled.li`
    display: flex;
    gap: 18px;
  `,

  InfoItemIcon: styled.img`
    width: 36px;
    height: 36px;
  `,

  InfoItemText: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,

  Label: styled.h3`
    display: inline-block;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.GREY_101};

    &:first-letter {
      text-transform: uppercase;
    }
  `,
  InfoValue: styled.h3`
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREY_100};
    word-break: break-word;

    &:first-letter {
      text-transform: uppercase;
    }

    span {
      display: inline-block;

      &:first-letter {
        text-transform: uppercase;
      }

      &:after {
        content: ',\\00a0';
      }

      &:last-child:after {
        content: '';
      }
    }
  `,
  Banner: styled.img`
    height: 188px;
    position: absolute;
    bottom: 0;
    right: 0;
  `,
}
