import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomerReviewsV3 = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
  `,
  WrapperReviews: styled.div`
    padding-bottom: 32px;
  `,
  ReviewContainer: styled.div`
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    padding: 16px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  ReviewTitle: styled.span`
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    text-align: left;
  `,
  ReviewHeader: styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
  `,
  Date: styled.span`
    color: ${Color.GREY_101};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: right;
  `,
  Rating: styled.div`
    aspect-ratio: 49 / 9;
    display: flex;
    margin-bottom: 8px;
    max-width: 98px;
    width: 98px;
  `,
  Author: styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: ${Color.GREY_101};
  `,
  Text: styled.p`
    color: ${Color.GREY_101};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
  Button: styled.button`
    background-color: transparent;
    border: none;
    color: ${Color.GREEN_300};
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 4px;
    outline: none;
    padding: 0;
  `,
  CustomDate: styled.span`
    color: ${Color.DISABLED};
    text-align: right;
    font-weight: 400;
    font-size: 14px;
  `,
  CustomAuthor: styled.span`
    color: ${Color.DISABLED};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
  `,
  TextContent: styled.div`
    display: flex;
  `,
  TextContainer: styled.div`
    overflow: hidden;
  `,
}
