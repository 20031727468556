const T_PREFIX = 'onboarding.sleepingTime.options'

export const OPTIONS_V1 = [
  {
    value: 'less_than_5_hours',
    textPath: `${T_PREFIX}.lessThanFive`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/man-in-bed.png',
  },
  {
    value: '5_6_hours',
    textPath: `${T_PREFIX}.fiveSix`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/alarm.png',
  },
  {
    value: '7_8_hours',
    textPath: `${T_PREFIX}.sevenEight`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/bed.png',
  },
  {
    value: 'more_than_8_hours',
    textPath: `${T_PREFIX}.moreThanEight`,
    textPathFemale: `${T_PREFIX}.moreThanEight_female`,
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/sleeping-mask.png',
  },
]
