import React from 'react'
import { Trans } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import checkIcon from 'assets/images/sprite/check-icon-white.svg'
import closeIcon from 'assets/images/sprite/close-icon-white.svg'

import { StyledComparison as S } from './Comparison.styles'

type TProps = {
  comparisonOptions: {
    titles: string[]
    images: string[]
    options: Array<string[]>
  }
}

export const Comparison: React.FC<TProps> = ({ comparisonOptions }) => {
  const { options, images, titles } = comparisonOptions

  return (
    <S.Container>
      <S.Row>
        {titles.map((title) => (
          <div key={title}>
            <S.Title>
              <Trans i18nKey={title} />
            </S.Title>
          </div>
        ))}
      </S.Row>
      <S.Row>
        {images.map((img) => (
          <S.ImageWrapper key={img}>
            <img src={img} alt="person" />
          </S.ImageWrapper>
        ))}
      </S.Row>
      <S.Row>
        {options.map((list, idx) => {
          const modifier = idx === 0 ? 'cons' : 'pros'
          const icon = idx === 0 ? closeIcon : checkIcon

          return (
            <div key={list[idx]}>
              <S.List>
                {list.map((item) => (
                  <S.ListItem key={item}>
                    <S.IconWrapper className={modifier}>
                      <SvgImage svg={icon} width={10} />
                    </S.IconWrapper>
                    <S.Text>
                      <Trans i18nKey={item} />
                    </S.Text>
                  </S.ListItem>
                ))}
              </S.List>
            </div>
          )
        })}
      </S.Row>
    </S.Container>
  )
}
