import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnimatedSequenceView } from 'views/AnimatedSequenceView'

import { selectLanguage } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

export const T_PREFIX = 'onboarding.KitSmartAiV1'

export const KitSmartAiV1: React.FC<TPageProps> = (props) => {
  const lng = useSelector(selectLanguage)
  const { t } = useTranslation()

  const animationPath = `${CDN_FOLDER_LINK}/animations/dish-scanner-${lng}.json`
  const previewPath = `${CDN_FOLDER_LINK}/images/scanner-dish-preview-${lng}.png`

  return (
    <AnimatedSequenceView
      title={t(`${T_PREFIX}.title`)}
      previewPath={previewPath}
      animationPath={animationPath}
      question={t(`${T_PREFIX}.title`, { lng: Language.EN })}
      {...props}
    />
  )
}
