import React from 'react'
import { useTranslation } from 'react-i18next'

import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { OPTIONS_V2 } from './constants'

const TITLE_PATH = 'onboarding.dailyWater.title'

export const KitDailyWater: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(TITLE_PATH)
  const question = t(TITLE_PATH, { lng: Language.EN })

  return (
    <KitRadioOptionsView
      title={title}
      question={question}
      options={OPTIONS_V2}
      {...props}
    />
  )
}
