import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { WorkOutFrequencyValue } from 'value-constants'
import { RadioOptionsView } from 'views/RadioOptionsView'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import flexedBicepsIcon from 'assets/images/flexed-biceps.png'
import female1 from 'assets/images/workout-female-1.png'
import female2 from 'assets/images/workout-female-2.png'
import female3 from 'assets/images/workout-female-3.png'
import female4 from 'assets/images/workout-female-4.png'
import male1 from 'assets/images/workout-male-1.png'
import male2 from 'assets/images/workout-male-2.png'
import male3 from 'assets/images/workout-male-3.png'
import male4 from 'assets/images/workout-male-4.png'

import { Gender } from 'root-constants'

import { TRANSLATION_FAMILY } from './constants'

export const WorkoutV2: React.FC<TPageProps> = (props) => {
  const gender = useSelector(selectCurrentUserGender)

  const options = useMemo(() => {
    const isFemale = gender === Gender.FEMALE

    return [
      {
        key: 'almostEveryDay',
        value: WorkOutFrequencyValue.ALMOST_EVERY_DAY,
        iconSrc: isFemale ? female1 : male1,
      },
      {
        key: 'severalTimesPerWeek',
        value: WorkOutFrequencyValue.SEVERAL_TIMES_PER_WEEK,
        iconSrc: isFemale ? female2 : male2,
      },
      {
        key: 'severalTimesPerMonth',
        value: WorkOutFrequencyValue.SEVERAL_TIMES_PER_MONTH,
        iconSrc: isFemale ? female3 : male3,
      },
      {
        key: 'never',
        value: WorkOutFrequencyValue.NEVER,
        iconSrc: isFemale ? female4 : male4,
      },
    ]
  }, [gender])

  return (
    <RadioOptionsView
      {...props}
      hasContinueButton
      hasOptionInfo
      options={options}
      optionInfoIcon={flexedBicepsIcon}
      translationFamily={TRANSLATION_FAMILY}
    />
  )
}
