import React from 'react'
import { useTranslation } from 'react-i18next'

import { ANSWER_OPTIONS_KIT_V2 } from 'pages/questions/Motivation/constants'
import { KitCheckboxOptionsView } from 'views/KitCheckboxOptionsView'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

const T_PREFIX = 'onboarding.kitMotivationV1'

export const KitMotivationV2: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(`${T_PREFIX}.title`)
  const question = t(title, { lng: Language.EN })

  return (
    <KitCheckboxOptionsView
      {...props}
      isReverse={false}
      title={title}
      question={question}
      options={ANSWER_OPTIONS_KIT_V2}
    />
  )
}
