import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

import { PaymentRequestButtonV2 } from '../../PaymentRequestButton/PaymentRequestButtonV2'
import { PrimerPayPalButton } from '../../PrimerPayPalButton/PrimerPayPalButton'

export const StyledPaymentMethodsV2 = {
  ButtonList: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 0 0 24px 0;
  `,
  Button: styled.button<{ isActive?: boolean }>`
    flex: 1;
    border-radius: 12px;
    border: 2px solid
      ${({ isActive }) => (isActive ? Color.GREEN_300 : Color.GREY_1000)};
    background-color: ${Color.WHITE};
    padding: 20px 2px;
    display: flex;
    justify-content: center;
  `,
  ImageContainer: styled.div<{ $height: number }>`
    height: ${({ $height }) => $height}px;

    img {
      height: inherit;
    }
  `,
  ButtonText: styled.h4<{ isActive?: boolean }>`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.BLACK};
    margin: 0 0 8px 0;
    ${({ isActive }) =>
      !isActive &&
      css`
        color: ${Color.GREY_102};
      `};
  `,
  Slogan: styled.h4`
    margin: 0 0 16px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.BLACK_400};
  `,
  PrimerPayPalButton: styled(PrimerPayPalButton)`
    border-radius: 30px;
  `,
  PaymentRequestButton: styled(PaymentRequestButtonV2)`
    & * {
      border-radius: 30px;
    }
  `,
  BannerContainer: styled.div`
    height: 36px;
    aspect-ratio: 292/36;
    margin: 0 auto 16px;
  `,
  PaymentContent: styled.div<{ isDisplayed: boolean }>`
    display: ${({ isDisplayed }) => (isDisplayed ? 'block' : 'none')};
  `,
}
