import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'

import starsIcon from 'assets/images/kit-stars-icon.png'

import { CUSTOMER_REVIEWS_V3 } from '../constants'
import { getLocalizedDate } from '../lib/getLocalizedDate'
import { StyledCustomerReviewsV3 as S } from './CustomerReviewsV3.styles'

type TCustomerReviewsProps = {
  withTitle?: boolean
  className?: string
}

export const CustomerReviewsV3: React.FC<TCustomerReviewsProps> = ({
  withTitle = true,
  className,
}) => {
  const [activeReviewIndex, setActiveReviewIndex] = useState<number | null>(
    null,
  )

  const { t } = useTranslation()
  const lng = useSelector(selectLanguage)
  const { customerReviewsTitle, customerReviews } = useDynamicPaywallConfig()

  const handleReviewClick = (index: number) => {
    setActiveReviewIndex(index)
  }

  return (
    <S.Wrapper className={className}>
      {withTitle && (
        <S.Title>
          {customerReviewsTitle || t('purchase.customerReviews.title')}
        </S.Title>
      )}
      {!customerReviews?.length &&
        CUSTOMER_REVIEWS_V3.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <S.ReviewContainer key={id}>
              <S.ReviewHeader>
                <S.ReviewTitle>{t(title)}</S.ReviewTitle>
                <S.Date>{getLocalizedDate(subtitle, lng)}</S.Date>
                <S.Rating>
                  <img src={starsIcon} alt="stars" />
                </S.Rating>
                <S.Author>{author}</S.Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <S.Text>
                    {activeReviewIndex !== index ? t(shortReview) : t(review)}
                    {activeReviewIndex !== index && (
                      <S.Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </S.Button>
                    )}
                  </S.Text>
                </S.TextContainer>
              </S.TextContent>
            </S.ReviewContainer>
          ),
        )}

      {!!customerReviews?.length &&
        customerReviews.map(
          ({ id, title, subtitle, author, review, shortReview }, index) => (
            <S.ReviewContainer key={id}>
              <S.ReviewHeader>
                <S.ReviewTitle>{title}</S.ReviewTitle>
                <S.Date>{subtitle}</S.Date>
                <S.Rating>
                  <img src={starsIcon} alt="stars" />
                </S.Rating>
                <S.Author>{author}</S.Author>
              </S.ReviewHeader>
              <S.TextContent>
                <S.TextContainer>
                  <S.Text>
                    {activeReviewIndex !== index ? shortReview : review}
                    {activeReviewIndex !== index && (
                      <S.Button onClick={() => handleReviewClick(index)}>
                        {t('purchase.customerReviews.more')}
                      </S.Button>
                    )}
                  </S.Text>
                </S.TextContainer>
              </S.TextContent>
            </S.ReviewContainer>
          ),
        )}
    </S.Wrapper>
  )
}
