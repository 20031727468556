import extraFemale from 'assets/images/extra-female.png'
import extraMale from 'assets/images/extra-male.png'
import midSizedFemale from 'assets/images/mid-sized-female.png'
import midSizedMale from 'assets/images/mid-sized-male.png'
import slimFemale from 'assets/images/slim-female.png'
import slimMale from 'assets/images/slim-male.png'

import { Gender } from 'root-constants'

const T_PREFIX_V2 = 'onboarding.bodyType.optionsV2'
export const OPTIONS_V2 = [
  {
    value: 'slim',
    title: `${T_PREFIX_V2}.slim`,
    icon: {
      [Gender.FEMALE]: slimFemale,
      [Gender.MALE]: slimMale,
    },
  },
  {
    value: 'mid_sized',
    title: `${T_PREFIX_V2}.midSized`,
    icon: {
      [Gender.FEMALE]: midSizedFemale,
      [Gender.MALE]: midSizedMale,
    },
  },
  {
    value: 'extra',
    title: `${T_PREFIX_V2}.extra`,
    icon: {
      [Gender.FEMALE]: extraFemale,
      [Gender.MALE]: extraMale,
    },
  },
]
