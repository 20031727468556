import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import creditCardIcon from 'assets/images/credit-card-icon.svg'
import alertIcon from 'assets/images/exclamation-mark-error.svg'
import infoIcon from 'assets/images/info-circle.svg'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.WHITE,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '17px',
    fontWeight: 400,
    lineHeight: '64px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.WHITE,
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '24px',
    },
  },
  invalid: {
    color: Color.RED_300,
  },
  complete: {
    ':-webkit-autofill': {
      color: Color.WHITE,
      backgroundColor: Color.GREY_104,
    },
  },
}

export const commonInputStyles = css`
  color: ${Color.WHITE};
  height: 56px;
  background-color: ${Color.GREY_104};
  line-height: 24px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 600;
  padding: 8px 8px 8px 16px;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(${alertIcon}) no-repeat;
      background-size: contain;
    }
  }

  &.StripeElement--webkit-autofill {
    background-color: ${Color.GREY_104} !important;
  }

  .ElementsApp .InputElement {
    height: 56px;
  }
`

export const StyledCreditCardFormV2 = {
  FieldsContainer: styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 11px;
    grid-template-areas:
      'cardNumber cardNumber'
      'cardExpiry cardCvc';
    border-radius: 12px;
    background: linear-gradient(106deg, #3d4554 1.61%, #333a49 93.48%);
    padding: 54px 8px 8px;
    margin: 0 0 16px 0;

    &:after {
      content: url(${creditCardIcon});
      position: absolute;
      top: 16px;
      right: 8px;
    }
  `,
  FieldContainer: styled.div`
    position: relative;
    background-color: ${Color.BLACK_500};
    border-radius: 8px;
    margin: 0 0 12px 0;

    &.creditCard {
      grid-area: cardNumber;
    }

    &.cardExpiry {
      grid-area: cardExpiry;
    }

    &.cardCvc {
      grid-area: cardCvc;
    }
  `,
  Label: styled.label`
    position: absolute;
    top: 6px;
    left: 16px;
    color: ${Color.GREY_111};
    font-size: 14px;
    font-weight: 600;
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
    height: 56px;
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
    grid-area: cardExpiry;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  InfoButton: styled.button`
    all: unset;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
    background: url(${infoIcon}) no-repeat center center;
    background-size: cover;
    border: none;
    border-radius: 50%;
  `,
  TooltipContent: styled.div`
    aspect-ratio: 151/40;
    height: 40px;
  `,
  Button: styled(Button)`
    grid-area: button;
    border-radius: 30px;
    text-transform: capitalize;
  `,
}
