import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { TPageProps } from 'models/common.model'

import { Gender, Language } from 'root-constants'

import { OPTIONS_V2 } from './constants'

const TITLE_PATH = 'onboarding.motivation.title'

export const KitMotivation1: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const userGender = useSelector(selectCurrentUserGender)
  const title = t(TITLE_PATH)
  const question = t(TITLE_PATH, { lng: Language.EN })

  const options = OPTIONS_V2.map((item) => ({
    ...item,
    textPath:
      userGender === Gender.FEMALE && item.textPathFemale
        ? item.textPathFemale
        : item.textPath,
  }))

  return (
    <KitRadioOptionsView
      title={title}
      question={question}
      options={options}
      {...props}
    />
  )
}
