import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledPersonalizeYourPlan as S } from './PersonalizeYourPlan.styles'
import { PERSONALIZE_YOUR_PLAN_IMAGES } from './constants'

export const PersonalizeYourPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const localePrefix = language === Language.ES ? language : Language.EN

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.personalizeYourPlanV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={t('actions.iAmReady')}
      onContinueButtonClick={handleNextClick}
    >
      <S.ImageContainer>
        <img
          src={`${CDN_FOLDER_LINK}${PERSONALIZE_YOUR_PLAN_IMAGES[gender]}-${localePrefix}.png`}
          alt="banner"
        />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.personalizeYourPlanV1.title')}
      </PageTitle>
      <S.Text>{t('onboarding.personalizeYourPlanV1.description')}</S.Text>
    </KitPageContainer>
  )
}
