import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  FEEDBACK_AUTHOR_MAP,
  FEEDBACK_TEXT_MAP,
  GENDER_NAME_MAP,
  IMAGE_SRC_MAP,
  LOST_WEIGHT_METRIC_MAP,
  LOST_WEIGHT_VALUE_MAP_V2,
} from 'pages/questions/Feedback/constants'

import { KitPageContainer } from 'components/PageContainer/KitPageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectCurrentVariantCohort,
  selectLanguage,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useNextStep } from 'hooks/useNextStep'

import { getCapitalizedString } from 'helpers/stringHelper'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/stars-2.png'

import { CDN_FOLDER_LINK, Cohort, Language } from 'root-constants'

import { StyledFeedbackV2 as S } from './FeedbackV2.styles'

const T_PREFIX = 'onboarding.feedbacksV2'
const useSelectors = () =>
  useBulkSelector({
    currentMeasurementSystem: selectUserMeasurementSystem,
    genderValue: selectCurrentUserGender,
    language: selectLanguage,
    cohort: selectCurrentVariantCohort,
  })

export const FeedbackV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const { language, genderValue, currentMeasurementSystem, cohort } =
    useSelectors()

  const titlePath =
    cohort === Cohort.MF_SCANNER ? `${T_PREFIX}.titleV2` : `${T_PREFIX}.title`

  const lostWeightLabel = `${
    LOST_WEIGHT_VALUE_MAP_V2[genderValue][currentMeasurementSystem]
  } ${t(LOST_WEIGHT_METRIC_MAP[currentMeasurementSystem])}`

  const feedbackAuthorLabel = `${t(
    FEEDBACK_AUTHOR_MAP[genderValue],
  )}, − ${lostWeightLabel}`

  const genderLabel = getCapitalizedString(t(GENDER_NAME_MAP[genderValue]))

  const localePrefix =
    language === Language.ES ? `-${language}` : `-${Language.EN}`

  const handleContinue = useNextStep({
    pageId,
    question: t(titlePath, {
      lng: Language.EN,
      gender: genderLabel,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={24}>
        <Trans i18nKey={titlePath} values={{ gender: genderLabel }} />
      </PageTitle>
      <S.Card>
        <S.ImageContainer>
          <img
            src={`${CDN_FOLDER_LINK}${IMAGE_SRC_MAP[genderValue]}${localePrefix}.png`}
            alt="before-after-img"
          />
        </S.ImageContainer>
        <S.Feedback>
          <S.FeedbackText>
            <Trans
              i18nKey={FEEDBACK_TEXT_MAP[genderValue]}
              values={{ value: lostWeightLabel }}
            />
          </S.FeedbackText>
          <S.FeedbackAuthor>
            <S.AuthorName>{feedbackAuthorLabel}</S.AuthorName>
            <S.Rating src={stars} alt="stars" />
          </S.FeedbackAuthor>
        </S.Feedback>
      </S.Card>
    </KitPageContainer>
  )
}
