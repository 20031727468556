import styled, { css } from 'styled-components'

import arrowDown from 'assets/images/arrow-down.svg'
import arrowUp from 'assets/images/arrow-up.svg'
import checkIcon from 'assets/images/check-white-v2.svg'

import { Color } from 'root-constants'

import { COMPLEX_CURRENCY_CN } from '../../constants/constants'

type TProps = {
  isSelected?: boolean
  isCompactVariant?: boolean
}

const commonPriceStyles = css`
  color: ${Color.GREY_101};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
`

export const StyledKitSubscriptionItemV1 = {
  PlanContainer: styled.div<TProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid;
    border-color: ${({ isSelected }) =>
      isSelected ? Color.GREEN_300 : Color.GREY_800};
    border-radius: 20px;

    &.${COMPLEX_CURRENCY_CN} {
      border: 2px solid;
      border-color: ${({ isSelected }) =>
        isSelected ? Color.GREEN_400 : Color.GREY_800};
    }
  `,

  Static: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
    padding-left: 28px;

    &::before {
      border: 2px solid;
      border-color: ${({ isSelected }) =>
        isSelected ? Color.GREEN_300 : Color.GREY_WHITE};
      border-radius: 50%;
      content: '';
      height: 20px;
      left: 0;
      mix-blend-mode: normal;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-color: ${Color.GREEN_300};
          background-image: url(${checkIcon});
          background-position: center center;
          background-repeat: no-repeat;
        `};
    }

    &.${COMPLEX_CURRENCY_CN} {
      &::before {
        border-color: ${({ isSelected }) =>
          isSelected ? Color.GREEN_300 : Color.GREY_1000};
      }
    }
  `,

  PopularBadge: styled.div<TProps>`
    background-color: ${({ isSelected }) =>
      isSelected ? Color.GREEN_300 : Color.GREY_1000};
    border-radius: 24px;
    color: ${Color.WHITE};
    font-size: 10px;
    font-weight: 700;
    left: 50%;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 4px 12px;
    position: absolute;
    text-transform: uppercase;
    top: -10px;
    transform: translateX(-50%);
  `,
  PlanColumn: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-of-type {
      flex-grow: 1;
    }
  `,
  PlanPeriod: styled.span<TProps>`
    color: ${({ isSelected }) =>
      isSelected ? Color.GREY_100 : Color.GREY_101};
    font-size: ${({ isCompactVariant }) =>
      isCompactVariant ? '14px' : '16px'};
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
    white-space: nowrap;
  `,
  PlanPrice: styled.div`
    column-gap: 8px;
    display: flex;

    &.${COMPLEX_CURRENCY_CN} {
      align-items: flex-end;
    }
  `,
  PreviousPrice: styled.span`
    ${commonPriceStyles};
    color: ${Color.GREY_101};
    position: relative;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED_300};

    &.${COMPLEX_CURRENCY_CN} {
      font-size: 12px;
      line-height: 20px;
      align-items: flex-end;
      font-weight: 600;
    }
  `,
  CurrentPrice: styled.span`
    ${commonPriceStyles};
    flex-grow: 1;

    &.${COMPLEX_CURRENCY_CN} {
      font-size: 12px;
      line-height: 20px;
      align-items: flex-end;
      font-weight: 600;
    }
  `,
  DayPriceContainer: styled.div<TProps>`
    color: ${({ isSelected }) => (isSelected ? Color.WHITE : Color.GREY_101)};
    display: flex;
    flex-direction: column;
    padding: ${({ isCompactVariant }) =>
      isCompactVariant ? '3px 12px 8px 26px' : '6px 14px 8px 26px'};

    &.${COMPLEX_CURRENCY_CN} {
      justify-content: center;
      min-height: 48px;
      min-width: 70px;
      padding: 4px 4px 4px 9px;
      row-gap: 4px;
      text-align: right;
    }
  `,
  CurrentDayPrice: styled.span`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;

    &.${COMPLEX_CURRENCY_CN} {
      font-size: 14px;
      line-height: 14px;
    }
  `,
  PreviousDayPrice: styled.span<TProps>`
    font-size: 17px;
    line-height: 24px;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED_300};

    &:not(.${COMPLEX_CURRENCY_CN}) {
      ${commonPriceStyles};
    }

    &.${COMPLEX_CURRENCY_CN} {
      color: ${({ isSelected }) =>
        isSelected ? Color.GREY_100 : Color.GREY_101};
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      white-space: nowrap;
    }
  `,
  DayPricePeriod: styled.span`
    font-size: 8px;
    font-weight: 600;
    line-height: 12px;

    &.${COMPLEX_CURRENCY_CN} {
      font-weight: 700;
      line-height: 8px;
    }
  `,
  LearnMoreButton: styled.button<{ isOpen: boolean }>`
    display: inline-flex;
    align-items: center;
    padding: 0;
    background: none;
    outline: none;
    border: none;
    margin-top: 8px;
    width: max-content;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.GREEN_300};
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: ${Color.GREEN_400};
    }

    &::after {
      content: url(${({ isOpen }) => (isOpen ? arrowUp : arrowDown)});
      margin: 0 0 3px 8px;
    }
  `,

  AccordionContent: styled.div<{ isOpen: boolean }>`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: ${Color.GREY_100};
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding: ${({ isOpen }) => (isOpen ? '24px 0 8px 0' : '0')};
    max-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.2s ease;
  `,

  ProsItem: styled.div`
    display: inline-flex;
    gap: 8px;

    img {
      width: 24px;
      height: 24px;
    }
  `,
}
