import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

interface ITranslationsContextType {
  translations: Map<string, string>
  addTranslation: (key: string, translation: string) => void
}

const I18nInterceptorContext = createContext<
  ITranslationsContextType | undefined
>(undefined)

export const I18nInterceptorProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [translations] = useState(new Map<string, string>())

  const addTranslation = useCallback(
    (key: string, translation: string) => {
      translations.set(key, translation)
    },
    [translations],
  )

  const values = useMemo(
    () => ({ translations, addTranslation }),
    [translations, addTranslation],
  )

  return (
    <I18nInterceptorContext.Provider value={values}>
      {children}
    </I18nInterceptorContext.Provider>
  )
}

export const useI18nInterceptor = () => {
  const context = useContext(I18nInterceptorContext)
  if (!context) {
    throw new Error(
      'useTranslations must be used within a TranslationsProvider',
    )
  }
  return context
}
