import { SleepingTimeValue } from 'value-constants'

import bedIcon from 'assets/images/bed.png'
import sleepingEmoji from 'assets/images/sleeping-emoji.png'
import superHeroIcon from 'assets/images/superhero-emoji.png'
import tripleZIcon from 'assets/images/triple-z-sleeping.png'

const T_PREFIX = 'onboarding.sleepingTime.options'

export const OPTIONS_V1 = [
  {
    value: SleepingTimeValue.LESS_THAN_FIVE,
    icon: bedIcon,
    title: `${T_PREFIX}.lessThanFive`,
  },
  {
    value: SleepingTimeValue.FIVE_SIX,
    icon: sleepingEmoji,
    title: `${T_PREFIX}.fiveSix`,
  },
  {
    value: SleepingTimeValue.SEVEN_EIGHT,
    icon: tripleZIcon,
    title: `${T_PREFIX}.sevenEight`,
  },
  {
    value: SleepingTimeValue.MORE_THAN_EIGHT,
    icon: superHeroIcon,
    title: `${T_PREFIX}.moreThanEight`,
  },
]
