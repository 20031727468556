import { useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { selectUserStatus } from 'root-redux/selects/user'

import { PageId } from 'page-constants'

export const useUserStatus = () => {
  const userStatus = useSelector(selectUserStatus)

  const {
    steps,
    currentSubscriptionPageId,
    currentUpsellPaywallPageId,
    activeUpsellPageIds,
  } = usePageInfo()

  if (userStatus?.account.hasAccount && userStatus?.hasSubscription) {
    return PageId.DOWNLOAD
  }

  if (userStatus?.hasSubscription && !activeUpsellPageIds?.length) {
    return PageId.FINISHING_TOUCHES
  }

  if (userStatus?.hasSubscription && activeUpsellPageIds?.length) {
    return currentUpsellPaywallPageId
  }

  if (userStatus?.email.hasEmail && !userStatus?.hasSubscription) {
    return currentSubscriptionPageId || steps[0].id
  }

  return '' as PageId
}
