import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'

import { StyledPlanHighlights as S } from './PlanHighlights.styles'

type TProps = {
  titleElementRef?: React.RefObject<HTMLHeadingElement>
  className?: string
}

const LIST_ITEMS = [
  { id: 1, text: 'purchase.benefits.shredPounds' },
  { id: 2, text: 'purchase.benefits.forgetAboutDiet' },
  { id: 3, text: 'purchase.benefits.learnNewPatterns' },
  { id: 4, text: 'purchase.benefits.getToned' },
  { id: 5, text: 'purchase.benefits.newHabits' },
  { id: 6, text: 'purchase.benefits.foodCravings' },
  { id: 7, text: 'purchase.benefits.enjoyLife' },
]

export const PlanHighlights: React.FC<TProps> = ({
  titleElementRef,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const { benefitsBlockTitle, benefits } = useDynamicPaywallConfig()

  return (
    <S.Wrapper>
      <S.Title ref={titleElementRef}>
        {benefitsBlockTitle || t('purchase.listTitle')}
      </S.Title>
      <S.List className={className} {...props}>
        {(benefits?.length ? benefits : LIST_ITEMS).map(({ id, text }) => (
          <S.ListItem key={id}>{benefits?.length ? text : t(text)}</S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
