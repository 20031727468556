import styled from 'styled-components'

import { SupportActionLink } from 'components/SupportActionLink'

import { Color } from 'root-constants'

export const StyledSecurityInfoV2 = {
  Container: styled.div`
    background-color: ${Color.WHITE};
    padding: 48px 16px 32px 16px;
    border: 1px solid ${Color.GREY_800};
    border-radius: 16px;
    position: relative;
  `,
  BadgeContainer: styled.div`
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${Color.WHITE};
    box-shadow: 0 4px 12px -2px #3b3b3e29;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 23px;
  `,
  Badge: styled.div`
    aspect-ratio: 124/44;
    height: 44px;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin: 0 0 20px 0;
    }
  `,
  InfoTitle: styled.h4`
    color: ${Color.GREY_100};
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin: 0 0 8px 0;
  `,
  InfoText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GREY_101};
  `,
  SupportActionLink: styled(SupportActionLink)`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.GREEN_300};
    text-decoration: none;
  `,
}
