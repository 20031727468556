import styled, { css } from 'styled-components'

import leftSpikelet from 'assets/images/left-spikelet.svg'
import rightSpikelet from 'assets/images/right-spikelet.svg'

import { Color } from 'root-constants'

const commonPseudoElementsStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 124px;
  height: 148px;
`

const commonTextContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 148px;
  position: relative;

  &::before {
    content: url(${leftSpikelet});
    left: 0;
    ${commonPseudoElementsStyle};
  }

  &::after {
    content: url(${rightSpikelet});
    right: 0;
    ${commonPseudoElementsStyle};
  }
`

const commonContainerStyle = css`
  padding: 16px;
  border-radius: 32px;
  background-color: rgba(255, 153, 0, 0.1);
`

export const StyledAppReached = {
  Container: styled.div`
    ${commonContainerStyle};
    position: relative;
    z-index: 1;
  `,
  TextContainer: styled.div`
    ${commonTextContainerStyle};
  `,
  Children: styled.div`
    margin-top: 16px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: ${Color.GREEN_300};
    margin: 0 0 4px 0;
  `,
  Subtitle: styled.p`
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  `,
}
