import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js'
import { usePageInfo } from 'contexts/PageInfoProvider'

import { Tooltip } from 'components/Tooltip'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store'

import {
  EMPTY_FIELD_ERROR,
  PaymentMethod,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchaseSubscription/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchaseSubscription/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
  setPaymentMethodAction,
} from 'modules/purchaseSubscription/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchaseSubscription/redux/selects'
import {
  TPaymentErrorState,
  TStripeFieldProps,
} from 'modules/purchaseSubscription/types'

import creditCardIcon from 'assets/images/credit-card-cvc.png'

import { Color, StripeFieldName } from 'root-constants'

import {
  StyledCreditCardFormV2 as S,
  stripeElementStyle,
} from './CreditCardFormV2.styles'

type TProps = { isDisplayed: boolean }

const CREDIT_CARD_PLACEHOLDER = 'XXXX XXXX XXXX XXXX'

export const CreditCardFormV2: React.FC<TProps> = ({ isDisplayed }) => {
  const [isVisibleTooltip, setIsVisibleTooltip] = useState(false)
  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const cardNumberElemRef = useRef<StripeCardNumberElement | null>(null)
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)

  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const { currentPageId } = usePageInfo()

  const hasErrors = Object.values(errors).some(
    (error) => error.isShown && error.error,
  )
  const hasUntouchedFields = Object.values(errors).some(
    (error) => error.isShown && !error.isTouched,
  )
  const hasUncompletedFields = Object.values(errors).some(
    (field) => !field.isComplete,
  )

  const isFormValid = !hasErrors && !hasUntouchedFields && !hasUncompletedFields

  const clearInputs = () => {
    cardNumberElemRef.current?.clear()
    cardExpiryElemRef.current?.clear()
    cvcElemRef.current?.clear()
  }

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(resetErrorAction())

    if (hasUntouchedFields) {
      setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
      return
    }

    if (hasErrors) return

    const card = elements?.getElement(CardNumberElement)

    if (!stripe || !card || !currentPageId) return

    dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    dispatch(
      purchaseAction({
        stripe,
        card,
        paymentPageId: currentPageId,
      }),
    )
  }

  const handleChange = ({
    fieldName,
    isEmpty,
    hasError,
    isComplete,
    nextElemRef,
  }: TStripeFieldProps) => {
    dispatch(resetErrorAction())

    let error = ''

    if (hasError) {
      error = 'is invalid'
    }

    if (isEmpty) {
      error = EMPTY_FIELD_ERROR
    }

    if (nextElemRef && isComplete) {
      nextElemRef.current?.focus()
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: { isTouched: true, error, isComplete },
    }))
  }

  const handleOnFocus = () => setIsVisibleTooltip(false)

  useEffect(() => {
    if (isDisplayed) clearInputs()
  }, [isDisplayed])

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  return (
    <form onSubmit={handleSubmit}>
      <S.FieldsContainer>
        <S.FieldContainer className="creditCard">
          <S.Label>
            <Trans i18nKey="checkout.cardNumber" />
          </S.Label>
          <S.CardNumberElement
            onReady={(elem) => {
              elem.focus()
              cardNumberElemRef.current = elem
            }}
            onFocus={handleOnFocus}
            options={{
              showIcon: false,
              placeholder: CREDIT_CARD_PLACEHOLDER,
              style: stripeElementStyle,
            }}
            onChange={({ empty, error, complete }) => {
              handleChange({
                fieldName: StripeFieldName.NUMBER,
                isEmpty: empty,
                hasError: !!error,
                isComplete: complete,
                nextElemRef: cardExpiryElemRef,
              })
            }}
          />
        </S.FieldContainer>

        <S.FieldContainer className="cardExpiry">
          <S.Label>
            <Trans i18nKey="checkout.cardExpiry" />
          </S.Label>
          <S.CardExpiryElement
            onReady={(elem) => {
              cardExpiryElemRef.current = elem
            }}
            onFocus={handleOnFocus}
            options={{
              placeholder: t('checkout.cardExpiryPlaceholder'),
              style: stripeElementStyle,
            }}
            onChange={({ empty, error, complete }) => {
              handleChange({
                fieldName: StripeFieldName.EXPIRY,
                isEmpty: empty,
                hasError: !!error,
                isComplete: complete,
                nextElemRef: cvcElemRef,
              })
            }}
          />
        </S.FieldContainer>

        <S.FieldContainer className="cardCvc">
          <S.Label>
            <Trans i18nKey="checkout.securityNumberV2" />
          </S.Label>

          <S.CardCvcElement
            onReady={(elem) => {
              cvcElemRef.current = elem
            }}
            onFocus={handleOnFocus}
            options={{
              placeholder: t('checkout.securityNumber'),
              style: stripeElementStyle,
            }}
            onChange={({ empty, error, complete }) => {
              handleChange({
                fieldName: StripeFieldName.CVC,
                isEmpty: empty,
                hasError: !!error,
                isComplete: complete,
              })
            }}
          />
          <S.InfoButton
            type="button"
            onClick={() => setIsVisibleTooltip(!isVisibleTooltip)}
          />
          {isVisibleTooltip && (
            <Tooltip onClose={() => setIsVisibleTooltip(false)}>
              <S.TooltipContent>
                <img src={creditCardIcon} alt="cvc-examples" />
              </S.TooltipContent>
            </Tooltip>
          )}
        </S.FieldContainer>
      </S.FieldsContainer>

      <S.Button
        type="submit"
        disabled={!stripe || !isFormValid}
        disableBackgroundColor={Color.GREY_1000}
        disableColor={Color.WHITE}
        backgroundColor={Color.GREEN_300}
        padding="16px"
        borderRadius="30px"
        fontSize="17px"
        fontWeight="700"
        lineHeight="24px"
        width="100%"
      >
        {t('actions.confirmPayment')}
      </S.Button>
    </form>
  )
}
