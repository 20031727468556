import styled from 'styled-components'

import { KitModalV1 } from 'components/KitModal'

import { Color, MODAL_OUTER_INDENT } from 'root-constants'

type TPropsWrapper = {
  isShown: boolean
}

export const StyledPaymentWaitingModal = {
  Wrapper: styled.div<TPropsWrapper>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 103 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 320px;
    margin: auto;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 20px;
    background-color: ${Color.WHITE};
  `,
  KitModalV1: styled(KitModalV1)`
    width: calc(100% - ${MODAL_OUTER_INDENT}px);
    max-width: 320px;
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > :not(:last-child) {
      margin-bottom: 24px;
    }
  `,
}
