import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonV2 } from 'components/AnswerButtonV2'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'

import { StyledMotivation as S } from './Motivation.styles'
import { OPTIONS, QUESTION_V2 } from './constants'

export const MotivationV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: CustomPageId.CHOOSE_EVENT,
    question: QUESTION_V2,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.CHOOSE_EVENT,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <S.Container>
      <PageTitle marginBottom={8}>
        {t('onboarding.motivation.titleV2')}
      </PageTitle>
      <S.SubTitle>{t('onboarding.motivation.subTitle')}</S.SubTitle>
      {OPTIONS.map(({ value, title }) => (
        <Option
          key={value}
          {...optionProps}
          marginBottom="12"
          disabled={isAnswersDisabled}
          value={value}
        >
          <AnswerButtonV2>
            <h3>{t(title)}</h3>
          </AnswerButtonV2>
        </Option>
      ))}
    </S.Container>
  )
}
