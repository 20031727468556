import styled from 'styled-components'

import discountBadge from 'assets/images/upsell-discount-badge.svg'

import { Color, KIT_MAX_COLUMN_WIDTH } from 'root-constants'

type TDiscountTextProps = {
  isComplexCurrency: boolean
}

export const StyledUpsellSubscriptionBlock = {
  Discount: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    padding: 23px 16px 23px 80px;
    background: rgba(169, 205, 80, 0.2);
    border: 1px solid ${Color.GREEN_400};
    border-radius: 12px;
    min-height: 68px;
  `,
  EmailWrapper: styled.div`
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 1px solid ${Color.GREEN_400};
    background: rgba(169, 205, 80, 0.2);
    padding: 16px;
    margin-bottom: 40px;
  `,
  Email: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    word-break: break-word;
  `,
  DiscountBadge: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    left: 10px;
    top: -12px;
    max-width: 60px;
    min-height: 76px;
    padding: 5px 4px 26px;
    background-image: url(${discountBadge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.WHITE};
  `,
  DiscountText: styled.p<TDiscountTextProps>`
    color: ${Color.GREY_100};
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: ${KIT_MAX_COLUMN_WIDTH}px) {
      font-size: ${({ isComplexCurrency }) =>
        isComplexCurrency ? '16px' : '17px'};
    }
  `,
}
