import { BmiValue } from 'value-constants'

import checkIcon from 'assets/images/check-circle-2.png'
import exclamationIcon from 'assets/images/exclaimation-circle-2.png'
import exclamationWarningIcon from 'assets/images/exclaimation-warning-circle-2.png'

const T_PREFIX_MBI = 'onboarding.currentWeight.mbiInfo'
const T_PREFIX_LABEL = 'onboarding.currentWeight.disclaimer.label'

export const BMI_INFO_MAP_V1 = {
  [BmiValue.UNDERWEIGHT]: {
    iconSrc: exclamationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: `${T_PREFIX_MBI}.underweight`,
    bmiValue: `${T_PREFIX_LABEL}.underweight`,
  },
  [BmiValue.NORMAL]: {
    iconSrc: checkIcon,
    background: 'linear-gradient(0deg, #ecfbf2 0%, #ecfbf2 100%), #f1fff8;',
    text: `${T_PREFIX_MBI}.normal`,
    bmiValue: `${T_PREFIX_LABEL}.normal`,
  },
  [BmiValue.OVERWEIGHT]: {
    iconSrc: exclamationWarningIcon,
    background: 'linear-gradient(0deg, #fff2e3 0%, #fff2e3 100%), #f5f5f5;',
    text: `${T_PREFIX_MBI}.overweight`,
    bmiValue: `${T_PREFIX_LABEL}.overweight`,
  },
  [BmiValue.OBESITY]: {
    iconSrc: exclamationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: `${T_PREFIX_MBI}.obesity`,
    bmiValue: `${T_PREFIX_LABEL}.obese`,
  },
  [BmiValue.EXTREME_OBESITY]: {
    iconSrc: exclamationIcon,
    background: 'linear-gradient(0deg, #ffe3e3 0%, #ffe3e3 100%), #f5f5f5;',
    text: `${T_PREFIX_MBI}.obesity`,
    bmiValue: `${T_PREFIX_LABEL}.extrObese`,
  },
}
