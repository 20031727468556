import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { OptionType } from 'components/Option'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { isPayPalAvailable } from 'helpers/isPayPalAvailable'

import { PaymentMethod } from 'modules/purchaseSubscription/constants'
import { selectOneClickPaymentMethod } from 'modules/purchaseSubscription/redux/selects'

import maestro from 'assets/images/maestro-icon.png'
import mastercard from 'assets/images/mastercard-icon.png'
import visa from 'assets/images/visa-icon.png'

import { CreditCardPayment, OneClickPayment } from '../../../components'
import { CHECKOUT_ICON_MAP } from '../constants'
import { StyledPaymentMethodsV1 as S } from './PaymentMethodsV1.styles'

const useSelectors = () =>
  useBulkSelector({
    countryCode: selectUserCountryCode,
    oneClickPaymentMethod: selectOneClickPaymentMethod,
  })

export const PaymentMethodsV1 = () => {
  const [activeMethod, setActiveMethod] = useState(PaymentMethod.CREDIT_CARD)

  const { countryCode, oneClickPaymentMethod } = useSelectors()

  const hasPaypal = isPayPalAvailable(countryCode)
  const options = [
    {
      value: PaymentMethod.ONE_CLICK,
      title: 'kitCheckoutV1.oneClick',
      icons: [] as string[],
      Form: OneClickPayment,
    },
    {
      value: PaymentMethod.CREDIT_CARD,
      title: 'kitCheckoutV1.creditCard',
      icons: [visa, mastercard, maestro],
      Form: CreditCardPayment,
    },
  ]

  if (oneClickPaymentMethod) {
    options[0].icons.push(CHECKOUT_ICON_MAP[oneClickPaymentMethod])
  }

  if (hasPaypal) options[0].icons.push(CHECKOUT_ICON_MAP[PaymentMethod.PAYPAL])

  useEffect(() => {
    if (oneClickPaymentMethod) {
      setActiveMethod(PaymentMethod.ONE_CLICK)
    }
  }, [oneClickPaymentMethod])

  return (
    <>
      {options.map(({ value, title, icons, Form }) => {
        if (!icons.length) return null

        const isOpen = activeMethod === value
        const onClick = () => setActiveMethod(value)

        return (
          <S.ItemContainer isActive={isOpen}>
            <S.Option
              type={OptionType.RADIO}
              name="paymentMethod"
              value={value}
              key={value}
              onChange={onClick}
            >
              <S.TitleBlock isSelected={isOpen}>
                <S.Title>
                  <Trans i18nKey={title} />
                </S.Title>
                <S.ImageContainer>
                  {icons.map((icon) => (
                    <img key={icon} src={icon} alt="card" />
                  ))}
                </S.ImageContainer>
              </S.TitleBlock>
            </S.Option>
            <S.ContentBlock
              isSelected={isOpen}
              ref={(ref) => {
                if (isOpen) {
                  ref?.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
              }}
            >
              <Form />
            </S.ContentBlock>
          </S.ItemContainer>
        )
      })}
    </>
  )
}
