import { TimeInterval } from 'root-constants'

export function createProductId({
  periodName,
  periodQuantity,
  price,
}: {
  periodName: TimeInterval | null
  periodQuantity: number | null
  price: number | string
}): string {
  return `my_fast_${periodQuantity}_${periodName}_${price}`
}
