import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  $isEnabled: boolean
}

export const StyledLanguageSelector = {
  Wrapper: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 1000;
    padding: 20px;
    text-align: center;
    border: 1px solid ${Color.GREEN_300};
  `,

  Overlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  `,

  Title: styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${Color.GREY_100};
    margin-bottom: 10px;
  `,

  StatusText: styled.div<TProps>`
    font-size: 16px;
    color: ${Color.GREY_100};
    margin-bottom: 20px;

    strong {
      font-weight: 700;
      color: ${({ $isEnabled }) =>
        $isEnabled ? Color.GREEN_300 : Color.RED_500};
    }
  `,

  Button: styled.button<TProps>`
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: ${({ $isEnabled }) =>
      $isEnabled ? Color.RED_500 : Color.GREEN_300};
    border: none;
    border-radius: 5px;
    cursor: pointer;
  `,
}
