import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFeaturedBlock = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    color: ${Color.GREY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
  `,
  Image: styled.div`
    aspect-ratio: 343 / 92;
    width: 100%;
  `,
}
