import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Footer } from 'components/Footer'
import { OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useDynamicOBConfig } from 'hooks/useDynamicOBConfig'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

import { StyledAgeRangeV3 as S } from './AgeRangeV3.styles'
import { RANGES } from './constants'

export const AgeRangeV3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { title, subtitle } = useDynamicOBConfig()

  const handleContinue = useNextStep({
    pageId: CustomPageId.AGE_RANGE,
    question: title || t('onboarding.ageRangeV3.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: CustomPageId.AGE_RANGE,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
        eventLogger.logFirstPageCompleted({
          question:
            title || t('onboarding.ageRangeV3.title', { lng: Language.EN }),
          answers: value,
        })
      },
    }),
    [handleChange, handleContinue, setIsAnswersDisabled, t, title],
  )

  return (
    <PageContainer>
      <S.Title>{title || t('onboarding.ageRangeV3.title')}</S.Title>
      <S.Subtitle>{subtitle || t('onboarding.ageRangeV3.subtitle')}</S.Subtitle>
      <S.Disclaimer>{t('onboarding.ageRangeV3.disclaimer')}</S.Disclaimer>

      <S.OptionsContainer>
        {RANGES.map(({ value, text, img }) => (
          <S.Option
            {...optionProps}
            key={value}
            disabled={isAnswersDisabled}
            value={value}
          >
            <S.Action>
              <S.RangeImage src={img} alt="range" />
              <S.Answer />
              <S.Range>{text}</S.Range>
            </S.Action>
          </S.Option>
        ))}
      </S.OptionsContainer>
      <Footer />
    </PageContainer>
  )
}
