import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'
import { PresaleGraph } from 'components/PresaleGraph'

import { selectCurrentVariantCohort } from 'root-redux/selects/common'

import { PurchasePageType } from 'modules/purchaseSubscription/constants'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { Cohort } from 'root-constants'

import { StyledPresaleV3 as S } from './PresaleV3.styles'

export const PresaleV3: React.FC = () => {
  const { search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)

  const { currentSubscriptionPageId } = usePageInfo()
  const isScannerCohort = cohort === Cohort.MF_SCANNER

  const handleNextClick = () => {
    eventLogger.logPresalePageCompleted()
    goTo({ pathname: currentSubscriptionPageId, search })
  }

  useEffect(() => {
    eventLogger.logPresalePageShown(PurchasePageType.WEB_LANDING)
  }, [])

  return (
    <KitPageContainer hasContinueButton onContinueButtonClick={handleNextClick}>
      <PageTitle marginBottom={24} textAlign="left">
        <Trans i18nKey="presaleV3.title" />
      </PageTitle>
      <S.UserInfoBlockV3 />
      <S.UserInfoBlockV4 />
      {!isScannerCohort ? <PresaleGraph /> : null}
    </KitPageContainer>
  )
}
