import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { CustomPageId } from 'page-constants'
import { INTEGER_NUMBER_REGEX } from 'root-constants'

export const useAgeField = (): {
  userAge: { value: string; isValid: boolean }
  handleAgeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
} => {
  const userAnswersCollection = useSelector(selectAnswers)

  const [userAge, setUserAge] = useState<{
    value: string
    isValid: boolean
  }>({
    value: '',
    isValid: false,
  })

  useEffect(() => {
    setUserAge({
      value: userAnswersCollection?.[CustomPageId.AGE]?.toString() || '',
      isValid: !!userAnswersCollection?.[CustomPageId.AGE],
    })
  }, [userAnswersCollection])

  const handleAgeChange = useCallback(({ target: { value, validity } }) => {
    if (!value || INTEGER_NUMBER_REGEX.test(value)) {
      setUserAge({
        value,
        isValid: validity.valid,
      })
    }
  }, [])

  return { userAge, handleAgeChange }
}
