import Select from 'react-select'

import styled from 'styled-components'

export const StyledLanguageSelector = {
  Wrapper: styled.div`
    position: fixed;
    top: 7px;
    right: 55px;
    z-index: 101;
  `,
  Select: styled(Select)`
    .language-select__control {
      min-height: 30px;
    }
    .language-select__dropdown-indicator {
      padding: 0 4px;
    }
    .language-select__value-container {
      padding: 0 8px;
    }
  `,
}
