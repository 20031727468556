import { userApi } from 'api'
import i18n from 'i18next'

import {
  setAnswersFromBackendAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { selectPlanAdditions } from 'modules/purchaseSubscription/redux/selects'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import {
  IGetUUIDResponseRaw,
  IUserStatus,
  TUserConfig,
} from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants'

const MODULE_NAME = 'USER'

const BIND_USER = `${MODULE_NAME}/BIND_USER`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
const SAVE_PLAN_ADDITIONS = `${MODULE_NAME}/SAVE_PLAN_ADDITIONS`
export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SET_AUTH_TOKEN = `${MODULE_NAME}/SET_AUTH_TOKEN`
export const SET_TEST_ENVIRONMENT_QUERY_PARAM = `${MODULE_NAME}/SET_TEST_ENVIRONMENT_QUERY_PARAM`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_EMAIL_CONSENT = `${MODULE_NAME}/SEND_USER_EMAIL_CONSENT`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SEND_FACEBOOK_PARAMS`
export const SET_UTM_TAGS = `${MODULE_NAME}/SET_UTM_TAGS`
export const SET_IS_PERSONAL_DATA_ALLOWED = `${MODULE_NAME}/SET_IS_PERSONAL_DATA_ALLOWED`
export const UPDATE_USER_CONFIG = `${MODULE_NAME}/UPDATE_USER_CONFIG`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setAuthTokenAction(authToken: string): IAction<string> {
  return {
    type: SET_AUTH_TOKEN,
    payload: authToken,
  }
}

export function setTestEnvironmentQueryParamAction(
  testEnvironmentQueryParam: string,
): IAction<string> {
  return {
    type: SET_TEST_ENVIRONMENT_QUERY_PARAM,
    payload: testEnvironmentQueryParam,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    utmTags: utmTagsFromUrl,
    giaApiKey,
    language,
  }: {
    cohort: string
    uuid: string | null
    utmTags: TUtmTags
    giaApiKey: string
    language: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID({
      cohort,
      appId: giaApiKey,
      locale: language,
      utm: utmTagsFromUrl,
    })

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export function bindUserAction(token: string): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))
    const response = await userApi.bindUser({ token, uuid })

    if (response.success && response.data) {
      response.data.custom_token &&
        localStorage.setItem(
          CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
          response.data.custom_token,
        )

      const userStatusResponse = await userApi.getUserStatus(uuid)
      const userStatus = getUserStatusFromRawUserStatus(
        userStatusResponse.data.state,
      )

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
      googleAnalyticsLogger.logAccountCreated()
    } else {
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? i18n.t('login.refundSubscriptionError')
          : i18n.t('login.haveAccountError')

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): any {
  return async (dispatch) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)

    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction =
  (unsuccessCallback?: (() => void) | null, isFinished = false): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(i18n.t('login.haveAccountError')))
      } else {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      unsuccessCallback && unsuccessCallback()
    }
    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export const sendUserConfigAction =
  (config: TUserConfig, unsuccessCallback?: (() => void) | null): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_CONFIG))

    const response = await userApi.saveUserConfig({
      uuid,
      config,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(i18n.t('login.haveAccountError')))
      } else {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_CONFIG))
  }

export const sendUserEmailAction =
  ({
    email,
    unsuccessCallback,
  }: {
    email: string
    unsuccessCallback?: (() => void) | null
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({
      uuid,
      email,
      consentRequired: true,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(i18n.t('login.haveAccountError')))
      } else {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SEND_USER_EMAIL))
      return
    }

    dispatch(getUserStatusAction(uuid))

    dispatch(stopFetching(SEND_USER_EMAIL))
  }

export const sendUserEmailConsentAction =
  ({ consented }: { consented: boolean }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL_CONSENT))

    const response = await userApi.saveUserEmailConsent({
      uuid,
      consented,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      dispatch(stopFetching(SEND_USER_ANSWERS))
      return
    }

    dispatch(getUserStatusAction(uuid))

    dispatch(stopFetching(SEND_USER_EMAIL_CONSENT))
  }

export const sendFacebookParamsAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')

    dispatch(startFetching(SEND_FACEBOOK_PARAMS))

    const response = await userApi.saveFacebookParams({
      uuid,
      fbp,
      fbc,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(i18n.t('login.haveAccountError')))
      } else {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }
    }

    dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
  }

export const savePlanAdditionsAction =
  (unsuccessCallback?: (() => void) | null): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const planAdditions = selectPlanAdditions(state)

    if (!planAdditions.length) return

    dispatch(startFetching(SAVE_PLAN_ADDITIONS))

    const response = await userApi.savePlanAdditions({
      uuid,
      planAdditions,
    })

    if (!response.success) {
      const errorMessage =
        response?.data?.error || i18n.t('login.somethingWentWrongError')

      dispatch(setErrorAction(errorMessage))

      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
      return
    }

    dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
  }

export const sendUserSCCID =
  (sccid: string, scid: string): any =>
  async (_, getState: () => IAppState): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    await userApi.saveUserSCCID({ sccid, uuid, scid })
  }

export const setUtmTags = (
  payload: Record<string, string>,
): IAction<Record<string, string>> => ({
  type: SET_UTM_TAGS,
  payload,
})

export function updateUserConfigAction(
  value: Record<string, string | number>,
): IAction<Record<string, string | number>> {
  return {
    type: UPDATE_USER_CONFIG,
    payload: value,
  }
}

export function setIsPersonalDataAllowedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PERSONAL_DATA_ALLOWED,
    payload,
  }
}
