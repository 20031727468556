import React, { VideoHTMLAttributes, forwardRef } from 'react'

import { StyledVideoWrapper as S } from './VideoPlayer.styles'

type TProps = {
  videoProps: VideoHTMLAttributes<HTMLVideoElement>
  className?: string
  children?: React.ReactNode
}

export const VideoPlayer = forwardRef<HTMLVideoElement, TProps>(
  ({ className, videoProps, children }, ref) => (
    <S.VideoWrapper $poster={videoProps.poster} className={className}>
      <video
        ref={ref}
        playsInline
        preload="auto"
        muted
        autoPlay
        controls={false}
        {...videoProps}
      >
        {children}
      </video>
    </S.VideoWrapper>
  ),
)
