import React from 'react'
import { Trans } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import GuaranteeBadge from 'assets/images/guarantee-badge.svg'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

type TProps = {
  variant?: 'primary' | 'secondary'
}

export const MoneyBackGuarantee: React.FC<TProps> = ({
  variant = 'primary',
}) => {
  const isSecondaryVariant = variant === 'secondary'

  return (
    <S.Wrapper isSecondaryVariant={isSecondaryVariant}>
      <S.Content isSecondaryVariant={isSecondaryVariant}>
        <S.Title>
          <Trans i18nKey="purchase.moneyBackGuarantee.title" />
        </S.Title>
        <S.Text>
          <Trans i18nKey="purchase.moneyBackGuarantee.text" />
          &nbsp;
          <TermsOfUseLink />
        </S.Text>
      </S.Content>
      {isSecondaryVariant && <S.Icon src={GuaranteeBadge} alt="icon" />}
    </S.Wrapper>
  )
}
