/* eslint-disable max-lines */
import { OnboardingButtonText } from 'value-constants'

import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getStringWithoutTags } from 'helpers/stringHelper'

import {
  PaymentMethod,
  PaymentSystem,
  PurchasePageType,
} from 'modules/purchaseSubscription/constants'

import { TAnswer } from 'models/common.model'
import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import {
  EventLoggerInstanceName,
  Gender,
  LoginMethod,
  ScreenName,
} from 'root-constants'

export const enum Events {
  SESSION_STARTED = 'session_start',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  PRESALE_PAGE_SHOW = 'presale_page_show',
  PRESALE_PAGE_COMPLETED = 'presale_page_completed',
  PRESALE_PAGE_SCROLLED = 'presale_page_scrolled',
  SALE_SCREEN_SHOW = 'plans_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  NEED_HELP = 'need_help_tap',
  TRIAL_PRICE_PAGE_SHOWN = 'trial_price_page_show',
  TRIAL_PRICE_PAGE_COMPLETED_SHOWN = 'trial_price_page_completed',
  UPSELL_SUBSCRIPTION_TOGGLE_ENABLE = 'upsell_subscription_toggle_enable',
  UPSELL_SUBSCRIPTION_TOGGLE_DISABLE = 'upsell_subscription_toggle_disable',
  PURCHASE_SHOW = 'subs_purchase_show',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_STARTED_PAYPAL = 'subs_purchase_started_paypal',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  INAPP_PURCHASE_STARTED = 'inapp_purchase_started',
  INAPP_PURCHASE_COMPLETED = 'inapp_purchase_completed',
  INAPP_PURCHASE_FAILED = 'inapp_purchase_failed',
  PURCHASE_SCREEN_CLOSED = 'subs_purchase_screen_close',
  PLANS_PAGE_BUTTON_TAP = 'plans_page_button_tap',
  PLANS_PAGE_SCROLLED = 'plans_page_scrolled',
  CANCEL_OFFER_PAGE_SHOW = 'cancel_offer_page_show',
  CANCEL_OFFER_PAGE_ANSWER = 'cancel_offer_page_answer',
  CANCEL_OFFER_PAGE_CLOSE = 'cancel_offer_page_close',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  OTHER_SIGN_UP_OPTION_TAP = 'other_sign_up_option_tap',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  FINISHING_TOUCHES_SCREEN_SHOW = 'finishing_touches_screen_show',
  FINISHING_TOUCHES_SCREEN_CONFIRM = 'finishing_touches_screen_confirm',
  UPSELL_PURCHASE_SHOW = 'upsell_purchase_show',
  UPSELL_PURCHASE_CLOSE = 'upsell_purchase_close',
  PLANS_PAGE_VIDEO_TAP = 'plans_page_video_tap',
  UPSELL_COACHING_PURCHASE_SHOW = 'upsell_coaching_purchase_show',
  UPSELL_COACHING_PURCHASE_CLOSE = 'upsell_coaching_purchase_close',
  UPSELL_COACHING_PAGE_SCROLLED = 'upsell_coaching_page_scrolled',
  UPSELL_COACHING_BUTTON_TAP = 'upsell_coaching_button_tap',
  UPSELL_COACHING_POPUP_SHOW = 'upsell_coaching_popup_show',
  UPSELL_COACHING_POPUP_CLOSE = 'upsell_coaching_popup_close',
  UPSELL_SUBSCRIPTION_SELECTED = 'upsell_subscription_selected',
  COOKIES_BANNER_SHOW = 'cookies_banner_show',
  COOKIES_BANNER_SETTINGS_TAP = 'cookies_banner_settings_tap',
  COOKIES_ACCEPT_ALL_TAP = 'cookies_accept_all_tap',
  COOKIES_REJECT_ALL_TAP = 'cookies_reject_all_tap',
  COOKIES_SETTINGS_TOGGLE_ENABLE = 'cookies_settings_toggle_enable',
  COOKIES_SETTINGS_TOGGLE_DISABLE = 'cookies_settings_toggle_disable',
  COOKIES_SETTING_CONFIRM_CHOICES_TAP = 'cookies_setting_confirm_choices_tap',
  COOKIES_SETTINGS_SCREEN_CLOSE = 'cookies_settings_screen_close',
  COOKIES_SETTINGS_EXPAND_OPTION_TAP = 'cookies_settings_expand_option_tap',
  COOKIES_POLICY_TAP = 'cookies_policy_button_tap',
}
class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()

      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted(): void {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logFirstPageCompleted({ question, answers }: TQuestionPageParams): void {
    const eventProperty = {
      question: getStringWithoutTags(question),
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }
    this.logEventOrPushToQueue(
      Events.FIRST_PAGE_COMPLETED,
      eventProperty,
      false,
    )
  }

  logGrowthBookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    const event = Events.AB_SEGMENT
    const eventProperty = {
      growthbook_feature_key: experimentKey || 'experiment_id_not_set',
      ab_variant: getCurrentOnboardingVariant(variantId),
      ab_segment_name: variantName || 'no_name',
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({ question, answers }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED
    const eventProperty = {
      question: getStringWithoutTags(question),
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logSalePageShown({
    productIds,
    screenName = ScreenName.ONBOARDING,
  }: {
    productIds: string[]
    screenName?: ScreenName
  }): void {
    const event = Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: screenName,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logVideoReviewTap({ videoKey }: { videoKey: string }): void {
    const event = Events.PLANS_PAGE_VIDEO_TAP
    const eventProperty = {
      tap: videoKey,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logTermsOfUseClicked(): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event)
  }

  logPrivacyPolicyClicked(): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event)
  }

  logNeedHelpClicked() {
    const event = Events.NEED_HELP
    this.logEventOrPushToQueue(event)
  }

  logTrialPricePageShown(): void {
    const event = Events.TRIAL_PRICE_PAGE_SHOWN
    this.logEventOrPushToQueue(event)
  }

  logTrialPricePageCompleted({
    trialPrice,
    currency,
  }: {
    trialPrice: number
    currency: string
  }): void {
    const event = Events.TRIAL_PRICE_PAGE_COMPLETED_SHOWN
    const eventProperty = {
      trial_price: trialPrice,
      currency,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_SHOW
    this.logEventOrPushToQueue(event)
  }

  logLoginMethodSelected(eventProperty: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    const event = Events.ACCOUNT_CREATED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logOtherSignupOptionsShown(): void {
    this.logEventOrPushToQueue(Events.OTHER_SIGN_UP_OPTION_TAP)
  }

  logFinishingTouchesScreenShow(): void {
    const event = Events.FINISHING_TOUCHES_SCREEN_SHOW
    this.logEventOrPushToQueue(event)
  }

  logUpsellSubscriptionEnable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_ENABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellSubscriptionDisable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_DISABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logFinishingTouchesScreenConfirm({
    name,
    sex,
    age,
    primaryGoal,
    goalWeight,
  }: {
    name: TAnswer
    sex: Gender
    age: TAnswer
    primaryGoal: TAnswer
    goalWeight: TAnswer
  }): void {
    const event = Events.FINISHING_TOUCHES_SCREEN_CONFIRM
    const eventProperty = {
      name,
      sex,
      age,
      primary_goal: primaryGoal,
      goal_weight: goalWeight,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(callback: () => void): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(event, {}, true, callback)
  }

  // Purchase events
  logPresalePageShown(pageType: PurchasePageType): void {
    const event = Events.PRESALE_PAGE_SHOW
    const eventProperty = {
      page_type: pageType,
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logPresalePageCompleted(): void {
    const event = Events.PRESALE_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, {}, false)
  }

  logPresaleScroll(value: number, screenName: ScreenName) {
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${value}%`,
    }
    this.logEventOrPushToQueue(Events.PRESALE_PAGE_SCROLLED, eventProperty)
  }

  logPurchaseShown({
    productId,
    screenName = ScreenName.ONBOARDING,
    stripeAccountId,
    stripeAccountName,
  }: {
    productId: TProductId
    screenName?: ScreenName
    stripeAccountId: string
    stripeAccountName: string
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      stripe_account_id: stripeAccountId,
      stripe_account_name: stripeAccountName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPaymentMethodSelected(paymentMethod: PaymentMethod): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    screenName,
    event = Events.PURCHASE_STARTED,
    stripeAccountName,
    stripeAccountId,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod: PaymentMethod
    paymentSystem?: PaymentSystem
    event?: Events
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
  }): void {
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    discountApplied,
    screenName,
    stripeAccountName,
    stripeAccountId,
    event = Events.PURCHASE_COMPLETED,
  }: {
    productId: TProductId
    event?: Events
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod?: PaymentMethod
    discountApplied?: string
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    paymentSystem?: PaymentSystem
  }): void {
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
      ...(discountApplied && { discount_applied: discountApplied }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency },
    error: { description, type, code, declineCode },
    paymentMethod,
    screenName,
    event = Events.PURCHASE_FAILED,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    error: {
      type: string
      description?: string
      code?: string
      declineCode?: string
    }
    paymentMethod?: PaymentMethod
    event?: Events
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    paymentSystem?: PaymentSystem
  }): void {
    const eventProperty = {
      trial,
      price,
      currency,
      error_type: type,
      decline_code: declineCode,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      ...(paymentSystem && { payment_system: paymentSystem }),
      product_id: productId,
      screen_name: screenName,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStartedPayPal({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    screenName,
    paymentSystem,
    accountId,
    accountName,
  }: {
    productId: TProductId
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    paymentMethod: PaymentMethod
    screenName: ScreenName
    paymentSystem: PaymentSystem
    accountId?: string
    accountName?: string
  }): void {
    const event = Events.PURCHASE_STARTED_PAYPAL
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.PAYPAL,
      payment_system: paymentSystem,
      ...(accountName && { stripe_account_name: accountName }),
      ...(accountId && { stripe_account_id: accountId }),
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPurchaseClose(screenName: ScreenName): void {
    const event = Events.UPSELL_PURCHASE_CLOSE
    const eventProperty = {
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPurchaseShown(screenName: ScreenName): void {
    const event = Events.UPSELL_PURCHASE_SHOW
    const eventProperty = {
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
  }: {
    productId: TProductId
    screenName: ScreenName
  }): void {
    const event = Events.PURCHASE_SCREEN_CLOSED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPlansPageButtonTapped({
    screenName,
    buttonNumber,
    buttonText,
  }: {
    screenName: ScreenName
    buttonNumber: number
    buttonText: OnboardingButtonText
  }) {
    const eventProperty = {
      screen_name: screenName,
      button_number: buttonNumber,
      button_text: buttonText,
    }
    this.logEventOrPushToQueue(Events.PLANS_PAGE_BUTTON_TAP, eventProperty)
  }

  logUpsellCoachingButtonTapped({
    screenName,
    buttonNumber,
  }: {
    screenName: ScreenName
    buttonNumber: number
  }) {
    const eventProperty = {
      screen_name: screenName,
      button_number: buttonNumber,
    }

    this.logEventOrPushToQueue(Events.UPSELL_COACHING_BUTTON_TAP, eventProperty)
  }

  logUpsellCoachingPurchaseShown(screenName: ScreenName) {
    const eventProperty = {
      screen_name: screenName,
    }

    this.logEventOrPushToQueue(
      Events.UPSELL_COACHING_PURCHASE_SHOW,
      eventProperty,
    )
  }

  logUpsellCoachingPurchaseClosed(screenName: ScreenName) {
    const eventProperty = {
      screen_name: screenName,
    }

    this.logEventOrPushToQueue(
      Events.UPSELL_COACHING_PURCHASE_CLOSE,
      eventProperty,
    )
  }

  logUpsellCoachingPopupShown() {
    const event = Events.UPSELL_COACHING_POPUP_SHOW

    this.logEventOrPushToQueue(event)
  }

  logUpsellCoachingPopupClosed() {
    const event = Events.UPSELL_COACHING_POPUP_CLOSE

    this.logEventOrPushToQueue(event)
  }

  logUpsellSubscriptionSelected(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_SELECTED
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellCoachingPageScrolled(value: number, screenName: ScreenName) {
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${value}%`,
    }

    this.logEventOrPushToQueue(
      Events.UPSELL_COACHING_PAGE_SCROLLED,
      eventProperty,
    )
  }

  logScrollToBottom(value: number, screenName: ScreenName) {
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${value}%`,
    }
    this.logEventOrPushToQueue(Events.PLANS_PAGE_SCROLLED, eventProperty)
  }

  logCancelOfferPageShown(): void {
    const event = Events.CANCEL_OFFER_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logCancelOfferPageAnswer(answer: string): void {
    const event = Events.CANCEL_OFFER_PAGE_ANSWER
    const eventProperty = {
      answer,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logCancelOfferPageClosed(): void {
    const event = Events.CANCEL_OFFER_PAGE_CLOSE
    this.logEventOrPushToQueue(event)
  }

  logCookiesConsentShown(): void {
    const event = Events.COOKIES_BANNER_SHOW

    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentSettingsTap(): void {
    const event = Events.COOKIES_BANNER_SETTINGS_TAP

    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentAcceptAllTap(source: string): void {
    const event = Events.COOKIES_ACCEPT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentRejectAllTap(source: string): void {
    const event = Events.COOKIES_REJECT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleEnable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_ENABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleDisable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_DISABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsConfirmChoice(options: string): void {
    const event = Events.COOKIES_SETTING_CONFIRM_CHOICES_TAP
    const eventProperty = { allowed_options: options }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsScreenClose(): void {
    const event = Events.COOKIES_SETTINGS_SCREEN_CLOSE

    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentExpandOptionTap(option: string): void {
    const event = Events.COOKIES_SETTINGS_EXPAND_OPTION_TAP
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiePolicyClicked(): void {
    const event = Events.COOKIES_POLICY_TAP
    this.logEventOrPushToQueue(event, {}, false)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
    isAmplitudeEvent = true,
    callback?: () => void,
  ): void {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        isAmplitudeEvent,
        eventProperty,
        callback,
      })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    if (this.loggers) {
      const eventLoggers = [...this.loggers.values()]

      eventLoggers.forEach((logger, index, array) => {
        index === array.length - 1
          ? logger.log(event, eventProperty, callback)
          : logger.log(event, eventProperty)
      })
    }
  }
}

export const eventLogger = new EventLoggerService()
