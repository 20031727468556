import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IMG_SRC_MAP } from 'pages/questions/UniquelyTailoredPlan/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { StyledImageWithText as S } from 'styles/ImageWithText.styles'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

export const UniquelyTailoredPlanV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const genderValue = useSelector(selectCurrentUserGender)

  const imgSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMG_SRC_MAP[genderValue]}.png`,
    [genderValue],
  )

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.kitUniquelyTailoredPlanV1.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.ImageContainer>
        <img src={imgSrc} alt="uniquely-tailored-plan" />
      </S.ImageContainer>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.kitUniquelyTailoredPlanV1.title')}
      </PageTitle>
      <S.Info>{t('onboarding.kitUniquelyTailoredPlanV1.info')}</S.Info>
    </KitPageContainer>
  )
}
