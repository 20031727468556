import styled from 'styled-components'

import { Color } from 'root-constants'

import { TPageTitleProps } from './PageTitle'

export const StyledPageTitle = {
  PageTitle: styled.h1<TPageTitleProps>`
    color: ${Color.GREY_100};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? `${marginBottom}px` : 0};
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
    text-align: ${({ textAlign }) => textAlign};
  `,
}
