import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PriceValue } from 'components/PriceValue'

import {
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectScreenName,
} from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePaymentStore } from 'hooks/usePaymentStore'

import { PurchaseProduct } from 'modules/purchaseSubscription/constants'
import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'
import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { getPriceBeforeDiscount } from 'modules/purchaseSubscription/helpers/getRecalculatedPrice'

import {
  Cohort,
  Color,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  ScreenName,
} from 'root-constants'

import { StyledCheckoutSummaryV2 as S } from '../../lib/styles'

type TProps = {
  className?: string
}

export const TrialCheckoutSummary: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const screenName = useSelector(selectScreenName)
  const {
    currency,
    fullPrice,
    trialCurrentPrice,
    trialPriceBeforeDiscount,
    product,
    discountAmount,
    paywallDiscountPercentage,
  } = usePaymentStore()

  const currencyOptions = getCurrencyOptions(currency)

  const hasVatInfo = useVatInfo()

  const hasDynamicDiscount = useMemo(
    () =>
      cohortToUse === Cohort.MF_INTRO_SALE &&
      screenName !== ScreenName.CANCEL_OFFER,
    [cohortToUse, screenName],
  )

  const discount = (() => {
    if (hasDynamicDiscount) {
      return dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT
    }

    return paywallDiscountPercentage
  })()

  const getPriceBeforeDiscountValue = () => {
    if (!hasDynamicDiscount) {
      return product === PurchaseProduct.SEVEN_DAY
        ? trialPriceBeforeDiscount.toFixed(2)
        : fullPrice.toFixed(2)
    }

    return product === PurchaseProduct.SEVEN_DAY
      ? getPriceBeforeDiscount(
          Number(trialPriceBeforeDiscount.toFixed(2)),
          discount,
        )
      : getPriceBeforeDiscount(Number(trialCurrentPrice.toFixed(2)), discount)
  }

  const getDiscountLabel = () => {
    if (!hasDynamicDiscount) {
      return t('checkout.checkoutSummaryV2.discountLabel', { value: discount })
    }

    const discountValue = t('commonComponents.percentFormat', {
      count: discount,
    })
    const discountLabel = dynamicDiscount
      ? dynamicDiscount?.checkout
      : t('commonComponents.dynamicDiscountBanner.checkoutDiscountName')

    return `${discountValue} ${discountLabel}`
  }

  const getSavedMoneyAmount = () => {
    if (hasDynamicDiscount) {
      return (
        Number(getPriceBeforeDiscountValue()) - trialCurrentPrice
      ).toFixed(2)
    }

    return discountAmount
  }

  return (
    <div className={className}>
      <S.DescriptionContainer>
        <S.PlanLabel>{t('checkout.checkoutSummaryV2.planLabel')}</S.PlanLabel>
        <S.PlanPrice>
          <PriceValue
            value={getPriceBeforeDiscountValue()}
            formatOptions={currencyOptions}
          />
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.DescriptionContainer>
        <S.PlanLabel>{getDiscountLabel()}</S.PlanLabel>
        <S.PlanPrice color={Color.GREEN_300}>
          -
          <PriceValue
            value={getSavedMoneyAmount()}
            formatOptions={currencyOptions}
          />
        </S.PlanPrice>
      </S.DescriptionContainer>
      <S.TotalContainer isComplex={isComplexCurrencySymbol(currency)}>
        <S.TotalLabel>
          {t('checkout.checkoutSummaryV2.totalLabel', {
            includingVat: hasVatInfo ? t('purchase.includingVat') : '',
          })}
        </S.TotalLabel>
        <S.TotalPrice>
          <span>
            <PriceValue
              value={trialCurrentPrice}
              formatOptions={currencyOptions}
            />
          </span>
        </S.TotalPrice>
      </S.TotalContainer>
      <S.SaveContainer>
        <Trans
          i18nKey="checkout.checkoutSummaryV2.saveMoney"
          values={{
            ...currencyOptions,
            price: getSavedMoneyAmount(),
            discount,
            currency,
          }}
        />
      </S.SaveContainer>
    </div>
  )
}
