import React from 'react'
import { Trans } from 'react-i18next'

import {
  ACTIVITY_DURING_WORK_MAP,
  ACTIVITY_LEVEL_MAP,
  BMI_VALUE_TO_MARKUP_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import {
  selectActivityDuringWork,
  selectCurrentUserGoalWeight,
  selectCurrentUserPrimaryGoal,
  selectWorkout,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'

import cupBullet from 'assets/images/bullet-cup.svg'
import personHandsUpBullet from 'assets/images/bullet-person-hands-up.svg'
import runningPersonBullet from 'assets/images/bullet-running-person.svg'
import weightBullet from 'assets/images/bullet-weight-2.svg'

import { StyledPersonalizedPlan as S } from './PersonalizedPlan.styles'

type TProps = {
  className?: string
  withTitle?: boolean
}

const useSelectors = () =>
  useBulkSelector({
    primaryGoal: selectCurrentUserPrimaryGoal,
    activityDuringWork: selectActivityDuringWork,
    workoutValue: selectWorkout,
    goalWeight: selectCurrentUserGoalWeight,
  })

export const PersonalizedPlan: React.FC<TProps> = ({
  className,
  withTitle = true,
}) => {
  const { primaryGoal, goalWeight, activityDuringWork, workoutValue } =
    useSelectors()
  const { title } = useDynamicPaywallConfig()
  const { measuringSystemLabel } = useWeightMeasurementSystem()
  const { value } = useUsersBmi().currentBMI.bmiValue

  const weighLabel = `${goalWeight} ${measuringSystemLabel}`
  const bmiValueKey = BMI_VALUE_TO_MARKUP_MAP[value]
  const goalKey = PRIMARY_GOAL_MAP[primaryGoal]
  const activityKey =
    ACTIVITY_DURING_WORK_MAP[activityDuringWork] ||
    ACTIVITY_LEVEL_MAP[workoutValue]

  return (
    <div className={className}>
      {withTitle ? (
        <S.Title>
          {title || <Trans i18nKey="purchase.personalizedPlan.title" />}
        </S.Title>
      ) : null}
      <S.PlanContainer>
        <S.PlanRow>
          <S.PlanItem bullet={cupBullet} hasPaddingBottom>
            <S.ItemTitle>
              <Trans i18nKey="purchase.personalizedPlan.goal" />
            </S.ItemTitle>
            <S.ItemValue>
              <Trans i18nKey={goalKey} />
            </S.ItemValue>
          </S.PlanItem>
          <S.PlanItem bullet={weightBullet} hasPaddingBottom>
            <S.ItemTitle>
              <Trans i18nKey="purchase.personalizedPlan.targetWeight" />
            </S.ItemTitle>
            <S.ItemValue>{weighLabel}</S.ItemValue>
          </S.PlanItem>
        </S.PlanRow>
        <S.PlanRow>
          <S.PlanItem bullet={personHandsUpBullet} hasPaddingTop>
            <S.ItemTitle>
              <Trans i18nKey="purchase.personalizedPlan.bmiCategory" />
            </S.ItemTitle>
            <S.ItemValue>
              <Trans i18nKey={bmiValueKey} />
            </S.ItemValue>
          </S.PlanItem>
          <S.PlanItem bullet={runningPersonBullet} hasPaddingTop>
            <S.ItemTitle>
              <Trans i18nKey="purchase.personalizedPlan.activityLevel" />
            </S.ItemTitle>
            <S.ItemValue>
              <Trans i18nKey={activityKey} />
            </S.ItemValue>
          </S.PlanItem>
        </S.PlanRow>
      </S.PlanContainer>
    </div>
  )
}
