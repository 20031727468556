import React, { Dispatch, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'
import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { selectCurrency } from 'modules/purchaseSubscription/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { Color, UpsellProduct } from 'root-constants'

import { UpsellSubscriptionItemSwitchers } from '../../../components'
import { MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS } from '../../../constants'
import { StyledUpsellSubscriptionBlock as S } from './UpsellSubscriptionBlock.styles'

type TPageProps = {
  onChangeUpsellItems: Dispatch<SetStateAction<string[]>>
  onCreateInApp: () => void
  onCreateInAppBundle: () => void
  onSkip: () => void
  upsellItems: string[]
}

const useSelectors = () =>
  useBulkSelector({
    subscriptions: selectSubscriptionList,
    currency: selectCurrency,
    email: selectUserOnboardingEmail,
  })

export const UpsellSubscriptionBlock: React.FC<TPageProps> = ({
  onChangeUpsellItems,
  onCreateInApp,
  onCreateInAppBundle,
  onSkip,
  upsellItems,
}) => {
  const { t } = useTranslation()
  const { currency, email, subscriptions } = useSelectors()

  const isComplexCurrency = isComplexCurrencySymbol(currency)

  const currencyOptions = getCurrencyOptions(currency)
  const isSecondaryButtonVisible =
    upsellItems.length < MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS

  const handleChange = ({ target: { value, checked } }) => {
    if (checked) {
      eventLogger.logUpsellSubscriptionEnable(value)
      onChangeUpsellItems((prev) => [...prev, value])
      return
    }

    eventLogger.logUpsellSubscriptionDisable(value)
    onChangeUpsellItems((prev) => prev.filter((item) => item !== value))
  }

  return (
    <>
      {subscriptions.map((item) =>
        item.product === UpsellProduct.BOTH ? (
          <S.Discount key={item.id}>
            <S.DiscountBadge>{t('upsell.discount.badge')}</S.DiscountBadge>
            <S.DiscountText isComplexCurrency={isComplexCurrency}>
              <Trans
                i18nKey="upsell.discount.text"
                values={{
                  price: item.trialPrices.fullPrice,
                  currency,
                  ...currencyOptions,
                }}
              />
            </S.DiscountText>
          </S.Discount>
        ) : (
          <UpsellSubscriptionItemSwitchers
            key={item.id}
            value={item.product}
            isChecked={upsellItems.includes(item.product)}
            subscription={item}
            onChange={handleChange}
          />
        ),
      )}

      <Button
        width="100%"
        backgroundColor={Color.GREEN_400}
        color={Color.WHITE}
        fontSize="17px"
        fontWeight="700"
        lineHeight="24px"
        padding="16px"
        borderRadius="30px"
        margin="24px 0 16px 0"
        onClick={onCreateInAppBundle}
      >
        {t('upsell.actions.acceptAll')}
      </Button>

      {isSecondaryButtonVisible && (
        <Button
          width="100%"
          backgroundColor={Color.GREY_1000}
          color={Color.WHITE}
          fontSize="17px"
          fontWeight="700"
          lineHeight="24px"
          padding="16px"
          borderRadius="30px"
          margin="0 0 16px 0"
          onClick={!upsellItems.length ? onSkip : onCreateInApp}
        >
          {!upsellItems.length
            ? t('upsell.actions.withoutCourses')
            : t('upsell.actions.acceptSelected')}
        </Button>
      )}

      <S.EmailWrapper>
        <S.Email>
          <Trans i18nKey="upsell.email" values={{ email }} />
        </S.Email>
      </S.EmailWrapper>
    </>
  )
}
