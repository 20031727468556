import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import { i18nFormatters } from 'helpers/i18nFormatters'

import af from 'assets/i18n/af.json'
import de from 'assets/i18n/de.json'
import en from 'assets/i18n/en.json'
import es from 'assets/i18n/es.json'
import fr from 'assets/i18n/fr.json'
import ja from 'assets/i18n/ja.json'
import pl from 'assets/i18n/pl.json'
import pt from 'assets/i18n/pt.json'

i18n.use(initReactI18next).init({
  resources: {
    en,
    es,
    af,
    pt,
    fr,
    de,
    ja,
    pl,
  },
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

i18n.services.formatter?.add('number', i18nFormatters.number)
i18n.services.formatter?.add('currency', i18nFormatters.currency)
i18n.services.formatter?.add('percent', i18nFormatters.percent)
i18n.services.formatter?.add('datetime', i18nFormatters.datetime)
