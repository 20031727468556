import React, { SyntheticEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { PageTitle } from 'components/PageTitle'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { IInputState, TPageProps } from 'models/common.model'

import alertIcon from 'assets/images/sprite/alert-circle.svg'

import { CustomPageId } from 'page-constants'
import {
  FloatNumberRegex,
  INITIAL_INPUT_VALUE,
  Language,
  MIN_MAX_WEIGHT,
} from 'root-constants'

import { StyledCurrentWeightV1 as S } from './CurrentWeightV1.styles'

const T_PREFIX = 'onboarding.currentWeight'

export const CurrentWeightV1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const [weight, setWeight] = useState<IInputState>({
    ...INITIAL_INPUT_VALUE,
  })
  const [isFocused, setIsFocused] = useState(false)

  const { t } = useTranslation()
  const { measuringSystemLabel, isMetricSelected, measurementSystem } =
    useWeightMeasurementSystem()

  const handleContinue = useNextStep({
    pageId: CustomPageId.CURRENT_WEIGHT,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleContinue(+weight.value, measurementSystem)
  }

  const handleChange = ({ target: { value, validity } }) => {
    if (!value || FloatNumberRegex.test(value)) {
      setWeight((prevState) => ({
        ...prevState,
        value,
        isValid: validity.valid,
      }))
    }
  }

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <PageTitle marginBottom={24}>
          <Trans i18nKey={`${T_PREFIX}.title`} />
        </PageTitle>
        <S.WeightLabel>{measuringSystemLabel}</S.WeightLabel>
        <S.Actions>
          <S.InputWrapper>
            <S.WeightInput
              type="number"
              value={weight.value}
              isContentCentered
              isValid={weight.isValid}
              min={
                isMetricSelected
                  ? MIN_MAX_WEIGHT.MIN_WEIGHT_KG
                  : MIN_MAX_WEIGHT.MIN_WEIGHT_LB
              }
              max={
                isMetricSelected
                  ? MIN_MAX_WEIGHT.MAX_WEIGHT_KG
                  : MIN_MAX_WEIGHT.MAX_WEIGHT_LB
              }
              lang="en"
              step="1"
              allowFloatNumbers={false}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {!isFocused && !weight.value && <S.Placeholder />}
            <S.Suffix>{measuringSystemLabel}</S.Suffix>
          </S.InputWrapper>
          {weight.value && !weight.isValid && (
            <S.ErrorMessage>
              <SvgImage svg={alertIcon} />
              <span>
                <Trans i18nKey={`${T_PREFIX}.errorMessage`} />
              </span>
            </S.ErrorMessage>
          )}
        </S.Actions>

        <NavigationButton type="submit" disabled={!weight.isValid}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </Container>
    </form>
  )
}
