import React from 'react'

import { CheckboxOptionsView } from 'views/CheckboxOptionsView'

import { TPageProps } from 'models/common.model'

import { CUSTOM_OPTIONS, OPTIONS, TRANSLATION_FAMILY } from './constants'

export const EventV1: React.FC<TPageProps> = (props) => (
  <CheckboxOptionsView
    {...props}
    options={[...OPTIONS, ...CUSTOM_OPTIONS.options]}
    translationFamily={TRANSLATION_FAMILY}
    customOptions={CUSTOM_OPTIONS}
  />
)
