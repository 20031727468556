import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { usePageInfo } from 'contexts/PageInfoProvider'
import { Button, buttonTheme } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { setSelectedSubscriptionAction } from 'modules/purchaseSubscription/redux/actions/common'
import {
  MAKE_UPSELL,
  createInAppAction,
} from 'modules/purchaseSubscription/redux/actions/upsell'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
  UpsellProduct,
} from 'root-constants'

import { UpsellGuides, UpsellSubscriptionBlock } from '../lib/components'
import { MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS } from '../lib/constants'
import { StyledUpsellPaywallV1 as S } from './UpsellPaywallV1.styles'

const NUMBER_OF_SINGLE_SUBSCRIPTION = 1

const useSelectors = () =>
  useBulkSelector({
    uuid: selectUUID,
    subscriptions: selectSubscriptionList,
    fetchingActionsList: selectActionList,
  })

export const UpsellPaywallV1: React.FC<TPageProps> = ({ nextPagePath }) => {
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const { t } = useTranslation()
  const { currentPageId, activeUpsellPageIds } = usePageInfo()
  const { subscriptions, uuid, fetchingActionsList } = useSelectors()

  const [upsellItems, setUpsellItems] = useState<string[]>([
    UpsellProduct.FITNESS,
  ])

  const bundleSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.BOTH,
  )

  const isUpsellInProgress = fetchingActionsList?.includes(MAKE_UPSELL)
  const arePricesReady = !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST)

  const isSingleUpsellItemSelected =
    upsellItems.length === NUMBER_OF_SINGLE_SUBSCRIPTION
  const areBothUpsellItemsSelected =
    upsellItems.length === MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS

  const createInApp = async () => {
    dispatch(
      sendUserConfigAction({
        available_upsell_pages: activeUpsellPageIds.filter(
          (page) => page !== currentPageId,
        ),
      }),
    )
    await dispatch(createInAppAction(ScreenName.UPSELL_SWITCHER))
    replaceHistory(nextPagePath || `${PageId.FINISHING_TOUCHES}${search}`)
    dispatch(getUserStatusAction(uuid))
  }

  const createInAppBundle = () => {
    if (!bundleSubscription) return

    dispatch(setSelectedSubscriptionAction(bundleSubscription))
    createInApp()
  }

  const handleSkip = () => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)

    const hasOtherUpsellPages = activeUpsellPageIds.some(
      (id) => id !== currentPageId,
    )
    if (!hasOtherUpsellPages) {
      goTo({ pathname: PageId.FINISHING_TOUCHES, search })
      return
    }

    goTo(nextPagePath || `${PageId.FINISHING_TOUCHES}${search}`)
  }

  useLayoutEffect(() => {
    const tags = [
      SubscriptionTags.NO_TAX,
      SubscriptionTags.INAPP_FITNESS_SELFCARE,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.ONE_TIME, tags))
  }, [dispatch])

  useLayoutEffect(() => {
    return () => {
      dispatch(setSubscriptionListAction([]))
    }
  }, [dispatch])

  useEffect(() => {
    eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)
  }, [])

  useEffect(() => {
    if (
      (areBothUpsellItemsSelected || !upsellItems.length) &&
      bundleSubscription
    ) {
      dispatch(setSelectedSubscriptionAction(bundleSubscription))
      return
    }

    if (isSingleUpsellItemSelected) {
      const [productId] = upsellItems
      const checkedSubscription = subscriptions.find(
        (item) => item.product === productId,
      )
      checkedSubscription &&
        dispatch(setSelectedSubscriptionAction(checkedSubscription))
    }
  }, [
    areBothUpsellItemsSelected,
    bundleSubscription,
    dispatch,
    isSingleUpsellItemSelected,
    subscriptions,
    upsellItems,
  ])

  return (
    <>
      {arePricesReady && (
        <KitPageContainer paddingTop={0}>
          <S.Title>{t('upsell.title')}</S.Title>
          <S.Subtitle>{t('upsell.subTitle')}</S.Subtitle>
          <UpsellSubscriptionBlock
            onChangeUpsellItems={setUpsellItems}
            onCreateInApp={createInApp}
            onCreateInAppBundle={createInAppBundle}
            onSkip={handleSkip}
            upsellItems={upsellItems}
          />
          <S.UpsellBenefits />
          <UpsellGuides />
          <S.ButtonContainer>
            <Button
              width="100%"
              type="submit"
              height="auto"
              minHeight="56px"
              margin="0 auto 24px"
              theme={buttonTheme.NUTRIMATE_PRIMARY}
              onClick={createInApp}
            >
              {isSingleUpsellItemSelected
                ? t`upsell.addToMyProgram`
                : t`upsell.addAllToMyProgram`}
            </Button>
            <S.SkipButton onClick={handleSkip}>
              {t('upsell.noThanksLink')}
            </S.SkipButton>
          </S.ButtonContainer>
        </KitPageContainer>
      )}
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </>
  )
}
