import React, { FC, useState } from 'react'
import { Trans } from 'react-i18next'

import { FlagContainer } from 'components/FlagContainer'
import { PriceValue } from 'components/PriceValue'

import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { Color } from 'root-constants'

import { useSubscriptionItem } from '../../hooks/useSubscriptionItem'
import { StyledKitSubscriptionItemV1 as S } from './KitSubscriptionItemV2.styles'
import { ACCORDION_INFO } from './constant'

export const KitSubscriptionItemV2: FC<TSubscriptionItemProps> = ({
  subscription,
  isSelected,
}) => {
  const { id, isDefault } = subscription
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const {
    isSubscriptionTrial,
    noTrialCurrentPrice,
    oldPrice,
    oldPricePerDay,
    pricePerDay,
    trialCurrentPrice,
    className,
    periodName,
    isComplexCurrency,
    isCompactVariant,
    currencyOptions,
  } = useSubscriptionItem(subscription)

  const toggleAccordion = () => setIsAccordionOpen((prev) => !prev)

  return (
    <S.PlanContainer key={id} isSelected={isSelected} className={className}>
      <S.Static isSelected={isSelected} className={className}>
        <S.PlanColumn>
          <S.PlanPeriod
            isSelected={isSelected}
            isCompactVariant={isCompactVariant}
          >
            {periodName}
          </S.PlanPeriod>
          <S.PlanPrice className={className}>
            {isSubscriptionTrial ? (
              <>
                <S.PreviousPrice className={className}>
                  <PriceValue
                    value={oldPrice}
                    formatOptions={currencyOptions}
                  />
                </S.PreviousPrice>
                <S.CurrentPrice className={className}>
                  <PriceValue
                    value={trialCurrentPrice}
                    formatOptions={currencyOptions}
                  />
                </S.CurrentPrice>
                {!isComplexCurrency && oldPricePerDay && (
                  <S.PreviousDayPrice
                    className={className}
                    isSelected={isSelected}
                  >
                    <PriceValue
                      value={oldPricePerDay}
                      formatOptions={currencyOptions}
                    />
                  </S.PreviousDayPrice>
                )}
              </>
            ) : (
              <S.CurrentPrice className={className}>
                <PriceValue
                  value={noTrialCurrentPrice}
                  formatOptions={currencyOptions}
                />
              </S.CurrentPrice>
            )}
          </S.PlanPrice>
        </S.PlanColumn>

        <S.PlanColumn>
          <FlagContainer
            backgroundColor={isSelected ? Color.GREEN_300 : Color.GREY_130}
            clipPath="polygon(22% 0%, 100% 0%, 100% 100%, 22% 100%, -1% 50%)"
          >
            <S.DayPriceContainer
              className={className}
              isSelected={isSelected}
              isCompactVariant={isCompactVariant}
            >
              {!isComplexCurrency && pricePerDay && (
                <S.CurrentDayPrice className={className}>
                  <PriceValue
                    value={pricePerDay}
                    formatOptions={currencyOptions}
                  />
                </S.CurrentDayPrice>
              )}
              {isComplexCurrency && (
                <>
                  {isSubscriptionTrial && (
                    <S.PreviousDayPrice
                      className={className}
                      isSelected={isSelected}
                    >
                      <PriceValue
                        value={pricePerDay}
                        formatOptions={currencyOptions}
                      />
                    </S.PreviousDayPrice>
                  )}
                  <S.CurrentDayPrice className={className}>
                    <PriceValue
                      value={pricePerDay}
                      formatOptions={currencyOptions}
                    />
                  </S.CurrentDayPrice>
                </>
              )}
              <S.DayPricePeriod className={className}>
                <Trans i18nKey="purchase.subscriptionV2.perDay" />
              </S.DayPricePeriod>
            </S.DayPriceContainer>
          </FlagContainer>
        </S.PlanColumn>
      </S.Static>
      {isDefault && (
        <S.PopularBadge isSelected={isSelected}>
          <Trans i18nKey="purchase.subscriptionV2.mostPopularBadge" />
        </S.PopularBadge>
      )}

      <S.AccordionContent
        isOpen={isAccordionOpen}
        className={isAccordionOpen ? 'open' : 'closed'}
      >
        {ACCORDION_INFO.map(({ icon, text }) => (
          <S.ProsItem key={text}>
            <img src={icon} alt="icon" />
            <Trans i18nKey={text} />
          </S.ProsItem>
        ))}
      </S.AccordionContent>

      <S.LearnMoreButton onClick={toggleAccordion} isOpen={isAccordionOpen}>
        <Trans
          i18nKey={
            isAccordionOpen ? 'actions.hideDetails' : 'actions.learnMore'
          }
        />
      </S.LearnMoreButton>
    </S.PlanContainer>
  )
}
