import { DailyWaterValue } from 'value-constants'

import coffeeIcon from 'assets/images/cup-of-coffee.png'
import okIcon from 'assets/images/ok-fingers.png'
import peaceIcon from 'assets/images/peace-emoji.png'
import rockIcon from 'assets/images/rock-hand.png'

export const DAILY_WATER_DISCLAIMER = {
  [DailyWaterValue.COFFEE_OR_TEE]:
    'onboarding.dailyWater.optionsV1.teaOrCoffee',
  [DailyWaterValue.TWO_GLASSES]: 'onboarding.dailyWater.optionsV1.twoGlasses',
  [DailyWaterValue.TWO_SIX_GLASSES]:
    'onboarding.dailyWater.optionsV1.twoSixGlasses',
  [DailyWaterValue.MORE_THAN_SIX_GLASSES]:
    'onboarding.dailyWater.optionsV1.moreThanSixGlasses',
} as const

export const TRANSLATION_FAMILY = 'onboarding.dailyWaterV3'

export const OPTIONS = [
  {
    key: 'coffeeOrTee',
    value: DailyWaterValue.COFFEE_OR_TEE,
    iconSrc: coffeeIcon,
  },
  {
    key: 'twoGlasses',
    value: DailyWaterValue.TWO_GLASSES,
    iconSrc: peaceIcon,
  },
  {
    key: 'twoSixGlasses',
    value: DailyWaterValue.TWO_SIX_GLASSES,
    iconSrc: okIcon,
  },
  {
    key: 'moreThanSixGlasses',
    value: DailyWaterValue.MORE_THAN_SIX_GLASSES,
    iconSrc: rockIcon,
  },
]

export const OPTIONS_V2 = [
  {
    value: 'coffee_or_tea',
    textPath: 'onboarding.dailyWater.optionsV1.teaOrCoffee',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/cup.png',
  },
  {
    value: 'about_2_glasses',
    textPath: 'onboarding.dailyWater.optionsV1.twoGlasses',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/glass.png',
  },
  {
    value: '2_to_6_glasses',
    textPath: 'onboarding.dailyWater.optionsV1.twoSixGlasses',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/bottle.png',
  },
  {
    value: 'more_than_6_glasses',
    textPath: 'onboarding.dailyWater.optionsV1.moreThanSixGlasses',
    imageUrl:
      'https://cdn-prod-unified-content.gismart.xyz/2c9373c40f481dcc4fd97a742c37cde6c308d26d827f41f1dc6509b2d3576cb2/nutrimate/onboarding-option-icons/big-bottle.png',
  },
]
