import { ReachIdealWeightValue } from 'value-constants'

const T_PREFIX_V1 = 'onboarding.kitReachIdealWeightV1.options'

export const ANSWER_OPTIONS_KIT_V1 = [
  {
    value: ReachIdealWeightValue.SOCIAL_MEDIA,
    textPath: `${T_PREFIX_V1}.postBeforeAfter`,
  },
  {
    value: ReachIdealWeightValue.NEW_CLOTHES,
    textPath: `${T_PREFIX_V1}.newClothes`,
  },
  {
    value: ReachIdealWeightValue.TAKE_PICTURES,
    textPath: `${T_PREFIX_V1}.takePictures`,
  },
  {
    value: ReachIdealWeightValue.TREAT_MYSELF,
    textPath: `${T_PREFIX_V1}.treatMyself`,
  },
]

const T_PREFIX_V2 = 'onboarding.kitReachIdealWeightV2.options'

export const ANSWER_OPTIONS_KIT_V2 = [
  {
    value: ReachIdealWeightValue.CHANGE_HAIR,
    textPath: `${T_PREFIX_V2}.changeHair`,
  },
  {
    value: ReachIdealWeightValue.PARTY,
    textPath: `${T_PREFIX_V2}.party`,
  },
  {
    value: ReachIdealWeightValue.SHARE_JOURNEY,
    textPath: `${T_PREFIX_V2}.shareJourney`,
  },
  {
    value: ReachIdealWeightValue.TELL_PEOPLE,
    textPath: `${T_PREFIX_V2}.tellSecrets`,
  },
]
