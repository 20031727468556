import React, { FC, InputHTMLAttributes, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'

import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'

import { selectLanguage } from 'root-redux/selects/common'

import { Language } from 'root-constants'

import { StyledCustomDatePickerV2 as S } from './CustomDatePickerV2.styles'

type TProps = {
  onChange: (date: Date) => void
  selectedDate: Date | null
  minDate: Date
  toggleDatePickerVisibility: () => void
}

type TInputProps = InputHTMLAttributes<HTMLInputElement>

export const CustomDatePickerV2 = ({
  onChange,
  selectedDate,
  minDate,
  toggleDatePickerVisibility,
  ...props
}: TProps): React.JSX.Element => {
  const language = useSelector(selectLanguage)
  const dateFormat = language === Language.JA ? 'yyyy年M月d日' : 'dd MMM yyyy'

  const handleDateFieldClick = (onClick) => {
    toggleDatePickerVisibility()
    onClick()
  }

  const handleOutsideDatePickerClick = () => {
    toggleDatePickerVisibility()
  }

  const CustomInput: FC = forwardRef<HTMLInputElement, TInputProps>(
    (inputProps, ref) => (
      <S.WrapperDataField
        onClick={() => {
          handleDateFieldClick(inputProps.onClick)
        }}
      >
        <S.DateField ref={ref} {...inputProps}>
          {inputProps.value}
        </S.DateField>
        <S.CalendarIcon />
      </S.WrapperDataField>
    ),
  )

  return (
    <S.Wrapper>
      <DatePicker
        customInput={<CustomInput />}
        showIcon
        onCalendarClose={handleOutsideDatePickerClick}
        selected={selectedDate}
        onChange={onChange}
        minDate={minDate}
        dateFormat={dateFormat}
        formatWeekDay={(date) => date.substring(0, 3)}
        popperPlacement="top"
        locale={language}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -64],
            },
          },
        ]}
        {...props}
      />
    </S.Wrapper>
  )
}
