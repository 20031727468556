import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { PurchaseProduct } from 'modules/purchaseSubscription/constants'
import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { selectCurrency } from 'modules/purchaseSubscription/redux/selects'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { StyledPurchaseDisclaimer as S } from './PurchaseDisclaimer.styles'
import { NO_TRIAL_DISCLAIMER_MAP, TRIAL_DISCLAIMER_MAP } from './constants'

type TProps = {
  className?: string
  selectedSubscription: TSubscriptionItemProps['subscription']
}

const SEVEN_DAYS = 7

export const PurchaseDisclaimer: React.FC<TProps> = ({
  className,
  selectedSubscription,
}) => {
  const { mainPrices, trialPrices } = selectedSubscription

  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const hasVatInfo = useVatInfo()

  const product =
    trialPrices.durationDays === SEVEN_DAYS
      ? PurchaseProduct.SEVEN_DAY
      : selectedSubscription.product

  const isSubscriptionTrial = trialPrices.fullPrice !== mainPrices.fullPrice
  const disclaimerTransKey = isSubscriptionTrial
    ? TRIAL_DISCLAIMER_MAP[product]
    : NO_TRIAL_DISCLAIMER_MAP[product]
  const currencyOptions = getCurrencyOptions(currency)

  return (
    <S.DisclaimerContainer className={className}>
      <Trans
        i18nKey={disclaimerTransKey}
        components={{ span: <span /> }}
        values={{
          price: mainPrices.fullPrice,
          includingVatUsd: hasVatInfo ? t('purchase.includingVat') : '',
          currency,
          ...currencyOptions,
        }}
      />
      <TermsOfUseLink /> {t('purchase.contactUs')} <SupportActionLink />
    </S.DisclaimerContainer>
  )
}
