import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import {
  EVENT_MAP_BASE,
  EVENT_MAP_V1,
  LOST_WEIGHT_PER_WEEK,
} from 'pages/questions/EventFeedback/constants'
import { MotivationValue } from 'value-constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGoalWeight,
  selectCurrentUserMotivation,
  selectEventDate,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'
import { useUsersBmi } from 'hooks/useUsersBmi'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { DEFAULT_DATE_FORMAT, daysFromNowToGoalDate } from 'helpers/dateHelper'

import { Period } from 'modules/purchaseSubscription/constants'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledEventFeedbackV3 as S } from './EventFeedbackV3.styles'

const T_PREFIX = 'onboarding.eventFeedbackV3'

const useSelectors = () =>
  useBulkSelector({
    goalWeight: selectCurrentUserGoalWeight,
    eventDate: selectEventDate,
    motivation: selectCurrentUserMotivation,
  })

export const EventFeedbackV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { eventDate, motivation, goalWeight } = useSelectors()
  const { t } = useTranslation()
  const { measuringSystemLabel, measurementSystem } =
    useWeightMeasurementSystem()
  const { predictableDate } = usePredictableData()
  const bmiValue = useUsersBmi().currentBMI.bmiValue.value

  const targetWeight = `${goalWeight} ${measuringSystemLabel}`
  const lostPerWeekValue = LOST_WEIGHT_PER_WEEK[bmiValue][measurementSystem]
  const event = t(EVENT_MAP_V1[motivation as MotivationValue])
  const graphEvent = t(EVENT_MAP_BASE[motivation as MotivationValue])
  const hasEvent = event && eventDate
  const lostWeightPerDay = Number(
    (lostPerWeekValue / Period.SEVEN_DAYS).toFixed(2),
  )
  const isEventBeforePredictableDay = !!predictableDate.diff(
    dayjs(eventDate, DEFAULT_DATE_FORMAT),
    'day',
  )
  const getLostWeightForEvent = () => {
    if (isEventBeforePredictableDay) {
      const lostWeight = Number(
        (daysFromNowToGoalDate(eventDate) * lostWeightPerDay).toFixed(1),
      )

      return `<strong>~${lostWeight} ${measuringSystemLabel}</strong>`
    }

    return t(`onboarding.eventFeedback.justInTime`)
  }

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={12}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
      <S.InfoContainer>
        <S.Prediction>
          {hasEvent ? (
            <Trans
              i18nKey={`${T_PREFIX}.withLoseSubTitle`}
              values={{
                targetWeight,
                date: predictableDate.format(),
                weight: getLostWeightForEvent(),
                event,
              }}
              components={{ span: <span />, p: <p />, div: <div /> }}
            />
          ) : (
            <Trans
              i18nKey={`${T_PREFIX}.subTitle`}
              values={{
                targetWeight,
                date: predictableDate.format(),
              }}
              components={{ span: <span />, p: <p /> }}
            />
          )}
        </S.Prediction>
      </S.InfoContainer>
      <S.LoseWeightGraphV3
        predictableDate={predictableDate}
        eventName={graphEvent}
      />
    </KitPageContainer>
  )
}
