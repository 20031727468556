import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { LoseWeightGraphV4 } from 'components/LoseWeightGraphV4'
import { KitPageContainer } from 'components/PageContainer'

import { selectCurrentUserGoalWeight } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'
import { usePredictableData } from 'hooks/usePredictableData'
import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledWeightLossDate as S } from './WeightLossDate.styles'

export const WeightLossDateV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const { measuringSystemLabel } = useWeightMeasurementSystem()
  const { realPredictableDate, daysToGoalWeight } = usePredictableData()

  const targetWeight = `${goalWeight} ${measuringSystemLabel}`

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.weightLossDateV1.title', {
      lng: Language.EN,
      targetWeight,
      date: realPredictableDate.format('MMMM D, YYYY'),
    }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.PageTitle marginBottom={20}>
        <Trans
          i18nKey="onboarding.weightLossDateV1.title"
          values={{
            targetWeight,
            date: realPredictableDate.format(),
            dateStyle: 'short',
          }}
        />
      </S.PageTitle>
      <LoseWeightGraphV4 days={daysToGoalWeight} />
      <S.Text>{t`onboarding.weightLossDateV1.text`}</S.Text>
    </KitPageContainer>
  )
}
