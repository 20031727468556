import React, { FC, RefObject, SyntheticEvent, useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import { useTimerEffect } from 'contexts/TimerProvider'
import lottie from 'lottie-web/build/player/lottie_light'
import { OnboardingButtonText } from 'value-constants'

import {
  selectCurrentVariantCohortToUse,
  selectDynamicDiscount,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useLottie } from 'hooks/useLottie'

import { getDecoratedTimerValue } from 'modules/purchaseSubscription/helpers/rootHelpers'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchaseSubscription/redux/selects'

import {
  CDN_FOLDER_LINK,
  Cohort,
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  DEFAULT_TIMER_CONTENT,
} from 'root-constants'

import { StyledDiscountTimer as S } from './DiscountTimer.styles'

type TProps = {
  elemRef: RefObject<HTMLDivElement>
  onButtonClick: (event: SyntheticEvent<HTMLButtonElement>) => void
  className?: string
}

const BUTTON_ANIMATION_PATH = '/animations/kit-timer-button'

const useSelectors = () =>
  useBulkSelector({
    locale: selectLanguage,
    cohortToUse: selectCurrentVariantCohortToUse,
    dynamicDiscount: selectDynamicDiscount,
    discountFromSubscriptions: selectSubscriptionPaywallDiscountPercent,
  })

export const DiscountTimer: FC<TProps> = ({
  elemRef,
  onButtonClick,
  className,
}) => {
  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  const { discountFromSubscriptions, dynamicDiscount, locale, cohortToUse } =
    useSelectors()

  const { animationRef } = useLottie<HTMLButtonElement>({
    path: `${CDN_FOLDER_LINK}${BUTTON_ANIMATION_PATH}-${locale}.json`,
    loop: true,
  })

  const getDiscount = () => {
    if (cohortToUse === Cohort.MF_INTRO_SALE) {
      return dynamicDiscount
        ? dynamicDiscount?.amount
        : DEFAULT_DYNAMIC_DISCOUNT_AMOUNT
    }

    return discountFromSubscriptions
  }

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  useEffect(() => {
    return () => lottie.destroy('btnAnimation')
  }, [])

  return (
    <S.Wrapper ref={elemRef} className={className}>
      <S.Timer>
        <S.TimerLabel>
          <Trans
            i18nKey="purchase.discountTimerV1.timerLabel"
            values={{
              discount: getDiscount(),
            }}
          />
        </S.TimerLabel>
        <S.TimerValue>
          <span ref={timerMinutesRef}>{DEFAULT_TIMER_CONTENT}</span>:
          <span ref={timerSecondsRef}>{DEFAULT_TIMER_CONTENT}</span>
        </S.TimerValue>
      </S.Timer>
      <S.Button
        data-button-number="1"
        data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
        ref={animationRef}
        onClick={onButtonClick}
      />
    </S.Wrapper>
  )
}
