import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setIsInContextShownAction } from 'root-redux/actions/common'
import { selectIsInContextEnabled } from 'root-redux/selects/common'

import { StyledLanguageSelector as S } from './InContextPopup.styles'

export const InContextPopup = () => {
  const dispatch = useDispatch()
  const IsInContextEnabled = useSelector(selectIsInContextEnabled)
  const [showMenu, setShowMenu] = useState(false)
  const status = IsInContextEnabled ? 'enabled' : 'disabled'

  const handleChangeInContextVisibility = () => {
    dispatch(setIsInContextShownAction(true))
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'C') {
      setShowMenu((prevShow) => !prevShow)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    showMenu && (
      <S.Overlay onClick={() => setShowMenu(false)}>
        <S.Wrapper>
          <S.Title>Crowdin In-Context</S.Title>
          <S.StatusText $isEnabled={IsInContextEnabled}>
            Status: <strong>{status}</strong>
          </S.StatusText>
          {!IsInContextEnabled && (
            <S.Button
              $isEnabled={IsInContextEnabled}
              type="button"
              onClick={handleChangeInContextVisibility}
            >
              Enable
            </S.Button>
          )}
        </S.Wrapper>
      </S.Overlay>
    )
  )
}
