import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OPTIONS_V4 } from 'pages/bodyType/v4/constants'
import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

const T_PREFIX = 'onboarding.bodyType'

export const BodyTypeV4: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userGender = useSelector(selectCurrentUserGender)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.titleV4`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <KitPageContainer>
      <PageTitle marginBottom={24} textAlign="left">
        <Trans i18nKey={`${T_PREFIX}.titleV4`} />
      </PageTitle>
      {OPTIONS_V4.map(({ value, text, img }) => (
        <AnswerWithIcon
          key={value}
          {...optionProps}
          disabled={isAnswersDisabled}
          value={value}
          theme={answerWithIconTheme.NUTRIMATE}
          iconSrc={img[userGender]}
          iconHeight="100px"
          iconWidth="100px"
          padding="0 16px 0 8px"
          borderRadius="16px"
          height="100px"
          margin="0 0 12px"
        >
          <Trans i18nKey={text} />
        </AnswerWithIcon>
      ))}
    </KitPageContainer>
  )
}
