import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  COUNTER_DURATION,
  CREATING_PLAN_ITEMS,
} from 'pages/creatingPlan/v4/constants'

import { KitPageContainer } from 'components/PageContainer'
import { KitProgressAnalyzer } from 'components/ProgressAnalyzer'

import { selectCurrentVariantCohort } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import arrowsIcon from 'assets/images/kit-arrows-down-icon.png'

import { Cohort, Color, Language } from 'root-constants'

import { StyledCreatingPlanV4 as S } from './CreatingPlanV4.styles'

const T_PREFIX = 'onboarding.creatingPlanV4'

export const CreatingPlanV4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [isFirstChangeableContentShown, setIsFirstChangeableContentShown] =
    useState(true)
  const [isSecondChangeableContentShown, setIsSecondChangeableContentShown] =
    useState(false)
  const [isThirdChangeableContentShown, setIsThirdChangeableContentShown] =
    useState(false)
  const [isFourthChangeableContentShown, setIsFourthChangeableContentShown] =
    useState(false)
  const [isFifthChangeableContentShown, setIsFifthChangeableContentShown] =
    useState(false)

  const cohort = useSelector(selectCurrentVariantCohort)
  const { t } = useTranslation()
  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })
  const { counter, linearCounterValue } = useCounter(true, {
    duration: COUNTER_DURATION,
    callback: handleContinue,
  })

  let options = CREATING_PLAN_ITEMS
  const isScannerCohort = cohort === Cohort.MF_SCANNER
  const isChangeableContentShownMap = [
    isFirstChangeableContentShown,
    isSecondChangeableContentShown,
    isThirdChangeableContentShown,
    isFourthChangeableContentShown,
    isFifthChangeableContentShown,
  ]

  if (isScannerCohort) {
    options = CREATING_PLAN_ITEMS.map((item) => ({
      ...item,
      subtitle: item.subtitle.replace('subtitleFourth', 'subtitleFourthV2'),
    }))
  }

  useEffect(() => {
    switch (linearCounterValue) {
      case 0:
        return setIsFirstChangeableContentShown(true)
      case 20:
        return setIsSecondChangeableContentShown(true)
      case 40:
        return setIsThirdChangeableContentShown(true)
      case 60:
        return setIsFourthChangeableContentShown(true)
      case 80:
        return setIsFifthChangeableContentShown(true)
      default:
        return setIsFirstChangeableContentShown(true)
    }
  }, [linearCounterValue])

  return (
    <KitPageContainer>
      <S.PageTitle marginBottom={16}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </S.PageTitle>
      <S.Arrows>
        <img src={arrowsIcon} alt="arrows" />
      </S.Arrows>
      <S.ChangeableContainer>
        {options.map(({ imgSrc, subtitle }, index) => (
          <S.ChangeableContent
            key={imgSrc}
            data-is-shown={isChangeableContentShownMap[index]}
          >
            <S.Subtitle>
              <Trans i18nKey={subtitle} />
            </S.Subtitle>
            <S.ImageContainer>
              <img src={imgSrc} alt="banner" />
            </S.ImageContainer>
          </S.ChangeableContent>
        ))}
      </S.ChangeableContainer>
      <S.ProgressbarContainer>
        <KitProgressAnalyzer
          value={counter}
          backgroundColor={Color.GREY_900}
          color={Color.GREEN_400}
        >
          <Trans i18nKey={`${T_PREFIX}.loaderText`} />
        </KitProgressAnalyzer>
      </S.ProgressbarContainer>
    </KitPageContainer>
  )
}
