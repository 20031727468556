import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { KitPageContainer } from 'components/PageContainer'
import { ScannerIntro } from 'components/ScannerIntro'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { StyledHitYourWeightLossV1 as S } from './HitYourWeightLossV1.styles'

export const T_PREFIX = 'onboarding.kitHitYourWeightLoss'

export const HitYourWeightLossV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  isFirstPage,
}) => {
  const { t } = useTranslation()
  const handleNextPage = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
    isFirstPage,
  })

  const handleContinue = () => handleNextPage('')

  const handleVideoEnd = () => {
    setTimeout(handleContinue, 500)
  }

  return (
    <KitPageContainer
      paddingTop={12}
      continueButtonContent={t(`${T_PREFIX}.startScanning`)}
    >
      <S.Title>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </S.Title>
      <S.Subtitle>
        <Trans i18nKey={`${T_PREFIX}.subtitle`} />
      </S.Subtitle>
      <ScannerIntro onVideoEnd={handleVideoEnd} withProgressBar />
    </KitPageContainer>
  )
}
