import styled, { createGlobalStyle, css } from 'styled-components'

import closeSvg from 'assets/images/close-icon-v2.svg'

import { Color } from 'root-constants'

export const ModalGlobalStyle = createGlobalStyle<{ $isVisible: boolean }>`
  body {
    overflow: ${({ $isVisible }) => ($isVisible ? 'hidden' : 'auto')};
  }

  #root {
    overflow: ${({ $isVisible }) => ($isVisible ? 'hidden' : 'auto')};
  }
`

export const StyledKitModalV1 = {
  Wrapper: styled.div<{ isVisible: boolean }>`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(53, 57, 72, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Modal: styled.div<{ isCentered?: boolean }>`
    height: fit-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 18px;
    border-radius: 20px;
    text-align: center;
    background-color: ${Color.WHITE};
    position: absolute;
    top: 16px;
    ${({ isCentered }) =>
      isCentered &&
      css`
        top: 50%;
        transform: translateY(-50%);
      `}
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 24px;
    right: 18px;
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
}
