const T_PREFIX = 'onboarding.otherStruggles.options'
export const KIT_OTHER_STRUGGLES_OPTIONS = [
  {
    textPath: `${T_PREFIX}.weightLoss`,
    value: 'plans_did_not_fit',
  },
  {
    textPath: `${T_PREFIX}.temptations`,
    value: 'temptations',
  },
  {
    textPath: `${T_PREFIX}.feelingGuilty`,
    value: 'feeling_guilty_after_binging',
  },
  {
    textPath: `${T_PREFIX}.gainingWeight`,
    value: 'gaining_weight_after_losing',
  },
]
