import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledUpsellBenefits as S } from './UpsellBenefits.styles'
import { UPSELL_BENEFITS } from './constants'

type TProps = {
  className?: string
}

export const UpsellBenefits: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.Container className={className}>
      <S.Title>{t('upsell.benefits.title')}</S.Title>
      <S.List>
        {UPSELL_BENEFITS.map(({ id, description }) => (
          <S.ListItem key={id}>{t(description)}</S.ListItem>
        ))}
      </S.List>
    </S.Container>
  )
}
