import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IMAGE_PATH } from 'pages/questions/DontExercise/constants'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Cohort, Language } from 'root-constants'

import { StyledDontExerciseV1 as S } from './DontExerciseV1.styles'

const T_PREFIX = 'onboarding.dontExercise'

const useSelectors = () =>
  useBulkSelector({
    language: selectLanguage,
    cohort: selectCurrentVariantCohort,
  })

export const DontExerciseV1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { cohort, language } = useSelectors()

  const imgSrc = `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`
  const titlePath =
    cohort === Cohort.MF_SCANNER ? `${T_PREFIX}.titleV2` : `${T_PREFIX}.title`

  const handleContinue = useNextStep({
    pageId,
    question: t(titlePath, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      continueButtonContent={
        <S.ContinueButtonText>{t('actions.gotIt')}!</S.ContinueButtonText>
      }
      onContinueButtonClick={() => handleContinue('')}
    >
      <PageTitle marginBottom={24} textAlign="center">
        <Trans i18nKey={titlePath} />
      </PageTitle>
      <S.ImageContainer>
        <img src={imgSrc} alt="dont-exercise" />
      </S.ImageContainer>
    </KitPageContainer>
  )
}
