import React from 'react'
import { useTranslation } from 'react-i18next'

import { KitCheckboxOptionsView } from 'views/KitCheckboxOptionsView'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

import { KIT_CHALLENGES_YOU_FACED_OPTIONS } from './constants'

const TITLE_PATH = 'onboarding.kitChallengesYouFaced.title'

export const KitChallengesYouFaced: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(TITLE_PATH)
  const question = t(TITLE_PATH, { lng: Language.EN })

  return (
    <KitCheckboxOptionsView
      title={title}
      question={question}
      options={KIT_CHALLENGES_YOU_FACED_OPTIONS}
      isReverse={false}
      {...props}
    />
  )
}
