import React from 'react'
import { useTranslation } from 'react-i18next'

import banner from 'assets/images/featured-v2-banner.png'

import { StyledPurchaseFeaturedBlock as S } from './PurchaseFeaturedBlock.styles'

type TProps = {
  className?: string
}
export const PurchaseFeaturedBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t('purchase.featuredBlockTitle')}</S.Title>
      <S.ImageContainer>
        <img src={banner} alt="featured-banner" />
      </S.ImageContainer>
    </div>
  )
}
