import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const centeredText = css`
  text-align: center;
  line-height: 24px;
`

export const StyledAchieveResultV4 = {
  Subtitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
    ${centeredText};

    strong {
      color: ${Color.GREEN_300};
    }
  `,
  Banner: styled.div`
    aspect-ratio: 343 / 240;
    margin-bottom: 24px;
    width: 100%;
  `,
  CallToAction: styled.h3`
    font-size: 17px;
    font-weight: 600;
    ${centeredText};
  `,
}
