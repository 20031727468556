import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTranslationKeysModal = {
  LoggerTrigger: styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: ${Color.GREEN_400};
    color: ${Color.WHITE};
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    z-index: 10000;
    border: none;
    transition: background-color 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background: ${Color.GREEN_300};
    }
  `,

  LoggerPanel: styled.div<{ isVisible: boolean }>`
    position: fixed;
    top: 65px;
    left: 15px;
    width: 400px;
    height: 600px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    z-index: 10001;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    flex-direction: column;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    will-change: transform;
    user-select: none;
  `,

  Header: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid ${Color.GREY_400};
    background: ${Color.WHITE};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    user-select: none;
  `,

  Title: styled.h2`
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: ${Color.BLACK_200};
  `,

  SearchContainer: styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid ${Color.GREY_400};
    background: ${Color.WHITE};
    user-select: text;
  `,

  SearchWrapper: styled.div`
    display: flex;
    align-items: center;
    background: ${Color.VERY_LIGHT_BLUE};
    border: 1px solid ${Color.GREY_400};
    border-radius: 8px;
    padding: 8px 12px;
  `,

  SearchInput: styled.input`
    border: none;
    background: none;
    width: 100%;
    font-size: 14px;
    color: ${Color.BLACK_200};
    outline: none;

    &::placeholder {
      color: ${Color.GREY_500};
    }
  `,

  SearchInfo: styled.div`
    font-size: 12px;
    color: ${Color.GREY_600};
    margin-top: 8px;
  `,

  Content: styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 16px 24px;
    user-select: text;
  `,

  EmptyState: styled.div`
    text-align: center;
    color: ${Color.GREY_600};
    padding: 24px;
  `,

  CloseButton: styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: ${Color.GREY_600};
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    z-index: 1;

    &:hover {
      background: ${Color.GREY_200};
      color: ${Color.BLACK_200};
    }
  `,

  TranslationItem: styled.div`
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${Color.GREY_400};

    &:last-child {
      border-bottom: none;
    }
  `,

  TranslationKey: styled.div`
    font-size: 12px;
    font-weight: 600;
    color: ${Color.GREEN_800};
    margin-bottom: 4px;
    word-break: break-all;
  `,

  TranslationText: styled.div`
    color: ${Color.BLACK_200};
    font-size: 14px;
  `,
}
