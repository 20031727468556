import React from 'react'
import { Trans } from 'react-i18next'

import {
  ACTIVITY_LEVEL_MAP,
  BODY_PART_NAME_MAP,
  PRIMARY_GOAL_MAP,
} from 'map-constants'

import { IMAGES_MAP } from 'components/UserInfoBlock/constants'

import {
  selectCurrentUserGender,
  selectCurrentUserPrimaryGoal,
  selectTargetAreas,
  selectWorkout,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useUsersBmi } from 'hooks/useUsersBmi'

import bulletCup from 'assets/images/bullet-cup.svg'
import bulletDarts from 'assets/images/bullet-darts.svg'
import bulletRunningMan from 'assets/images/bullet-running-man.svg'

import { TARGET_AREA_FULL_LIST } from 'root-constants'

import { StyledUserInfoBlockV4 as S } from './UserInfoBlockV4.styles'

type TProps = {
  className?: string
}

const useSelectors = () =>
  useBulkSelector({
    gender: selectCurrentUserGender,
    primaryGoalValue: selectCurrentUserPrimaryGoal,
    targetAreas: selectTargetAreas,
    workoutValue: selectWorkout,
  })

export const UserInfoBlockV4 = ({ className }: TProps) => {
  const { gender, primaryGoalValue, workoutValue, targetAreas } = useSelectors()
  const { value } = useUsersBmi().currentBMI.bmiValue

  const imgSrc = IMAGES_MAP[gender][value]
  const goalKey = PRIMARY_GOAL_MAP[primaryGoalValue]
  const activityLevelKey = ACTIVITY_LEVEL_MAP[workoutValue]

  const isWholeBodySelected =
    JSON.stringify(targetAreas) === JSON.stringify(TARGET_AREA_FULL_LIST)

  const areasKeys = isWholeBodySelected
    ? ['commonComponents.bodyPart.whole']
    : targetAreas.map((area) => BODY_PART_NAME_MAP[area])

  const options = [
    {
      iconSrc: bulletCup,
      alt: 'cup-icon',
      labelKey: 'commonComponents.goal',
      children: <Trans i18nKey={goalKey} />,
    },
    {
      iconSrc: bulletDarts,
      alt: 'darts-icon',
      labelKey: 'commonComponents.targetArea',
      children: areasKeys.map((areaKey) => (
        <span key={areaKey}>
          <Trans i18nKey={areaKey} />
        </span>
      )),
    },
    {
      iconSrc: bulletRunningMan,
      alt: 'running-man-icon',
      labelKey: 'commonComponents.activityLevel',
      children: <Trans i18nKey={activityLevelKey} />,
    },
  ]

  return (
    <S.Container className={className}>
      <S.InfoList>
        {options.map(({ children, labelKey, iconSrc, alt }) => (
          <S.InfoItem key={labelKey}>
            <S.InfoItemIcon src={iconSrc} alt={alt} />
            <S.InfoItemText>
              <S.Label>
                <Trans i18nKey={labelKey} />
              </S.Label>
              <S.InfoValue>{children}</S.InfoValue>
            </S.InfoItemText>
          </S.InfoItem>
        ))}
      </S.InfoList>
      <S.Banner src={imgSrc} alt="user-info" />
    </S.Container>
  )
}
