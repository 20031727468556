import styled from 'styled-components'

import { Color } from 'root-constants'

import { UpsellBenefits } from '../lib/components'

export const StyledUpsellPaywallV1 = {
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  Subtitle: styled.p`
    color: ${Color.GREY_101};
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  `,
  UpsellBenefits: styled(UpsellBenefits)`
    margin: 0 0 40px 0;
  `,
  ButtonContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SkipButton: styled.button`
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};
    text-decoration: underline;
  `,
}
