import helenAvatarV6 from 'assets/images/helen-avatar.png'
import helenImgV6 from 'assets/images/helen-img.png'
import helenV5 from 'assets/images/helen-mason.png'
import margaretAvatarV6 from 'assets/images/margaret-avatar.png'
import margaretImgV6 from 'assets/images/margaret-img.png'
import margaretV5 from 'assets/images/margaret-wilson.png'
import robertAvatarV6 from 'assets/images/robert-avatar.png'
import robertImgV6 from 'assets/images/robert-img.png'
import robertV5 from 'assets/images/robert-martinez.png'

export const CUSTOMER_REVIEWS_V1 = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.01.2022',
    author: 'Marta86',
    text: 'purchase.customerReviews.firstReview',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.11.2021',
    author: 'Athletic',
    text: 'purchase.customerReviews.secondReview',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.05.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviews.thirdReview',
  },
]

export const CUSTOMER_REVIEWS_V2 = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.07.2023',
    author: 'Mike86',
    text: 'purchase.customerReviewsV3.firstReviewText',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.02.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV3.secondReviewText',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.10.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV3.thirdReviewText',
  },
]

export const CUSTOMER_REVIEWS_V3 = [
  {
    id: '1',
    title: 'purchase.customerReviews.firstReviewTitle',
    subtitle: '05.06.2023',
    author: 'Marta86',
    review: 'purchase.customerReviews.firstReviewText',
    shortReview: 'purchase.customerReviews.firstPreview',
  },
  {
    id: '2',
    title: 'purchase.customerReviews.secondReviewTitle',
    subtitle: '29.02.2022',
    author: 'Athletic',
    review: 'purchase.customerReviews.secondReviewText',
    shortReview: 'purchase.customerReviews.secondPreview',
  },
  {
    id: '3',
    title: 'purchase.customerReviews.thirdReviewTitle',
    subtitle: '20.09.2022',
    author: 'PBarcelo',
    review: 'purchase.customerReviews.thirdReviewText',
    shortReview: 'purchase.customerReviews.thirdPreview',
  },
]

export const CUSTOMER_REVIEWS_V4_A = [
  {
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.09.2023',
    author: 'Luke86',
    text: 'purchase.customerReviewsV2.firstReviewText',
  },
  {
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.03.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV2.secondReviewText',
  },
  {
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.11.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV2.thirdReviewText',
  },
]

export const CUSTOMER_REVIEWS_V4_B = [
  {
    title: 'purchase.customerReviews.firstReviewTitle',
    date: '05.09.2023',
    author: 'Luke86',
    text: 'purchase.customerReviewsV2.firstReviewText',
  },
  {
    title: 'purchase.customerReviews.secondReviewTitle',
    date: '29.03.2023',
    author: 'Athletic',
    text: 'purchase.customerReviewsV2.secondReviewText',
  },
  {
    title: 'purchase.customerReviews.thirdReviewTitle',
    date: '20.11.2022',
    author: 'PBarcelo',
    text: 'purchase.customerReviewsV2.thirdReviewTextV2',
  },
]

export const CUSTOMER_REVIEWS_V5 = [
  {
    authorName: 'purchase.customerReviewsV5.firstReview.name',
    authorImg: margaretV5,
    reviewText: 'purchase.customerReviewsV5.firstReview.text',
  },
  {
    authorName: 'purchase.customerReviewsV5.secondReview.name',
    authorImg: robertV5,
    reviewText: 'purchase.customerReviewsV5.secondReview.text',
  },
  {
    authorName: 'purchase.customerReviewsV5.thirdReview.name',
    authorImg: helenV5,
    reviewText: 'purchase.customerReviewsV5.thirdReview.text',
  },
]

export const CUSTOMER_REVIEWS_V6 = [
  {
    name: 'purchase.customerReviewsV5.firstReview.name',
    date: 'purchase.customerReviewsV5.firstReview.date',
    text: 'purchase.customerReviewsV5.firstReview.text',
    avatar: margaretAvatarV6,
    img: margaretImgV6,
    commentsCount: 55,
  },
  {
    name: 'purchase.customerReviewsV5.secondReview.name',
    date: 'purchase.customerReviewsV5.secondReview.date',
    text: 'purchase.customerReviewsV5.secondReview.text',
    avatar: robertAvatarV6,
    img: robertImgV6,
    commentsCount: 26,
  },
  {
    name: 'purchase.customerReviewsV5.thirdReview.name',
    date: 'purchase.customerReviewsV5.thirdReview.date',
    text: 'purchase.customerReviewsV5.thirdReview.text',
    avatar: helenAvatarV6,
    img: helenImgV6,
    commentsCount: 19,
  },
] as const
