import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTimerEffect } from 'contexts/TimerProvider'

import { selectDynamicDiscount } from 'root-redux/selects/common'

import { convertTextFromSnakeCase } from 'helpers/convertTextFromSnakeCase'

import { getDecoratedTimerValue } from 'modules/purchaseSubscription/helpers/rootHelpers'

import {
  DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
  DEFAULT_TIMER_CONTENT,
} from 'root-constants'

import { StyledDiscountBlockV2 as S } from './DiscountBlockV2.styles'

type TProps = {
  className?: string
}

export const DiscountBlockV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  const discount = convertTextFromSnakeCase(
    t('purchase.discountBlockV2.discount', {
      discount: dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT_AMOUNT,
    }),
  )

  return (
    <S.ContentBlock className={className} theme={dynamicDiscount?.theme}>
      <S.Discount>{discount}</S.Discount>
      <S.DiscountDivider />
      <S.ContentRow>
        <S.DiscountLabel>
          {t('purchase.discountBlockV2.discountLabel')}
        </S.DiscountLabel>
        <S.ContentColumn>
          <S.TimerTitle>
            {t('purchase.discountBlockV2.applicable')}
          </S.TimerTitle>
          <S.TimerBlock>
            <S.TimerColumn>
              <S.TimerValue ref={timerMinutesRef}>
                {DEFAULT_TIMER_CONTENT}
              </S.TimerValue>
              <S.TimerUnits>{t('commonComponents.timer.minutes')}</S.TimerUnits>
            </S.TimerColumn>
            <S.TimerColumn>
              <S.TimerValue ref={timerSecondsRef}>
                {DEFAULT_TIMER_CONTENT}
              </S.TimerValue>
              <S.TimerUnits>{t('commonComponents.timer.seconds')}</S.TimerUnits>
            </S.TimerColumn>
          </S.TimerBlock>
        </S.ContentColumn>
      </S.ContentRow>
    </S.ContentBlock>
  )
}
