import { Currency } from 'modules/purchaseSubscription/constants'
import { isComplexCurrencySymbol } from 'modules/purchaseSubscription/helpers/currency'

export const getCurrencyOptions = (
  currency: string,
): Intl.NumberFormatOptions => {
  const isComplexCurrency = isComplexCurrencySymbol(currency)

  return {
    currencyDisplay:
      isComplexCurrency && currency === Currency.MXN ? 'code' : 'narrowSymbol',
  }
}
