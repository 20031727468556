import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTimerEffect } from 'contexts/TimerProvider'

import { getDecoratedTimerValue } from 'modules/purchaseSubscription/helpers/rootHelpers'

import { DEFAULT_TIMER_CONTENT } from 'root-constants'

import { StyledPositionedTimerV1 as S } from './PositionedTimerV1.styles'

type TProps = {
  className?: string
}

export const PositionedTimerV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    const { minutes, seconds } = getDecoratedTimerValue(time, false)
    if (timerMinutesRef.current && timerSecondsRef.current) {
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  return (
    <S.Container className={className}>
      <S.TimerContent>
        {t('purchase.personalizedPlanTimer.offerExpires')}{' '}
        <span ref={timerMinutesRef}>{DEFAULT_TIMER_CONTENT}</span>:
        <span ref={timerSecondsRef}>{DEFAULT_TIMER_CONTENT}</span>
      </S.TimerContent>
    </S.Container>
  )
}
