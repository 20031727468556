import { AgeRangeValue } from 'value-constants'

export const KIT_AGE_RANGE_V2_OPTIONS = [
  {
    value: AgeRangeValue.AR_18_29,
    text: '18-29',
  },
  {
    value: AgeRangeValue.AR_30_39,
    text: '30-39',
  },
  {
    value: AgeRangeValue.AR_40_49,
    text: '40-49',
  },
  {
    value: AgeRangeValue.AR_50_PLUS,
    text: '50+',
  },
]
