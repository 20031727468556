import styled from 'styled-components'

import pageBg from 'assets/images/green-banner.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledAchieveResultV1 = {
  Wrapper: styled.div`
    padding: 10px 0 24px;
    background-image: url(${pageBg});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      background-size: 360px auto;
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 328/229;
    margin: 0 auto 50px;

    img {
      box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
      border-radius: 24px;
    }
  `,
  Title: styled.h2`
    margin-bottom: 32px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.WHITE};

    strong {
      font-weight: 800;
      text-decoration: underline;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.GREY_100};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
    text-align: center;
  `,
}
