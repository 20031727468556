import React, { useCallback, useEffect, useRef } from 'react'

import { StyledVideoProgressBar as S } from './VideoProgressBar.styles'

type TProps = {
  videoRef: React.RefObject<HTMLVideoElement>
}

export const VideoProgressBar = ({ videoRef }: TProps) => {
  const progressBarRef = useRef<HTMLDivElement | null>(null)
  const animationFrameRef = useRef<number>()

  const updateProgress = useCallback(() => {
    if (!videoRef.current || !progressBarRef.current) return

    const { currentTime, duration } = videoRef.current
    const progressPercent = (currentTime / duration) * 100

    progressBarRef.current.style.width = `${progressPercent}%`
    animationFrameRef.current = requestAnimationFrame(updateProgress)
  }, [videoRef])

  const startAnimation = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }
    animationFrameRef.current = requestAnimationFrame(updateProgress)
  }, [updateProgress])

  useEffect(() => {
    const video = videoRef.current
    if (!video) return undefined

    video.addEventListener('timeupdate', startAnimation)

    return () => {
      video.removeEventListener('timeupdate', startAnimation)
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [startAnimation, videoRef])

  return (
    <S.ProgressBarWrapper>
      <S.ProgressBar ref={progressBarRef} />
    </S.ProgressBarWrapper>
  )
}
