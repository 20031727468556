import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, KIT_MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

export const StyledFloatingCTABanner = {
  Container: styled.div`
    position: fixed;
    display: none;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-image: linear-gradient(
      74deg,
      ${Color.YELLOW_200} 0%,
      ${Color.GREEN_750} 100%
    );
    background-position: left center, left top;
    background-size: auto auto, cover;
    background-repeat: no-repeat;
    padding: 4px 16px;
    width: 100%;
    margin: 0 auto;

    &[data-is-visible='true'] {
      display: flex;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${KIT_MAX_COLUMN_WIDTH}px;
    }
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    img {
      margin-left: -30px;
      width: 150px;
    }
  `,
  Button: styled(Button)<{ $fontSize: number }>`
    width: initial;
    min-width: 147px;
    height: 48px;
    background-color: ${Color.WHITE};
    color: ${Color.GREEN_300};
    padding: 12px 22px;
    font-size: ${({ $fontSize }) => $fontSize}px;
    font-weight: 700;
    margin: 0 0 0 auto;
    text-transform: capitalize;
  `,
}
