import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectLanguage } from 'root-redux/selects/common'

import starSvg from 'assets/images/sprite/star-v2.svg'

import { CUSTOMER_REVIEWS_V1 } from '../constants'
import { getLocalizedDate } from '../lib/getLocalizedDate'
import { StyledCustomerReviewsV1 as S } from './CustomerReviewsV1.styles'

export const CustomerReviewsV1: React.FC = () => {
  const { t } = useTranslation()
  const lng = useSelector(selectLanguage)

  return (
    <S.Wrapper>
      <S.Title>{t('purchase.customerReviews.title')}</S.Title>
      {CUSTOMER_REVIEWS_V1.map(({ id, title, date, author, text }) => (
        <S.ReviewContainer key={id}>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(title)}</S.ReviewTitle>
            <S.Date>{getLocalizedDate(date, lng)}</S.Date>
            <S.Rating>
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
              <SvgImage svg={starSvg} />
            </S.Rating>
            <S.Author>{author}</S.Author>
          </S.ReviewHeader>
          <S.TextContent>
            <S.TextContainer>
              <S.Text>{t(text)}</S.Text>
            </S.TextContainer>
          </S.TextContent>
        </S.ReviewContainer>
      ))}
    </S.Wrapper>
  )
}
