import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'

import { store } from 'root-redux/store'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'
import { initSentry } from 'helpers/initSentry'

import { App } from './App'
import './i18n'
import './styles.css'

initSentry()
const { isLocalEnvironment } = getCurrentEnvironment()
const root = createRoot(document.getElementById('root') as HTMLElement)

const renderApp = () => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  )
}

if (isLocalEnvironment && import.meta.webpackHot) {
  import.meta.webpackHot.accept('./App', () => {
    renderApp()
  })
  // eslint-disable-next-line no-console
  console.log('[HMR] - App updated')
}

renderApp()
