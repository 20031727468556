import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { InputWithFloatPlaceholder } from 'storybook-ui'

import { KitPageContainer } from 'components/PageContainer'
import { Select } from 'components/Select'

import { updateAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'
import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'
import {
  selectIsOnboardingFinished,
  selectIsOnboardingSkipped,
} from 'root-redux/selects/user'

import { useWeightMeasurementSystem } from 'hooks/useWeightMeasurementSystem'

import { TAnswer } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { CustomPageId, PageId } from 'page-constants'
import {
  Cohort,
  Color,
  Gender,
  MEN_COHORTS,
  MIN_MAX_WEIGHT,
  WOMEN_COHORTS,
} from 'root-constants'

import { MAX_AGE, MIN_AGE } from '../../questions/Age/constants'
import {
  MIN_KG_DIFF,
  MIN_LB_DIFF,
  MIN_WEIGHT_KG,
  MIN_WEIGHT_LB,
} from '../../questions/GoalWeight/constants'
import {
  PRIMARY_GOAL_OPTIONS_V1,
  PRIMARY_GOAL_OPTIONS_V2,
} from '../lib/constants'
import {
  useAgeField,
  useGoalWeightField,
  useNameField,
  usePrimaryGoalField,
} from '../lib/hooks'
import { StyledFinalReview as S } from './FinishingTouches.styles'

export const FinishingTouches: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const { userAge, handleAgeChange } = useAgeField()
  const { userGoalWeight, handleGoalWeightChange } = useGoalWeightField()
  const { userName, handleNameChange } = useNameField()
  const { selectedPrimaryGoal, handlePrimaryGoalChange } = usePrimaryGoalField()

  const currentWeight = +useSelector(selectCurrentUserCurrentWeight)
  const isOnboardingSkipped = useSelector(selectIsOnboardingSkipped)
  const isOnboardingFinished = useSelector(selectIsOnboardingFinished)
  const gender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const { measuringSystemLabel, isMetricSelected } =
    useWeightMeasurementSystem()

  const isMaleSexSelected = useMemo(() => gender === Gender.MALE, [gender])

  const isConfirmationDisabled = useMemo(
    () =>
      !userGoalWeight?.isValid ||
      !userAge.isValid ||
      !selectedPrimaryGoal?.value,
    [selectedPrimaryGoal, userGoalWeight, userAge],
  )

  const primaryGoalOptions = useMemo(
    () =>
      (cohortToUse === Cohort.MF_MEN_FLOW
        ? PRIMARY_GOAL_OPTIONS_V2
        : PRIMARY_GOAL_OPTIONS_V1
      ).map((goal) => ({
        ...goal,
        label: t(goal.label),
      })),
    [cohortToUse, t],
  )

  const maxWeight = useMemo(() => {
    const userMaxWeight =
      currentWeight - (isMetricSelected ? MIN_KG_DIFF : MIN_LB_DIFF)

    const defaultMaxWeight = isMetricSelected
      ? MIN_MAX_WEIGHT.MAX_WEIGHT_KG
      : MIN_MAX_WEIGHT.MAX_WEIGHT_LB

    return userMaxWeight || defaultMaxWeight
  }, [isMetricSelected, currentWeight])

  const hasGenderSection = useMemo(
    () => !!gender && ![...MEN_COHORTS, ...WOMEN_COHORTS].includes(cohortToUse),
    [gender, cohortToUse],
  )

  useLayoutEffect(() => {
    if (isOnboardingSkipped || isOnboardingFinished) {
      goTo({ pathname: PageId.LOGIN, search })
      return
    }
    eventLogger.logFinishingTouchesScreenShow()
  }, [isOnboardingFinished, isOnboardingSkipped, search])

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus()
    }
  }, [isFocused])

  const handleConfirm = useCallback(() => {
    dispatch(
      updateAnswersAction({
        answers: {
          name: userName,
          [CustomPageId.AGE]: +userAge.value,
          [CustomPageId.PRIMARY_GOAL]: selectedPrimaryGoal?.value as TAnswer,
          [CustomPageId.GOAL_WEIGHT]: +userGoalWeight.value,
        },
      }),
    )
    dispatch(sendUserAnswersAction(null, true))

    eventLogger.logFinishingTouchesScreenConfirm({
      name: userName,
      sex: gender,
      age: userAge.value || 25,
      primaryGoal: selectedPrimaryGoal?.value || 'lose_weight',
      goalWeight: userGoalWeight.value || 60,
    })
    goTo({ pathname: PageId.LOGIN, search })
  }, [
    dispatch,
    userName,
    userAge.value,
    selectedPrimaryGoal?.value,
    userGoalWeight.value,
    gender,
    search,
  ])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <KitPageContainer
      paddingTop={0}
      hasContinueButton
      continueButtonContent={t('actions.confirm')}
      isContinueButtonDisabled={isConfirmationDisabled}
      onContinueButtonClick={handleConfirm}
    >
      <S.Title>{t('finishingTouches.title')}</S.Title>
      <S.Subtitle>{t('finishingTouches.subtitle')}</S.Subtitle>
      <S.List>
        <S.Item>
          <InputWithFloatPlaceholder
            width="100%"
            minHeight="64px"
            padding="30px 16px 10px"
            border="1px solid #CACACA"
            focusedBorderColor="#CACACA"
            focusedLabelFontSize="14px"
            focusedLabelColor={Color.GREY_101}
            borderRadius="16px"
            fontWeight="400"
            fontSize="17px"
            lineHeight="24px"
            color={Color.BLACK_100}
            labelColor={Color.GREY_101}
            labelLineHeight="18px"
            backgroundColor={Color.WHITE}
            onChange={handleNameChange}
            label={t('finishingTouches.userName.label')}
            maxLength={30}
            value={userName as string}
          />
        </S.Item>
        {hasGenderSection && (
          <S.Item>
            <S.Gender>
              <S.SectionTitle>
                {t('finishingTouches.gender.title')}
              </S.SectionTitle>
              <S.Label htmlFor={Gender.FEMALE}>
                <S.Radio
                  disabled
                  value={Gender.FEMALE}
                  defaultChecked={!isMaleSexSelected}
                  name="gender"
                  id={Gender.FEMALE}
                  type="radio"
                />
                <S.MarkIcon isSelected={!isMaleSexSelected} />
                <S.MarkValue>{t('finishingTouches.gender.female')}</S.MarkValue>
              </S.Label>
              <S.Label htmlFor={Gender.MALE}>
                <S.Radio
                  disabled
                  defaultChecked={isMaleSexSelected}
                  value={Gender.MALE}
                  name="gender"
                  id={Gender.MALE}
                  type="radio"
                />
                <S.MarkIcon isSelected={isMaleSexSelected} />
                <S.MarkValue>{t('finishingTouches.gender.male')}</S.MarkValue>
              </S.Label>
            </S.Gender>
          </S.Item>
        )}
        <S.Item>
          <InputWithFloatPlaceholder
            width="100%"
            padding="30px 16px 10px"
            border="1px solid #CACACA"
            focusedBorderColor="#CACACA"
            focusedLabelFontSize="14px"
            focusedLabelColor={Color.GREY_101}
            borderRadius="16px"
            fontWeight="400"
            fontSize="17px"
            lineHeight="24px"
            type="number"
            color={Color.BLACK_100}
            labelColor={Color.GREY_101}
            backgroundColor={Color.WHITE}
            labelLineHeight="18px"
            min={MIN_AGE}
            max={MAX_AGE}
            pattern="\d*"
            label={t('finishingTouches.age.placeholder')}
            value={userAge.value}
            onChange={handleAgeChange}
          />
        </S.Item>
        <S.Item>
          <Select
            placeholder={t('finishingTouches.primaryGoal.placeholder')}
            options={primaryGoalOptions}
            value={selectedPrimaryGoal}
            onChange={handlePrimaryGoalChange}
          />
        </S.Item>
        <S.Item>
          <InputWithFloatPlaceholder
            type="number"
            width="100%"
            inputRef={inputRef}
            padding="30px 16px 10px"
            border="1px solid #CACACA"
            focusedBorderColor="#CACACA"
            focusedLabelFontSize="14px"
            focusedLabelColor={Color.GREY_101}
            borderRadius="16px"
            fontWeight="400"
            fontSize="17px"
            lineHeight="24px"
            color={Color.BLACK_100}
            labelColor={Color.GREY_101}
            backgroundColor={Color.WHITE}
            labelLineHeight="18px"
            label={t('finishingTouches.goalWeight.placeholder', {
              measurementSystem: measuringSystemLabel,
            })}
            value={userGoalWeight.value}
            isValid={userGoalWeight.isValid}
            min={isMetricSelected ? MIN_WEIGHT_KG : MIN_WEIGHT_LB}
            max={maxWeight}
            allowFloatNumbers={isMetricSelected}
            step={isMetricSelected ? '0.1' : '1'}
            onBlur={handleBlur}
            onChange={(event) => {
              handleGoalWeightChange(event)
            }}
          />
        </S.Item>
      </S.List>
    </KitPageContainer>
  )
}
