import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import graph from 'assets/images/graph.png'

import { Language } from 'root-constants'

import { StyledAchieveResultV2 as S } from './AchieveResultV2.styles'

const T_PREFIX = 'onboarding.achieveResult'

export const AchieveResultV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <Logo />
      <S.Container>
        <S.Title>
          <Trans i18nKey={`${T_PREFIX}.title`} />
        </S.Title>
        <S.Img src={graph} alt="graph" />
        <S.Subtitle>
          <Trans i18nKey={`${T_PREFIX}.subtitle`} />
        </S.Subtitle>
        <StickyButtonContainer>
          <Button type="button" onClick={() => handleContinue('')}>
            <Trans i18nKey="actions.continue" />
          </Button>
        </StickyButtonContainer>
      </S.Container>
    </S.Wrapper>
  )
}
