import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledPurchaseMoneyBackGuaranteeV2 as S } from './PurchaseMoneyBackGuaranteeV2.styles'
import { CHECKOUT_METHODS, IMAGE_PATH } from './constants'

type TProps = {
  className?: string
}
export const PurchaseMoneyBackGuaranteeV2: React.FC<TProps> = ({
  className,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const moneyBackSrc = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}-${language}.png`,
    [language],
  )

  return (
    <S.GuaranteeContainer className={className}>
      <S.ImageContainer>
        <img src={moneyBackSrc} alt="money-back-guarantee" />
      </S.ImageContainer>
      <S.TextContainer>
        <S.Title>{t('purchase.moneyBackGuaranteeV3.title')}</S.Title>
        <S.Text>{t('purchase.moneyBackGuaranteeV3.text')}</S.Text>
      </S.TextContainer>
      <div>
        <S.CheckoutTitle>
          {t('purchase.moneyBackGuaranteeV3.checkoutTitle')}
        </S.CheckoutTitle>
        <S.CheckoutMethods>
          {CHECKOUT_METHODS.map((method) => (
            <img key={method} src={method} alt={method} />
          ))}
        </S.CheckoutMethods>
      </div>
    </S.GuaranteeContainer>
  )
}
