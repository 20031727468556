import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellIntroMedia = {
  Wrapper: styled.div`
    margin-bottom: 40px;
  `,
  PageTitle: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    color: ${Color.DISABLED};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;

    strong {
      font-weight: 700;
    }
  `,
  BeforeAfter: styled.div`
    display: flex;
    margin-bottom: 16px;
    position: relative;
    width: 100%;
  `,
  BeforeAfterImg: styled.div`
    aspect-ratio: 82 / 75;
    width: 100%;
  `,
  BeforeAfterInfo: styled.h4`
    background-color: ${Color.WHITE};
    border-radius: 8px;
    bottom: 12px;
    box-shadow: 0px 8px 20px 0px rgba(17, 30, 23, 0.1);
    left: 50%;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 10px;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: calc(100% - 16px);

    strong {
      color: ${Color.ORANGE};
      font-weight: 700;
    }
  `,
  ActionItems: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  `,
  ActionItemsInfo: styled.h3`
    color: ${Color.DISABLED};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  ActionItemsList: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  `,
  ActionItemImg: styled.div`
    aspect-ratio: 1 / 1;
    height: 44px;
    min-width: 44px;
  `,
  ActionItemInfo: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  ActionItemTitle: styled.h4`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 4px;
  `,
  ActionItemContent: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}

export const ActionItem = styled.li`
  display: flex;
  column-gap: 12px;

  &:not(:last-of-type) {
    ${StyledUpsellIntroMedia.ActionItemInfo} {
      border-bottom: 1px solid ${Color.GREY_WHITE};
      padding-bottom: 16px;
    }
  }
`
