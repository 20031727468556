import { combineReducers } from 'redux'

import { commonReducer } from 'root-redux/reducers/common'
import { userReducer } from 'root-redux/reducers/user'

import { loginReducer } from 'modules/login/redux'
import { paymentReducer } from 'modules/purchaseSubscription/redux/reducer'

import { IAppState } from 'models/store.model'

const rootReducer = combineReducers<IAppState>({
  common: commonReducer,
  user: userReducer,
  payment: paymentReducer,
  login: loginReducer,
})

// eslint-disable-next-line import/no-default-export
export default rootReducer
