import { Button } from 'storybook-ui'
import styled, { css } from 'styled-components'

import { Color, KIT_MAX_COLUMN_WIDTH, MediaBreakpoint } from 'root-constants'

import {
  DiscountTimer,
  PersonalizedPlan,
  PurchaseDisclaimer,
  VideoReviews,
} from '../lib/components'
import { PurchaseMoneyBackGuaranteeV2 } from './components'

const titleStyles = css`
  font-weight: 700;
  font-size: 24px;
  color: ${Color.GREY_100};
`

export const StyledKitPurchaseVariant1 = {
  Wrapper: styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 16px 40px;
    background-color: ${Color.GREY_700};
    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: ${KIT_MAX_COLUMN_WIDTH}px;
    }
  `,
  Section: styled.section`
    margin: 0 0 40px 0;
  `,
  Stack: styled.div`
    margin: 0 0 16px 0;
  `,
  DiscountTimer: styled(DiscountTimer)`
    position: sticky;
    top: 0;
    z-index: 100;
    margin: 0 auto 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 #e8e8e8;
  `,
  PersonalizedPlanV1: styled(PersonalizedPlan)`
    margin: 0 0 32px 0;
  `,
  Title: styled.h2`
    ${titleStyles};
    margin: 24px 0;

    &.v3 {
      margin: 16px 0;
    }
  `,
  DiscountTitle: styled.h2`
    ${titleStyles};
    margin: 0 0 16px 0;

    &.v3 {
      margin: 40px 0 16px 0;
    }
  `,
  ScannerPreview: styled.div`
    margin: 40px 0 24px;

    h3 {
      ${titleStyles};
      margin-bottom: 20px;
    }
  `,
  VideoReviews: styled(VideoReviews)`
    margin: 0 auto 40px;
  `,
  HowItWorksWrap: styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
  `,
  MoneyBackText: styled.p`
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: ${Color.GREY_101};
    text-decoration: underline;
    text-align: center;
    margin: 0 0 24px 0;
  `,
  Button: styled(Button)`
    text-transform: capitalize;

    &.v3 {
      margin: 0 auto;
    }
  `,
  DisclaimerV2: styled(PurchaseDisclaimer)`
    height: 86px;
    margin: 0 0 64px 0;
  `,
  MoneyBackGuaranteeV3: styled(PurchaseMoneyBackGuaranteeV2)`
    margin: 0 0 40px 0;

    &.v3 {
      margin: 40px 0 40px 0;
    }
  `,
}
