import React, { SyntheticEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'

import { CDN_FOLDER_LINK, Gender } from 'root-constants'

import { MEN_ANIMATION_PATH_V2, WOMEN_ANIMATION_PATH_V2 } from '../constants'
import { StyledIntroMedia as S } from './IntroMedia.styles'

type TProps = {
  onButtonClick: (event: SyntheticEvent<HTMLButtonElement>) => void
  elemForComparisonRef: React.RefObject<HTMLHeadingElement>
}

export const IntroMedia: React.FC<TProps> = ({
  onButtonClick,
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)
  const gender = useSelector(selectCurrentUserGender)

  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)
  const { buttonText } = useDynamicPaywallConfig()

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path:
          gender === Gender.FEMALE
            ? `${CDN_FOLDER_LINK}${WOMEN_ANIMATION_PATH_V2}`
            : `${CDN_FOLDER_LINK}${MEN_ANIMATION_PATH_V2}`,
        loop: true,
        name: 'resultAnimation',
      })
    }

    return () => lottie.destroy('resultAnimation')
  }, [gender])

  return (
    <S.Wrapper>
      <S.BeforeAfter ref={animationRef} />
      <S.TimerContainer isTimerHidden={isTargetHidden}>
        {isTargetHidden && (
          <S.TimerLabel>{t('purchase.subheaderText')}</S.TimerLabel>
        )}
        <S.Timer isHeaderTimer={isTargetHidden} hasIndent hasText />
        <S.Button isTimerHidden={isTargetHidden} onClick={onButtonClick}>
          {buttonText || t('actions.getMyPlan')}
        </S.Button>
      </S.TimerContainer>
    </S.Wrapper>
  )
}
