import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonTitleStyles = css`
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${Color.GREY_100};
`

export const StyledPaymentLoading = {
  Animation: styled.div`
    width: 80px;
    height: 80px;
  `,

  Title: styled.div`
    ${commonTitleStyles};
    color: ${Color.BLACK_100};
    font-size: 24px;
    line-height: 32px;
  `,
  Subtitle: styled.div`
    ${commonTitleStyles};
    color: ${Color.BLACK_300};
    opacity: 0.5;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `,
}
