import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { MediaBreakpoint } from 'root-constants'

const animation = keyframes`
    0% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const StyledCreatingPlanV3 = {
  Wrapper: styled.div`
    position: relative;
    padding: 8px 0 40px;
  `,
  Container: styled(Container)`
    max-width: 100%;
    padding: 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 8px;
    padding: 0 16px;
  `,
  Arrows: styled(SvgImage)`
    display: block;
    width: 12px;
    height: 12px;
    margin: 0 auto 458px;
  `,

  ChangeableContent: styled.div`
    position: absolute;
    top: 114px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    &[data-is-shown='true'] {
      animation: ${animation} 3000ms ease-in 1;
    }

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 360px;
    }
  `,

  Subtitle: styled.h2`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 312/274;
    max-width: 312px;
  `,
  ProgressbarContainer: styled.div`
    position: relative;
  `,
}
