import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnimatedSequenceView } from 'views/AnimatedSequenceView'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

export const T_PREFIX = 'onboarding.kitAiTechV1'

const useSelectors = () =>
  useBulkSelector({
    lng: selectLanguage,
    gender: selectCurrentUserGender,
  })

export const KitAiTechV1: React.FC<TPageProps> = (props) => {
  const { lng, gender } = useSelectors()
  const { t } = useTranslation()

  const animationPath = `${CDN_FOLDER_LINK}/animations/scanner-before-after-${gender}-${lng}.json`
  const previewPath = `${CDN_FOLDER_LINK}/images/scanner-before-after-preview-${gender}-${lng}.png`

  return (
    <AnimatedSequenceView
      title={t(`${T_PREFIX}.title`)}
      previewPath={previewPath}
      animationPath={animationPath}
      question={t(`${T_PREFIX}.title`, { lng: Language.EN })}
      {...props}
    />
  )
}
