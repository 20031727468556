import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { ModalGlobalStyle, StyledKitModalV1 as S } from './KitModal.styles'

type TProps = {
  children: React.ReactNode
  className?: string
  isCentered?: boolean
  onClose?: () => void
}

export const KitModalV1: React.FC<TProps> = ({
  children,
  className,
  isCentered,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const targetElemRef = useRef<HTMLDivElement>(null)
  const rootElement = document.getElementById('root') || document.body

  useLayoutEffect(() => {
    const previousFocus = document.activeElement as HTMLElement

    if (targetElemRef.current) {
      targetElemRef.current.focus()
    }

    return () => {
      if (previousFocus) {
        previousFocus.focus()
      }
    }
  }, [])

  useEffect(() => {
    setIsVisible(true)
    rootElement.style.overflow = 'hidden'

    return () => {
      setIsVisible(false)
      rootElement.style.overflow = ''
    }
  }, [rootElement])

  const modalElement = (
    <>
      <ModalGlobalStyle $isVisible={isVisible} />
      <S.Wrapper
        isVisible={isVisible}
        role="dialog"
        aria-modal="true"
        ref={targetElemRef}
        tabIndex={-1}
      >
        <S.Modal isCentered={isCentered} className={className}>
          {onClose && <S.CloseButton onClick={onClose} />}
          {children}
        </S.Modal>
      </S.Wrapper>
    </>
  )

  return createPortal(modalElement, rootElement)
}
