import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PaymentWaitingModal } from 'widgets/PaymentWaitingModal'

import { PriceValue } from 'components/PriceValue'
import { Spinner } from 'components/Spinner'
import { ThreeDSecureIframe } from 'components/ThreeDSecureIframe'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { useStripeButton } from 'hooks/useStripeButton'

import { selectIsCheckoutFlowShown } from 'modules/purchaseSubscription/redux/selects'

import svgSrc from 'assets/images/sprite/spinner-grey.svg'

import { PaymentMethodsV2 } from '../lib/components'
import { useCheckout } from '../lib/useCheckout'
import { StyledKitCheckoutModalV2 as S } from './KitCheckoutModalV2.styles'

export const KitCheckoutModalV2: React.FC = () => {
  const { t } = useTranslation()
  const isCheckoutFlowShown = useSelector(selectIsCheckoutFlowShown)
  const hasVatInfo = useVatInfo()
  const {
    isPaymentWaitingShown,
    trialCurrentPrice,
    threeDSecureIframeUrl,
    setIsPaymentWaitingShown,
    handleCloseCheckout,
  } = useCheckout()

  useStripeButton()

  if (threeDSecureIframeUrl) return <ThreeDSecureIframe />

  return (
    <>
      <S.KitModalV1 onClose={handleCloseCheckout}>
        <S.Container>
          <S.ModalTitle>
            <Trans i18nKey="kitCheckoutModalV1.modalTitle" />
          </S.ModalTitle>
          <S.Title>
            <Trans i18nKey="kitCheckoutModalV1.title" />
          </S.Title>
          <S.TotalRow>
            <S.TotalLabel>
              <Trans
                i18nKey="kitCheckoutModalV1.total"
                values={{
                  includingVat: hasVatInfo ? t('kitCheckoutModalV1.tax') : '',
                }}
              />
            </S.TotalLabel>
            <S.TotalAmount>
              <PriceValue value={trialCurrentPrice} />
            </S.TotalAmount>
          </S.TotalRow>
          <S.PaymentMethodsTitle>
            <Trans i18nKey="kitCheckoutModalV1.oneClickPaymentMethodTitle" />
          </S.PaymentMethodsTitle>
          {isCheckoutFlowShown ? (
            <PaymentMethodsV2 />
          ) : (
            <Spinner svgSrc={svgSrc} isFullScreen={false} />
          )}
        </S.Container>
      </S.KitModalV1>
      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
      />
    </>
  )
}
