import React from 'react'
import { Trans } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { OptionType } from 'components/Option'
import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import { StyledOptions as S } from 'styles/KitOptions.styles'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import {
  TAnswer,
  TBuilderOptionData,
  TBuilderOptionProps,
  TPageProps,
} from 'models/common.model'

type TProps = {
  title: string
  subtitle?: string
  question?: string
  buttonText?: string
  options?: TBuilderOptionData[]
  answers?: string[]
  isReverse?: boolean
  handleContinue?: (value: TAnswer) => void
} & TPageProps &
  Partial<TBuilderOptionProps>

export const KitRadioOptionsView: React.FC<TProps> = ({
  title,
  subtitle,
  question,
  options,
  isFirstPage,
  isReverse,
  name,
  pageId,
  onChange,
  handleContinue,
  nextPagePath,
}) => {
  const {
    isAnswersDisabled,
    handleChange: handleDelayChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const onContinue = useNextStep({
    pageId,
    question: question || title,
    nextPagePath,
    isFirstPage,
  })

  const continueHandler = handleContinue || onContinue

  const handleChange = (value: string) => {
    handleDelayChange(value, continueHandler)
    setIsAnswersDisabled(true)
  }

  const radioProps = {
    name: name || pageId,
    type: OptionType.RADIO,
    onChange: onChange || handleChange,
    theme: answerWithIconTheme.NUTRIMATE,
    borderRadius: '48px',
    margin: '0 0 12px 0',
    reverse: isReverse,
    disabled: isAnswersDisabled,
  }

  return (
    <KitPageContainer>
      <PageTitle marginBottom={subtitle ? 8 : 24} textAlign="left">
        {title}
      </PageTitle>
      {!!subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      {options?.map(({ value, imageUrl, text, textPath }) => {
        const size = imageUrl ? '40px' : undefined
        const spacing = imageUrl ? '16px' : '0 0 0 0'

        return (
          <AnswerWithIcon
            {...radioProps}
            key={value}
            value={value}
            spacingBetweenIconAndContent={spacing}
            iconSrc={imageUrl}
            iconHeight={size}
            iconWidth={size}
          >
            <S.AnswerTitle>
              {text || <Trans i18nKey={textPath} />}
            </S.AnswerTitle>
          </AnswerWithIcon>
        )
      })}
    </KitPageContainer>
  )
}
