import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { KitPageContainer } from 'components/PageContainer'
import { PageTitle } from 'components/PageTitle'

import {
  selectCurrentUserGender,
  selectLanguage,
} from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK, Language } from 'root-constants'

import { StyledSupport as S } from './Support.styles'
import { SUPPORT_IMAGES } from './constants'

export const SupportV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const gender = useSelector(selectCurrentUserGender)
  const language = useSelector(selectLanguage)

  const localePrefix = language === Language.ES ? language : Language.EN

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.supportV1.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <KitPageContainer
      hasContinueButton
      onContinueButtonClick={() => handleContinue('')}
    >
      <S.Banner>
        <img
          src={`${CDN_FOLDER_LINK}${SUPPORT_IMAGES[gender]}-${localePrefix}.png`}
          alt="banner"
        />
      </S.Banner>
      <PageTitle textAlign="left" marginBottom={16}>
        {t('onboarding.supportV1.title')}
      </PageTitle>
      <S.Subtitle>
        <Trans i18nKey="onboarding.supportV1.subtitle" />
      </S.Subtitle>
      <S.Info>
        <Trans i18nKey="onboarding.supportV1.info" />
      </S.Info>
    </KitPageContainer>
  )
}
