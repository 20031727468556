import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { OnboardingButtonText } from 'value-constants'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CustomerReviewsV4 } from 'components/CustomerReviews'
import { Disclaimer } from 'components/Disclaimer'
import { FAQList } from 'components/FAQList'
import { FeaturedBlock } from 'components/FeaturedBlock'
import { Spinner } from 'components/Spinner'

import {
  selectCurrentUserPrimaryGoal,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { TPageProps } from 'models/common.model'

import { Cohort, TOTAL_USERS } from 'root-constants'

import {
  IntroMediaV2,
  PurchaseMoneyBackGuarantee,
  PurchasePlanHighlights,
} from '../lib/components'
import { usePurchase } from '../lib/usePurchase'
import { StyledPurchaseV2 as S } from './PurchaseV2.styles'
import { PRIMARY_GOAL_MAP } from './constants'

const useSelectors = () =>
  useBulkSelector({
    primaryGoal: selectCurrentUserPrimaryGoal,
    cohortToUse: selectCurrentVariantCohortToUse,
  })

export const PurchaseV2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { primaryGoal, cohortToUse } = useSelectors()
  const {
    hasPrices,
    btnLabel,
    firstSubscriptionsRef,
    titleElementRef,
    selectedSubscription,
    handleGetPlan,
    handleIntroMediaButtonClick,
  } = usePurchase(nextPagePath)

  const titleProps =
    cohortToUse === Cohort.MF_MEN_FLOW
      ? {
          i18nKey: 'purchase.titleV2',
          values: {
            goal: t(PRIMARY_GOAL_MAP[primaryGoal]),
          },
        }
      : { i18nKey: 'purchase.title' }

  const photoResultTitle =
    cohortToUse === Cohort.MF_MEN_FLOW
      ? 'purchase.photoResult.titleV3'
      : 'purchase.photoResult.titleV2'

  if (!hasPrices) return <Spinner />

  return (
    <>
      <S.Wrapper>
        <S.ProgramPlanContainer>
          <S.Title>
            <Trans {...titleProps} />
          </S.Title>
          <S.Subtitle>
            <Trans
              i18nKey="purchase.subtitle"
              values={{ count: TOTAL_USERS }}
            />
          </S.Subtitle>
          <IntroMediaV2
            elemForComparisonRef={titleElementRef}
            data-button-number="1"
            data-button-text={OnboardingButtonText.GET_PLAN_UPPER}
            onButtonClick={handleIntroMediaButtonClick}
          />
          <PurchasePlanHighlights titleElementRef={titleElementRef} />
        </S.ProgramPlanContainer>
        <SubscriptionsBlock ref={firstSubscriptionsRef} titleMargin={16} />

        <S.Button
          type="button"
          data-button-number="2"
          data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
          onClick={handleGetPlan}
        >
          {btnLabel}
        </S.Button>
        {selectedSubscription && cohortToUse === Cohort.MF_MEN_FLOW && (
          <S.DisclaimerV2 selectedSubscription={selectedSubscription} />
        )}

        <FeaturedBlock />
        <PurchaseMoneyBackGuarantee />
        <S.SecurityInfo />
      </S.Wrapper>

      <S.CustomerReviewsContainer>
        <S.PhotoResult titlePath={photoResultTitle} />
        <FAQList />
        <CustomerReviewsV4 />
        <S.Button
          type="button"
          data-button-number="3"
          data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
          onClick={handleGetPlan}
        >
          {btnLabel}
        </S.Button>
        {selectedSubscription && cohortToUse !== Cohort.MF_MEN_FLOW && (
          <Disclaimer selectedSubscription={selectedSubscription} />
        )}
      </S.CustomerReviewsContainer>
    </>
  )
}
