import React from 'react'
import { Trans } from 'react-i18next'

import { TimerProvider } from 'contexts/TimerProvider'
import { buttonTheme } from 'storybook-ui'
import { OnboardingButtonText } from 'value-constants'
import { AdaptiveDiscountBlock } from 'widgets/AdaptiveDiscountBlock'
import { SubscriptionsBlock } from 'widgets/SubscriptionsBlock'

import { CaloriesComparison } from 'components/CaloriesComparison'
import { CustomerReviewsV6 } from 'components/CustomerReviews'
import { DynamicDiscountBanner } from 'components/DynamicDiscountBanner'
import { PresaleGraph } from 'components/PresaleGraph'
import { ScannerIntro } from 'components/ScannerIntro'
import { SecurityInfoV2 } from 'components/SecurityInfo'
import { Spinner } from 'components/Spinner'

import { TEN_MINUTES_IN_SECONDS } from 'modules/purchaseSubscription/constants'

import { TPageProps } from 'models/common.model'

import { Cohort } from 'root-constants'

import { StyledKitPurchaseVariant1 as S } from '../lib/KitPurchaseVariant1.styles'
import {
  AppReached,
  BoostResults,
  FastSaleBanner,
  NutrimateComparison,
  PurchaseDisclaimer,
  PurchaseFeaturedBlock,
  WeightLossJourney,
} from '../lib/components'
import { usePurchase } from '../lib/usePurchase'

const T_PREFIX = 'kitPurchaseV3'

export const KitPurchaseVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const {
    hasPrices,
    selectedSubscription,
    btnLabel,
    cohort,
    hasDynamicDiscountBanner,
    headerTimerRef,
    firstSubscriptionsRef,
    handleScrollOnSubscriptionBlock,
    handleGetPlan,
  } = usePurchase(nextPagePath)

  const isScannerCohort = cohort === Cohort.MF_SCANNER
  const titlePath = isScannerCohort ? 'titleV2' : 'title'
  const discountTitlePath = isScannerCohort
    ? 'discountTitleV2'
    : 'discountTitle'

  if (!hasPrices) return <Spinner />

  return (
    <>
      {hasDynamicDiscountBanner && <DynamicDiscountBanner />}
      <div>
        <TimerProvider id={pageId} time={TEN_MINUTES_IN_SECONDS}>
          <S.DiscountTimer
            elemRef={headerTimerRef}
            onButtonClick={handleScrollOnSubscriptionBlock}
          />
          <div>
            <S.Wrapper>
              <S.Title>
                <Trans i18nKey={`${T_PREFIX}.${titlePath}`} />
              </S.Title>
              <S.Stack>
                <PresaleGraph />
              </S.Stack>
              <S.PersonalizedPlanV1 withTitle={false} />
              <S.DiscountTitle className="v3">
                <Trans i18nKey={`${T_PREFIX}.${discountTitlePath}`} />
              </S.DiscountTitle>
              <AdaptiveDiscountBlock />
              <S.Stack>
                <SubscriptionsBlock
                  hasTitle={false}
                  ref={firstSubscriptionsRef}
                />
              </S.Stack>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="2"
                data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {btnLabel}
              </S.Button>
              {selectedSubscription && (
                <S.Section>
                  <PurchaseDisclaimer
                    selectedSubscription={selectedSubscription}
                  />
                </S.Section>
              )}
              <S.Section>
                <AppReached />
              </S.Section>
              <S.Title className="v3">
                <Trans i18nKey={`${T_PREFIX}.secondTitle`} />
              </S.Title>
              <FastSaleBanner />
              {isScannerCohort ? (
                <CaloriesComparison />
              ) : (
                <NutrimateComparison />
              )}
              <S.Button
                margin="24 auto 40px"
                width="100%"
                className="v3"
                data-button-number="3"
                data-button-text={OnboardingButtonText.GET_PLAN_MIDDLE_2}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleScrollOnSubscriptionBlock}
              >
                {btnLabel}
              </S.Button>
              <S.MoneyBackGuaranteeV3 className="v3" />
              {isScannerCohort ? (
                <S.ScannerPreview>
                  <h3>
                    <Trans i18nKey={`${T_PREFIX}.scannerTitle`} />
                  </h3>
                  <ScannerIntro videoProps={{ loop: true }} />
                </S.ScannerPreview>
              ) : null}
            </S.Wrapper>
            {!isScannerCohort ? (
              <S.VideoReviews title="kitPurchaseV1.videoReviewTitle" />
            ) : null}
            <S.Wrapper>
              <S.Section>
                <WeightLossJourney title="kitPurchaseV1.weightLossJourneyTitle" />
              </S.Section>
              <S.Section>
                <BoostResults />
              </S.Section>
              <CustomerReviewsV6 />
              <S.Section>
                <PurchaseFeaturedBlock />
              </S.Section>
              <S.DiscountTitle>
                <Trans i18nKey="purchase.discountTitle" />
              </S.DiscountTitle>
              <AdaptiveDiscountBlock />
              <S.Stack>
                <SubscriptionsBlock hasTitle={false} />
              </S.Stack>
              <S.MoneyBackText>
                <Trans i18nKey="purchase.moneyBackGuaranteeV3.description" />
              </S.MoneyBackText>
              <S.Button
                margin="0 auto 24px"
                width="100%"
                data-button-number="4"
                data-button-text={OnboardingButtonText.GET_PLAN_BOTTOM}
                theme={buttonTheme.NUTRIMATE_PRIMARY}
                onClick={handleGetPlan}
              >
                {btnLabel}
              </S.Button>
              {selectedSubscription && (
                <S.DisclaimerV2 selectedSubscription={selectedSubscription} />
              )}
              <SecurityInfoV2 />
            </S.Wrapper>
          </div>
        </TimerProvider>
      </div>
    </>
  )
}
