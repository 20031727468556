import React, { useMemo, useState } from 'react'

import { useI18nInterceptor } from 'contexts/I18nInterceptorProvider'

import { StyledTranslationKeysModal as S } from './TranslationKeysModal.styles'

export const TranslationKeysModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const { translations } = useI18nInterceptor()
  const entries = Array.from(translations.entries())

  const filteredTranslations = useMemo(() => {
    const query = searchQuery.trim().toLowerCase()

    if (!query) return entries

    return entries.filter(
      ([key, translation]) =>
        key.toLowerCase().includes(query) ||
        String(translation).toLowerCase().includes(query),
    )
  }, [entries, searchQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  if (!translations.size) return null

  return (
    <>
      {!isVisible && (
        <S.LoggerTrigger onClick={() => setIsVisible(true)}>🔑</S.LoggerTrigger>
      )}
      <S.LoggerPanel isVisible={isVisible}>
        <S.Header>
          <S.Title>Translation Keys</S.Title>
          <S.CloseButton onClick={() => setIsVisible(false)}>×</S.CloseButton>
        </S.Header>
        <S.SearchContainer>
          <S.SearchWrapper>
            <S.SearchInput
              type="text"
              placeholder="Search translations..."
              value={searchQuery}
              onChange={handleSearch}
              autoFocus
            />
          </S.SearchWrapper>
          <S.SearchInfo>
            Found: {filteredTranslations.length} of {translations.size}
          </S.SearchInfo>
        </S.SearchContainer>
        <S.Content>
          {filteredTranslations.length > 0 ? (
            filteredTranslations.map(([key, translation]) => (
              <S.TranslationItem key={key}>
                <S.TranslationKey>{key}</S.TranslationKey>
                <S.TranslationText>{translation}</S.TranslationText>
              </S.TranslationItem>
            ))
          ) : (
            <S.EmptyState>
              No translations found for &quot;{searchQuery}&quot;
            </S.EmptyState>
          )}
        </S.Content>
      </S.LoggerPanel>
    </>
  )
}
