import styled, { css } from 'styled-components'

import { Input } from 'components/Input'

import { Color } from 'root-constants'

export const StyledCurrentWeightV1 = {
  Actions: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `,
  InputWrapper: styled.div`
    position: relative;
    min-width: 160px;
  `,
  WeightInput: styled(Input)`
    padding: 8px 62px 8px 16px;
    border: 1px solid transparent;
    ${({ isValid, value }) =>
      value &&
      !isValid &&
      css`
        background: #fff2f2;
        border: 1px solid ${Color.DANGER};
        color: ${Color.DANGER};
      `};

    &:focus,
    &:active {
      & ~ span {
        display: none;
      }
    }
  `,
  Placeholder: styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 30px);
    height: 4px;
    width: 14px;
    background-color: ${Color.DISABLED};
    pointer-events: none;
  `,
  ErrorMessage: styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-top: 9px;
    color: ${Color.DANGER};

    & svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
  `,
  Suffix: styled.span`
    position: absolute;
    top: 18px;
    right: 16px;
    height: 30px;
    width: 42px;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DISABLED};
  `,
  WeightLabel: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 34px;
    margin: 0 auto 30px;
    border-radius: 25px;
    background-color: ${Color.PRIMARY};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  ActionsV2: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 24px;
  `,
  InfoContainer: styled.div<{ background: string }>`
    background: ${({ background }) => background};
    border-radius: 12px;
    display: flex;
    column-gap: 8px;
    padding: 16px;
  `,
  InfoImage: styled.div`
    aspect-ratio: 1 / 1;
    min-width: 24px;
    height: 24px;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  `,
  InfoTitle: styled.h4`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  InfoText: styled.p`
    font-size: 12px;
    font-weight: 40;
    line-height: 16px;
  `,
}
