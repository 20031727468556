import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'
import { AnyAction } from 'redux'

import { PaymentMethod } from 'modules/purchaseSubscription/constants'
import { IPrimerSubscriptionBackupConfig } from 'modules/purchaseSubscription/types'

import { ISubscription } from 'models/subscriptions.model'

import { PlanAddition } from 'root-constants'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG,
  SET_CHECKOUT_FLOW_SHOWN,
  SET_CHECKOUT_MODAL_SHOWN,
  SET_IS_FIRST_PAYMENT_RETRY_PASSED,
  SET_IS_PRIMER_PAY_PAL_RENDERED,
  SET_ONE_CLICK_PAYMENT_METHOD,
  SET_PAYMENT_CLIENT_SECRET,
  SET_PAYMENT_METHOD,
  SET_PLAN_ADDITIONS,
  SET_PRIMER_CLIENT_SESSION_TOKEN,
  SET_STRIPE_PAYMENT_REQUEST,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
  SET_TAX_AMOUNT,
  SET_TRIAL_PERIOD_DAYS,
} from './actions/common'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  subscriptionId: string
  planAdditions: PlanAddition[]
  taxAmount: number
  isCheckoutFlowShown: boolean
  isCheckoutModalShown: boolean
  oneClickPaymentMethod: PaymentMethod | null
  stripePaymentRequest: PaymentRequest | null
  paymentMethod: PaymentMethod
  isFirstPaymentRetryPassed: boolean
  primerClientSessionToken: string
  backupPrimerSubscriptionConfig: IPrimerSubscriptionBackupConfig | null
  isPrimerPaypalButtonRendered: boolean
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  subscriptionId: '',
  planAdditions: [],
  taxAmount: 0,
  isCheckoutFlowShown: false,
  isCheckoutModalShown: false,
  oneClickPaymentMethod: null,
  stripePaymentRequest: null,
  paymentMethod: PaymentMethod.CREDIT_CARD,
  isFirstPaymentRetryPassed: false,
  primerClientSessionToken: '',
  backupPrimerSubscriptionConfig: null,
  isPrimerPaypalButtonRendered: false,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_SUBSCRIPTION_ID: {
      return {
        ...state,
        subscriptionId: payload,
      }
    }
    case SET_PLAN_ADDITIONS: {
      return {
        ...state,
        planAdditions: payload,
      }
    }
    case SET_TAX_AMOUNT: {
      return {
        ...state,
        taxAmount: payload,
      }
    }
    case SET_ONE_CLICK_PAYMENT_METHOD: {
      return {
        ...state,
        oneClickPaymentMethod: payload,
      }
    }
    case SET_STRIPE_PAYMENT_REQUEST: {
      return {
        ...state,
        stripePaymentRequest: payload,
      }
    }
    case SET_CHECKOUT_FLOW_SHOWN: {
      return {
        ...state,
        isCheckoutFlowShown: payload,
      }
    }
    case SET_CHECKOUT_MODAL_SHOWN: {
      return {
        ...state,
        isCheckoutModalShown: payload,
      }
    }
    case SET_PRIMER_CLIENT_SESSION_TOKEN: {
      return {
        ...state,
        primerClientSessionToken: payload,
      }
    }
    case SET_BACKUP_PRIMER_SUBSCRIPTION_CONFIG: {
      return {
        ...state,
        backupPrimerSubscriptionConfig: payload,
      }
    }
    case SET_IS_FIRST_PAYMENT_RETRY_PASSED: {
      return {
        ...state,
        isFirstPaymentRetryPassed: payload,
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_IS_PRIMER_PAY_PAL_RENDERED: {
      return {
        ...state,
        isPrimerPaypalButtonRendered: payload,
      }
    }
    default:
      return state
  }
}
