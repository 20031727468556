import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const commonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
`

export const StyledCheckoutSummaryV2 = {
  DescriptionContainer: styled.div`
    ${commonContainerStyles};
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 16px 0;
  `,
  PlanLabel: styled.span`
    text-align: left;
    text-transform: capitalize;
  `,
  PlanPrice: styled.span<{ color?: string }>`
    white-space: nowrap;
    font-weight: 600;
    text-align: right;
    color: ${({ color }) => color || Color.GREY_100}};
  `,
  TotalContainer: styled.div<{ isComplex?: boolean }>`
    ${commonContainerStyles};
    font-size: ${({ isComplex }) => (isComplex ? 15 : 16)}px;
    line-height: 24px;
    padding: 16px 0 0 0;
    border-top: 1px solid ${Color.GREY_1000};
    margin: 0 0 4px 0;
  `,
  TotalLabel: styled.span`
    text-align: left;
    font-weight: 700;
  `,
  TotalPrice: styled.span`
    text-align: right;

    span {
      font-weight: 700;
    }
  `,
  SaveContainer: styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: ${Color.GREEN_300};
  `,
}
