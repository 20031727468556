import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSubscriptionIntroDifPercents } from 'modules/purchaseSubscription/redux/selects'

import { StyledDiscountBanner as S } from './DiscountBanner.styles'

export const DiscountBanner = () => {
  const discount = useSelector(selectSubscriptionIntroDifPercents)

  return (
    <S.Container>
      <S.Title>
        <Trans
          i18nKey="purchase.discountBannerV1.title"
          values={{
            discount,
          }}
        />
      </S.Title>
      <S.Timer>
        <Trans i18nKey="purchase.discountBannerV1.timerLabel" />
        <S.TimerValue />
      </S.Timer>
    </S.Container>
  )
}
