import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CUSTOMER_REVIEWS_V5 } from '../constants'
import { StyledCustomerReviewsV5 as S } from './CustomerReviewsV5.styles'

type TProps = {
  className?: string
  title?: string
}

export const CustomerReviewsV5: React.FC<TProps> = ({ className, title }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      {title ? (
        <S.Title>
          <Trans i18nKey={title} />
        </S.Title>
      ) : null}

      {CUSTOMER_REVIEWS_V5.map((review, index) => (
        <S.ReviewCard key={review.authorName}>
          {index === 0 && (
            <S.CardTitle>
              <Trans i18nKey="purchase.customerReviewsV5.customerReviews" />
            </S.CardTitle>
          )}
          <S.CardContent>
            <S.Author>
              <S.AuthorImg>
                <img src={review.authorImg} alt={t(review.authorName)} />
              </S.AuthorImg>
              <S.AuthorName>
                <S.Name>
                  <Trans i18nKey={review.authorName} />
                </S.Name>
                <S.Recommendation>
                  <Trans i18nKey="purchase.customerReviewsV5.recommends" />
                </S.Recommendation>
              </S.AuthorName>
            </S.Author>
            <S.Text>
              <Trans i18nKey={review.reviewText} />
            </S.Text>
          </S.CardContent>
        </S.ReviewCard>
      ))}
    </div>
  )
}
