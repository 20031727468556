import React, { useRef } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useTimerEffect } from 'contexts/TimerProvider'

import { getDecoratedTimerValue } from 'modules/purchaseSubscription/helpers/rootHelpers'
import { selectSubscriptionPaywallDiscountPercent } from 'modules/purchaseSubscription/redux/selects'

import { DEFAULT_TIMER_CONTENT } from 'root-constants'

import { StyledFastSaleTimer as S } from './FastSaleBanner.styles'

export const FastSaleBanner = () => {
  const discount = useSelector(selectSubscriptionPaywallDiscountPercent)
  const timerMinutesRef = useRef<HTMLSpanElement>(null)
  const timerSecondsRef = useRef<HTMLSpanElement>(null)

  useTimerEffect((time) => {
    if (timerMinutesRef.current && timerSecondsRef.current) {
      const { minutes, seconds } = getDecoratedTimerValue(time, false)
      timerMinutesRef.current.innerText = minutes
      timerSecondsRef.current.innerText = seconds
    }
  })

  return (
    <S.Wrapper>
      <S.TimerLabel>
        <Trans
          i18nKey="kitPurchaseV3.fastTimerTitle"
          values={{ value: discount }}
        />
      </S.TimerLabel>
      <S.TimerValue>
        <span ref={timerMinutesRef}>{DEFAULT_TIMER_CONTENT}</span>:
        <span ref={timerSecondsRef}>{DEFAULT_TIMER_CONTENT}</span>
      </S.TimerValue>
    </S.Wrapper>
  )
}
