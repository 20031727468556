import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPaymentError = {
  Title: styled.div`
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: ${Color.BLACK_100};
    font-size: 24px;
    line-height: 32px;
  `,
  Subtitle: styled.div`
    text-align: center;
    color: ${Color.BLACK_300};
    opacity: 0.5;
    font-weight: 400;
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
  `,
}
