import React from 'react'
import { Trans } from 'react-i18next'

import { TOTAL_USERS } from 'root-constants'

import { StyledAppReached as S } from './AppReached.styles'

type TProps = {
  className?: string
  children?: React.ReactNode
}
export const AppReached: React.FC<TProps> = ({ className, children }) => {
  return (
    <S.Container className={className}>
      <S.TextContainer>
        <S.Title>
          <Trans
            i18nKey="purchase.appReached.titleV1"
            values={{ count: TOTAL_USERS }}
          />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="purchase.appReached.subtitleV1" />
        </S.Subtitle>
      </S.TextContainer>
      {children ? <S.Children>{children}</S.Children> : null}
    </S.Container>
  )
}
