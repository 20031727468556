import styled from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  isVisible: boolean
}

export const StyledCheckout = {
  Wrapper: styled.div<TWrapperProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    margin: 32px auto 0;
    max-width: 360px;
    padding: 0 0 24px;
    text-align: center;
  `,
  Title: styled.h2`
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `,
  Discount: styled.span`
    font-weight: 700;
    color: ${Color.ORANGE};
  `,
  PaymentContainer: styled.div`
    margin-top: 32px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 24px;
  `,
  Image: styled.img`
    margin: 32px auto 23px;
    max-width: 256px;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(19, 29, 48, 0.5);
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 56px;
    right: 16px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
    opacity: 0.3;
  `,
}
