import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useElements, useStripe } from '@stripe/react-stripe-js'
import { usePageInfo } from 'contexts/PageInfoProvider'

import { resetErrorAction } from 'root-redux/actions/common'
import { selectLanguage } from 'root-redux/selects/common'

import { usePaymentStore } from 'hooks/usePaymentStore'

import {
  LANGUAGE_STRIPE_LOCALE_MAP,
  PaymentMethod,
  PurchaseProduct,
  ZERO_DECIMAL_CURRENCIES,
} from 'modules/purchaseSubscription/constants'
import {
  purchaseAction,
  setCheckoutFlowShownAction,
  setOneClickPaymentMethodAction,
  setStripePaymentRequestAction,
} from 'modules/purchaseSubscription/redux/actions/common'

import { CENTS_IN_DOLLAR } from 'root-constants'

export const useStripeButton = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const language = useSelector(selectLanguage)

  const {
    currency,
    periodQuantity,
    periodName,
    fullPrice,
    trialCurrentPrice,
    product,
    trialPeriodDays,
  } = usePaymentStore()

  const { currentPageId } = usePageInfo()

  const planPeriodDescription = t('purchase.checkoutV1.planDescription', {
    periodQuantity,
    periodName,
  })

  const multiplier = ZERO_DECIMAL_CURRENCIES.includes(currency)
    ? 1
    : CENTS_IN_DOLLAR
  const calculatedPrice = +(
    (trialCurrentPrice || fullPrice) * multiplier
  ).toFixed()

  const label =
    product === PurchaseProduct.SEVEN_DAY
      ? t('purchase.subscription.weeklyPeriod', {
          periodQuantity: trialPeriodDays,
        })
      : t('purchase.subscription.monthlyPeriod', {
          periodQuantity,
          count: periodQuantity,
        })

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    dispatch(setCheckoutFlowShownAction(false))
    dispatch(setStripePaymentRequestAction(null))
    dispatch(setOneClickPaymentMethodAction(''))

    const pr = stripe?.paymentRequest({
      currency,
      country: LANGUAGE_STRIPE_LOCALE_MAP[language],
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: label.toLowerCase(),
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        dispatch(setStripePaymentRequestAction(pr))
        dispatch(
          setOneClickPaymentMethodAction(
            result?.applePay
              ? PaymentMethod.APPLE_PAY
              : PaymentMethod.GOOGLE_PAY,
          ),
        )
      }
      dispatch(setCheckoutFlowShownAction(true))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    language,
    label,
  ])
}
