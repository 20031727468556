import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePageInfo } from 'contexts/PageInfoProvider'

import { Spinner } from 'components/Spinner'

import {
  GET_SUBSCRIPTION_LIST,
  getSubscriptionListAction,
  setHasHeaderSkipButton,
  setHeaderSkipButtonCallback,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectActionList } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'
import { useVatInfo } from 'hooks/useHasVatInfo'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchaseSubscription/redux/actions/upsell'
import { selectSubscription } from 'modules/purchaseSubscription/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  Gender,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

import {
  Steps,
  UpsellDiscountBanner,
  UpsellDiscountModalV1,
  UpsellFeedbacksV1,
  UpsellIntroMedia,
  UpsellPhotoResult,
  UpsellSubscriptionBlockV2,
  WhatYouGet,
} from '../lib/components'
import { StyledUpsellPaywallV2 as S } from './UpsellPaywallV2.styles'

const discountModalDefaultState = {
  isVisible: false,
  isPreviouslyOpened: false,
}

type TDiscountModal = {
  isVisible: boolean
  isPreviouslyOpened: boolean
}

export const UpsellPaywallV2: React.FC<TPageProps> = () => {
  const dispatch: TAppDispatch = useDispatch()

  const [discountModal, setDiscountModal] = useState<TDiscountModal>(
    discountModalDefaultState,
  )
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)

  const hasVatInfo = useVatInfo()
  const { currentPageId, activeUpsellPageIds } = usePageInfo()
  const { isAlternativeUpsell } = useExperimentalFeatures()
  const viewportValue = useViewportValue()
  const hasDiscount = discountModal.isPreviouslyOpened

  const screenName = hasDiscount
    ? ScreenName.UPSELL_COACHING_CANCEL
    : ScreenName.UPSELL_COACHING

  const isUpsellInProgress = fetchingActionsList?.includes(MAKE_UPSELL)
  const arePricesReady = !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST)

  useEffect(() => {
    eventLogger.logUpsellCoachingPurchaseShown(ScreenName.UPSELL_COACHING)
  }, [])

  useEffect(() => {
    if (
      viewportValue > ViewportScrollPercentage.V_0 &&
      !discountModal.isVisible &&
      screenName === ScreenName.UPSELL_COACHING
    ) {
      eventLogger.logUpsellCoachingPageScrolled(viewportValue, screenName)
    }
  }, [viewportValue, screenName, discountModal.isVisible])

  const handleSkip = useCallback(() => {
    eventLogger.logUpsellCoachingPurchaseClosed(screenName)

    setDiscountModal((prevState) => {
      if (!prevState.isPreviouslyOpened && !isAlternativeUpsell) {
        return {
          isVisible: true,
          isPreviouslyOpened: true,
        }
      }

      return {
        isVisible: false,
        isPreviouslyOpened: true,
      }
    })
  }, [isAlternativeUpsell, screenName])

  useEffect(() => {
    dispatch(setHasHeaderSkipButton(true))
    dispatch(setHeaderSkipButtonCallback(handleSkip))

    return () => {
      dispatch(setHasHeaderSkipButton(false))
      dispatch(setHeaderSkipButtonCallback())
    }
  }, [dispatch, handleSkip])

  const handleCloseModal = useCallback(() => {
    const tags = [
      hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      SubscriptionTags.UPSELL_LONG,
      SubscriptionTags.CANCEL_OFFER,
    ].join(',')

    dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))

    setDiscountModal({
      isVisible: false,
      isPreviouslyOpened: true,
    })

    eventLogger.logUpsellCoachingPurchaseShown(
      ScreenName.UPSELL_COACHING_CANCEL,
    )
  }, [dispatch, setDiscountModal, hasVatInfo])

  const handleMakeUpsell = () => {
    dispatch(
      sendUserConfigAction({
        available_upsell_pages: activeUpsellPageIds.filter(
          (page) => page !== currentPageId,
        ),
      }),
    )
    dispatch(makeUpsellAction(screenName))
  }

  return (
    <>
      {arePricesReady && (
        <S.PageContainer>
          {hasDiscount && <UpsellDiscountBanner />}
          <UpsellIntroMedia />
          <UpsellSubscriptionBlockV2
            buttonNumber={1}
            screenName={screenName}
            selectedSubscription={selectedSubscription}
            hasDiscount={hasDiscount}
            makeUpsell={handleMakeUpsell}
          />

          {!hasDiscount && (
            <>
              <WhatYouGet />
              <Steps />
              <UpsellPhotoResult
                titleI18nKey="upsellV2.photoResultTitle"
                gender={Gender.FEMALE}
              />
              <UpsellFeedbacksV1 />
              <UpsellIntroMedia titleI18nKey="upsellV2.titleV2" />
              <UpsellSubscriptionBlockV2
                buttonNumber={2}
                screenName={screenName}
                selectedSubscription={selectedSubscription}
                makeUpsell={handleMakeUpsell}
              />
              <S.SecurityInfo topIndent={32} />
            </>
          )}

          {discountModal.isVisible && (
            <UpsellDiscountModalV1 onClose={handleCloseModal} />
          )}
        </S.PageContainer>
      )}
      {(isUpsellInProgress || !arePricesReady) && <Spinner />}
    </>
  )
}
