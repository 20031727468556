const T_PREFIX = 'onboarding.energyValueV1.options'

export const ENERGY_VALUE_V1_OPTIONS = [
  {
    value: 'yes',
    textPath: `${T_PREFIX}.yes`,
  },
  {
    value: 'read',
    textPath: `${T_PREFIX}.read`,
  },
  {
    value: 'no',
    textPath: `${T_PREFIX}.no`,
  },
]
