import { SyntheticEvent, useEffect, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohortToUse,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useExperimentalFeatures } from 'hooks/useExperimentalFeatures'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { usePayment } from 'hooks/usePayment'
import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { createProductId } from 'helpers/createProductId'

import { useDynamicPaywallConfig } from 'modules/purchaseSubscription/hooks/useDynamicPaywallConfig'
import { setCheckoutModalShownAction } from 'modules/purchaseSubscription/redux/actions/common'
import { selectSubscription } from 'modules/purchaseSubscription/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTags,
} from 'root-constants'

export const PURCHASE_OFFSET_TOP = 150

const useSelectors = () =>
  useBulkSelector({
    subscriptions: selectSubscriptionList,
    cohort: selectCurrentVariantCohortToUse,
    selectedSubscription: selectSubscription,
  })

export const usePurchase = (nextPagePath: string) => {
  const firstSubscriptionsRef = useRef<HTMLDivElement>(null)
  const headerTimerRef = useRef<HTMLDivElement>(null)
  const titleElementRef = useRef<HTMLHeadingElement>(null)

  const dispatch: TAppDispatch = useDispatch()
  const hasVatInfo = useVatInfo()
  const viewportValue = useViewportValue()
  const { t } = useTranslation()
  const { buttonText, title, subtitle } = useDynamicPaywallConfig()
  const { isAlternativeCheckoutVariant } = useExperimentalFeatures()
  const { subscriptions, cohort, selectedSubscription } = useSelectors()
  const { hasPrices, handleShowPayment, handleAmplitudePaymentEvents } =
    usePayment()

  const btnLabel = buttonText || t('actions.getMyPlan')
  const hasDynamicDiscountBanner = cohort === Cohort.MF_INTRO_SALE

  const resolveCorrectRoute = () => {
    if (!isAlternativeCheckoutVariant) {
      goTo(nextPagePath)
    }

    if (isAlternativeCheckoutVariant) {
      dispatch(setCheckoutModalShownAction(true))
    }
  }

  const handleGetPlan = (event: SyntheticEvent<HTMLButtonElement>) => {
    handleShowPayment()
    handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
    resolveCorrectRoute()
  }

  const handleScrollOnSubscriptionBlock = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const top = firstSubscriptionsRef.current?.offsetTop
    const headerTimerHeight = headerTimerRef.current?.offsetHeight

    if (top && headerTimerHeight) {
      document.body.scrollTo(0, top - headerTimerHeight)
    }

    handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
  }

  const handleIntroMediaButtonClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const top = firstSubscriptionsRef.current?.offsetTop

    if (top) document.body.scrollTo(0, top - PURCHASE_OFFSET_TOP)
    handleAmplitudePaymentEvents(event.target as HTMLButtonElement)
  }

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.ONBOARDING))

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        hasVatInfo ? SubscriptionTags.TAX : SubscriptionTags.NO_TAX,
      ),
    )
  }, [dispatch, hasVatInfo])

  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) =>
        createProductId({
          periodName: mainPrices.periodName,
          periodQuantity: mainPrices.periodQuantity,
          price: mainPrices.fullPrice,
        }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptions])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logScrollToBottom(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  return {
    hasPrices,
    selectedSubscription,
    hasDynamicDiscountBanner,
    btnLabel,
    title,
    subtitle,
    cohort,
    headerTimerRef,
    titleElementRef,
    firstSubscriptionsRef,
    handleScrollOnSubscriptionBlock,
    handleIntroMediaButtonClick,
    handleGetPlan,
  }
}
