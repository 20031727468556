import styled from 'styled-components'

import { Button } from 'components/Button'
import { Disclaimer } from 'components/Disclaimer'
import { PhotoResult } from 'components/PhotoResult'
import { PlanHighlights } from 'components/PlanHighlights'
import { SecurityInfo } from 'components/SecurityInfo'

import purpleLine from 'assets/images/purple-line.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseV3 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  Disclaimer: styled(Disclaimer)`
    margin-bottom: 28px;
  `,
  PlanHighlights: styled(PlanHighlights)`
    padding: 0;
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    margin: 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.GREY_100};
  `,
  Subtitle: styled.h2`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.GREY_100};
    padding-bottom: 24px;

    strong {
      font-weight: 700;
      position: relative;
      color: ${Color.GREY_100};

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -12px;
        width: 100px;
        height: 25px;
        background-image: url(${purpleLine});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px 23px;
      }
    }
  `,
  ProgramPlanContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SecurityInfo: styled(SecurityInfo)`
    margin-bottom: 32px;
    padding: 50px 20px 20px;
    background-color: ${Color.WHITE};
    box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
    border-radius: 14px;

    h2 {
      padding-bottom: 10px;
    }

    p {
      padding-bottom: 20px;
      font-weight: normal;
      font-size: 14px;
    }

    img {
      background: ${Color.WHITE};
      box-shadow: 0 4px 12px -2px rgba(59, 59, 62, 0.16);
      padding: 3px 26px;
      border-radius: 14px;
      position: absolute;
      top: -30px;
      left: 27%;
      max-width: 156px;
    }
  `,
  SecondaryWrapper: styled.div`
    width: 100%;
  `,
  CustomerReviewsContainer: styled.div`
    background-color: ${Color.LIGHT};
    padding: 16px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  PhotoResult: styled(PhotoResult)`
    & > div:first-of-type {
      border-radius: 12px;
    }
  `,
  Button: styled(Button)`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  `,
}
