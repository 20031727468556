import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SupportActionLink } from 'components/SupportActionLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectCurrentVariantCohortToUse } from 'root-redux/selects/common'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCurrencyOptions } from 'modules/purchaseSubscription/helpers/getCurrencyOptions'
import { selectCurrency } from 'modules/purchaseSubscription/redux/selects'
import { TSubscriptionItemProps } from 'modules/purchaseSubscription/types'

import { Cohort } from 'root-constants'

import { StyledDisclaimer as S } from './Disclaimer.styles'
import { SUBSCRIPTION_DESCRIPTIONS, TRIAL_DISCLAIMER } from './constants'

type TProps = {
  className?: string
  isTrialOnly?: boolean
  selectedSubscription: TSubscriptionItemProps['subscription']
}

const useSelectors = () =>
  useBulkSelector({
    cohort: selectCurrentVariantCohortToUse,
    currency: selectCurrency,
  })

export const Disclaimer: React.FC<TProps> = ({
  className,
  isTrialOnly = false,
  selectedSubscription,
}) => {
  const { product, mainPrices } = selectedSubscription

  const { t } = useTranslation()
  const { cohort, currency } = useSelectors()
  const hasVatInfo = useVatInfo()

  const disclaimerTransKey = isTrialOnly
    ? TRIAL_DISCLAIMER[product]
    : (SUBSCRIPTION_DESCRIPTIONS[cohort] ||
        SUBSCRIPTION_DESCRIPTIONS[Cohort.MF_INTRO])[product]

  const currencyOptions = getCurrencyOptions(currency)

  return (
    <S.Disclaimer className={className}>
      <Trans
        i18nKey={disclaimerTransKey}
        components={{ span: <span /> }}
        values={{
          price: mainPrices.fullPrice,
          includingVatUsd: hasVatInfo ? t('purchase.includingVat') : '',
          currency,
          ...currencyOptions,
        }}
      />
      <TermsOfUseLink /> {t('purchase.contactUs')} <SupportActionLink />
    </S.Disclaimer>
  )
}
