import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OPTIONS_V1 } from 'pages/bodyType/v1/constants'

import { AnswerButton, IconVerticalAlignments } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option, OptionType } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { selectCurrentUserGender } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Language } from 'root-constants'

const T_PREFIX = 'onboarding.bodyType'

export const BodyTypeV1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userGender = useSelector(selectCurrentUserGender)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${T_PREFIX}.title`, { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <Container>
      <PageTitle marginBottom={24}>
        <Trans i18nKey={`${T_PREFIX}.title`} />
      </PageTitle>
      {OPTIONS_V1[userGender].map(({ value, icon, title }) => (
        <Option {...optionProps} disabled={isAnswersDisabled} value={value}>
          <AnswerButton
            iconSrc={icon}
            iconWidth={92}
            iconHeight={92}
            iconVerticalAlignment={IconVerticalAlignments.CENTER}
          >
            <h3>
              <Trans i18nKey={title} />
            </h3>
          </AnswerButton>
        </Option>
      ))}
    </Container>
  )
}
