import React from 'react'
import { useTranslation } from 'react-i18next'

import { KIT_IDEAL_WEIGHT_TIME_OPTIONS } from 'pages/questions/IdealWeightTime/constants'
import { KitRadioOptionsView } from 'views/KitRadioOptionsView'

import { TPageProps } from 'models/common.model'

import { CustomPageId } from 'page-constants'
import { Language } from 'root-constants'

const TITLE_PATH = 'onboarding.idealWeightTime.title'

export const KitIdealWeightTime: React.FC<TPageProps> = (props) => {
  const { t } = useTranslation()
  const title = t(TITLE_PATH)
  const question = t(TITLE_PATH, { lng: Language.EN })

  return (
    <KitRadioOptionsView
      title={title}
      options={KIT_IDEAL_WEIGHT_TIME_OPTIONS}
      question={question}
      isReverse={false}
      {...props}
      pageId={CustomPageId.IDEAL_WEIGHT_TIME}
    />
  )
}
