import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { LOST_WEIGHT_PER_WEEK_MAP } from 'map-constants'

import {
  selectCurrentUserCurrentWeight,
  selectCurrentUserGoalWeight,
  selectUserMeasurementSystem,
} from 'root-redux/selects/common'

import { useUsersBmi } from 'hooks/useUsersBmi'

import { Period } from 'modules/purchaseSubscription/constants'

const weekDays = 7

export const usePredictableData = () => {
  const goalWeight = useSelector(selectCurrentUserGoalWeight)
  const currentWeight = useSelector(selectCurrentUserCurrentWeight)
  const measurementSystem = useSelector(selectUserMeasurementSystem)

  const {
    currentBMI: {
      bmiValue: { value },
    },
  } = useUsersBmi()

  const lostWeightPerDay = Number(
    (
      LOST_WEIGHT_PER_WEEK_MAP[value][measurementSystem] / Period.SEVEN_DAYS
    ).toFixed(2),
  )

  const realPredictableDate = (() => {
    const countOfDay = Number(
      ((currentWeight - goalWeight) / lostWeightPerDay).toFixed(2),
    )

    return dayjs().add(countOfDay, 'day')
  })()

  const hasWeekEarlyPredictableDate = !(
    realPredictableDate.isBefore(dayjs()) ||
    Number(realPredictableDate.diff(dayjs(), 'day', true).toFixed(1)) <=
      weekDays
  )

  const weekEarlyPredictableDate = realPredictableDate.subtract(weekDays, 'day')

  const daysToGoalWeight = Math.round(
    Number(((currentWeight - goalWeight) / lostWeightPerDay).toFixed(2)),
  )

  return {
    predictableDate: hasWeekEarlyPredictableDate
      ? weekEarlyPredictableDate
      : realPredictableDate,
    hasWeekEarlyPredictableDate,
    realPredictableDate,
    daysToGoalWeight,
    earlyDaysToGoalWeight: hasWeekEarlyPredictableDate
      ? daysToGoalWeight - weekDays
      : daysToGoalWeight,
  }
}
