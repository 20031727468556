import React, { ButtonHTMLAttributes } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OnboardingButtonText } from 'value-constants'

import { selectLanguage } from 'root-redux/selects/common'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import giftImg from 'assets/images/gift-2.webp'

import { Language } from 'root-constants'

import { StyledFloatingCTABanner as S } from './FloatingCTABanner.styles'

type TBtnProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  'data-button-number'?: string
  'data-button-text'?: string
}

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLElement>
  btnProps: TBtnProps
  buttonText?: string
}

const fontSizes = {
  [Language.FR]: 14,
  [Language.DE]: 15,
}

export const FloatingCTABanner: React.FC<IProps> = ({
  elemForComparisonRef,
  buttonText = '',
  btnProps,
}) => {
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)
  const lng = useSelector(selectLanguage)
  const fontSize = fontSizes[lng] || 17

  return (
    <S.Container data-is-visible={isTargetHidden}>
      <S.Content>
        <img src={giftImg} alt="gift" />
        <S.Button
          $fontSize={fontSize}
          data-button-number="1"
          data-button-text={OnboardingButtonText.GIFT_CANCEL_OFFER}
          {...btnProps}
        >
          {buttonText || <Trans i18nKey="actions.getMyPlan" />}
        </S.Button>
      </S.Content>
    </S.Container>
  )
}
