import React from 'react'
import { Trans } from 'react-i18next'

import { Button } from 'storybook-ui'

import { Color } from 'root-constants'

import { StyledPaymentSuccess as S } from './PaymentSuccess.styles'

type TProps = {
  onClick: () => void
}

export const PaymentSuccess: React.FC<TProps> = ({ onClick }) => (
  <>
    <S.Title>
      <Trans i18nKey="purchase.paymentWaiting.paymentWasSuccessful" />
    </S.Title>
    <Button
      width="100%"
      backgroundColor={Color.GREEN_400}
      color={Color.WHITE}
      fontSize="17px"
      fontWeight="700"
      lineHeight="24px"
      borderRadius="30px"
      padding="16px"
      onClick={onClick}
    >
      <Trans i18nKey="purchase.paymentWaiting.toLoginPage" />
    </Button>
  </>
)
