import styled from 'styled-components'

import lockIcon from 'assets/images/protected-payment-icon.svg'

import { Color } from 'root-constants'

export const StyledCreditCardHeader = {
  TitleContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
  `,
  PaymentInfo: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
  `,
  Label: styled.span`
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${Color.GREY_500};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      width: 12px;
      height: 14px;
      background: url(${lockIcon}) no-repeat;
      background-size: contain;
    }
  `,
}
