import { FastingValue } from 'value-constants'

import glassesIcon from 'assets/images/glasses-face.png'
import happyIcon from 'assets/images/happy-face.png'
import smileFaceIcon from 'assets/images/smile-face.png'

export const TRANSLATION_FAMILY = 'onboarding.fastingV1'
export const OPTIONS = [
  {
    key: 'nothing',
    value: FastingValue.NOTHING,
    iconSrc: smileFaceIcon,
  },
  {
    key: 'heard',
    value: FastingValue.HEARD,
    iconSrc: happyIcon,
  },
  {
    key: 'experienced',
    value: FastingValue.EXPERIENCED,
    iconSrc: glassesIcon,
  },
]

export const OPTIONS_V2 = [
  {
    value: 'no',
    textPath: 'onboarding.fastingV1.options2.no',
  },
  {
    value: 'try_but_quit',
    textPath: 'onboarding.fastingV1.options2.tryButQuit',
  },
  {
    value: 'yes_still_fasting',
    textPath: 'onboarding.fastingV1.options2.yesStillFasting',
  },
]
